import { useNavigate } from "react-router-dom";
import { useAuth } from "src/contexts/auth-provider";

const TableOrderMOdal = ({ tableOrderDetails, isShowTableOrderModal }: any) => {
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(
    tableOrderDetails.tableNumber
  ).toString();
  const { hasRestaurant } = useAuth();

  return (
    <>
      <div
        className="relative z-10"
        aria-labelledby="modal-title"
        role="dialog"
        aria-modal="true"
      >
        <div className="fixed inset-0 flex bg-black/30 min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <div className="relative transform rounded-lg bg-white text-left shadow-[0px_4px_0px_880px_rgba(37,37,37,0.75)] transition-all sm:my-8">
            <div className="flex items-center justify-center bg-gray-100">
              <div className="flex">
                <div className="bg-white flex flex-col items-center px-8 py-2 rounded-xl mx-2 ">
                  <div className="mt-4">
                    <h1 className="text-xl text-center">
                      {tableOrderDetails.type === "update"
                        ? "Update"
                        : "Create"}{" "}
                      Order
                    </h1>
                  </div>
                  <div className="flex gap-3 mt-6">
                    <div className="flex justify-center my-4">
                      <button
                        className="w-[100%] px-8 text-xs flex justify-center items-center gap-2 text-black h-[34px] rounded-xl bg-[#F5F5F6] hover:bg-orange hover:text-white duration-500"
                        onClick={isShowTableOrderModal}
                      >
                        Cancel
                      </button>
                    </div>

                    <div className="flex justify-center my-4">
                      <button
                        className="w-[100%] px-8 text-xs flex justify-center items-center gap-2 text-white h-[34px] rounded-xl bg-orange hover:bg-white hover:text-orange duration-500"
                        onClick={() =>
                          navigate(
                            `/create-new-order${"?restaurantId="}${
                              hasRestaurant?.correlationId
                            }${"?tableId/"}${queryParams}`,
                            { state: { TableOrderdata: tableOrderDetails } }
                          )
                        }
                      >
                        {tableOrderDetails.type === "update"
                          ? "Update"
                          : "Continue"}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TableOrderMOdal;
