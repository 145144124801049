import CrossIcon from "../../assests/images/dashboard/Vector 5.png";
import ArrowUpIcon from "../../assests/images/dashboard/Container 3.png";
import ArrowDownIcon from "../../assests/images/dashboard/Rating container.png";
import DownloadIcon from "../../assests/images/dashboard/download.png";
import ShareIcon from "../../assests/images/dashboard/Vector 6.png";
import { formatDateTime } from "src/utils/date";
import { useAuth } from "src/contexts/auth-provider";

export const OrdersDetails = ({
  setShowCreateModal,
  orderDetails,
  manageOrderCardDetail,
  updateOrder,
}: any) => {
  const { user, hasRestaurant } = useAuth();

  const handleSelectChange = (event: any) => {
    const selectedStatus = event.target.value;
    updateUserOrder(selectedStatus);
  };

  const updateUserOrder = async (selectedStatus: any) => {
    const variables = {
      updateCustomerOrderInput: {
        appUserCorrelationId: user?.storeUser?.correlationId,
        storeCorrelationId: hasRestaurant?.correlationId,
        customerOrderCorrelationId: orderDetails?.correlationId,
        orderStatus: selectedStatus,
      },
    };
    updateOrder(variables);
  };

  return (
    <>
      <div
        className="relative z-10"
        aria-labelledby="modal-title"
        role="dialog"
        aria-modal="true"
      >
        <div className="fixed inset-0 flex bg-black/30 h-full justify-end p-4 text-center sm:items-center sm:p-0">
          <div className="relative transform h-full w-[40%] overflow-hidden bg-white text-left shadow-[0px_4px_0px_880px_rgba(37,37,37,0.75)] transition-all sm:my-8">
            <div className="p-8">
              <div className="flex justify-between w-full">
                <div className="flex items-center">
                  <img
                    onClick={setShowCreateModal}
                    className="mr-6 cursor-pointer"
                    src={CrossIcon}
                    alt=""
                  />
                  <img
                    onClick={() => manageOrderCardDetail("increment")}
                    className=" cursor-pointer"
                    src={ArrowUpIcon}
                    alt=""
                  />
                  <img
                    onClick={() => manageOrderCardDetail("decrement")}
                    className=" cursor-pointer"
                    src={ArrowDownIcon}
                    alt=""
                  />
                </div>
                <div className="flex items-center gap-x-5">
                  <img
                    className="w-[16px] h-[16px] cursor-pointer"
                    src={DownloadIcon}
                    alt=""
                  />
                  <img className=" cursor-pointer" src={ShareIcon} alt="" />
                </div>
              </div>

              <div className="flex justify-between mt-6">
                <div>

                <h1 className="text-xl font-semibold">
                  {orderDetails?.correlationId || "-"}
                </h1>
                </div>
                <div>

                <span
                  className={`${
                    orderDetails?.orderStatus?.toLowerCase() === "voided" &&
                    "bg-[#F144451A] text-[#F14445]"
                  } ${
                    orderDetails?.orderStatus?.toLowerCase() === "completed" &&
                    "bg-[#3ACC481A] text-[#3ACC48]"
                  } ${
                    orderDetails?.orderStatus?.toLowerCase() === "pending" &&
                    "bg-[#3A51CC1A] text-[#3A51CC]"
                  } rounded-[8px] py-2 px-4 text-xs`}
                >
                  {orderDetails?.orderStatus.charAt(0).toUpperCase() +
                    orderDetails?.orderStatus.slice(1).toLowerCase() || "-"}
                </span>
                </div>
              </div>
              <h1 className="text-sm text-[#666666]">
                {formatDateTime(orderDetails?.createdAt) || "-"}
              </h1>
              <h1 className="text-base text-[#1A1A1A] font-bold mt-6">
                Order Details
              </h1>
              <div className="flex mt-5 items-center ">
                <h1 className="text-[#666666] text-sm w-[200px]">
                  Table Number
                </h1>
                <h1 className="text-[#1A1A1A] text-sm font-medium">
                  {orderDetails?.tableNumber || "-"}
                </h1>
              </div>
              <div className="flex mt-5 items-center ">
                <h1 className="text-[#666666] text-sm w-[200px]">
                  Total Price
                </h1>
                <h1 className="text-[#1A1A1A] text-sm font-medium">
                  {orderDetails?.totalPrice || "-"}
                </h1>
              </div>
              <div className="flex mt-5 items-center ">
                <h1 className="text-[#666666] text-sm w-[200px]">Items</h1>
                <h1 className="text-[#1A1A1A] text-sm font-medium">
                  {orderDetails?.items
                    ?.map((item: any) => item?.englishName)
                    .join(", ")}
                </h1>
              </div>
              <div className="flex mt-5 items-center ">
                <h1 className="text-[#666666] text-sm w-[200px]">Status</h1>
                <select
                  name="roles"
                  className="text-[#] h-[40px] text-xs border rounded-lg border-[#F1F1F2] mt-1 px-4"
                  onChange={handleSelectChange}
                  value={orderDetails?.orderStatus}
                >
                  <option className="text-[]" value="PENDING">
                    Pending
                  </option>
                  <option className="text-[]" value="COMPLETED">
                    Completed
                  </option>
                  <option className="text-[]" value="VOIDED">
                    Voided
                  </option>
                </select>
              </div>
              <h1 className="text-base text-[#1A1A1A] font-bold mt-6">
                Customer Details
              </h1>
              <div className="flex mt-5 items-center ">
                <h1 className="text-[#666666] text-sm w-[200px]">Email</h1>
                <h1 className="text-[#1A1A1A] text-sm font-medium">
                  {orderDetails?.customerEmail || "-"}
                </h1>
              </div>
              <div className="flex mt-5 items-center ">
                <h1 className="text-[#666666] text-sm w-[200px]">First Name</h1>
                <h1 className="text-[#1A1A1A] text-sm font-medium">
                  {orderDetails?.customerFirstName || "-"}
                </h1>
              </div>
              <div className="flex mt-5 items-center ">
                <h1 className="text-[#666666] text-sm w-[200px]">Last Name</h1>
                <h1 className="text-[#1A1A1A] text-sm font-medium">
                  {orderDetails?.customerLastName || "-"}
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
