import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  categoryItems: [],
  filteredCategoryItems: [],
  hasCategoryItemId: null,
};

export const categoryItemSlice = createSlice({
  name: "categoryItem",
  initialState,
  reducers: {
    set_category_items: (state :any, { payload }) => {
      state.categoryItems = payload.categoryItems;
      state.filteredCategoryItems = payload.categoryItems;
    },
    search_category_items: (state, { payload }) => {
      state.filteredCategoryItems = state.categoryItems.filter((item: any) => item.englishName.toLowerCase().includes(payload.toLowerCase()));
    },
    delete_category_item_modal: (state :any, { payload }) => {
      state.hasCategoryItemId = payload;
    },
    sort_category_items: (state, { payload }) => {
      if (payload === 'aToZ') {
        state.filteredCategoryItems = state.categoryItems.slice().sort((a: any, b: any) => 
          a.englishName.toLowerCase().localeCompare(b.englishName.toLowerCase())
        );
      } else if (payload === 'zToA') {
        state.filteredCategoryItems = state.categoryItems.slice().sort((a:any, b: any) => 
          b.englishName.toLowerCase().localeCompare(a.englishName.toLowerCase())
        );
      }
    },
  },
});

export const { set_category_items, search_category_items, delete_category_item_modal, sort_category_items } = categoryItemSlice.actions;

export default categoryItemSlice.reducer;
