import DashboardIcon from "../../icons/DashboardIcon";
import RestuarantIcon from "../../icons/RestuarantIcon";
import RestuarantSettingIcon from "../../icons/RestaurantSetting";
import EmployeeIcon from "../../icons/EmployeeIcon";
import MenuIcon from "../../icons/MenuIcon";
import ComboIcon from "../../icons/ComboICon";
import TableICon from "../../icons/TableIcon";
import RecentOrderIcon from "../../icons/RecentOrderIcon";
import ActiveOrderICon from "../../icons/ActiveOrderIcon";
import { AvailbleFeatures } from "src/utils/isAllowedFeature";

export const ownerSidebar = [
  {
    url: "/dashboard",
    name: "Dashboard",
    icon: DashboardIcon,
  },
  {
    url: "/my-restaurants",
    name: "My Restaurants",
    icon: RestuarantIcon,
  },
];

export const restaurantSidebar = [
  {
    url: (correlationId: string) => `/my-restaurant/${correlationId}/dashboard`,
    name: "Dashboard",
    icon: DashboardIcon,
    isRoleBase: false,
  },
  {
    url: (correlationId: string) =>
      `/my-restaurant/${correlationId}/restaurant-setting`,
    name: "General Settings",
    icon: RestuarantSettingIcon,
    isRoleBase: false,
  },
  {
    url: (correlationId: string) => `/my-restaurant/${correlationId}/employee`,
    name: "Employees",
    icon: EmployeeIcon,
    isRoleBase: true,
    roleName: AvailbleFeatures.TeamManagement,
  },
  {
    url: (correlationId: string) =>
      `/my-restaurant/${correlationId}/restaurant-table`,
    name: "Tables",
    icon: TableICon,
    isRoleBase: true,
    roleName: AvailbleFeatures.TableManagement,
  },
  {
    url: (correlationId: string) =>
      `/my-restaurant/${correlationId}/promotions`,
    name: "Promotions",
    icon: EmployeeIcon,
    isRoleBase: true,
    roleName: AvailbleFeatures.PromotionManagement,
  },
  {
    url: (correlationId: string) =>
      `/my-restaurant/${correlationId}/OrderManagement`,
    name: "Recent Orders",
    icon: RecentOrderIcon,
    isRoleBase: false,
  },
  {
    url: (correlationId: string) =>
      `/my-restaurant/${correlationId}/active-orders`,
    name: "Active Orders",
    icon: ActiveOrderICon,
    isRoleBase: false,
  },
  {
    url: (correlationId: string) => `/my-restaurant/${correlationId}/menu`,
    name: "Menu",
    section: "Manage",
    isSubMenuOpen: false,
    icon: MenuIcon,
    children: [],
    isRoleBase: true,
    roleName: AvailbleFeatures.MenuCreationAndManagement,
  },

  {
    url: (correlationId: string) => `/my-restaurant/${correlationId}/combo`,
    name: "Combos",
    icon: ComboIcon,
    isRoleBase: true,
    roleName: AvailbleFeatures.ComboCreationAndManagement,
  },

];

export const employeeSidebar = [
  {
    url: (correlationId: string) => `/my-employee/${correlationId}/orders`,
    name: "Active Orders",
    icon: "",
  },
  {
    url: (correlationId: string) => `/my-employee/${correlationId}/combo`,
    name: "Combos",
    icon: RestuarantIcon,
  },
  {
    url: (correlationId: string) => `/my-employee/${correlationId}/restaurant-table`,
    name: "Tables",
    icon: TableICon,
  },
];
