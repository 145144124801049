import React, { useState } from "react";
import RestaurantSettingComponent from "../../components/RestaurantSetting/RestaurantSettingComponent";
import SubcriptionSettinf from "../../components/SubcriptionSetting/SubcriptionSetting";
import ConnectedAccount from "src/components/ConnectedAccount/ConnectedAccount";
import DeliveryPolicy from "src/components/DeliveryPolicy/DeliveryPolicy";
import { useAuth } from "src/contexts/auth-provider";
import { allowedDeliveryPolicy } from "src/utils/isAllowedFeature";

const RestaurantSetting = () => {
  const { hasRestaurant } = useAuth();
  const [activeTab, setActiveTab] = useState("setting");

  return (
    <>
      <div className="w-full bg-white flex gap-x-6 items-center rounded-3xl">
        <div
          className={`h-[45px] flex items-center ml-4 cursor-pointer relative ${
            activeTab === "setting" ? "text-orange-500" : "text-gray-500"
          }`}
          onClick={() => setActiveTab("setting")}
        >
          <h1 className="text-sm">General</h1>
          {activeTab === "setting" && (
            <div className="absolute bottom-0 left-0 w-full border-b-2 border-orange"></div>
          )}
        </div>
        <div
          className={`h-[45px] flex items-center cursor-pointer relative ${
            activeTab === "subscription" ? "text-orange-500" : "text-gray-500"
          }`}
          onClick={() => setActiveTab("subscription")}
        >
          <h1 className="text-sm">Subscription</h1>
          {activeTab === "subscription" && (
            <div className="absolute bottom-0 left-0 w-full border-b-2 border-orange"></div>
          )}
        </div>
        <div
          className={`h-[45px] flex items-center cursor-pointer relative ${
            activeTab === "connectedAccount"
              ? "text-orange-500"
              : "text-gray-500"
          }`}
          onClick={() => setActiveTab("connectedAccount")}
        >
          <h1 className="text-sm">Connected Account</h1>
          {activeTab === "connectedAccount" && (
            <div className="absolute bottom-0 left-0 w-full border-b-2 border-orange"></div>
          )}
        </div>
        <div
          className={`h-[45px] flex items-center cursor-pointer relative ${
            activeTab === "deliveryPolicy" ? "text-orange-500" : "text-gray-500"
          }`}
          onClick={() => setActiveTab("deliveryPolicy")}
        >
          <h1 className="text-sm">Delivery policy</h1>
          {activeTab === "deliveryPolicy" && (
            <div className="absolute bottom-0 left-0 w-full border-b-2 border-orange"></div>
          )}
        </div>
      </div>

      {activeTab === "setting" && <RestaurantSettingComponent />}
      {activeTab === "subscription" && <SubcriptionSettinf />}
      {activeTab === "connectedAccount" && <ConnectedAccount />}
      {allowedDeliveryPolicy.includes(hasRestaurant?.planId) &&
        activeTab === "deliveryPolicy" && <DeliveryPolicy />}
    </>
  );
};

export default RestaurantSetting;
