import { useCallback, useEffect, useRef, useState } from "react";
import PlusIcon from "../../assests/images/dashboard/Vector 2.png";
import SortIcon from "../../assests/images/dashboard/swap.png";
import { useDispatch, useSelector } from "react-redux";
import { BtnLoader } from "src/commonLoader/index";
import SortbyDropdown from "../Menu/SortbyDropdown";
import { ManageTableModal } from "src/components/ManageTableModal/ManageTableModal";
import TwoSeattTable from "./../../assests/images/tables/2_seat_table.svg";
import FourSeatTable from "./../../assests/images/tables/4_seat_table.svg";
import SixSeatTable from "./../../assests/images/tables/6_seat_table.svg";
import EightSeatTable from "./../../assests/images/tables/8_seat_table.svg";
import TenSeatTable from "./../../assests/images/tables/10_seat_table.svg";
import TwelveSeatTable from "./../../assests/images/tables/12_seat_table.svg";
import {
  delete_table_modal,
  search_table,
  set_table_loding,
  set_tables,
  sort_tables,
} from "src/store/features/restuarant-table-slice";
import DeleteIcon from "../../assests/images/dashboard/delete.png";
import { GRAPHQL_ENDPOINT } from "src/constants/endpoints";
import {
  GET_CUSTOMER_ORDER_FOR_EMPLOYEE,
  GET_TABLES,
} from "src/constants/queries";
import { useAuth } from "src/contexts/auth-provider";
import { useAxiosInterceptor } from "src/hooks/useAxiosInterceptor";
import TableOrderMOdal from "src/components/TableOrderModal/TableOrderModal";
import { isEmployee } from "src/utils/isEmployee";

const RestaurantTable = () => {
  const dispatch = useDispatch();
  const dropdownRef = useRef<any>(null);
  const { axBe } = useAxiosInterceptor();
  const { hasRestaurant, userRole, user } = useAuth();
  const [isOpen, setIsOpen] = useState(false);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [editTableModal, setEditTableModal] = useState<any>(null);
  const [isShowTableOrderModal, setisShowTableOrderModal] = useState<any>(null);
  const [tableOrderDetails, setTableOrderDetails] = useState<any>(null);
  const [orders, setOrders] = useState<any>([]);
  const [employeeOrders, setEmployeeOrders] = useState<any>([]);

  const { filteredTables } = useSelector((state: any) => state.restaurantTable);
  const { isTableLoading } = useSelector((state: any) => state.restaurantTable);

  const tableContainerStyle = "w-[100%] h-[150px]";
  const tableStyle = "w-[100%] h-[150px]";

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  const searchTable = (value: string) => {
    dispatch(search_table(value));
  };

  const handleTableClick = (id: any) => {
    dispatch(delete_table_modal(id));
  };

  const getTables = useCallback(async () => {
    dispatch(set_table_loding(true));
    try {
      const { data } = await axBe.post(GRAPHQL_ENDPOINT, {
        query: GET_TABLES,
        variables: {
          storeCorrelationId: hasRestaurant.correlationId,
        },
      });
      dispatch(
        set_tables({
          tables: data?.data?.storeTablesByStoreCorrelationId || [],
        })
      );
      setTimeout(() => {
        dispatch(set_table_loding(false));
      }, 500);
    } catch (error) {
      setTimeout(() => {
        dispatch(set_table_loding(false));
      }, 500);
      console.error(error);
    }
  }, [axBe, dispatch, hasRestaurant?.correlationId]);

  const magnageTableOrder = (table: any, type?: string) => {
    if (isEmployee(userRole)) {
      setisShowTableOrderModal(true);
      setTableOrderDetails({ ...table, type });
    } else setEditTableModal(table);
  };

  const getOrders = useCallback(async () => {
    const isEmployee = userRole.toLowerCase().includes("employee");
    try {
      const { data } = await axBe.post(GRAPHQL_ENDPOINT, {
        query: GET_CUSTOMER_ORDER_FOR_EMPLOYEE,
        variables: {
          storeCorrelationId: isEmployee
            ? user?.storeUser?.stores?.[0]?.correlationId
            : hasRestaurant?.correlationId,
        },
      });
      setOrders((prev: any) => [
        ...prev,
        ...(data?.data?.activeCustomerOrdersByStoreCorrelationId || []),
      ]);
    } catch (error) {
      console.error(error);
    }
  }, [axBe, hasRestaurant?.correlationId, user?.storeUser?.stores, userRole]);

  useEffect(() => {
    getTables();
    getOrders();
  }, [getOrders, getTables]);

  useEffect(() => {
    if (isEmployee(userRole)) {
      const result = filteredTables.map((table: any) => {
        return {
          ...table,
          markStatus: orders.some(
            (item: any) => table.tableNumber === +item.tableNumber
          )
            ? "IN_USE"
            : "AVAILABLE",
        };
      });
      setEmployeeOrders(result);
    }
  }, [filteredTables, orders, userRole]);

  return (
    <div>
      <div className="flex justify-between items-center">
        <div>
          <form className="w-[550px] flex items-center gap-x-3">
            <div className="relative">
              <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                <svg
                  className="w-4 h-4 text-gray-500 dark:text-gray-400"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 20 20"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                  />
                </svg>
              </div>
              <input
                type="search"
                id="default-search"
                className="block border-[#F1F1F2] border w-full p-3 ps-10 text-sm text-black rounded-lg "
                placeholder="Search Table..."
                onChange={(e) => searchTable(e.target.value)}
              />
            </div>
            <div className="flex items-center gap-x-2">
              <div className="w-[20px] h-[20px]  bg-[#35c91c] rounded-full"></div>
              <div className="">Available</div>
              <div className="w-[20px] h-[20px] ml-2 bg-[#3395f0] rounded-full "></div>
              <div className="">In use</div>
              <div className="w-[20px] h-[20px] ml-2 bg-[#f95d04] rounded-full"></div>
              <div className="">Reserved</div>
            </div>
          </form>
        </div>
        <div className="relative inline-block text-left" ref={dropdownRef}>
          <div className="flex justify-center my-4">
            <button
              className={`flex items-center justify-evenly duration-500 w-[118px] h-[46px] rounded-xl border-solid border border-[#FD7657] 
          ${
            isOpen
              ? "bg-[#FD76571A] text-[#FD7657]"
              : "bg-transparent text-[#FD7657] hover:bg-white hover:text-orange"
          }`}
              onClick={toggleDropdown}
            >
              <img
                className="w-[24px] h-[24px]"
                src={SortIcon}
                alt="Sort Icon"
              />
              <span>Sort by</span>
            </button>
          </div>
          {isOpen && <SortbyDropdown sortBy={sort_tables} type={"number"} />}
        </div>
      </div>
      <div className="w-full flex gap-3">
        {isEmployee(userRole) ? (
          ""
        ) : (
          <div className="w-[90px]">
            <div
              className="h-[766px] bg-white rounded-xl flex justify-center items-center cursor-pointer"
              onClick={() => setShowCreateModal(!showCreateModal)}
            >
              <img className="w-[17px] h-[17px] " src={PlusIcon} alt="" />
            </div>
          </div>
        )}
        {!isEmployee(userRole) && (
          <div className="w-full">
            {isTableLoading ? (
              <div className="absolute top-[50%] left-[50%]">
                <BtnLoader />
              </div>
            ) : (
              <>
                {filteredTables && filteredTables.length > 0 ? (
                  <div className="flex flex-wrap gap-3 cursor-pointer">
                    {filteredTables.map((table: any, index: any) => (
                      <div
                        key={index}
                        className="flex flex-wrap "
                        // onClick={() => setEditTableModal(table)}
                        onClick={() => magnageTableOrder(table)}
                      >
                        <div className="w-[100%] h-[150px] mt-4 flex justify-center items-center p-4 relative">
                          <div
                            className={`text-sm absolute z-0 ${
                              table?.status === "AVAILABLE"
                                ? "text-[#35c91c]"
                                : table?.status === "RESERVED"
                                ? "text-[#f95d04]"
                                : table?.status === "IN_USE"
                                ? "text-[#3395f0]"
                                : ""
                            }   ${
                              table?.status === "AVAILABLE"
                                ? "bg-[#edfeee]"
                                : table?.status === "RESERVED"
                                ? "bg-[#fef5ee]"
                                : table?.status === "IN_USE"
                                ? "bg-[#edf6ff]"
                                : ""
                            } h-[50px] w-[50px] flex items-center justify-center rounded-full`}
                          >
                            T-{table?.tableNumber}
                          </div>

                          {userRole?.toLowerCase()?.includes("employee") ? (
                            ""
                          ) : (
                            <div
                              className={`absolute ${
                                table?.tableSize === 2
                                  ? "right-[25px]"
                                  : "right-[40px]"
                              } top-[25px] h-[20px] w-[20px] rounded-xl bg-[#FEF5F4] flex justify-center items-center cursor-pointer z-0`}
                              onClick={(e) => {
                                e.stopPropagation();
                                handleTableClick(table?.correlationId);
                              }}
                            >
                              <img
                                className="w-[10px] h-[10px]"
                                src={DeleteIcon}
                                alt="Delete"
                              />
                            </div>
                          )}

                          {table?.tableSize === 2 && (
                            <div className={tableContainerStyle}>
                              <img
                                src={TwoSeattTable}
                                className={tableStyle}
                                alt=""
                              />
                            </div>
                          )}
                          {table?.tableSize === 4 && (
                            <div className={tableContainerStyle}>
                              <img
                                src={FourSeatTable}
                                className={tableStyle}
                                alt=""
                              />
                            </div>
                          )}
                          {table?.tableSize === 6 && (
                            <div className={tableContainerStyle}>
                              <img
                                src={SixSeatTable}
                                className={tableStyle}
                                alt=""
                              />
                            </div>
                          )}
                          {table?.tableSize === 8 && (
                            <div className={tableContainerStyle}>
                              <img
                                src={EightSeatTable}
                                className={tableStyle}
                                alt=""
                              />
                            </div>
                          )}
                          {table?.tableSize === 10 && (
                            <div className={tableContainerStyle}>
                              <img
                                src={TenSeatTable}
                                className={tableStyle}
                                alt=""
                              />
                            </div>
                          )}
                          {table?.tableSize === 12 && (
                            <div className={tableContainerStyle}>
                              <img
                                src={TwelveSeatTable}
                                className={tableStyle}
                                alt=""
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className="absolute top-[50%] left-[40%] text-[#666666] text-sm">
                    <span>
                      Click the button on the left to create some Tables!
                    </span>
                  </div>
                )}
              </>
            )}
          </div>
        )}

        {isEmployee(userRole) && (
          <div className="w-full">
            {isTableLoading ? (
              <div className="absolute top-[50%] left-[50%]">
                <BtnLoader />
              </div>
            ) : (
              <>
                {employeeOrders.length > 0 ? (
                  <div className="flex flex-wrap gap-3 cursor-pointer">
                    {employeeOrders?.map((table: any, index: any) => (
                      <div
                        key={index}
                        className="flex flex-wrap "
                        onClick={() =>
                          magnageTableOrder(
                            table,
                            table?.markStatus === "AVAILABLE" ? "add" : "update"
                          )
                        }
                      >
                        <div className="w-[100%] h-[150px] mt-4 flex justify-center items-center p-4 relative">
                          <div
                            className={`text-sm absolute z-0 ${
                              table?.markStatus === "AVAILABLE"
                                ? "text-[#35c91c]"
                                : table?.markStatus === "RESERVED"
                                ? "text-[#f95d04]"
                                : table?.markStatus === "IN_USE"
                                ? "text-[#3395f0]"
                                : ""
                            }   ${
                              table?.markStatus === "AVAILABLE"
                                ? "bg-[#edfeee]"
                                : table?.markStatus === "RESERVED"
                                ? "bg-[#fef5ee]"
                                : table?.markStatus === "IN_USE"
                                ? "bg-[#edf6ff]"
                                : ""
                            } h-[50px] w-[50px] flex items-center justify-center rounded-full`}
                          >
                            T-{table?.tableNumber}
                          </div>

                          {userRole?.toLowerCase()?.includes("employee") ? (
                            ""
                          ) : (
                            <div
                              className={`absolute ${
                                table?.tableSize === 2
                                  ? "right-[25px]"
                                  : "right-[40px]"
                              } top-[25px] h-[20px] w-[20px] rounded-xl bg-[#FEF5F4] flex justify-center items-center cursor-pointer z-0`}
                              onClick={(e) => {
                                e.stopPropagation();
                                handleTableClick(table?.correlationId);
                              }}
                            >
                              <img
                                className="w-[10px] h-[10px]"
                                src={DeleteIcon}
                                alt="Delete"
                              />
                            </div>
                          )}

                          {table?.tableSize === 2 && (
                            <div className={tableContainerStyle}>
                              <img
                                src={TwoSeattTable}
                                className={tableStyle}
                                alt=""
                              />
                            </div>
                          )}
                          {table?.tableSize === 4 && (
                            <div className={tableContainerStyle}>
                              <img
                                src={FourSeatTable}
                                className={tableStyle}
                                alt=""
                              />
                            </div>
                          )}
                          {table?.tableSize === 6 && (
                            <div className={tableContainerStyle}>
                              <img
                                src={SixSeatTable}
                                className={tableStyle}
                                alt=""
                              />
                            </div>
                          )}
                          {table?.tableSize === 8 && (
                            <div className={tableContainerStyle}>
                              <img
                                src={EightSeatTable}
                                className={tableStyle}
                                alt=""
                              />
                            </div>
                          )}
                          {table?.tableSize === 10 && (
                            <div className={tableContainerStyle}>
                              <img
                                src={TenSeatTable}
                                className={tableStyle}
                                alt=""
                              />
                            </div>
                          )}
                          {table?.tableSize === 12 && (
                            <div className={tableContainerStyle}>
                              <img
                                src={TwelveSeatTable}
                                className={tableStyle}
                                alt=""
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className="absolute top-[50%] left-[40%] text-[#666666] text-sm">
                    <span>
                      Click the button on the left to create some Tables!
                    </span>
                  </div>
                )}
              </>
            )}
          </div>
        )}
      </div>
      {showCreateModal || !!editTableModal ? (
        <ManageTableModal
          title={!!editTableModal ? "Edit Table" : "Create Table"}
          setShowCreateModal={() =>
            !!editTableModal
              ? setEditTableModal(null)
              : setShowCreateModal(!showCreateModal)
          }
          editTableModal={editTableModal}
        />
      ) : (
        <></>
      )}
      {isShowTableOrderModal && (
        <TableOrderMOdal
          tableOrderDetails={tableOrderDetails}
          isShowTableOrderModal={() => setisShowTableOrderModal(false)}
        />
      )}
    </div>
  );
};

export default RestaurantTable;
