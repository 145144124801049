import React from "react";

const PlanTypeButton = ({ name, type, selectedType, onClick }: any) => {
  return (
    <div className="flex max-md:w-full justify-center mt-8 mx-1 ">
      <button
        className={`w-[281px] max-md:w-[100%] h-[56px] rounded-xl ${
          selectedType === type
            ? "bg-orange  text-white "
            : "bg-white text-black"
        }`}
        onClick={() => onClick(type)}
      >
        {name}
      </button>
    </div>
  );
};

export default PlanTypeButton;
