import React, { forwardRef, useEffect, useRef, useState } from "react";
import DotIcon from "../../assests/images/dashboard/Text input container 2.png";
import DeleteIcon from "../../assests/images/dashboard/delete 2.png";
import EditIcon from "../../assests/images/dashboard/edit.png";

interface Employee {
  correlationId: string;
}

interface DropdownProps {
  handleEmployeeClick: (correlationId: string) => void;
  employee: Employee;
  editEmployeeModal: (employee: Employee, index: number) => void;
  index: number;
}

const Dropdown = forwardRef<HTMLDivElement, DropdownProps>(
  ({ handleEmployeeClick, employee, editEmployeeModal, index }, ref) => {
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef<HTMLDivElement | null>(null);

    const toggleDropdown = () => {
      setIsOpen((prev) => !prev);
    };

    const closeDropdown = () => {
      setIsOpen(false);
    };

    useEffect(() => {
      const handleClickOutside = (event: MouseEvent) => {
        const target = event.target as Node;
        const isRefCallback = typeof ref === "function";
        const isRefObject = ref && "current" in ref;
        const dropdownIsOpen =
          dropdownRef.current && dropdownRef.current.contains(target);
        const refContainsTarget =
          isRefObject && ref.current && ref.current.contains(target);
        if (
          !dropdownIsOpen &&
          !(isRefCallback && ref(target as any)) &&
          !refContainsTarget
        ) {
          closeDropdown();
        }
      };

      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [dropdownRef, ref]);

    return (
      <div className="relative inline-block text-left" ref={dropdownRef}>
        <div>
          <img
            aria-expanded={isOpen}
            aria-haspopup="true"
            onClick={toggleDropdown}
            className="h-[35px] w-[35px] cursor-pointer"
            src={DotIcon}
            alt=""
          />
        </div>

        {isOpen && (
          <div
            className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-2xl bg-white shadow-lg ring-opacity-5 focus:outline-none"
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="menu-button"
          >
            <div className="py-1 px-2" role="none">
              <div className="flex items-center px-4 bg-[#F1F1F2] rounded-3xl">
                <img className="w-[15px] h-[15px]" src={EditIcon} alt="Edit" />
                <button
                  className="block w-full px-4 py-2 text-left text-sm text-[#828282]"
                  role="menuitem"
                  onClick={(event) => {
                    event.stopPropagation();
                    editEmployeeModal(employee, index);
                    closeDropdown();
                  }}
                >
                  Edit
                </button>
              </div>
              <div className="flex items-center px-4 mt-1">
                <img
                  className="w-[14px] h-[15px]"
                  src={DeleteIcon}
                  alt="Delete"
                />
                <button
                  className="block w-full px-4 py-2 text-left text-sm text-[red]"
                  role="menuitem"
                  onClick={(event) => {
                    event.stopPropagation();
                    handleEmployeeClick(employee.correlationId);
                    closeDropdown();
                  }}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
);

export default Dropdown;
