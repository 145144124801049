import React, { useState } from "react";

const Dropdown = ({ fetchGraph }: any) => {
  const [isOpen, setIsOpen] = useState(false);
  const [showValue, setShowValue] = useState("Last 7 days");

  const toggleDropdown = (value?: string) => {
    setIsOpen(!isOpen);
    if (value) setShowValue(value);
    if (value) {
      let calculatedValue: any;
      switch (value) {
        case "Today":
          calculatedValue = 1 * 24;
          break;
        case "Last 7 Days":
          calculatedValue = 7 * 24;
          break;
        case "Last 30 Days":
          calculatedValue = 30 * 24;
          break;
        case "Last 90 Days":
          calculatedValue = 90 * 24;
          break;
        default:
          calculatedValue = 0;
          break;
      }
      fetchGraph(calculatedValue, value)
    }
  };

  return (
    <div className="relative text-left">
      <div>
        <button
          type="button"
          className="inline-flex w-full border-0 justify-center gap-x-1.5 rounded-md px-3 py-2 text-sm text-gray-900 ring-inset ring-gray-300 hover:bg-gray-50"
          id="menu-button"
          aria-expanded={isOpen}
          aria-haspopup="true"
          onClick={() => toggleDropdown()}
        >
          {showValue}
          <svg
            className="-mr-1 h-5 w-5 text-gray-400"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              fillRule="evenodd"
              d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
              clipRule="evenodd"
            />
          </svg>
        </button>
      </div>

      {isOpen && (
        <div
          className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-opacity-5 focus:outline-none"
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="menu-button"
        >
          <div className="py-1" role="none">
            <button
              className="block w-full px-4 py-2 text-left text-sm text-gray-700"
              role="menuitem"
              onClick={() => toggleDropdown("Today")}
            >
              Today
            </button>
            <button
              className="block w-full px-4 py-2 text-left text-sm text-gray-700"
              role="menuitem"
              onClick={() => toggleDropdown("Last 7 Days")}
            >
              Last 7 Days
            </button>
            <button
              className="block w-full px-4 py-2 text-left text-sm text-gray-700"
              role="menuitem"
              onClick={() => toggleDropdown("Last 30 Days")}
            >
              Last 30 Days
            </button>
            <button
              className="block w-full px-4 py-2 text-left text-sm text-gray-700"
              role="menuitem"
              onClick={() => toggleDropdown("Last 90 Days")}
            >
              Last 90 Days
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Dropdown;
