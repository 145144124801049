export const PLANS = `query plans {
    plans{
      name,
      productDescription,
      monthlyPriceMonthlyBilled,
      monthlyPriceAnnuallyBilled,
      features
    }
  }`;

export const GET_ALL_RESTAURANTS = `query loggedInUserStores{
    loggedInUserStores{
      correlationId
      name
      bucketKeyName
      employeeCount
      planId
      allowedFeatures
      address{
        addressLine1
        addressLine2
        city
        state
        zipCode
      }
      deliveryPolicies{
        startMile
        endMile
        deliveryCharge
        }
      latitude
      longitude
    }
  }`;

export const RESTAURANT_SUBSCRIPTION_TAB_DATA = `
  query restaurantSubscriptionTabData($restaurantSubscriptionTabDataInput: RestaurantSubscriptionTabDataInput!) {
    restaurantSubscriptionTabData(restaurantSubscriptionTabDataInput: $restaurantSubscriptionTabDataInput) {
      customerPortalUrl
      eatsbitsSubscription{
      stripeSubscriptionId
      planId
      currentPeriodStart
      currentPeriodEnd
      status
    }
      invoices{
      stripeInvoiceNumber
      invoiceCreatedAtByStripe
      status
      periodStart
      periodEnd
      hostedInvoiceUrl
      nextPaymentAttempt
      totalExcludingTax
      description
    }
      subscribedPlan{
      planId
      tier
      frequency
      price
      productName
      productDescription
    }
      upcomingInvoice {
      stripeInvoiceNumber
      invoiceCreatedAtByStripe
      description
      status
      totalExcludingTax
      periodStart
      periodEnd
      nextPaymentAttempt
      hostedInvoiceUrl
    }
    }
  }
`;

export const STORES = `
  query stores($retrieveStoresInput: RetrieveStoresInput!) {
    stores(retrieveStoresInput: $retrieveStoresInput) {
      correlationId
      name
      nonEnglishName
      primaryContactPhoneNumber
      primaryContactEmail
      primaryContactFirstName
      primaryContactLastName
      style
      employeeCount
      connectedAccountId
      bucketKeyName
      categories {
        correlationId
        name
      }
      operationHours {
        day
        startHour
        endHour
      }
      address {
        addressLine1
        addressLine2
        city
        state
        zipCode
      }
      planId
      allowedFeatures
      latitude
      longitude
    }
  }
`;

export const GET_CATEGORIES = `query categoriesByStoreCorrelationId($storeCorrelationId: UUID!){
    categoriesByStoreCorrelationId(storeCorrelationId: $storeCorrelationId){
    correlationId
    name
    nonEnglishName
    bucketKeyName
    items{
      correlationId
      abbreviation
      englishName
      nonEnglishName
      englishDescription
      nonEnglishDescription
      price
      taxRate
      tag
      bucketKeyName
      itemOptions{
      correlationId
      name
      description
      renderSequence
      isRequired
      itemOptionElements{
      correlationId
      name
      description
      renderSequence
      price
      }

      }
    }
  }
}`;

export const GET_ITEMS = `query itemsByCategoryCorrelationId ($categoryCorrelationId: UUID!){
  itemsByCategoryCorrelationId(categoryCorrelationId: $categoryCorrelationId){
    correlationId
    englishName
    nonEnglishName
    englishDescription
    nonEnglishDescription
    price
    bucketKeyName
    abbreviation,
    taxRate,
    tag,
    itemOptions {
      correlationId
      name
      description
      renderSequence
      isRequired
      itemOptionElements {
        correlationId
        name
        description
        renderSequence
        price
      }
    }
  }
}`;

export const GET_TABLES = `query storeTablesByStoreCorrelationId ($storeCorrelationId: UUID!){
  storeTablesByStoreCorrelationId(storeCorrelationId: $storeCorrelationId){
   	correlationId
    storeCorrelationId
    tableNumber
    tableSize
    status
    currentOrderCorrelationId
  }
}`;

export const GET_COMBOS = `query combosByStoreCorrelationId ($storeCorrelationId: UUID!){
  combosByStoreCorrelationId(storeCorrelationId: $storeCorrelationId){
   correlationId
    englishName
    nonEnglishName
    englishDescription
    nonEnglishDescription
    deadlineToPlaceOrder
    comboReadyTime
    comboRepeatFrequency
    price
    taxRate
    bucketKeyName
    itemList{
      correlationId
      abbreviation
      englishName
      nonEnglishName
      englishDescription
      nonEnglishDescription
      price
      taxRate
      tag
      bucketKeyName
      itemOptions {
      	correlationId
        name
        description
        renderSequence
        isRequired
        itemOptionElements{
          correlationId
          name
          description
          renderSequence
          price
        }
      }
    }
  }
}`;

export const GET_PROMOTIONS = `query promotionsByStoreCorrelationId ($storeCorrelationId: UUID!){
  promotionsByStoreCorrelationId(storeCorrelationId: $storeCorrelationId){
    correlationId
    eatsbitsUserCorrelationId
    eatsbitsUserEmail
    expiresAt
    percentOff
    stripePromotionCode
    maxRedemptions
    timesRedeemed
  }
}`;

export const GET_PROMOTIONS_BY_DATES = `query storeCustomersByStoreCorrelationIdBetweenTwoInstants( $storeCorrelationId: UUID!, $startTimeStamp: Long, $endTimeStamp: Long) {
  storeCustomersByStoreCorrelationIdBetweenTwoInstants(storeCorrelationId: $storeCorrelationId,startTimeStamp: $startTimeStamp,endTimeStamp: $endTimeStamp) {
    customerCorrelationId
    customerName
    customerEmail
    visitTimes
    lastVisitTime
  }
}`;

export const GET_PROMOTIONS_BY_SEARCH = `query storeCustomersByStoreCorrelationIdAndNameOrEmail($storeCorrelationId: UUID!,$keyword: String!) {
  storeCustomersByStoreCorrelationIdAndNameOrEmail(storeCorrelationId: $storeCorrelationId,keyword: $keyword) {
    customerCorrelationId
    customerName
    customerEmail
    visitTimes
    lastVisitTime
  }
}
`;

export const GET_CUSTOMER_ORDERS = `query customerOrdersByStoreCorrelationId($storeCorrelationId: UUID!, $first: Int, $after: String, $last: Int, $before:String){
  customerOrdersByStoreCorrelationId(storeCorrelationId:$storeCorrelationId, first:$first, after:$after, last:$last, before:$before){
    pageInfo{
      startCursor
      endCursor
      hasPreviousPage
      hasNextPage
    }
    edges{
      cursor
      node{
        correlationId
        createdAt
        customerFirstName
        customerLastName
        customerEmail
        storeCorrelationId
        tableNumber
        orderStatus
        totalPrice
        items{
          englishName
          price
          bucketKeyName
          quantity
        }
      }
    }

  }
}
`;

export const GET_EMPLOYEE = `query storeEmployeesByStoreCorrelationId($storeCorrelationId: UUID!){
  storeEmployeesByStoreCorrelationId(storeCorrelationId: $storeCorrelationId){
    	correlationId
    	firstName
      lastName
      email
      phoneNumber
      employerName
      roles
  }
}
`;

export const GET_CUSTOMER_ORDER_FOR_EMPLOYEE = `query activeCustomerOrdersByStoreCorrelationId($storeCorrelationId: UUID!){
  activeCustomerOrdersByStoreCorrelationId(storeCorrelationId: $storeCorrelationId) {
      correlationId
      createdAt
      customerCorrelationId
      customerFirstName
      customerLastName
      customerEmail
      customerPhoneNumber
      storeCorrelationId
      isGuest
      orderStatus
      orderType
      tableNumber
      totalPrice
      totalItemQuantity
      customerNote
      serverFirstName
      serverLastName
      serverEmployeeCorrelationId
      paid
      tip
    items {
      bucketKeyName
      correlationId
      englishDescription
      englishName
      isCombo
      nonEnglishDescription
      nonEnglishName
      price
      quantity
      taxRate
      itemOptionElements
      itemCorrelationId
    }
  }
}
`;

export const GET_CUSTOMER_TABLE_ORDER_FOR_UPDATE = `query customerOrderByCorrelationId($customerOrderCorrelationId: UUID!){
  customerOrderByCorrelationId(customerOrderCorrelationId: $customerOrderCorrelationId) {
    correlationId
      createdAt
      customerFirstName
      customerLastName
      customerEmail
      customerPhoneNumber
      storeCorrelationId
      isGuest
      orderStatus
      orderType
      tableNumber
      totalPrice
      totalItemQuantity
      customerNote
      serverFirstName
      serverLastName
      serverEmployeeCorrelationId
      paid
    items {
      bucketKeyName
      correlationId
      englishDescription
      englishName
      isCombo
      nonEnglishDescription
      nonEnglishName
      price
      quantity
      taxRate
      itemOptionElements
      itemCorrelationId
    }
  }
}
`;

export const GET_SALES_HISTORY = `query salesHistoryByStoreCorrelationId($getSalesHistoryInput: GetSalesHistoryInput!){
   salesHistoryByStoreCorrelationId(getSalesHistoryInput: $getSalesHistoryInput){
		bucket
    storeCorrelationId
    orderNumber
    storeTotalPrice
    averageOrderTotalPrice
    }
  }
`;

export const GET_EXISTING_CUSTOMER = `query customersByPhoneNumber($customersByPhoneNumberOrEmailInput: CustomersByPhoneNumberOrEmailInput!) {
  customersByPhoneNumber(customersByPhoneNumberOrEmailInput: $customersByPhoneNumberOrEmailInput) {
    correlationId
    firstName
    lastName
    email
    phoneNumber
    address{
        addressLine1
				addressLine2
        city
        state
        zipCode
      }
  }
}
`;

export const GET_DELIVERY_CHARGES = `query deliveryCharge($deliveryChargeInput: DeliveryChargeInput!){
    deliveryCharge(deliveryChargeInput: $deliveryChargeInput)
}
`;

export const GET_CUSTOMERS_BY_CORRELATIONIDS = `query customersByCorrelationIds($customersByCorrelationIdsInput: CustomersByCorrelationIdsInput!){
    customersByCorrelationIds(customersByCorrelationIdsInput: $customersByCorrelationIdsInput){
			correlationId
      firstName
      lastName
      email
      phoneNumber
      address{
        addressLine1
				addressLine2
        city
        state
        zipCode
      }
    }
  }
`;


