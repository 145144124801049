import { useState } from "react";
import { CreatePromotion } from "src/components/CreatePromotion/CreatePromotion";
import CustomerPromotions from "src/components/CustomerPromotions/CustomerPromotions";
import ExistingCustomerPromotions from "src/components/ExistingCustomerPromotions/ExistingCustomerPromotions";

const Promotions = () => {
  const [activeTab, setActiveTab] = useState("promotions");

  return (
    <>
      <div className="w-full bg-white flex gap-x-6 items-center rounded-3xl">
        <div
          className={`h-[45px] flex items-center ml-4 cursor-pointer relative ${
            activeTab === "promotions" ? "text-orange-500" : "text-gray-500"
          }`}
          onClick={() => setActiveTab("promotions")}
        >
          <h1 className="text-sm">Promotions</h1>
          {activeTab === "promotions" && (
            <div className="absolute bottom-0 left-0 w-full border-b-2 border-orange"></div>
          )}
        </div>
        <div
          className={`h-[45px] flex items-center cursor-pointer relative ${
            activeTab === "existing-customers-promotion"
              ? "text-orange-500"
              : "text-gray-500"
          }`}
          onClick={() => setActiveTab("existing-customers-promotion")}
        >
          <h1 className="text-sm">Send to existing customers</h1>
          {activeTab === "existing-customers-promotion" && (
            <div className="absolute bottom-0 left-0 w-full border-b-2 border-orange"></div>
          )}
        </div>
        <div
          className={`h-[45px] flex items-center cursor-pointer relative ${
            activeTab === "guests-promotions"
              ? "text-orange-500"
              : "text-gray-500"
          }`}
          onClick={() => setActiveTab("guests-promotions")}
        >
          <h1 className="text-sm">Send to guests</h1>
          {activeTab === "guests-promotions" && (
            <div className="absolute bottom-0 left-0 w-full border-b-2 border-orange"></div>
          )}
        </div>
      </div>

      {activeTab === "promotions" && <CustomerPromotions />}
      {activeTab === "existing-customers-promotion" && (
        <ExistingCustomerPromotions />
      )}
      {activeTab === "guests-promotions" && <CreatePromotion />}
    </>
  );
};

export default Promotions;
