import React from "react";
import defaultImg from "../../assests/images/dashboard/user_4159471.png";

const UserProfileAccount = () => {
  return (
    <>
      <div className="flex flex-col w-full gap-6">
        <div className="w-[100%]">
          <div className="rounded-2xl bg-white p-6">
            <div className="mb-6">
              <h1 className="text-lg font-medium">Account</h1>
              <p className="text-[#606360] text-sm">
                Please configure your profile and fill in your information.
              </p>
            </div>

            <div className="flex gap-4 items-center">
              <div>
                <img className="w-[50px] h-[50px]" src={defaultImg} alt="" />
              </div>
              <button className="text-sm text-black bg-white rounded-lg border border-[#EBEBEB] px-3 py-[9px]">
                Upload Photo
              </button>
              <button className="text-sm text-orange bg-white rounded-lg border border-[#EBEBEB] px-3 py-[9px]">
                Remove Photo
              </button>
            </div>

            <div className="mt-4">
              <div className="flex flex-col">
                <div className="flex justify-between gap-x-4 mt-2">
                  <div className="w-full">
                    <span className="text-[#9B9B9B] text-sm">Full name</span>
                    <input
                      className="text-xs text-[#9B9B9B]  h-[40px] w-full border rounded-lg border-[#F1F1F2] mt-1 px-2"
                      type="text"
                      name="street"
                      id="street"
                      placeholder=""
                      //   value={formik.values.street}
                      //   onBlur={formik.handleBlur}
                      //   onChange={formik.handleChange}
                    />
                    {/* {formik.touched.street && formik.errors.street ? (
                      <div className="text-orange text-xs mt-2">
                        {formik.errors.street}
                      </div>
                    ) : null} */}
                  </div>
                  <div className="w-full">
                    <span className="text-[#9B9B9B] text-sm">Email</span>
                    <input
                      className="text-xs text-[#9B9B9B]  h-[40px] w-full border rounded-lg border-[#F1F1F2] mt-1 px-2"
                      type="text"
                      name="city"
                      id="city"
                      placeholder=""
                      //   value={formik.values.city}
                      //   onBlur={formik.handleBlur}
                      //   onChange={formik.handleChange}
                    />
                    {/* {formik.touched.city && formik.errors.city ? (
                      <div className="text-orange text-xs mt-2">
                        {formik.errors.city}
                      </div>
                    ) : null} */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="w-[100%]">
          <div className="rounded-2xl bg-white p-6">
            <h1 className="text-lg font-medium">Business contact details</h1>

            <div className="flex justify-between gap-x-4 mt-2">
              <div className="w-full">
                <span className="text-[#9B9B9B] text-sm">Name</span>
                <input
                  className="text-xs text-[#9B9B9B]  h-[40px] w-full border rounded-lg border-[#F1F1F2] mt-1 px-2"
                  type="number"
                  name="contactNumber"
                  id="contactNumber"
                  placeholder=""
                  //   value={formik.values.contactNumber}
                  //   onBlur={formik.handleBlur}
                  //   onChange={formik.handleChange}
                />
              </div>
              <div className="w-full">
                <span className="text-[#9B9B9B] text-sm">DBA name</span>
                <input
                  className="text-xs text-[#9B9B9B]  h-[40px] w-full border rounded-lg border-[#F1F1F2] mt-1 px-2"
                  type="email"
                  name="email"
                  id="email"
                  placeholder=""
                  //   value={formik.values.email}
                  //   onBlur={formik.handleBlur}
                  //   onChange={formik.handleChange}
                />
                {/* {formik.touched.email && formik.errors.email ? (
      <div className="text-orange text-xs mt-2">
        {formik.errors.email}
      </div>
    ) : null}  */}
              </div>
            </div>
            <div className="flex justify-between gap-x-4 mt-2">
              <div className="w-full">
                <span className="text-[#9B9B9B] text-sm">Support Number</span>
                <input
                  className="text-xs text-[#9B9B9B]  h-[40px] w-full border rounded-lg border-[#F1F1F2] mt-1 px-2"
                  type="number"
                  name="contactNumber"
                  id="contactNumber"
                  placeholder=""
                  //   value={formik.values.contactNumber}
                  //   onBlur={formik.handleBlur}
                  //   onChange={formik.handleChange}
                />
              </div>
              <div className="w-full">
                <span className="text-[#9B9B9B] text-sm">Support Email</span>
                <input
                  className="text-xs text-[#9B9B9B]  h-[40px] w-full border rounded-lg border-[#F1F1F2] mt-1 px-2"
                  type="email"
                  name="email"
                  id="email"
                  placeholder=""
                  //   value={formik.values.email}
                  //   onBlur={formik.handleBlur}
                  //   onChange={formik.handleChange}
                />
                {/* {formik.touched.email && formik.errors.email ? (
      <div className="text-orange text-xs mt-2">
        {formik.errors.email}
      </div>
    ) : null}  */}
              </div>
            </div>
            <div className="flex justify-between gap-x-4 mt-2">
              <div className="w-[49%]">
                <span className="text-[#9B9B9B] text-sm">Website</span>
                <input
                  className="text-xs text-[#9B9B9B]  h-[40px] w-full border rounded-lg border-[#F1F1F2] mt-1 px-2"
                  type="number"
                  name="contactNumber"
                  id="contactNumber"
                  placeholder=""
                  //   value={formik.values.contactNumber}
                  //   onBlur={formik.handleBlur}
                  //   onChange={formik.handleChange}
                />
              </div>
            </div>
          </div>

          <div className="rounded-2xl bg-white p-6 mt-6">
            <h1 className="text-lg font-medium">Business location details</h1>

            <div className="flex justify-between gap-x-4 mt-2">
              <div className="w-full">
                <span className="text-[#9B9B9B] text-sm">Country</span>
                <input
                  className="text-xs text-[#9B9B9B]  h-[40px] w-full border rounded-lg border-[#F1F1F2] mt-1 px-2"
                  type="number"
                  name="contactNumber"
                  id="contactNumber"
                  placeholder=""
                  //   value={formik.values.contactNumber}
                  //   onBlur={formik.handleBlur}
                  //   onChange={formik.handleChange}
                />
              </div>
              <div className="w-full">
                <span className="text-[#9B9B9B] text-sm">City</span>
                <input
                  className="text-xs text-[#9B9B9B]  h-[40px] w-full border rounded-lg border-[#F1F1F2] mt-1 px-2"
                  type="email"
                  name="email"
                  id="email"
                  placeholder=""
                  //   value={formik.values.email}
                  //   onBlur={formik.handleBlur}
                  //   onChange={formik.handleChange}
                />
                {/* {formik.touched.email && formik.errors.email ? (
      <div className="text-orange text-xs mt-2">
        {formik.errors.email}
      </div>
    ) : null}  */}
              </div>
            </div>
            <div className="flex justify-between gap-x-4 mt-2">
              <div className="w-full">
                <span className="text-[#9B9B9B] text-sm">State</span>
                <input
                  className="text-xs text-[#9B9B9B]  h-[40px] w-full border rounded-lg border-[#F1F1F2] mt-1 px-2"
                  type="number"
                  name="contactNumber"
                  id="contactNumber"
                  placeholder=""
                  //   value={formik.values.contactNumber}
                  //   onBlur={formik.handleBlur}
                  //   onChange={formik.handleChange}
                />
              </div>
              <div className="w-full">
                <span className="text-[#9B9B9B] text-sm">Adress</span>
                <input
                  className="text-xs text-[#9B9B9B]  h-[40px] w-full border rounded-lg border-[#F1F1F2] mt-1 px-2"
                  type="email"
                  name="email"
                  id="email"
                  placeholder=""
                  //   value={formik.values.email}
                  //   onBlur={formik.handleBlur}
                  //   onChange={formik.handleChange}
                />
                {/* {formik.touched.email && formik.errors.email ? (
      <div className="text-orange text-xs mt-2">
        {formik.errors.email}
      </div>
    ) : null}  */}
              </div>
            </div>
            <div className="flex justify-between gap-x-4 mt-2">
              <div className="w-[49%]">
                <span className="text-[#9B9B9B] text-sm">Zip Code</span>
                <input
                  className="text-xs text-[#9B9B9B]  h-[40px] w-full border rounded-lg border-[#F1F1F2] mt-1 px-2"
                  type="number"
                  name="contactNumber"
                  id="contactNumber"
                  placeholder=""
                  //   value={formik.values.contactNumber}
                  //   onBlur={formik.handleBlur}
                  //   onChange={formik.handleChange}
                />
              </div>
            </div>
          </div>
          <div className="rounded-2xl bg-white p-6 mt-6">
            <h1 className="text-lg font-medium">Business location details</h1>

            <div className="flex justify-between gap-x-4 mt-2">
              <div className="w-full">
                <span className="text-[#9B9B9B] text-sm">Country</span>
                <input
                  className="text-xs text-[#9B9B9B]  h-[40px] w-full border rounded-lg border-[#F1F1F2] mt-1 px-2"
                  type="number"
                  name="contactNumber"
                  id="contactNumber"
                  placeholder=""
                  //   value={formik.values.contactNumber}
                  //   onBlur={formik.handleBlur}
                  //   onChange={formik.handleChange}
                />
              </div>
              <div className="w-full">
                <span className="text-[#9B9B9B] text-sm">City</span>
                <input
                  className="text-xs text-[#9B9B9B]  h-[40px] w-full border rounded-lg border-[#F1F1F2] mt-1 px-2"
                  type="email"
                  name="email"
                  id="email"
                  placeholder=""
                  //   value={formik.values.email}
                  //   onBlur={formik.handleBlur}
                  //   onChange={formik.handleChange}
                />
                {/* {formik.touched.email && formik.errors.email ? (
      <div className="text-orange text-xs mt-2">
        {formik.errors.email}
      </div>
    ) : null}  */}
              </div>
            </div>
            <div className="flex justify-between gap-x-4 mt-2">
              <div className="w-full">
                <span className="text-[#9B9B9B] text-sm">State</span>
                <input
                  className="text-xs text-[#9B9B9B]  h-[40px] w-full border rounded-lg border-[#F1F1F2] mt-1 px-2"
                  type="number"
                  name="contactNumber"
                  id="contactNumber"
                  placeholder=""
                  //   value={formik.values.contactNumber}
                  //   onBlur={formik.handleBlur}
                  //   onChange={formik.handleChange}
                />
              </div>
              <div className="w-full">
                <span className="text-[#9B9B9B] text-sm">Adress</span>
                <input
                  className="text-xs text-[#9B9B9B]  h-[40px] w-full border rounded-lg border-[#F1F1F2] mt-1 px-2"
                  type="email"
                  name="email"
                  id="email"
                  placeholder=""
                  //   value={formik.values.email}
                  //   onBlur={formik.handleBlur}
                  //   onChange={formik.handleChange}
                />
                {/* {formik.touched.email && formik.errors.email ? (
      <div className="text-orange text-xs mt-2">
        {formik.errors.email}
      </div>
    ) : null}  */}
              </div>
            </div>
            <div className="flex justify-between gap-x-4 mt-2">
              <div className="w-[49%]">
                <span className="text-[#9B9B9B] text-sm">Zip Code</span>
                <input
                  className="text-xs text-[#9B9B9B]  h-[40px] w-full border rounded-lg border-[#F1F1F2] mt-1 px-2"
                  type="number"
                  name="contactNumber"
                  id="contactNumber"
                  placeholder=""
                  //   value={formik.values.contactNumber}
                  //   onBlur={formik.handleBlur}
                  //   onChange={formik.handleChange}
                />
              </div>
            </div>
          </div>

          <div className="rounded-2xl bg-white p-6 mt-6">
            <div className="flex justify-between items-center py-3 border-b border-[#F1F1F2]">
              <div>
                <h1 className="text-sm">Password</h1>
                <h1 className="text-[#606360] text-sm">
                  Change your password.
                </h1>
              </div>
              <div>
                <div className="flex justify-center">
                  <button className="w-[150px] font-semibold text-xs flex justify-center items-center gap-x-4 text-[#FD7657] h-[38px] rounded-xl bg-[#FD76571A] duration-500">
                  Change Password
                  </button>
                </div>
              </div>
            </div>

            <div className="flex justify-between items-center py-3 ">
              <div>
                <h1 className="text-sm">Delete your account</h1>
                <h1 className="text-[#606360] text-sm">
                  Permanently delete your Eats Bits data.
                </h1>
              </div>
              <div>
                <div className="flex justify-center">
                  <button className="w-[150px] font-semibold text-xs flex justify-center items-center gap-x-4 text-[#FD7657] h-[38px] rounded-xl bg-[#FD76571A] duration-500">
                    Delete Account
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserProfileAccount;
