import { S3_BASE_URL } from "src/constants/endpoints";
import DeleteIcon from "../../assests/images/dashboard/delete.png";
import CheckBox from "../../assests/images/dashboard/Checkbox 2.png";
import RestuarantImg from "../../assests/images/dashboard/default-thumbnail (1).jpg";

const ItemsCard = ({
  item,
  handleCategoryItemClick,
  setEditCategoryItemModal,
  isSelected,
  isCombo,
  handleClick,
  indexes,
  onClick,
  showQuantityOptions,
  changeCardQuantity,
  isUpdateCase,
}: any) => {

  const handleCardClick = (e: any) => {
    e.stopPropagation();
    if (handleClick) {
      handleClick(indexes);
    }
    if (onClick) {
      onClick(e, item);
    }
  };


  return (
    <div
      className="flex items-center justify-center bg-gray-100"
      onClick={handleCardClick}
    >
      <div
        className={`${
          isSelected && "border-[#FD7657]"
        }  xl:w-[290px] relative  ml-3 mb-3 border-[#F1F1F2] border bg-white rounded-xl h-[240px] w-[280px]`}
      >
        {isCombo && isSelected && (
          <>
            <img
              className="w-[20px] h-[20px] absolute right-2 top-2"
              src={CheckBox}
              alt=""
            />
          </>
        )}

        {!isCombo && (
          <div
            className="absolute right-2 top-2 h-[42px] w-[42px] rounded-xl bg-[#FEF5F4] flex justify-center items-center cursor-pointer"
            onClick={() => handleCategoryItemClick(item?.correlationId)}
          >
            <img className="w-[12px] h-[13px]" src={DeleteIcon} alt="Delete" />
          </div>
        )}

        <div
          className="flex justify-center bg-[#dadada] h-[140px] rounded-xl"
          onClick={() =>
            setEditCategoryItemModal && setEditCategoryItemModal(item)
          }
        >
          <img
            className="w-full rounded-t-xl cursor-pointer object-cover bg-no-repeat"
            src={
              item?.bucketKeyName
                ? item?.bucketKeyName?.includes("blob")
                  ? item?.bucketKeyName
                  : S3_BASE_URL + item?.bucketKeyName
                : RestuarantImg
            }
            alt=""
          />
        </div>
        <div className="pb-4 pr-4 pl-4 pt-[10px]">
          <div className="flex justify-between items-center mt-1">
            <div className="tooltip">
              <h1 className="tooltip-section w-[200px] text-sm font-medium ">
                {item?.englishName}{" "}
                {item?.nonEnglishName && `, ${item?.nonEnglishName}`}
              </h1>
              <span className="tooltip-text">
                {item?.englishName}, {item?.nonEnglishName}
              </span>
            </div>
            <span className="text-base font-medium">${item?.price}</span>
          </div>

          <div className="flex flex-wrap w-full  gap-1  mt-1 items-center">
            {item?.itemOptions && item?.itemOptions?.length > 0 ? (
              item?.itemOptions?.slice(0, 4)?.map((option: any, index: any) => (
                <>
                  <h1 className="text-xs text-[#A3A3A3]" key={index}>
                    {option?.name}
                  </h1>
                  {index < item?.itemOptions?.slice(0, 4)?.length - 1 && (
                    <span className="w-[4px] h-[4px] bg-[#D9D9D9] rounded-full"></span>
                  )}
                </>
              ))
            ) : (
              <></>
            )}
            {item?.itemOptions?.length > 5 && (
              <div className="mb-1">
                <span className="text-[#252525] text-[10px] px-2 py-1 bg-[#f3f3f4] rounded-xl font-medium">
                  +{item?.itemOptions?.length - 4}
                </span>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ItemsCard;
