export const roleBasesRoute = (restaurantSidebar: any, permissions: any) => {
  return restaurantSidebar.filter((item: any) => {
    if (!item.isRoleBase) {
      return true;
    } else if (
      item.isRoleBase &&
      permissions[item.roleName as keyof typeof permissions]
    ) {
      return true;
    }
    return false;
  });
};
