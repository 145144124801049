import closeIcon from "../../assests/images/dashboard/Vector 3.png";
import editIcon from "../../assests/images/dashboard/pencil.png";
import infoIcon from "../../assests/images/dashboard/info.svg";
import { useCallback, useEffect, useState } from "react";
import Dropzone from "react-dropzone";
import EyeIcon from "../../assests/images/dashboard/visibility.png";
import { ManageOptionModal } from "./ManageOptionModal";
import ItemPreviewModal from "./ItemPreviewModal";
import { useItemsForm } from "./ItemsForm/useItemsForm";
import {
  ADD_ITEM,
  UPDATE_ITEM,
  UPDATE_ITEM_OPTION,
  UPLOAD_CATEGORY_ITEM_IMAGE,
} from "src/constants/mutations";
import { GRAPHQL_ENDPOINT, S3_BASE_URL } from "src/constants/endpoints";
import { useAxiosInterceptor } from "src/hooks/useAxiosInterceptor";
import { successToast } from "src/utils/toasts";
import { mimeTypeToExtension } from "../RestaurantSetting/RestaurantSettingComponent";
import { useAuth } from "src/contexts/auth-provider";
import axios from "axios";
import DeleteIcon from "../../assests/images/dashboard/Text input container.png";
import defaultImg from "../../assests/images/dashboard/default-thumbnail (1).jpg";
import { BtnLoader } from "src/Loader";

export const ManageitemModal = ({
  setShowCreateModal,
  title,
  categoryId,
  categoryName,
  editCategoryItemModal,
  setEditCategoryItemModal,
  getItems
}: any) => {
  const { user, hasRestaurant } = useAuth();
  const { formik } = useItemsForm();
  const { axBe } = useAxiosInterceptor();
  const [uploadImage, setUploadImage] = useState<any>(null);
  const [isOtherLanguageEnabled, setIsOtherLanguageEnabled] = useState(false);
  const [showOptionModal, setShowOptionModal] = useState(false);
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [editOptionIndex, setEditOptionIndex] = useState(-1);
  const [loading, setLoading] = useState(false);
  const { setValues } = formik;

  const handleToggleChange = () => {
    setIsOtherLanguageEnabled((prevState) => !prevState);
  };

  const handleFileChange = (file: any) => {
    setUploadImage({
      url: URL.createObjectURL(file),
      file,
    });
  };

  const addItem = async () => {
    setLoading(true);
    try {
      const payload: any = {
        categoryCorrelationId: categoryId,
        ...formik.values.item,
        price: formik.values.item?.price.toString(),
        taxRate: formik.values.item?.taxRate.toString(),
        itemOptionInputList: formik.values.item?.options?.map(
          (option: any) => ({
            description: option.description,
            isRequired: option.isRequired === "false" ? false : true,
            name: option.name,
            renderSequence: Number(option.renderSequence),
            addItemOptionElementInputList: option?.elements
              .filter((element: any) => element?.price)
              ?.map((item: any) => ({
                name: item?.name,
                description: item?.description,
                price: item?.price || 0,
                renderSequence: Number(item.renderSequence),
              })),
          })
        ),
      };
      delete payload.options;
      const { data } = await axBe.post(GRAPHQL_ENDPOINT, {
        query: ADD_ITEM,
        variables: {
          addItemInput: payload,
        },
      });
      if (data?.errors?.length) {
        throw data?.errors[0];
      }
      const addItemId = data.data?.addItem?.correlationId;
      if (addItemId && uploadImage?.file) {
        await uploadItemImage(addItemId);
      } else {
        setShowCreateModal(true);
        successToast("Category Item Created!");
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const updateItem = async () => {
    setLoading(true);
    try {
      if (formik?.touched?.item) {
        const {
          englishDescription,
          englishName,
          nonEnglishDescription,
          nonEnglishName,
          price,
          abbreviation,
          taxRate,
          tag,
        } = formik.values.item;
        const variables = {
          updateItemInput: {
            itemCorrelationId: editCategoryItemModal?.correlationId,
            englishName,
            englishDescription,
            price: String(price),
            abbreviation,
            taxRate: String(taxRate),
            tag,
            nonEnglishDescription,
            nonEnglishName,
            isActive: true,
          },
        };
        const { data } = await axBe.post(GRAPHQL_ENDPOINT, {
          query: UPDATE_ITEM,
          variables: variables,
        });
        if (data?.errors?.length) {
          throw data?.errors[0];
        }
        setEditCategoryItemModal(data?.data?.updateItemInput);
      }
      if (uploadImage?.file) {
        await uploadItemImage(editCategoryItemModal?.correlationId);
      } else {
        showToastAndHideLoader(false);
      }
      getItems()
    } catch (error) {
      setLoading(false);
    }
  };

  const deleteOption = async (option: any) => {
    // setUpdateOptionLoading(true);
    try {
      const { correlationId } = option;
      const variables = {
        updateItemOptionInput: {
          itemOptionCorrelationId: correlationId,
          isActive: false,
        },
      };
      const { data } = await axBe.post(GRAPHQL_ENDPOINT, {
        query: UPDATE_ITEM_OPTION,
        variables: variables,
      });
      if (data?.errors?.length) {
        throw data?.errors[0];
      }
      removeOption(
        formik.values.item.options.findIndex(
          (option: any) => option?.correlationId === correlationId
        )
      );
      successToast("Item Option Deleted!");
    } catch (error) {
    }
  };

  const uploadItemImage = async (itemCorrelationId: string) => {
    const variables = {
      createItemPreSignedUrlInput: {
        storeUserCorrelationId: user?.storeUser?.correlationId,
        itemName: formik.values.item.englishName,
        fileNameExtension: mimeTypeToExtension[uploadImage?.file?.type],
        storeCorrelationId: hasRestaurant?.correlationId,
        itemCorrelationId,
        categoryName,
      },
    };
    try {
      const res = await axBe.post(GRAPHQL_ENDPOINT, {
        query: UPLOAD_CATEGORY_ITEM_IMAGE,
        variables: variables,
      });
      if (!res?.data?.error?.length) {
        const awsUrl =
          res.data?.data?.createUploadPreSignedUrlForItem?.preSignedUrl;
        await axios.put(awsUrl, uploadImage.file, {
          headers: {
            "Content-Type": uploadImage?.file?.type,
            "x-amz-acl": "public-read",
          },
        });
        showToastAndHideLoader();
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const showToastAndHideLoader = (isClosedModal = true) => {
    if (isClosedModal) {
      setShowCreateModal(true);
    }
    // successToast(editCategoryItemModal?.correlationId && "Category Item Updated!");
    setLoading(false);
  };

  const removeOption = (index: number) => {
    const tempOptions = [...formik.values.item?.options];
    tempOptions.splice(index, 1);
    formik.setFieldValue("item.options", tempOptions);
  };

  const addOptionForm = () => {
    formik.setFieldValue("item.options", [
      ...formik.values.item.options,
      {
        name: "",
        description: "",
        renderSequence: null,
        isRequired: false,
        elements: [
          {
            name: "",
            description: "",
            renderSequence: null,
            price: null,
          },
        ],
      },
    ]);
    setShowOptionModal(!showOptionModal);
  };

  const patchAndSort = useCallback(() => {
    const {
      englishDescription,
      englishName,
      // bucketKeyName,
      nonEnglishDescription,
      nonEnglishName,
      price,
      itemOptions,
      abbreviation,
      taxRate,
      tag,
    } = editCategoryItemModal;
    setValues({
      item: {
        englishDescription,
        englishName,
        nonEnglishDescription,
        nonEnglishName,
        price,
        abbreviation,
        taxRate,
        tag,
        options: itemOptions
          .map((option: any) => ({
            correlationId: option.correlationId,
            description: option.description,
            isRequired:
              option.isRequired === "false" || option.isRequired === false
                ? false
                : true,
            name: option.name,
            renderSequence: String(option.renderSequence),
            elements: option?.itemOptionElements
              .map((element: any) => ({
                ...element,
                correlationId: element.correlationId,
                renderSequence: String(element.renderSequence),
              }))
              .sort((a: any, b: any) =>
                a.renderSequence?.localeCompare(b.renderSequence)
              ),
          }))
          .sort((a: any, b: any) =>
            a.renderSequence?.localeCompare(b.renderSequence)
          ),
      },
    });
  }, [editCategoryItemModal, setValues]);

  useEffect(() => {
    if (!!editCategoryItemModal) {
      patchAndSort();
      if (editCategoryItemModal?.bucketName) {
        setUploadImage({
          url: S3_BASE_URL + editCategoryItemModal?.bucketName,
          file: null,
        });
      }
      setIsOtherLanguageEnabled(!!editCategoryItemModal?.nonEnglishName);
    }
  }, [editCategoryItemModal, setValues, patchAndSort]);

  const sortedOptions = formik.values.item?.options;

  const isBtnDisabled = editCategoryItemModal?.correlationId
    ? !formik.touched?.item && !uploadImage?.file
    : !formik.isValid || !formik.dirty;

  return (
    <>
      <div
        className="relative z-10"
        aria-labelledby="modal-title"
        role="dialog"
        aria-modal="true"
      >
        <div className="fixed inset-0 flex bg-black/30 min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-[0px_4px_0px_880px_rgba(37,37,37,0.75)] transition-all sm:my-8">
            <div className="flex items-center justify-center bg-gray-100">
              <div
                className={`bg-white overflow-scroll px-8 py-6 rounded-lg w-[788px] max-w-[788px] min-w-10 flex flex-col`}
              >
                <div className="top flex justify-between items-center">
                  <span className="text-xl font-semibold text-[#252525]">
                    {title}
                  </span>
                  <img
                    className="w-3 h-3 cursor-pointer"
                    src={closeIcon}
                    alt=""
                    onClick={() => setShowCreateModal(true)}
                  />
                </div>
                {/* Image with category */}
                <div className="mt-8 flex">
                  <Dropzone
                    accept={{
                      "image/png": [".png"],
                      "image/jpg": [".jpg"],
                      "image/jpeg": [".jpeg"],
                    }}
                    onDrop={(acceptedFiles) =>
                      handleFileChange(acceptedFiles[0])
                    }
                  >
                    {({ getRootProps, getInputProps }) => (
                      <div>
                        <div className="w-[67px] h-[67px] rounded-lg border border-[#F1F1F2] flex justify-center items-center relative">
                          <img
                            src={
                              uploadImage?.url
                                ? uploadImage?.url
                                : editCategoryItemModal?.bucketKeyName
                                ? editCategoryItemModal?.bucketKeyName.includes(
                                    "blob"
                                  )
                                  ? editCategoryItemModal?.bucketKeyName
                                  : S3_BASE_URL +
                                    editCategoryItemModal?.bucketKeyName
                                : defaultImg
                            }
                            alt=""
                            className="w-[55px] h-[55px] object-cover"
                          />
                        </div>
                        <button>
                          <img
                            src={editIcon}
                            alt=""
                            className="w-6 h-6 absolute top-[85px] left-[83px]"
                            {...getRootProps()}
                          />
                          <input {...getInputProps()} />
                        </button>
                      </div>
                    )}
                  </Dropzone>
                  <div className="w-full ml-7">
                    <span className="text-[#9B9B9B] text-sm">
                      Name <span className="text-[red]">*</span>
                    </span>
                    <input
                      className="text-xs text-[#9B9B9B] h-[40px] w-full border rounded-lg border-[#F1F1F2] mt-1 px-2"
                      type="text"
                      name="item.englishName"
                      id="item.englishName"
                      value={formik.values.item.englishName}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                    />
                    {formik.touched.item?.englishName &&
                    formik.errors.item?.englishName ? (
                      <div className="text-orange text-xs mt-2">
                        {formik.errors.item?.englishName}
                      </div>
                    ) : null}
                  </div>
                  <div className="w-full ml-7">
                    <span className="text-[#9B9B9B] text-sm">
                      Abbreviation
                    </span>
                    <input
                      className="text-xs text-[#9B9B9B] h-[40px] w-full border rounded-lg border-[#F1F1F2] mt-1 px-2"
                      type="text"
                      name="item.abbreviation"
                      id="item.abbreviation"
                      value={formik.values.item.abbreviation}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                    />
                  </div>
                  <div className="w-full ml-7">
                    <span className="text-[#9B9B9B] text-sm">
                      Price <span className="text-[red]">*</span>
                    </span>
                    <input
                      className="text-xs text-[#9B9B9B] h-[40px] w-full border rounded-lg border-[#F1F1F2] mt-1 px-2"
                      type="number"
                      name="item.price"
                      id="item.price"
                      value={formik.values.item?.price}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                    />
                    {formik.touched.item?.price && formik.errors.item?.price ? (
                      <div className="text-orange text-xs mt-2">
                        {formik.errors.item?.price}
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="flex justify-between w-full gap-x-2">
                  <div className="w-full">
                    <span className="text-[#9B9B9B] text-sm">
                      Tax Rate <span className="text-[red]">*</span>
                    </span>
                    <input
                      className="text-xs text-[#9B9B9B] h-[40px] w-full border rounded-lg border-[#F1F1F2] mt-1 px-2"
                      type="number"
                      name="item.taxRate"
                      id="item.taxRate"
                      value={formik.values.item?.taxRate}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                    />
                    {formik.touched.item?.taxRate &&
                    formik.errors.item?.taxRate ? (
                      <div className="text-orange text-xs mt-2">
                        {formik.errors.item?.taxRate}
                      </div>
                    ) : null}
                  </div>
                  <div className="w-full">
                    <span className="text-[#9B9B9B] text-sm">Tag</span>
                    <input
                      className="text-xs text-[#9B9B9B] h-[40px] w-full border rounded-lg border-[#F1F1F2] mt-1 px-2"
                      type="text"
                      name="item.tag"
                      id="item.tag"
                      value={formik.values.item?.tag}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                    />
                  </div>
                </div>

                <div className="flex gap-x-4">
                  <div className="w-full mt-2">
                    <span className="text-[#9B9B9B] text-sm">Description</span>
                    <textarea
                      className="p-2 text-xs text-[#9B9B9B] h-[70px] w-full border rounded-lg border-[#F1F1F2] mt-1 px-2"
                      name="item.englishDescription"
                      id="item.englishDescription"
                      value={formik.values.item?.englishDescription}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                    />
                  </div>
                </div>

                <div className="divider border-[#EBF5FF] border-[1px] mt-2 "></div>
                {isOtherLanguageEnabled && (
                  <>
                    <div className="w-full mt-4">
                      <span className="text-[#9B9B9B] text-sm">
                        Name (Other language)
                      </span>
                      <input
                        className="text-xs text-[#9B9B9B] h-[40px] w-full border rounded-lg border-[#F1F1F2] mt-1 px-2"
                        type="text"
                        name="item.nonEnglishName"
                        id="item.nonEnglishName"
                        value={formik.values.item?.nonEnglishName}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                      />
                    </div>
                    <div className="mt-2">
                      <span className="text-[#9B9B9B] text-sm">
                        Description (Other language)
                      </span>
                      <textarea
                        className="p-2 text-xs text-[#9B9B9B] h-[70px] w-full border rounded-lg border-[#F1F1F2] mt-1 px-2"
                        name="item.nonEnglishDescription"
                        id="item.nonEnglishDescription"
                        value={formik.values.item?.nonEnglishDescription}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                      />
                    </div>
                  </>
                )}
                <div className="flex mt-6 items-center">
                  <input
                    className="mr-2 h-3.5 w-8 appearance-none rounded-[0.4375rem] bg-[#9B9B9B] before:pointer-events-none before:absolute before:h-3.5 before:w-3.5 before:rounded-full before:bg-transparent before:content-[''] after:absolute after:z-[2] after:-mt-[0.1875rem] after:h-5 after:w-5 after:rounded-full after:border-none after:bg-white after:shadow-[0_0px_3px_0_rgb(0_0_0_/_7%),_0_2px_2px_0_rgb(0_0_0_/_4%)] after:transition-[background-color_0.2s,transform_0.2s] after:content-[''] checked:bg-orange checked:after:absolute checked:after:z-[2] checked:after:-mt-[3px] checked:after:ml-[1.0625rem] checked:after:h-5 checked:after:w-5 checked:after:rounded-full checked:after:border-none checked:after:bg-primary checked:after:shadow-[0_3px_1px_-2px_rgba(0,0,0,0.2),_0_2px_2px_0_rgba(0,0,0,0.14),_0_1px_5px_0_rgba(0,0,0,0.12)] checked:after:transition-[background-color_0.2s,transform_0.2s] checked:after:content-[''] hover:cursor-pointer focus:outline-none focus:ring-0 focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-[3px_-1px_0px_13px_rgba(0,0,0,0.6)] focus:before:transition-[box-shadow_0.2s,transform_0.2s] focus:after:absolute focus:after:z-[1] focus:after:block focus:after:h-5 focus:after:w-5 focus:after:rounded-full focus:after:content-[''] checked:focus:border-primary checked:focus:bg-primary checked:focus:before:ml-[1.0625rem] checked:focus:before:scale-100 checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca] checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] dark:bg-neutral-600 dark:after:bg-neutral-400 dark:checked:bg-primary dark:checked:after:bg-primary dark:focus:before:shadow-[3px_-1px_0px_13px_rgba(255,255,255,0.4)] dark:checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca]"
                    type="checkbox"
                    role="switch"
                    checked={isOtherLanguageEnabled}
                    onChange={handleToggleChange}
                  />
                  <p className="mx-2 text-[14px] font-medium">Other language</p>
                  <img src={infoIcon} alt="" width={15} height={15} />
                </div>
                {sortedOptions.length > 0 && (
                  <div className="mx-2 text-[14px] font-medium mt-2">
                    Options
                  </div>
                )}

                <div className="overflow-scroll px-2 max-h-[200px]">
                  {sortedOptions?.map((option: any, index) => {
                    if (!option?.name) return null;
                    return (
                      <>
                        <div
                          className="mt-3 transition-transform hover:shadow-lg flex-col flex justify-between rounded-md border border-[#F1F1F2] p-4 mb-6 relative"
                          key={index}
                        >
                          <div className="flex gap-x-5 w-full items-center relative">
                            <div>
                              <div className="text-sm text-[#9B9B9B] w-[200px] flex items-center">
                                <span className="">{option?.name}</span>
                              </div>
                            </div>
                            <div></div>
                            <div className="absolute right-0 flex gap-x-2">
                              <button
                                onClick={() => {
                                  setEditOptionIndex(index);
                                  setShowOptionModal(true);
                                }}
                              >
                                <img
                                  className="w-[35px] h-[35px]"
                                  src={editIcon}
                                  alt=""
                                />
                              </button>
                              <img
                                className="h-[40px] w-[40px] cursor-pointer"
                                onClick={() =>
                                  option?.correlationId
                                    ? deleteOption(option)
                                    : removeOption(index)
                                }
                                src={DeleteIcon}
                                alt=""
                              />
                            </div>
                          </div>
                          {option?.description && (
                            <div className="">
                              <div className="text-sm flex items-center text-[#9B9B9B]  w-[420px]">
                                <span className="">
                                  {" "}
                                  {option?.description || "-"}
                                </span>
                              </div>
                            </div>
                          )}
                        </div>
                      </>
                    );
                  })}
                </div>

                <div className="flex justify-end mt-4 flex-1 items-end gap-x-4">
                  <div className="flex justify-start mt-4 flex-1 items-start gap-x-4">
                    <button
                      className="px-8 text-xs flex justify-center items-center gap-2 text-black h-[34px] rounded-md bg-[#F5F5F6] hover:bg-[#F5F5F6] hover:text-black duration-500"
                      onClick={() => setShowPreviewModal(!showPreviewModal)}
                    >
                      <img className="h-[13px] w-[20px]" src={EyeIcon} alt="" />{" "}
                      Item Preview
                    </button>
                  </div>
                  <button
                    className={`${
                      (  !editCategoryItemModal?.correlationId && (!formik.isValid || !formik.dirty) && "opacity-20" )
                    } px-8 text-xs flex justify-center items-center gap-2 text-black h-[34px] rounded-md bg-[#F5F5F6] hover:bg-[#F5F5F6] hover:text-black duration-500`}
                    onClick={addOptionForm}
                    disabled={ editCategoryItemModal?.correlationId ? false : (!formik.isValid || !formik.dirty)}
                  >
                    Add Option
                  </button>

                  {loading ? (
                    <div className="mb-2">
                      <BtnLoader />
                    </div>
                  ) : (
                    <button
                      disabled={isBtnDisabled}
                      onClick={!!editCategoryItemModal ? updateItem : addItem}
                      className={`${
                        isBtnDisabled && "opacity-20"
                      } px-8 text-xs flex justify-center items-center gap-2 text-white h-[34px] rounded-md bg-orange hover:bg-[#F5F5F6] hover:text-black duration-500 `}
                    >
                      {!!editCategoryItemModal ? "Update Item" : "Confirm"}
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {showOptionModal ? (
        <ManageOptionModal
          title={editOptionIndex >= 0 ? "Edit Option" : "Add Option"}
          setShowOptionModal={() => {
            setEditOptionIndex(-1);
            setShowOptionModal(!showOptionModal);
          }}
          formik={formik}
          optionIndex={
            editOptionIndex >= 0
              ? editOptionIndex
              : formik?.values.item.options?.length - 1
          }
          removeOption={removeOption}
          itemCorrelationId={editCategoryItemModal?.correlationId}
        />
      ) : (
        <></>
      )}

      {showPreviewModal ? (
        <ItemPreviewModal
          setShowPreviewModal={() => setShowPreviewModal(!showPreviewModal)}
          item={formik.values.item}
          img={uploadImage}
          editCategoryItemModal={editCategoryItemModal}
        />
      ) : (
        <></>
      )}
    </>
  );
};
