import defaultImg from "../../assests/images/dashboard/user_4159471.png";
import React, { useEffect, useState } from "react";
import { GRAPHQL_ENDPOINT, S3_BASE_URL } from "src/constants/endpoints";
import { useAxiosInterceptor } from "src/hooks/useAxiosInterceptor";
import {
  UPDATE_EMPLOYEE,
  UPLOAD_EMPLOYEE_IMAGE,
} from "src/constants/mutations";
import { successToast } from "src/utils/toasts";
import axios from "axios";
import Dropzone from "react-dropzone";
import DeleteIcon from "../../assests/images/dashboard/Button 2.png";
import { BtnLoader } from "src/Loader";
import { useAuth } from "src/contexts/auth-provider";
import { useFormik } from "formik";
import * as Yup from "yup";

export const mimeTypeToExtension: any = {
  "image/png": "png",
  "image/jpg": "jpg",
  "image/jpeg": "jpeg",
};

export const EmployeeProfile = () => {
  const { axBe } = useAxiosInterceptor();
  const { user, setUser } = useAuth();
  const [imageUrl, setImageUrl] = useState<string>("");
  const [uploadImage, setUploadImage] = useState<any>(null);
  const [uploadImageLoader, setUploadImageLoader] = useState(false);
  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      // password: "",
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required("Employer first name is required"),
      lastName: Yup.string().required("Employer last name is required"),
      email: Yup.string().email("Invalid email").required("Email Required"),
      phoneNumber: Yup.string()
        .matches(/^[0-9]+$/, "Phone number must be digits only")
        .required("Phone number is required"),
    }),
    onSubmit: () => {},
  });

  useEffect(() => {
    if (user) {
      formik.setFieldValue("firstName", user?.storeUser?.firstName || "");
      formik.setFieldValue("lastName", user?.storeUser?.lastName || "");
      formik.setFieldValue("email", user?.storeUser?.email || "");
      formik.setFieldValue("phoneNumber", user?.storeUser?.phoneNumber || "");
    }
  }, [user]);

  const uploadEmoloyeeImage = async () => {
    setUploadImageLoader(true);
    const variables = {
      createUserPreSignedUrlInput: {
        userCorrelationId: user.storeUser.correlationId,
        fileNameExtension: mimeTypeToExtension[uploadImage?.file?.type],
      },
    };
    try {
      const res = await axBe.post(GRAPHQL_ENDPOINT, {
        query: UPLOAD_EMPLOYEE_IMAGE,
        variables: variables,
      });
      if (!res?.data?.error?.length) {
        const awsUrl =
          res.data?.data?.createUploadPreSignedUrlForUser?.preSignedUrl;
        await axios.put(awsUrl, uploadImage.file, {
          headers: {
            "Content-Type": uploadImage?.file?.type,
            "x-amz-acl": "public-read",
          },
        });
        setImageUrl(uploadImage?.url);
        setUploadImageLoader(false);
        successToast("Image successfully uploaded");
      }
    } catch (error) {
      console.error("Error uploading image:", error);
      setUploadImageLoader(false);
    }
  };

  const handleFileChange = (file: any) => {
    setUploadImage({
      url: URL.createObjectURL(file),
      file,
    });
  };

  const updateEmployee = async () => {
    setLoading(true);
    try {
      const payload: any = {
        employeeCorrelationId: user.storeUser.correlationId,
        firstName: formik.values.firstName,
        lastName: formik.values.lastName,
        email: formik.values.email,
        phoneNumber: formik.values.phoneNumber.toString(),
      };
      if (!payload.password) {
        delete payload.password;
      }
      const variables: any = {
        updateStoreEmployeeInput: payload,
      };
      const { data } = await axBe.post(GRAPHQL_ENDPOINT, {
        query: UPDATE_EMPLOYEE,
        variables: variables,
      });
      if (data && data.data.updateStoreEmployee) {
        // const user = data?.data?.updateStoreEmployee;
        // const localUser: any = localStorage.getItem("user");
        // const parsedUser = JSON.parse(localUser);
        // const stores = parsedUser.storeUser.stores;
        // const completeUser = {
        //   ...parsedUser,
        //   storeUser: {
        //     ...user,
        //     stores,
        //   },
        // };
        // await localStorage.setItem("user", JSON.stringify(completeUser));
        // setUser(user);
        successToast("Employee has been Updated!");
      } else {
        throw new Error(data.error || "Failed to send invitation.");
      }
    } catch (error) {
      console.error("Error sending invitation:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setImageUrl(S3_BASE_URL + user?.storeUser?.bucketKeyName);
  }, []);

  return (
    <>
      <div className="h-[425px] bg-white rounded-3xl p-6 border border-[#F1F1F2]">
        <h1 className="font-semibold text-xl">Account</h1>
        <p className="text-sm text-[#606360] my-2">
          Please configure your profile and fill in your information.
        </p>

        <div className="flex items-center mt-4">
          <div className="flex gap-4 items-center">
            <Dropzone
              accept={{
                "image/png": [".png"],
                "image/jpg": [".jpg"],
                "image/jpeg": [".jpeg"],
              }}
              onDrop={(acceptedFiles) => handleFileChange(acceptedFiles[0])}
            >
              {({ getRootProps, getInputProps }) => (
                <>
                  <div className="relative w-[61px] h-[61px] rounded-full border border-[#F1F1F2]">
                    <img
                      className="absolute object-cover h-full w-full rounded-full"
                      src={uploadImage?.url || imageUrl || defaultImg}
                      alt=""
                    />
                  </div>
                  {uploadImage?.url ? (
                    <>
                      {uploadImageLoader ? (
                        <div>
                          <BtnLoader />
                        </div>
                      ) : (
                        <button
                          className="text-sm text-black bg-white rounded-lg border border-[#EBEBEB] px-3 py-[9px]"
                          onClick={uploadEmoloyeeImage}
                        >
                          Upload Photo
                        </button>
                      )}
                    </>
                  ) : (
                    <button
                      className="text-sm text-orange bg-white rounded-lg border border-[#EBEBEB] px-3 py-[9px]"
                      {...getRootProps()}
                    >
                      Select new image
                      <input {...getInputProps()} />
                    </button>
                  )}
                </>
              )}
            </Dropzone>
            {uploadImage?.url && (
              <img
                className="w-[40px] h-[40px] cursor-pointer"
                src={DeleteIcon}
                alt="Delete"
                onClick={() => setUploadImage(null)}
              />
            )}
          </div>
          {/* <div className="w-[124px] h-[124px] rounded-full flex justify-center items-center">
            <img
              className="object-cover rounded-full"
              src={defaultImg}
              alt=""
            />
          </div>
          <div className="ml-4">
            <div className="flex items-center justify-center w-[140px] h-[40px] rounded-xl border border-[#EBEBEB]">
              <span className="text-sm font-medium">Upload Photo</span>
            </div>
            <div className="text-sm text-[#606360] mt-2">
              Pick a photo up to 4 MB.
            </div>
          </div>
          <div className="mb-7 flex items-center justify-center w-[140px] h-[40px] rounded-xl border border-[#EBEBEB]">
            <span className="text-sm font-medium text-[#FF6A55]">
              Remove Photo
            </span>
          </div> */}
        </div>
        <div className="flex gap-x-3 w-full items-center mt-2">
          <div className="mt-2 w-full">
            <label className="block text-xs font-medium text-[#9B9B9B] p-1">
              First Name <span>*</span>
            </label>
            <input
              name="firstName"
              type="text"
              className="h-[40px] text-xs w-full border rounded-lg border-[#F1F1F2] mt-1 px-2"
              placeholder="Enter first name"
              value={formik.values?.firstName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {formik.touched.firstName && formik.errors?.firstName ? (
              <div className="text-orange text-xs mt-2">
                {formik.errors?.firstName}
              </div>
            ) : null}
          </div>
          <div className="mt-2 w-full">
            <label className="block text-xs font-medium text-[#9B9B9B] p-1">
              Last Name <span>*</span>
            </label>
            <input
              name="lastName"
              type="text"
              className="h-[40px] text-xs w-full border rounded-lg border-[#F1F1F2] mt-1 px-2"
              placeholder="Enter last name"
              value={formik.values.lastName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {formik.touched.lastName && formik.errors.lastName ? (
              <div className="text-orange text-xs mt-2">
                {formik.errors.lastName}
              </div>
            ) : null}
          </div>
        </div>
        <div className="flex gap-x-3 w-full items-center mt-2">
          <div className="mt-2 w-full">
            <label className="block text-xs font-medium text-[#9B9B9B] p-1">
              Email <span>*</span>
            </label>
            <input
              name="email"
              type="email"
              className="h-[40px] text-xs w-full border rounded-lg border-[#F1F1F2] mt-1 px-2"
              placeholder="Enter email"
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {formik.touched.email && formik.errors.email ? (
              <div className="text-orange text-xs mt-2">
                {formik.errors.email}
              </div>
            ) : null}
          </div>
          <div className="mt-2 w-full">
            <label className="block text-xs font-medium text-[#9B9B9B] p-1">
              Phone number <span>*</span>
            </label>
            <input
              name="phoneNumber"
              type="number"
              className="h-[40px] text-xs w-full border rounded-lg border-[#F1F1F2] mt-1 px-2"
              placeholder="Enter phone number"
              value={formik.values.phoneNumber}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {formik.touched.phoneNumber && formik.errors.phoneNumber ? (
              <div className="text-orange text-xs mt-2">
                {formik.errors.phoneNumber}
              </div>
            ) : null}
          </div>
        </div>
        {loading ? (
          <div className="mt-7 ml-4 flex justify-start">
            <BtnLoader />
          </div>
        ) : (
          <button
            className="text-sm mt-6 px-8 py-2 bg-[white] text-orange border border-[#F1F1F2] rounded-lg"
            onClick={updateEmployee}
          >
            Save
          </button>
        )}
      </div>
      <div className="h-[100px] bg-white rounded-3xl p-6 border border-[#F1F1F2] mt-4">
        <div className="flex justify-between items-center">
          <div>
            <h1 className="text-sm font-normal">Password</h1>
            <h1 className="text-sm text-[#606360] mt-2">
              Change your password.
            </h1>
          </div>

          <div>
            <div className=" bg-[#FD76571A] flex items-center justify-center w-[170px] h-[42px] rounded-xl border border-[#EBEBEB]">
              <span className="text-sm font-medium text-[#FD7657]">
                Change Password
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
