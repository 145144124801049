import { useMutation } from "@apollo/client";
import Logo from "../../components/common/Logo";
import { useEffect, useState } from "react";
import "react-phone-input-2/lib/style.css";
import { Link, useParams } from "react-router-dom";
import { ACTIVATE_USER_ACCOUNT } from "src/constants/mutations";
import ArrowBack from "../../assests/images/landing-page/arrow_back.png";

export const ActivateAccount = () => {
  const routeParams = useParams();
  const [message, setMessage] = useState("");

  const [activateUserAccount, { data, loading, error }] = useMutation(
    ACTIVATE_USER_ACCOUNT,
    {
      variables: {
        activateUserAccountInput: {
          activationCode: routeParams.verificationCode,
        },
      },
    }
  );

  useEffect(() => {
    activateUserAccount();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (data) {
      setMessage(data.activateUserAccount.message);
    }
  }, [data]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error : {error.message}</p>;

  return (
    <div className="flex justify-center flex-col items-center gap-64">
      <div className=" mt-[5%]">
        <div className="flex ">
          <Logo />
          <h1 className="ml-2 text-3xl">EatsBits</h1>
        </div>
      </div>

      <div className="max-sm:mt-[5%] w-[420px] rounded-[12px] mt-[0%] p-6 border border-lightGrey">
        <h1 className="text-center text-2xl mt-2">
          {message.length > 0 && message}
        </h1>
      </div>

      <div className="flex justify-center items-center absolute bottom-12 cursor-pointer">
        <div className="mr-2">
          <img src={ArrowBack} alt="" />
        </div>
        <Link to="/login">
          <h1 className="text-orange">Back To Login</h1>
        </Link>
      </div>
    </div>
  );
};
