import React, { forwardRef } from "react";
import EmployeeImg from "../../assests/images/dashboard/Text input 2.png";
import Dropdown from "../Dropdown/EmployeeDropdown";

const EmployeeCard = forwardRef<HTMLDivElement, any>(
  (
    {
      employee,
      handleEmployeeClick,
      editEmployeeModal,
      index,
      changeModeToEmployee,
    },
    ref
  ) => {
    return (
      <div
        key={employee.correlationId}
        ref={ref}
        className="relative w-[280px] border-[#F1F1F2] border bg-white rounded-xl"
      >
        <div>
          <img
            className="h-[73px] w-[100%] rounded-t-xl "
            src={employee.bucketKeyName ? employee.bucketKeyName : EmployeeImg}
            alt={employee.name}
          />
        </div>
        <div
          className="absolute right-2 top-2 h-[42px] w-[42px] rounded-xl bg-[#FEF5F4] flex justify-center items-center cursor-pointer"
          onClick={(event) => {
            event.stopPropagation();
          }}
        >
          <Dropdown
            ref={ref}
            index={index}
            editEmployeeModal={editEmployeeModal}
            employee={employee}
            handleEmployeeClick={handleEmployeeClick}
          />
        </div>

        <div className="p-4 flex flex-col justify-center items-center gap-y-2">
          <h1 className="text-lg font-semibold">
            {employee?.firstName} {employee?.lastName}
          </h1>
          <h1 className="text-xs">{employee?.email}</h1>
          <h1 className="text-xs text-[#828282]">
            {employee?.roles[0].slice(14).charAt(0).toUpperCase() +
              employee?.roles[0].slice(14).slice(1).toLowerCase()}
          </h1>
        </div>
      </div>
    );
  }
);

export default EmployeeCard;
