import { useFormik } from "formik";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { GRAPHQL_ENDPOINT } from "src/constants/endpoints";
import { SIGNIN_STORE_USER } from "src/constants/mutations";
import { SIGNIN_EMPLOYEE_USER } from "src/constants/mutations";
import * as Yup from "yup";
import eyeIconVisible from "../../assests/images/landing-page/eye-open.png";
import eyeIcon from "../../assests/images/landing-page/visibility_off.png";
import DropIcon from "../../assests/images/landing-page/Vector (1).png";
import Logo from "../../components/common/Logo";
import { useAuth } from "../../contexts/auth-provider";
import { BtnLoader } from "../../Loader/index";
import { useAxiosInterceptor } from "src/hooks/useAxiosInterceptor";

export const Login = () => {
  const navigate = useNavigate();
  const { axBe } = useAxiosInterceptor();
  const { setToken, setUser, setRefreshtoken } = useAuth();
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [inProgress, setInProgress] = useState(false);
  const [role, setRole] = useState<string | null>(null);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggleDropdown = () => setDropdownOpen(!dropdownOpen);  

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Must be a valid email")
        .required("Email is required"),
      password: Yup.string().max(255).required("Password is required"),
    }),
    onSubmit: async () => {
      let variables;
      if (role === "admin") {
        variables = {
          storeUserSignInInput: {
            ...formik.values,
          },
        };
      } else {
        variables = {
          storeEmployeeSignInInput: {
            ...formik.values,
          },
        };
      }
      if (!GRAPHQL_ENDPOINT) {
        console.error("GraphQL endpoint is not defined.");
        return;
      }
      try {
        setInProgress(true);
        const { data } = await axBe.post(GRAPHQL_ENDPOINT, {
          query: role === "admin" ? SIGNIN_STORE_USER : SIGNIN_EMPLOYEE_USER,
          variables: variables,
        });
        let token;
        let userRole;
        let user;
        let refreshToken;
        let restaurant;
        if (data?.data?.storeUserSignIn) {
          token = data?.data?.storeUserSignIn?.accessToken;
          refreshToken = data?.data?.storeUserSignIn?.refreshToken;
          userRole = data?.data?.storeUserSignIn?.roles?.[0];
          user = data?.data?.storeUserSignIn;
        } else {
          token = data?.data?.storeEmployeeSignIn?.accessToken;
          refreshToken = data?.data?.storeEmployeeSignIn?.refreshToken;
          userRole = data?.data?.storeEmployeeSignIn?.roles?.[0];
          user = data?.data?.storeEmployeeSignIn;
          restaurant =
            data?.data?.storeEmployeeSignIn?.storeUser?.stores?.[0] || {};
        }
        if (data?.errors){
          setInProgress(false);
          return
        }
        localStorage.setItem("refreshToken", refreshToken);
        localStorage.setItem("token", token);
        localStorage.setItem("role", userRole);
        localStorage.setItem("user", JSON.stringify(user));
        if (restaurant?.correlationId)
          localStorage.setItem("restaurant", JSON.stringify(restaurant));
        setRefreshtoken(refreshToken);
        setToken(token);
        setUser(user);
        navigate("/dashboard");
        setInProgress(false);
      } catch (error) {
        setInProgress(false);
      }
    },
  });

  const togglePassword = () => {
    setPasswordVisible(!passwordVisible);
  };

  return (
    <>
      <div className="mt-[80px]">
        <div onClick={() => navigate("/")} className="flex items-center justify-center gap-x-1 cursor-pointer">
          <Logo />
          <h1 className="ml-2 text-3xl">EatsBits</h1>
        </div>
      </div>

    <div className="flex justify-center mt-[110px] h-full">
        <div className="max-sm:w-[100%] h-[500px] w-[420px] rounded-[12px] p-6 border border-lightGrey">
          <h1 className="text-center text-2xl mt-4 font-semibold">Login</h1>
          <form onSubmit={formik.handleSubmit}>
            <div className="mt-6">
              <p className="text-sm text-[#9B9B9B]">Email</p>
              <div className="flex items-center">
                <input
                  className="text-sm text-[#9B9B9B] h-[42px] w-[100%] border rounded-lg border-[#F1F1F2] mt-2 px-2"
                  type="text"
                  placeholder="Enter your email"
                  id="email"
                  name="email"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.email}
                />
              </div>
              {formik.touched.email && formik.errors.email ? (
                <div className="text-orange text-xs mt-2 mx-1">
                  {formik.errors.email}
                </div>
              ) : null}

              <div className="flex justify-between mt-8">
                <p className="text-sm text-[#9B9B9B]">Password</p>
                <Link to="/forgot-password">
                  <p className="text-sm text-orange cursor-pointer">
                    Forget Password?
                  </p>
                </Link>
              </div>
              <div className="flex items-center relative">
                <input
                  className="text-sm text-[#9B9B9B] h-[42px] w-[100%] border rounded-lg border-[#F1F1F2] mt-2 px-2"
                  type={passwordVisible ? "text" : "password"}
                  placeholder="Enter your password"
                  id="password"
                  name="password"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.password}
                />
                <img
                  onClick={togglePassword}
                  className="absolute right-[10px] top-[20px] cursor-pointer"
                  src={passwordVisible ? eyeIconVisible : eyeIcon}
                  alt="Toggle visibility"
                />
              </div>
              <div className="relative my-7">
                <button
                  type="button"
                  onClick={toggleDropdown}
                  className={`w-full p-[9px] flex items-center justify-between text-sm text-[#9B9B9B] border rounded-lg border-[#F1F1F2] ${
                    dropdownOpen ? "border-[#f97658] shadow-md" : "border-gray-300"
                  } rounded-md text-left focus:outline-none`}
                >
                  {role
                    ? role.charAt(0).toUpperCase() + role.slice(1)
                    : "Select role"}
                    <img alt="" className="h-[7px] w-[12px]" src={DropIcon} />
                </button>
                {dropdownOpen && (
                  <div className="absolute z-10 mt-1 w-full bg-white border  rounded-lg border-[#F1F1F2] shadow-lg">
                    <ul className="py-1">
                      <li
                        className={`px-4 py-2 cursor-pointer duration-300 hover:bg-[#FD76570F]  ${
                          role === "admin" ? "bg-[#FD76571A]" : ""
                        }`}
                        onClick={() => {
                          setRole("admin");
                          setDropdownOpen(false);
                        }}
                      >
                        Admin
                      </li>
                      <li
                        className={`px-4 py-2 cursor-pointer duration-300 hover:bg-[#FD76570F] ${
                          role === "employee" ? "bg-[#FD76571A]" : ""
                        }`}
                        onClick={() => {
                          setRole("employee");
                          setDropdownOpen(false);
                        }}
                      >
                        Employee
                      </li>
                    </ul>
                  </div>
                )}
              </div>

              <div className="mt-4 flex">
                <input type="checkbox" id="rememberMe" name="rememberMe" />
                <label htmlFor="rememberMe" className="text-sm ml-2">
                  Remember me
                </label>
              </div>

              <div className="flex justify-center my-4">
                {inProgress ? (
                  <BtnLoader />
                ) : (
                  <button
                    type="submit"
                    disabled={!formik.isValid || !formik.dirty || !role}
                    className={`w-[100%] hover:bg-white hover:text-orange duration-500 text-white h-[40px] rounded-xl bg-[#FD7657] ${
                      (!formik.isValid || !formik.dirty || !role) &&
                      "opacity-[50%] pointer-events-none"
                    }`}
                  >
                    Login
                  </button>
                )}
              </div>
              <div className="flex justify-center">
                <p className="text-sm">
                  <Link to="/sign-up">
                    Don't have an account?{" "}
                    <span className="text-orange">Signup now</span>
                  </Link>
                </p>
              </div>
            </div>
          </form>
        </div>

    </div>
    </>
  );
};
