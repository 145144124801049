import { S3_BASE_URL } from "src/constants/endpoints";
import DeleteIcon from "../../assests/images/dashboard/delete.png";
import RestuarantImg from "../../assests/images/dashboard/default-thumbnail (1).jpg";

const UpdateComboItemCard = ({
  combo,
  handleRemoveItem,
}: any) => {
  return (
    <div className="flex flex-wrap justify-between">
      <div className="relative mx-2 mb-3 border-[#F1F1F2] border bg-white rounded-xl h-[240px] w-[250px]">
        <div
          className="flex justify-center bg-[#dadada] h-[140px] rounded-xl">
          <img
            alt=""
            className="h-[140px] w-full rounded-t-xl"
            src={
              combo?.bucketKeyName
                ? combo?.bucketKeyName.includes("blob")
                  ? combo?.bucketKeyName
                  : S3_BASE_URL + combo?.bucketKeyName
                : RestuarantImg
            }
          />
        </div>
        <div
          className="absolute right-2 top-2 h-[42px] w-[42px] rounded-xl bg-[#FEF5F4] flex justify-center items-center cursor-pointer"
          onClick={() => handleRemoveItem(combo)}
        >
          <img className="w-[12px] h-[13px]" src={DeleteIcon} alt="Delete" />
        </div>

        <div className="pb-4 pr-4 pl-4 pt-[10px]">
          <div className="flex justify-between mt-1">
            <h1 className="text-sm">{combo?.englishName}</h1>
          </div>
          <div className="">
            <div className="flex flex-wrap w-full gap-1  mt-1 items-center">
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateComboItemCard;
