import axios from "axios";
import { useFormik } from "formik";
import { useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { Link, useNavigate } from "react-router-dom";
import { GRAPHQL_ENDPOINT } from "src/constants/endpoints";
import { REGISTER_STORE_USER } from "src/constants/mutations";
import * as Yup from "yup";
import eyeIconVisible from "../../assests/images/landing-page/eye-open.png";
import eyeIcon from "../../assests/images/landing-page/visibility_off.png";
import Logo from "../../components/common/Logo";
import { BtnLoader } from "src/commonLoader";

export const SignUp = () => {
  const navigate = useNavigate();
  const [inProgress, setInProgress] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [confirmPasswordIcon, setConfirmPasswordIcon] = useState(false);

  const togglePassword = () => {
    setPasswordVisible(!passwordVisible);
  };
  const showConfirmPassword = () => {
    setConfirmPasswordIcon(!confirmPasswordIcon);
  };

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      employerName: "",
      password: "",
      confirmPassword: "",
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required("First name is required"),
      lastName: Yup.string().required("last name is required"),
      email: Yup.string()
        .email("Must be a valid email")
        .required("Email is required"),
      phoneNumber: Yup.string().required("Phone Number is required"),
      employerName: Yup.string().required("Employer Name is required"),
      password: Yup.string()
        .min(8, "Password must be at least 8 characters")
        .max(255)
        .matches(/[a-z]/, "Password must contain at least one lowercase letter")
        .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
        .matches(/[0-9]/, "Password must contain at least one number")
        .matches(
          /[\W_]/,
          "Password must contain at least one special character"
        )
        .required("Password is required"),
    }),

    onSubmit: async () => {
      const { confirmPassword, ...rest } = formik.values;
      const httpOrHttps = window.location.href.split("//")[0];
      const variables = {
        createStoreUserInput: {
          ...rest,
          hostName: httpOrHttps + "//" + window.location.host,
        },
      };

      if (!GRAPHQL_ENDPOINT) {
        console.error("GraphQL endpoint is not defined.");
        return;
      }
      try {
        setInProgress(true);
        const { data } = await axios.post(
          GRAPHQL_ENDPOINT,
          {
            query: REGISTER_STORE_USER,
            variables: variables,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        navigate("/account-registered");
        if (data.errors) {
          setInProgress(false);
          return;
        }
      } catch (error) {
        console.error("Error creating user:", error);
      }
    },
  });

  return (
    <div className="flex justify-center flex-col items-center">
      <div className=" mt-[80px]">
        <div
          onClick={() => navigate("/")}
          className="flex items-center cursor-pointer"
        >
          <Logo />
          <h1 className="ml-2 text-3xl">EatsBits</h1>
        </div>
      </div>
      <form className="" onSubmit={formik.handleSubmit}>
        <div className="rounded-[12px] mt-[13%] p-6 border border-lightGrey">
          <h1 className="text-center text-2xl mt-4 font-semibold">Sign Up</h1>

          <div className="mt-6">
            <div className="flex">
              <div className="m-1">
                <span className="text-sm text-[#9B9B9B]">First name</span>
                <input
                  className="text-sm text-[[#9B9B9B]] h-[42px] w-[100%] border rounded-lg border-[#F1F1F2] mt-2 px-2"
                  type="text"
                  name="firstName"
                  id="firstName"
                  placeholder="Enter your first name"
                  value={formik.values.firstName}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                />
                {formik.touched.firstName && formik.errors.firstName ? (
                  <div className="text-orange text-xs mt-2 mx-1">
                    {formik.errors.firstName}
                  </div>
                ) : null}
              </div>
              <div className="m-1">
                <span className="text-sm text-[#9B9B9B]">Last name</span>
                <input
                  className="text-sm text-[[#9B9B9B]] h-[42px] w-[100%] border rounded-lg border-[#F1F1F2] mt-2 px-2"
                  type="text"
                  name="lastName"
                  id="lastName"
                  placeholder="Enter your last name"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.lastName}
                />
                {formik.touched.lastName && formik.errors.lastName ? (
                  <div className="text-orange text-xs mt-2 mx-1">
                    {formik.errors.lastName}
                  </div>
                ) : null}
              </div>
            </div>
            <p className="text-sm text-[#9B9B9B] mt-4">Employer name</p>
            <div className="flex items-center">
              <input
                className="text-sm text-[[#9B9B9B]] h-[42px] w-[100%] border rounded-lg border-[#F1F1F2] mt-2 px-2"
                type="text"
                id="employerName"
                name="employerName"
                placeholder="Enter your employer name"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.employerName}
              />
            </div>
            {formik.touched.employerName && formik.errors.employerName ? (
              <div className="text-orange text-xs mt-2 mx-1">
                {formik.errors.employerName}
              </div>
            ) : null}
            <p className="text-sm text-[#9B9B9B] mt-4">Email</p>
            <div className="flex items-center">
              <input
                className="text-sm text-[[#9B9B9B]] h-[42px] w-[100%] border rounded-lg border-[#F1F1F2] mt-2 px-2"
                type="text"
                id="email"
                name="email"
                placeholder="Enter your email"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.email}
              />
            </div>
            {formik.touched.email && formik.errors.email ? (
              <div className="text-orange text-xs mt-2 mx-1">
                {formik.errors.email}
              </div>
            ) : null}
            <p className="text-sm text-[#9B9B9B] mt-4 mb-2">Phone number</p>
            <div className="flex items-center">
              <PhoneInput
                country={"us"}
                onlyCountries={["us"]}
                countryCodeEditable={false}
                inputProps={{
                  name: "phoneNumber",
                  id: "phoneNumber",
                  country: "us",
                  required: true,
                }}
                value={formik.values.phoneNumber}
                onBlur={formik.handleBlur}
                onChange={(value) => formik.setFieldValue("phoneNumber", value)}
                containerStyle={{
                  width: "100%",
                }}
                inputStyle={{
                  width: "100%",
                  border: "1px solid #F1F1F2",
                }}
              />
            </div>
            {formik.touched.phoneNumber && formik.errors.phoneNumber ? (
              <div className="text-orange text-xs mt-2 mx-1">
                {formik.errors.phoneNumber}
              </div>
            ) : null}
            <div className="flex  mt-4">
              <p className="text-sm text-[#9B9B9B]">Password</p>
            </div>
            <div className="flex items-center relative">
              <input
                className="text-sm text-[#9B9B9B] h-[42px] w-[100%] border rounded-lg border-[#F1F1F2] mt-2 px-2"
                type={passwordVisible ? "text" : "password"}
                placeholder="Enter your password"
                id="password"
                name="password"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.password}
              />
              <img
                onClick={togglePassword}
                className="absolute right-[10px] top-[20px] cursor-pointer"
                src={passwordVisible ? eyeIconVisible : eyeIcon}
                alt="Toggle visibility"
              />
            </div>
            {formik.touched.password && formik.errors.password ? (
              <div className="text-orange text-xs mt-2 mx-1">
                {formik.errors.password}
              </div>
            ) : null}
            <div className="flex  mt-4">
              <p className="text-sm text-[#9B9B9B]">Confirm password</p>
            </div>
            <div className="flex items-center relative">
              <input
                className="text-sm text-[#9B9B9B] h-[42px] w-[100%] border rounded-lg border-[#F1F1F2] mt-2 px-2"
                type={confirmPasswordIcon ? "text" : "password"}
                placeholder="Enter your password"
                id="confirmPassword"
                name="confirmPassword"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.confirmPassword}
              />
              <img
                onClick={showConfirmPassword}
                className="absolute right-[10px] top-[20px] cursor-pointer"
                src={confirmPasswordIcon ? eyeIconVisible : eyeIcon}
                alt="Toggle visibility"
              />
            </div>
            {formik.errors.confirmPassword &&
              formik.touched.confirmPassword && (
                <p className="text-orange text-xs mt-2 mx-1">
                  {formik.errors.confirmPassword}
                </p>
              )}
            <div className="flex justify-center my-4">
              {inProgress ? (
                <BtnLoader />
              ) : (
                <button
                  type="submit"
                  className={`w-[100%] text-white h-[40px] rounded-xl bg-[#FD7657]
                    ${
                      (!formik.isValid || !formik.dirty) &&
                      "opacity-[50%] pointer-events-none"
                    }
                  `}
                >
                  Sign Up
                </button>
              )}
            </div>
            <div className="flex justify-center">
              <p className="text-sm">
                Already have an account?
                <Link to="/login">
                  <span className="text-orange"> Login</span>
                </Link>
              </p>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};
