import React from "react";
import { useLocation } from "react-router-dom";
import SidebarLinkGroup from "./SidebarLinkGroup";
import ToggleIcon from "../../assests/images/dashboard/left_panel_close.png";
import ToggleIcon2 from "../../assests/images/dashboard/left_panel_close 2.png";
import Logo from "../../components/common/Logo";
import { employeeSidebar } from "./sidebarRoutes";
import HelpIcon from "../../assests/images/dashboard/help.png";
import SidebarItem from "./SidebarItem";

interface SidebarProps {
  sidebarOpen: boolean;
  setSidebarOpen: (arg: boolean) => void;
  setPageTitle: any;
}

const Sidebar = ({
  sidebarOpen,
  setSidebarOpen,
  setPageTitle,
}: SidebarProps) => {
  const location = useLocation();
  const { pathname } = location;

  const toggleMenu = (index: number) => {
    // const tempRoutes = [...sidebarRoutes];
    // if (tempRoutes?.[index]) {
    //   tempRoutes[index].isSubMenuOpen = !tempRoutes[index]?.isSubMenuOpen;
    // }
    // setSidebarRoutes([...tempRoutes]);
  };

  return (
    <div
      className={`border border-[#F1F1F2] left-0 top-0 z-9999 flex h-screen ${
        sidebarOpen ? "w-[275px]" : "w-[90px]"
      } flex-col overflow-y-hidden  duration-500 ease-linear dark:bg-boxdark static translate-x-0 ${
        sidebarOpen ? "translate-x-0" : "-translate-x-full"
      }`}
      style={{ position: "static", translate: 0 }}
    >
      <div className="flex items-center justify-between gap-2 p-4 h-[80px] border-b-[#F1F1F2] border-b ">
        <div className="flex items-center justify-center gap-2">
          <Logo />
          {sidebarOpen && <h1 className="text-3xl font-medium">EatsBits</h1>}
        </div>
        <button onClick={() => setSidebarOpen(!sidebarOpen)}>
          <img
            className="h-[18px] w-[18px]"
            src={sidebarOpen ? ToggleIcon : ToggleIcon2}
            alt=""
          />
        </button>
      </div>

      <div className="no-scrollbar flex flex-col overflow-y-auto duration-500 ease-linear">
        <nav className=" px-4 ">
          <div>
            <ul className="mb-6 flex flex-col gap-1.5">
              <SidebarLinkGroup
                activeCondition={
                  pathname === "/" || pathname.includes("dashboard")
                }
              >
                {(_handleClick) => (
                  <React.Fragment>
                    <div className={`translate transform`}>
                      <ul className="mt-4 mb-5.5 flex flex-col gap-1">
                        {employeeSidebar?.map((route: any, index: number) => (
                          <>
                            {!route?.children?.length ? (
                              <SidebarItem
                                key={index}
                                index={index}
                                route={route}
                                isSubRoute={false}
                                sidebarOpen={sidebarOpen}
                                setPageTitle={setPageTitle}
                              />
                            ) : null}
                            {route?.children?.length ? (
                              <>
                                {sidebarOpen && (
                                  <h1 className="text-[#B7B7B7] text-xs">
                                    {route?.section}
                                  </h1>
                                )}
                                <SidebarItem
                                  index={index}
                                  route={route}
                                  isSubRoute={false}
                                  sidebarOpen={sidebarOpen}
                                  hasDropdown={!!route?.children?.length}
                                  toggleMenu={toggleMenu}
                                  setPageTitle={setPageTitle}
                                  isSubMenuOpen={route?.isSubMenuOpen}
                                />
                                {route?.isSubMenuOpen &&
                                  sidebarOpen &&
                                  route?.children?.map(
                                    (subRoute: any, subIndex: number) => (
                                      <SidebarItem
                                        index={subIndex}
                                        route={subRoute}
                                        isSubRoute={true}
                                        sidebarOpen={sidebarOpen}
                                        isSubMenuOpen={route?.isSubMenuOpen}
                                        setPageTitle={setPageTitle}
                                        toggleMenu={toggleMenu}
                                      />
                                    )
                                  )}
                              </>
                            ) : (
                              <></>
                            )}
                          </>
                        ))}
                      </ul>
                    </div>
                  </React.Fragment>
                )}
              </SidebarLinkGroup>
            </ul>
          </div>
          <div className="fixed bottom-6">
            {sidebarOpen && (
              <h1 className="text-sm text-[#B7B7B7]">Applications</h1>
            )}
            <div className="flex items-center gap-x-4 mt-6 ml-2">
              <img className="w-[17px] h-[17px]" src={HelpIcon} alt="" />
              {sidebarOpen && (
                <h1 className="text-xs text-[#B7B7B7]">Help Center</h1>
              )}
            </div>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default Sidebar;
