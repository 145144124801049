import { useNavigate } from "react-router-dom";
import CreateRestuarantHeader from "../../../components/CreateRestuarantHeader/CreateRestuarantHeader";
import SuccessCard from "src/components/SuccessCard/SuccessCard";

const Success = () => {
  const navigate = useNavigate();

  const onHanldeDone = () => {
    localStorage.removeItem("createRestaurantDetails");
    navigate("/my-restaurants");
  };

  return (
    <>
      <CreateRestuarantHeader heading={"Create Restaurant"} />
      <SuccessCard
          onHanldeDone={onHanldeDone}
          heading="Congratulations! Your subscription was created successfully."
          subHeading="Now you can go ahead to configure your restaurant"
      />
    </>
  );
};

export default Success;
