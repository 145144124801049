import { forwardRef, useEffect, useState } from "react";
import PlusIcon from "../../assests/images/dashboard/Vector 2.png";
import { useSelector } from "react-redux";
import { BtnLoader } from "src/commonLoader/index";
import { GRAPHQL_ENDPOINT, S3_BASE_URL } from "src/constants/endpoints";
import { useAxiosInterceptor } from "src/hooks/useAxiosInterceptor";
import infoIcon from "../../assests/images/dashboard/info.svg";
import { useFormik } from "formik";
import DatePicker from "react-datepicker";
import CelenderIcon from "../../assests/images/dashboard/Button container 2.png";
import DeleteIcon from "../../assests/images/dashboard/delete.png";
import ComboItemsModal from "src/components/ComboItemsModal/ComboItemsModal";
import UpdateComboItemCard from "src/components/ComboCard/UpdateComboItemCard";
import { UPDATE_COMBO, UPLOAD_COMBO_IMAGE } from "src/constants/mutations";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "src/contexts/auth-provider";
import Dropzone from "react-dropzone";
import RestuarantImg from "../../assests/images/dashboard/test.png";
import { mimeTypeToExtension } from "src/components/RestaurantSetting/RestaurantSettingComponent";
import axios from "axios";

const UpdateCombo = () => {
  const location = useLocation();
  const itemData = location.state?.itemData || {};
  const locationId =
    location.pathname?.split("/")[location.pathname.split("/").length - 1];
  const [isOtherLanguageEnabled, setIsOtherLanguageEnabled] = useState(false);
  const { axBe } = useAxiosInterceptor();
  const { isComboLoading } = useSelector((state: any) => state.combo);
  const [showItemModal, setShowItemModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [deleteLoader, setDeleteLoader] = useState(false);
  const [uploadImage, setUploadImage] = useState<any>(null);
  const [uploadImageLoader, setUploadImageLoader] = useState(false);
  const [imageUrl, setImageUrl] = useState<string>("");
  const navigate = useNavigate();
  const { user, hasRestaurant, hasEmployee } = useAuth();

  const formik = useFormik({
    initialValues: {
      englishName: "",
      nonEnglishName: "",
      deadlineToPlaceOrder: "",
      comboReadyTime: "",
      comboRepeatFrequency: "",
      englishDescription: "",
      nonEnglishDescription: "",
      price: "",
      taxRate: "",
      itemCorrelationIds: [],
    },
    onSubmit: () => {},
  });

  const testData = formik.values.itemCorrelationIds;

  const {setFieldValue} =formik

  useEffect(() => {
    if (itemData?.correlationId) {
      setFieldValue("englishName", itemData?.englishName);
      setFieldValue("taxRate", itemData?.taxRate);
      setFieldValue("nonEnglishName", itemData?.nonEnglishName);
      setFieldValue(
        "deadlineToPlaceOrder",
        itemData?.deadlineToPlaceOrder
          ? new Date(itemData?.deadlineToPlaceOrder)
          : new Date()
      );
      setFieldValue(
        "comboReadyTime",
        itemData?.comboReadyTime
          ? new Date(itemData?.comboReadyTime)
          : new Date()
      );
      setFieldValue(
        "comboRepeatFrequency",
        itemData?.comboRepeatFrequency
      );
      setFieldValue("englishDescription", itemData?.englishDescription);
      setFieldValue(
        "nonEnglishDescription",
        itemData?.nonEnglishDescription
      );
      setFieldValue("price", itemData?.price);
      setFieldValue("itemCorrelationIds", itemData?.itemList);
      setImageUrl(S3_BASE_URL + itemData?.bucketKeyName);
    }
  }, [itemData, setFieldValue]);

  const handleToggleChange = () => {
    setIsOtherLanguageEnabled((prevState) => !prevState);
  };

  const DateRreadyTime = forwardRef(({ value, onClick }: any, ref: any) => (
    <>
      <div
        className="flex cursor-pointer relative items-center bg-white rounded-xl text-sm text-center"
        onClick={onClick}
      >
        <input
          className="h-[40px] text-xs w-full border rounded-lg border-[#F1F1F2] mt-1 px-2"
          ref={ref}
          placeholder={value}
        />
        <img
          className="w-[26px] h-[26px] absolute right-[10px]"
          src={CelenderIcon}
          alt=""
        />
      </div>
    </>
  ));
  const DatePlaceOrder = forwardRef(({ value, onClick }: any, ref: any) => (
    <>
      <div
        className="flex cursor-pointer relative items-center bg-white rounded-xl text-sm text-center"
        onClick={onClick}
      >
        <input
          className="h-[40px] text-xs w-full border rounded-lg border-[#F1F1F2] mt-1 px-2"
          ref={ref}
          placeholder={value}
        />
        <img
          className="w-[26px] h-[26px] absolute right-[10px]"
          src={CelenderIcon}
          alt=""
        />
      </div>
    </>
  ));

  const updateCombo = async () => {
    setLoading(true);
    try {
      let variables: any = {};
      const itemsId = formik.values.itemCorrelationIds.map((item: any) => {
        return item.correlationId;
      });
      const comboReadyTimeInMillis = Math.floor(
        new Date(formik.values.comboReadyTime).getTime()
      );
      const deadlineToPlaceOrderMillis = Math.floor(
        new Date(formik.values.deadlineToPlaceOrder).getTime()
      );

      variables = {
        updateComboInput: {
          comboCorrelationId: locationId,
          comboReadyTime: comboReadyTimeInMillis,
          comboRepeatFrequency: formik.values.comboRepeatFrequency,
          deadlineToPlaceOrder: deadlineToPlaceOrderMillis,
          englishDescription: formik.values.englishDescription,
          englishName: formik.values.englishName,
          itemCorrelationIds: itemsId,
          nonEnglishDescription: formik.values.nonEnglishDescription,
          nonEnglishName: formik.values.nonEnglishName,
          taxRate: formik.values.taxRate,
          price: formik.values.price.toString(),
        },
      };
      const { data } = await axBe.post(GRAPHQL_ENDPOINT, {
        query: UPDATE_COMBO,
        variables: variables,
      });
      if (data?.errors?.length) {
        throw data?.errors;
      }
      setLoading(false);
      navigate(`/my-${hasEmployee ? 'employee' :'restaurant'}/${hasEmployee?.correlationId || hasRestaurant?.correlationId}/combo`);
    } catch (error) {
      console.error("Error creating user:", error);
      setLoading(false);
    }
  };

  const comboDelete = async () => {
    setDeleteLoader(true);
    try {
      let variables: any = {};
      variables = {
        updateComboInput: {
          comboCorrelationId: locationId,
          isActive: false,
        },
      };
      const { data } = await axBe.post(GRAPHQL_ENDPOINT, {
        query: UPDATE_COMBO,
        variables: variables,
      });

      if (data?.errors?.length) {
        throw data?.errors;
      }
      setDeleteLoader(false);
      navigate(`/my-${hasEmployee ? 'employee' :'restaurant'}/${hasEmployee?.correlationId || hasRestaurant?.correlationId}/combo`);
    } catch (error) {
      console.error("Error creating user:", error);
      setLoading(false);
      setDeleteLoader(false);
    }
  };

  const handleRemoveItem = (combo: any) => {
    const tempItemCorrelationIds = [...formik.values.itemCorrelationIds];
    const index = tempItemCorrelationIds.findIndex(
      (data: any) => data.correlationId === combo.correlationId
    );
    tempItemCorrelationIds.splice(index, 1);
    formik.setFieldValue("itemCorrelationIds", tempItemCorrelationIds);
  };

  const handleFileChange = (file: any) => {
    setUploadImage({
      url: URL.createObjectURL(file),
      file,
    });
  };

  const uploadComboImage = async () => {
    setUploadImageLoader(true);
    const variables = {
      createComboPreSignedUrlInput: {
        storeUserCorrelationId: user?.storeUser?.correlationId,
        storeCorrelationId: hasRestaurant.correlationId,
        comboName: formik.values.englishName,
        fileNameExtension: mimeTypeToExtension[uploadImage?.file?.type],
        comboCorrelationId: locationId,
      },
    };
    try {
      const res = await axBe.post(GRAPHQL_ENDPOINT, {
        query: UPLOAD_COMBO_IMAGE,
        variables: variables,
      });
      if (!res?.data?.error?.length) {
        const awsUrl =
          res.data?.data?.createUploadPreSignedUrlForCombo?.preSignedUrl;
        await axios.put(awsUrl, uploadImage.file, {
          headers: {
            "Content-Type": uploadImage?.file?.type,
            "x-amz-acl": "public-read",
          },
        });
        setUploadImageLoader(false);
        navigate(`/my-restaurant/${hasRestaurant?.correlationId}/combo`);
      }
    } catch (error) {
      console.error("Error uploading image:", error);
      setUploadImageLoader(false);
    }
  };

  return (
    <>
      <div>
        <div className="flex justify-between items-center">
          <div className="flex gap-x-4 items-center">
            <Dropzone
              accept={{
                "image/png": [".png"],
                "image/jpg": [".jpg"],
                "image/jpeg": [".jpeg"],
              }}
              onDrop={(acceptedFiles) => handleFileChange(acceptedFiles[0])}
            >
              {({ getRootProps, getInputProps }) => (
                <>
                  <div className="relative w-[61px] h-[61px] rounded-full border border-[#F1F1F2]">
                    <img
                      className="absolute object-cover h-full w-full rounded-full"
                      src={uploadImage?.url || imageUrl || RestuarantImg}
                      alt=""
                    />
                  </div>
                  {uploadImage?.url ? (
                    <>
                      {uploadImageLoader ? (
                        <div>
                          <BtnLoader />
                        </div>
                      ) : (
                        <button
                          className="text-sm text-orange bg-[#FD76571A] rounded-lg px-3 py-[10px]"
                          onClick={uploadComboImage}
                        >
                          Upload
                        </button>
                      )}
                    </>
                  ) : (
                    <button
                      className="text-sm text-orange bg-[#FD76571A] rounded-lg px-3 py-[10px]"
                      {...getRootProps()}
                    >
                      Select new image
                      <input {...getInputProps()} />
                    </button>
                  )}
                </>
              )}
            </Dropzone>
            {uploadImage?.url && (
              <img
                className="w-[18px] h-[20px] cursor-pointer"
                src={DeleteIcon}
                alt="Delete"
                onClick={() => setUploadImage(null)}
              />
            )}
          </div>
          <div className="flex justify-end items-center gap-x-2 mt-2">
            {loading ? (
              <BtnLoader />
            ) : (
              <button
                className={`${
                  (!formik.isValid || !formik.dirty) && "opacity-20"
                } h-[40px] bg-[#FD7657] px-8 text-sm text-white rounded-xl flex justify-center items-center cursor-pointer`}
                onClick={updateCombo}
                disabled={!formik.isValid || !formik.dirty}
              >
                Update
              </button>
            )}
            {deleteLoader ? (
              <BtnLoader />
            ) : (
              <div
                className="h-[42px] px-6 text-sm text-[#FD7657] rounded-xl bg-[#FEF5F4] flex gap-x-2 items-center cursor-pointer"
                onClick={comboDelete}
              >
                <img
                  className="w-[12px] h-[13px]"
                  src={DeleteIcon}
                  alt="Delete"
                />
                Delete
              </div>
            )}
          </div>
        </div>
        <div className="w-full flex gap-3 mt-4">
          <div className="w-[90px]">
            <div
              className="h-[240px] bg-white rounded-xl flex justify-center items-center cursor-pointer"
              onClick={() => setShowItemModal(!showItemModal)}
            >
              <img className="w-[17px] h-[17px] " src={PlusIcon} alt="" />
            </div>
          </div>
          <div className="w-full">
            {isComboLoading ? (
              <div className="flex items-center justify-center w-full h-full">
                <BtnLoader />
              </div>
            ) : testData && testData.length > 0 ? (
              <div className="flex flex-wrap">
                {testData?.map((combo: any, index: any) => (
                  <UpdateComboItemCard
                    key={index}
                    combo={combo}
                    handleRemoveItem={handleRemoveItem}
                  />
                ))}
              </div>
            ) : (
              <div className="absolute top-[27%] left-[35%]">
                <p>Click the button on the left to create some combo!</p>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="flex w-full justify-center">
        <div
          className={`bg-white rounded-lg w-[100%] relative mt-3 ${
            isOtherLanguageEnabled ? "h-[600px]" : "h-[420px]"
          }`}
        >
          <div className="pb-4 pt-4 w-[95%] m-auto">
            <div className="flex gap-x-2">
              <div className="w-full mt-2">
                <label className="block text-sm font-medium text-[#9B9B9B] p-1">
                  Combo Name
                </label>
                <input
                  name="englishName"
                  type="text"
                  className="h-[40px] text-xs w-full border rounded-lg border-[#F1F1F2] mt-1 px-2"
                  placeholder="Enter Name"
                  value={formik.values.englishName}
                  onChange={formik.handleChange}
                />
              </div>
              <div className="w-full mt-2">
                <label className="block text-sm font-medium text-[#9B9B9B] p-1">
                  Combo Price
                </label>
                <input
                  name="price"
                  type="text"
                  className="h-[40px] text-xs w-full border rounded-lg border-[#F1F1F2] mt-1 px-2"
                  placeholder="Enter price"
                  value={`$${formik.values.price}`}
                  onChange={(e) => {
                    const valueWithoutDollar = e.target.value.replace(
                      /^\$/,
                      ""
                    );
                    formik.setFieldValue("price", valueWithoutDollar);
                  }}
                />
              </div>
              <div className="w-full mt-2">
                <label className="block text-sm font-medium text-[#9B9B9B] p-1">
                   Tax Rate
                </label>
                <input
                  name="taxRate"
                  id="taxRate"
                  type="text"
                  className="h-[40px] text-xs w-full border rounded-lg border-[#F1F1F2] mt-1 px-2"
                  placeholder="Enter tax rate"
                  value={formik.values.taxRate}
                  onChange={formik.handleChange}
                />
              </div>
            </div>
            <div className="w-full mt-2">
              <label className="block text-sm font-medium text-[#9B9B9B] p-1">
                Combo Description
              </label>
              <input
                name="englishDescription"
                type="text"
                className="h-[40px] text-xs w-full border rounded-lg border-[#F1F1F2] mt-1 px-2"
                placeholder="Enter Description"
                value={formik.values.englishDescription}
                onChange={formik.handleChange}
              />
            </div>
            <div className="flex gap-x-2">
              <div className="w-full mt-2">
                <label className=" text-sm font-medium text-[#9B9B9B]">
                  Combo Ready Time
                </label>
                <DatePicker
                  showTimeSelect
                  dateFormat="MMMM d, yyyy h:mm aa"
                  selected={(formik.values.comboReadyTime as any) || new Date()}
                  onChange={(date) =>
                    formik.setFieldValue("comboReadyTime", date)
                  }
                  customInput={<DateRreadyTime />}
                  minDate={new Date()}
                />
              </div>
              <div className="w-full mt-2">
                <label className=" text-sm font-medium text-[#9B9B9B]">
                  Place Order Date
                </label>
                <DatePicker
                  dateFormat="MMMM d, yyyy h:mm aa"
                  showTimeSelect
                  selected={
                    (formik.values?.deadlineToPlaceOrder as any) || new Date()
                  }
                  onChange={(date) =>
                    formik.setFieldValue("deadlineToPlaceOrder", date)
                  }
                  customInput={<DatePlaceOrder />}
                  minDate={new Date()}
                />
              </div>
            </div>
            <div className="flex gap-x-2">
              <div className="w-full mt-2">
                <label className="block text-sm font-medium text-[#9B9B9B] p-1">
                  Frequency
                </label>
                <select
                  name="comboRepeatFrequency"
                  className="text-[#9B9B9B] h-[40px] text-xs w-full border rounded-lg border-[#F1F1F2] mt-1 px-2"
                  value={formik.values.comboRepeatFrequency}
                  onChange={formik.handleChange}
                >
                  <option className="text-[#9B9B9B]" value="">
                    Select Frequency
                  </option>
                  <option className="text-[#9B9B9B]" value="NONE">
                    NONE
                  </option>
                  <option className="text-[#9B9B9B]" value="ONETIME">
                    ONETIME
                  </option>
                  <option className="text-[#9B9B9B]" value="WEEKLY">
                    WEEKLY
                  </option>
                </select>
              </div>
            </div>
            <div className="flex mt-4 items-center">
              <input
                className="mr-2 h-3.5 w-8 appearance-none rounded-[0.4375rem] bg-[#9B9B9B] before:pointer-events-none before:absolute before:h-3.5 before:w-3.5 before:rounded-full before:bg-transparent before:content-[''] after:absolute after:z-[2] after:-mt-[0.1875rem] after:h-5 after:w-5 after:rounded-full after:border-none after:bg-white after:shadow-[0_0px_3px_0_rgb(0_0_0_/_7%),_0_2px_2px_0_rgb(0_0_0_/_4%)] after:transition-[background-color_0.2s,transform_0.2s] after:content-[''] checked:bg-orange checked:after:absolute checked:after:z-[2] checked:after:-mt-[3px] checked:after:ml-[1.0625rem] checked:after:h-5 checked:after:w-5 checked:after:rounded-full checked:after:border-none checked:after:bg-primary checked:after:shadow-[0_3px_1px_-2px_rgba(0,0,0,0.2),_0_2px_2px_0_rgba(0,0,0,0.14),_0_1px_5px_0_rgba(0,0,0,0.12)] checked:after:transition-[background-color_0.2s,transform_0.2s] checked:after:content-[''] hover:cursor-pointer focus:outline-none focus:ring-0 focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-[3px_-1px_0px_13px_rgba(0,0,0,0.6)] focus:before:transition-[box-shadow_0.2s,transform_0.2s] focus:after:absolute focus:after:z-[1] focus:after:block focus:after:h-5 focus:after:w-5 focus:after:rounded-full focus:after:content-[''] checked:focus:border-primary checked:focus:bg-primary checked:focus:before:ml-[1.0625rem] checked:focus:before:scale-100 checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca] checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] dark:bg-neutral-600 dark:after:bg-neutral-400 dark:checked:bg-primary dark:checked:after:bg-primary dark:focus:before:shadow-[3px_-1px_0px_13px_rgba(255,255,255,0.4)] dark:checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca]"
                type="checkbox"
                role="switch"
                checked={isOtherLanguageEnabled}
                onChange={handleToggleChange}
              />
              <p className="mx-2 text-[14px] font-medium">Other language</p>
              <img src={infoIcon} alt="" width={15} height={15} />
            </div>
            {isOtherLanguageEnabled && (
              <>
                <div className="w-full mt-4">
                  <span className="text-[#9B9B9B] text-sm">
                    Combo name (Other language)
                  </span>
                  <input
                    className="text-xs text-[#9B9B9B] h-[40px] w-full border rounded-lg border-[#F1F1F2] mt-1 px-2"
                    type="text"
                    name="nonEnglishName"
                    id="nonEnglishName"
                    value={formik.values.nonEnglishName}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                  />
                </div>
                <div className="w-full mt-4">
                  <span className="text-[#9B9B9B] text-sm">
                    Combo Description (Other language)
                  </span>
                  <input
                    className="text-xs text-[#9B9B9B] h-[40px] w-full border rounded-lg border-[#F1F1F2] mt-1 px-2"
                    type="text"
                    name="nonEnglishDescription"
                    id="nonEnglishDescription"
                    value={formik.values.nonEnglishDescription}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                  />
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      {showItemModal ? (
        <ComboItemsModal
          title="Select Menu Item for your combo"
          formik={formik}
          setShowItemModal={() => {
            setShowItemModal(!showItemModal);
          }}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default UpdateCombo;
