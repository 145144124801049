import React, { useEffect, useState } from "react";
import DeleteIcon from "../../assests/images/dashboard/Button 2.png";
import { useFormik } from "formik";
import * as Yup from "yup";
import TimePicker from "rc-time-picker";
import moment from "moment";
import { useAxiosInterceptor } from "src/hooks/useAxiosInterceptor";
import { useAuth } from "src/contexts/auth-provider";
import { GRAPHQL_ENDPOINT, S3_BASE_URL } from "src/constants/endpoints";
import { STORES } from "src/constants/queries";
import { BtnLoader } from "src/Loader/index";
import {
  UPDATE_RESTAURANT,
  UPLOAD_RESTAURANT_IMAGE,
} from "../../constants/mutations";
import { successToast } from "src/utils/toasts";
import {
  convertDateToMilliseconds,
  convertMillisecondsToTime,
} from "src/utils/date";
import Dropzone from "react-dropzone";
import axios from "axios";
import RestuarantImg from "../../assests/images/dashboard/test.png";
import FormSkeletonLoader from "./FormLoading";
import TimePickerSkeletonLoader from "./TimePickerLoader";

export const mimeTypeToExtension: any = {
  "image/png": "png",
  "image/jpg": "jpg",
  "image/jpeg": "jpeg",
};

const RestaurantSetting = () => {
  const [imageUrl, setImageUrl] = useState<string>("");
  const formik = useFormik({
    initialValues: {
      restaurantName: "",
      street: "",
      stateProvince: "",
      city: "",
      zipCode: "",
      email: "",
      firstName: "",
      lastName: "",
      contactNumber: "",
      style:"",
      time: [
        { day: "MONDAY", endHour: 0, startHour: 0, isSelected: false },
        { day: "TUESDAY", endHour: 0, startHour: 0, isSelected: false },
        { day: "WEDNESDAY", endHour: 0, startHour: 0, isSelected: false },
        { day: "THURSDAY", endHour: 0, startHour: 0, isSelected: false },
        { day: "FRIDAY", endHour: 0, startHour: 0, isSelected: false },
        { day: "SATURDAY", endHour: 0, startHour: 0, isSelected: false },
        { day: "SUNDAY", endHour: 0, startHour: 0, isSelected: false },
      ],
    },
    validationSchema: Yup.object({
      restaurantName: Yup.string().required("Restaurant is Required"),
      street: Yup.string().required("Street is Required"),
      stateProvince: Yup.string().required("State is Required"),
      city: Yup.string().required("City is Required"),
      zipCode: Yup.string().required("Zipcode is Required"),
      email: Yup.string()
        .email("Invalid Email address")
        .required("Email is Required"),
      firstName: Yup.string().required("First name is Required"),
      lastName: Yup.string().required("Last name is Required"),
      contactNumber: Yup.number().required("Contact number is Required"),
    }),

    onSubmit: () => {},
  });
  const format = "hh:mm a";
  const minuteStep = 10;
  const { axBe } = useAxiosInterceptor();
  const { hasRestaurant, setRestaurantData } = useAuth();
  const [timeInProgress, setTimeInProgress] = useState(false);
  const [generalInProgress, setGeneralInProgress] = useState(false);
  const { user } = useAuth();
  const [uploadImage, setUploadImage] = useState<any>(null);
  const [uploadImageLoader, setUploadImageLoader] = useState(false);
  const [loading, setloading] = useState(false);

  const handleCheckboxChange = (index: any) => {
    const newSelection = !formik.values.time[index].isSelected;
    formik.setFieldValue(`time[${index}].isSelected`, newSelection);
  };

  const updateRestaurantSetting = async () => {
    try {
      const updatedTime = formik.values?.time?.map((item) => {
        let startMilliseconds = 0;
        let endMilliseconds = 0;
        if (item.startHour || item.endHour) {
          startMilliseconds = convertDateToMilliseconds(item.startHour);
          endMilliseconds = convertDateToMilliseconds(item.endHour);
        }
        return {
          day: item.day,
          startHour: startMilliseconds,
          endHour: endMilliseconds,
        };
      });
      const payload: any = {
        name: formik.values.restaurantName,
        primaryContactFirstName: formik.values.firstName,
        primaryContactLastName: formik.values.lastName,
        primaryContactEmail: user.storeUser.email,
        style: formik.values.style,
        primaryContactPhoneNumber: formik.values.contactNumber?.toString(),
        address: {
          addressLine1: formik.values.street,
          city: formik.values.city,
          state: formik.values.stateProvince,
          zipCode: formik.values.zipCode,
        },
        operationHours: updatedTime,
      };
      const variables = {
        updateStoreInput: {
          ...payload,
          storeCorrelationId: hasRestaurant.correlationId,
        },
      };
      const { data }: any = await axBe.post(GRAPHQL_ENDPOINT, {
        query: UPDATE_RESTAURANT,
        variables: variables,
      });
      console.log(data);
      setGeneralInProgress(false);
      setTimeInProgress(false);
      fetchStores();
      successToast("Restuarant has been Updated!");
    } catch (error) {
      console.error("Error creating user:", error);
      setGeneralInProgress(false);
      setTimeInProgress(false);
    }
  };

  useEffect(() => {
    setloading(true);
    fetchStores(true);
  }, []);

  const fetchStores = async (isPatchValues = false, restuarant = {}) => {
    try {
      const response: any = await axBe.post(GRAPHQL_ENDPOINT, {
        query: STORES,
        variables: {
          retrieveStoresInput: {
            storeCorrelationIds: [hasRestaurant.correlationId],
          },
        },
      });
      const { data }: any = response.data;
      if (data?.stores && data.stores.length > 0) {
        const info: any = data?.stores?.[0];
        setRestaurantData({...info, ...restuarant});
        if (!isPatchValues) {
          return;
        }
        formik.setFieldValue("restaurantName", info.name);
        formik.setFieldValue(
          "restaurantNameOtherLanguage",
          info.nonEnglishName
        );
        formik.setFieldValue("street", info.address?.addressLine1);
        formik.setFieldValue("stateProvince", info.address?.state);
        formik.setFieldValue("city", info.address?.city);
        formik.setFieldValue("zipCode", info.address?.zipCode);
        formik.setFieldValue("email", info.primaryContactEmail);
        formik.setFieldValue("firstName", info.primaryContactFirstName);
        formik.setFieldValue("lastName", info.primaryContactLastName);
        formik.setFieldValue("contactNumber", info.primaryContactPhoneNumber);
        formik.setFieldValue("style", info.style);
        formik.setFieldValue(
          "time",
          info.operationHours?.map((time: any) => {
            return {
              ...time,
              startHour: time.startHour
                ? convertMillisecondsToTime(time.startHour)
                : 0,
              endHour: time.endHour
                ? convertMillisecondsToTime(time.endHour)
                : 0,
              isSelected: !!time?.startHour || !!time?.endHour,
            };
          })
        );
        setTimeout(() => {
          formik.setFieldValue(
            "contactNumber",
            info.primaryContactPhoneNumber
          );
        }, 5);
        setloading(false);
        if (info?.bucketKeyName) {
          setImageUrl(S3_BASE_URL + info?.bucketKeyName);
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const uploadRestaurantImage = async () => {
    setUploadImageLoader(true);
    const variables = {
      createStorePreSignedUrlInput: {
        storeUserCorrelationId: user?.storeUser?.correlationId,
        storeName: hasRestaurant?.name,
        fileNameExtension: mimeTypeToExtension[uploadImage?.file?.type],
        storeCorrelationId: hasRestaurant?.correlationId,
      },
    };
    try {
      const res = await axBe.post(GRAPHQL_ENDPOINT, {
        query: UPLOAD_RESTAURANT_IMAGE,
        variables: variables,
      });
      if (!res?.data?.error?.length) {
        const awsUrl =
          res.data?.data?.createUploadPreSignedUrlForStore?.preSignedUrl;

        await axios.put(awsUrl, uploadImage.file, {
          headers: {
            "Content-Type": uploadImage?.file?.type,
            "x-amz-acl": "public-read",
          },
        });
        setImageUrl(uploadImage?.url);
        setUploadImage(null);
        fetchStores(false, {bucketKeyName: uploadImage?.url});
        successToast("Image successfully uploaded");
        setUploadImageLoader(false);
      }
    } catch (error) {
      console.error("Error uploading image:", error);
      setUploadImageLoader(false);
    }
  };

  const handleFileChange = (file: any) => {
    setUploadImage({
      url: URL.createObjectURL(file),
      file,
    });
  };

  return (
    <>
      <div className="flex w-full gap-6 mt-4">
        <div className="w-[50%]">
          <div className="flex justify-between p-3">
            <h1 className="font-medium text-xl">Add General Info</h1>
          </div>

          <div className="rounded-2xl bg-white p-6">
            {loading ? (
              <FormSkeletonLoader />
            ) : (
              <>
                <div className="flex gap-4 items-center">
                  <Dropzone
                    accept={{
                      "image/png": [".png"],
                      "image/jpg": [".jpg"],
                      "image/jpeg": [".jpeg"],
                    }}
                    onDrop={(acceptedFiles) =>
                      handleFileChange(acceptedFiles[0])
                    }
                  >
                    {({ getRootProps, getInputProps }) => (
                      <>
                        <div className="relative w-[61px] h-[61px] rounded-full border border-[#F1F1F2]">
                          <img
                            className="absolute object-cover h-full w-full rounded-full"
                            src={uploadImage?.url || imageUrl || RestuarantImg}
                            alt=""
                          />
                        </div>
                        {uploadImage?.url ? (
                          <>
                            {uploadImageLoader ? (
                              <div>
                                <BtnLoader />
                              </div>
                            ) : (
                              <button
                                className="text-sm text-orange bg-[#FD76571A] rounded-lg px-3 py-[10px]"
                                onClick={uploadRestaurantImage}
                              >
                                Upload
                              </button>
                            )}
                          </>
                        ) : (
                          <button
                            className="text-sm text-orange bg-[#FD76571A] rounded-lg px-3 py-[10px]"
                            {...getRootProps()}
                          >
                            Select new image
                            <input {...getInputProps()} />
                          </button>
                        )}
                      </>
                    )}
                  </Dropzone>
                  {uploadImage?.url && (
                    <img
                      className="w-[40px] h-[40px] cursor-pointer"
                      src={DeleteIcon}
                      alt="Delete"
                      onClick={() => setUploadImage(null)}
                    />
                  )}
                </div>

                <div className="mt-4">
                  <span className="text-[#9B9B9B] text-sm">
                    Restaurant Name
                  </span>
                  <input
                    className="text-xs text-[#9B9B9B]  h-[40px] w-full border rounded-lg border-[#F1F1F2] mt-1 px-2"
                    type="text"
                    name="restaurantName"
                    id="restaurantName"
                    placeholder=""
                    value={formik.values.restaurantName}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                  />
                  {formik.touched.restaurantName &&
                  formik.errors.restaurantName ? (
                    <div className="text-orange text-xs mt-2">
                      {formik.errors.restaurantName}
                    </div>
                  ) : null}

                  <div className="flex flex-col">
                    <div className="flex justify-between gap-x-4 mt-2">
                      <div className="w-full">
                        <span className="text-[#9B9B9B] text-sm">Street</span>
                        <input
                          className="text-xs text-[#9B9B9B]  h-[40px] w-full border rounded-lg border-[#F1F1F2] mt-1 px-2"
                          type="text"
                          name="street"
                          id="street"
                          placeholder=""
                          value={formik.values.street}
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                        />
                        {formik.touched.street && formik.errors.street ? (
                          <div className="text-orange text-xs mt-2">
                            {formik.errors.street}
                          </div>
                        ) : null}
                      </div>
                      <div className="w-full">
                        <span className="text-[#9B9B9B] text-sm">City</span>
                        <input
                          className="text-xs text-[#9B9B9B]  h-[40px] w-full border rounded-lg border-[#F1F1F2] mt-1 px-2"
                          type="text"
                          name="city"
                          id="city"
                          placeholder=""
                          value={formik.values.city}
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                        />
                        {formik.touched.city && formik.errors.city ? (
                          <div className="text-orange text-xs mt-2">
                            {formik.errors.city}
                          </div>
                        ) : null}
                      </div>
                    </div>

                    <div className="flex justify-between gap-x-4 mt-2">
                      <div className="w-full">
                        <span className="text-[#9B9B9B] text-sm">
                          State/Province
                        </span>
                        <input
                          className="text-xs text-[#9B9B9B]  h-[40px] w-full border rounded-lg border-[#F1F1F2] mt-1 px-2"
                          type="text"
                          name="stateProvince"
                          id="stateProvince"
                          placeholder=""
                          value={formik.values.stateProvince}
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                        />
                        {formik.touched.stateProvince &&
                        formik.errors.stateProvince ? (
                          <div className="text-orange text-xs mt-2">
                            {formik.errors.stateProvince}
                          </div>
                        ) : null}
                      </div>
                      <div className="w-full">
                        <span className="text-[#9B9B9B] text-sm">Zip code</span>
                        <input
                          className="text-xs text-[#9B9B9B]  h-[40px] w-full border rounded-lg border-[#F1F1F2] mt-1 px-2"
                          type="text"
                          name="zipCode"
                          id="zipCode"
                          placeholder=""
                          value={formik.values.zipCode}
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                        />
                        {formik.touched.zipCode && formik.errors.zipCode ? (
                          <div className="text-orange text-xs mt-2">
                            {formik.errors.zipCode}
                          </div>
                        ) : null}
                      </div>
                    </div>
                      <div className="w-[49%]">
                        <span className="text-[#9B9B9B] text-sm">Style</span>
                        <input
                          className="text-xs text-[#9B9B9B]  h-[40px] w-full border rounded-lg border-[#F1F1F2] mt-1 px-2"
                          type="text"
                          name="style"
                          id="style"
                          placeholder=""
                          value={formik.values.style}
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                        />
                      </div>

                    <h1 className="mt-6">Contact Information</h1>
                    <div className="flex justify-between gap-x-4 mt-2">
                      <div className="w-full">
                        <span className="text-[#9B9B9B] text-sm">
                          First Name
                        </span>
                        <input
                          className="text-xs text-[#9B9B9B]  h-[40px] w-full border rounded-lg border-[#F1F1F2] mt-1 px-2"
                          type="text"
                          name="firstName"
                          id="firstName"
                          placeholder=""
                          value={formik.values.firstName}
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                        />
                      </div>
                      <div className="w-full">
                        <span className="text-[#9B9B9B] text-sm">
                          Last Name
                        </span>
                        <input
                          className="text-xs text-[#9B9B9B]  h-[40px] w-full border rounded-lg border-[#F1F1F2] mt-1 px-2"
                          type="text"
                          name="lastName"
                          id="lastName"
                          placeholder=""
                          value={formik.values.lastName}
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                        />
                      </div>
                    </div>
                    <div className="flex justify-between gap-x-4 mt-4">
                      <div className="w-full">
                        <span className="text-[#9B9B9B] text-sm">
                          Contact Number
                        </span>
                        <input
                          className="text-xs text-[#9B9B9B]  h-[40px] w-full border rounded-lg border-[#F1F1F2] mt-1 px-2"
                          type="number"
                          name="contactNumber"
                          id="contactNumber"
                          placeholder=""
                          value={formik.values.contactNumber}
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                        />
                      </div>
                      <div className="w-full">
                        <span className="text-[#9B9B9B] text-sm">
                          Email Address
                        </span>
                        <input
                          className="text-xs text-[#9B9B9B]  h-[40px] w-full border rounded-lg border-[#F1F1F2] mt-1 px-2"
                          type="email"
                          name="email"
                          id="email"
                          placeholder=""
                          value={formik.values.email}
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                        />
                        {formik.touched.email && formik.errors.email ? (
                          <div className="text-orange text-xs mt-2">
                            {formik.errors.email}
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}

            {generalInProgress ? (
              <div className="mt-7">
                <BtnLoader />
              </div>
            ) : (
              <button
                className="w-full mt-6 px-4 py-2 bg-[white] text-orange border border-[#F1F1F2] rounded-lg"
                onClick={() => {
                  setGeneralInProgress(true);
                  updateRestaurantSetting();
                }}
              >
                Save
              </button>
            )}
          </div>
        </div>

        <div className="w-[50%]">
          <div className="flex justify-between p-3">
            <h1 className="font-medium text-xl">Opening Hours</h1>
          </div>

          <div className="rounded-2xl bg-white py-4 px-6 flex  flex-col">
            <div className="w-full flex justify-between">
              {loading ? <TimePickerSkeletonLoader/> :
              
              <div className="flex flex-col w-[100%] mt-3">
                {formik.values?.time?.map((item, index) => (
                  <div
                    key={index}
                    className={`flex justify-between items-center pb-[19px] gap-20 my-2 h-[43px] ${
                      formik.values?.time?.length !== index + 1 && "border-b"
                    } border-[#F7F7F7]`}
                  >
                    <div className="flex gap-36">
                      <span
                        className={`w-[50px] text-sm ${
                          item.isSelected ? "text-black" : "text-[#9B9B9B] "
                        }`}
                      >
                        {item.day.charAt(0).toUpperCase() +
                          item.day.slice(1).toLowerCase()}
                      </span>
                      <div className="flex">
                        <input
                          className="mr-2 mt-[0.3rem] h-3.5 w-8 appearance-none rounded-[0.4375rem] bg-[#9B9B9B] before:pointer-events-none before:absolute before:h-3.5 before:w-3.5 before:rounded-full before:bg-transparent before:content-[''] after:absolute after:z-[2] after:-mt-[0.1875rem] after:h-5 after:w-5 after:rounded-full after:border-none after:bg-white after:shadow-[0_0px_3px_0_rgb(0_0_0_/_7%),_0_2px_2px_0_rgb(0_0_0_/_4%)] after:transition-[background-color_0.2s,transform_0.2s] after:content-[''] checked:bg-orange checked:after:absolute checked:after:z-[2] checked:after:-mt-[3px] checked:after:ml-[1.0625rem] checked:after:h-5 checked:after:w-5 checked:after:rounded-full checked:after:border-none checked:after:bg-primary checked:after:shadow-[0_3px_1px_-2px_rgba(0,0,0,0.2),_0_2px_2px_0_rgba(0,0,0,0.14),_0_1px_5px_0_rgba(0,0,0,0.12)] checked:after:transition-[background-color_0.2s,transform_0.2s] checked:after:content-[''] hover:cursor-pointer focus:outline-none focus:ring-0 focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-[3px_-1px_0px_13px_rgba(0,0,0,0.6)] focus:before:transition-[box-shadow_0.2s,transform_0.2s] focus:after:absolute focus:after:z-[1] focus:after:block focus:after:h-5 focus:after:w-5 focus:after:rounded-full focus:after:content-[''] checked:focus:border-primary checked:focus:bg-primary checked:focus:before:ml-[1.0625rem] checked:focus:before:scale-100 checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca] checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] dark:bg-neutral-600 dark:after:bg-neutral-400 dark:checked:bg-primary dark:checked:after:bg-primary dark:focus:before:shadow-[3px_-1px_0px_13px_rgba(255,255,255,0.4)] dark:checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca]"
                          type="checkbox"
                          role="switch"
                          checked={item.isSelected}
                          onChange={() => handleCheckboxChange(index)}
                        />
                        <label
                          className={`inline-block pl-[0.15rem] text-sm hover:cursor-pointer ${
                            item.isSelected ? "text-black" : "text-[#9B9B9B] "
                          }`}
                        >
                          {item.isSelected ? "Open" : "Closed"}
                        </label>
                      </div>
                    </div>
                    <div className="w-[300px]">
                      {item.isSelected && (
                        <div className="flex gap-8">
                          <div>
                            <TimePicker
                              minuteStep={minuteStep}
                              showSecond={false}
                              defaultValue={moment(
                                item.startHour || "00:00",
                                format
                              )}
                              onChange={(value) => {
                                formik.setFieldValue(
                                  `time[${index}].startHour`,
                                  value.toISOString()
                                );
                              }}
                              format={format}
                              allowEmpty={false}
                            />
                          </div>
                          <div>
                            <TimePicker
                              minuteStep={minuteStep}
                              showSecond={false}
                              defaultValue={moment(
                                item.endHour || "00:00",
                                format
                              )}
                              onChange={(value) => {
                                formik.setFieldValue(
                                  `time[${index}].endHour`,
                                  value.toISOString()
                                );
                              }}
                              format={format}
                              allowEmpty={false}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                ))}
              </div>
              }
            </div>

            {timeInProgress ? (
              <BtnLoader />
            ) : (
              <button
                className="w-full mb-2 mt-4 px-4 py-2 bg-[white] text-orange border border-[#F1F1F2] rounded-lg"
                onClick={() => {
                  setTimeInProgress(true);
                  updateRestaurantSetting();
                }}
              >
                Save
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default RestaurantSetting;
