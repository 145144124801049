import DashboardIcon from "../../icons/DashboardIcon";
import RestuarantIcon from "../../icons/RestuarantIcon";
import RestuarantSettingIcon from "../../icons/RestaurantSetting";
import EmployeeIcon from "../../icons/EmployeeIcon";
import MenuIcon from "../../icons/MenuIcon";
import ComboIcon from "../../icons/ComboICon";
import TableICon from "../../icons/TableIcon";
import { AvailbleFeatures } from "src/utils/isAllowedFeature";

export const ownerSidebar = [
  {
    url: "/dashboard",
    name: "Dashboard",
    icon: DashboardIcon,
  },
  {
    url: "/my-restaurants",
    name: "My Restaurants",
    icon: RestuarantIcon,
  },
];

export const restaurantSidebar = [
  {
    url: (correlationId: string) => `/my-restaurant/${correlationId}/dashboard`,
    name: "Dashboard",
    icon: DashboardIcon,
    isRoleBase: false,
  },
  {
    url: (correlationId: string) =>
      `/my-restaurant/${correlationId}/restaurant-setting`,
    name: "General Settings",
    icon: RestuarantSettingIcon,
    isRoleBase: false,
  },
  {
    url: (correlationId: string) => `/my-restaurant/${correlationId}/employee`,
    name: "Employee",
    icon: EmployeeIcon,
    isRoleBase: true,
    roleName: AvailbleFeatures.TeamManagement,
  },
  {
    url: (correlationId: string) =>
      `/my-restaurant/${correlationId}/restaurant-table`,
    name: "Table",
    icon: TableICon,
    isRoleBase: true,
    roleName: AvailbleFeatures.TableManagement,
  },
  {
    url: (correlationId: string) =>
      `/my-restaurant/${correlationId}/promotions`,
    name: "Promotions",
    icon: EmployeeIcon,
    isRoleBase: true,
    roleName: AvailbleFeatures.PromotionManagement,
  },
  {
    url: (correlationId: string) =>
      `/my-restaurant/${correlationId}/OrderManagement`,
    name: "Recent Order",
    icon: EmployeeIcon,
    isRoleBase: false,
  },
  {
    url: (correlationId: string) =>
      `/my-restaurant/${correlationId}/active-orders`,
    name: "Active Orders",
    icon: EmployeeIcon,
    isRoleBase: false,
  },
  {
    url: (correlationId: string) => `/my-restaurant/${correlationId}/menu`,
    name: "Menu",
    section: "Manage",
    isSubMenuOpen: false,
    icon: MenuIcon,
    children: [],
    isRoleBase: true,
    roleName: AvailbleFeatures.MenuCreationAndManagement,
  },
  {
    url: (correlationId: string) => `/my-restaurant/${correlationId}/combo`,
    name: "Combo",
    icon: ComboIcon,
    isRoleBase: true,
    roleName: AvailbleFeatures.ComboCreationAndManagement,
  },
];

export const employeeSidebar = [
  {
    url: (correlationId: string) => `/active-order`,
    name: "Active Orders",
    icon: DashboardIcon,
  },
  // {
  //   url: (correlationId: string) => `/combo`,
  //   name: "Combo",
  //   icon: RestuarantIcon,
  // },
  {
    url: (correlationId: string) => `/employee-table`,
    name: "Table",
    icon: TableICon,
  },
];
