import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ItemsCard from "../MenuCard/ItemsCard";
import closeIcon from "../../assests/images/dashboard/Vector 3.png";

const ComboItemsModal = ({ title, setShowItemModal, formik }: any) => {
  const [allData, setAllData] = useState<any>([]);
  const [orignalData, setOrignalData] = useState<any>([]);
  const [selectedCategory, setSelectedCategory] = useState<any>(null);
  const { categories } = useSelector((state: any) => state.category);

  useEffect(() => {
    const tempData = [...categories];
    const ids = formik.values.itemCorrelationIds.map(
      (item: any) => item?.correlationId
    );

    const allTempData = tempData[0]?.items
      ? tempData[0].items.map((item: any) => ({
          ...item,
          isSelected: ids?.includes(item.correlationId),
        }))
      : [];
    setOrignalData(tempData);
    setSelectedCategory(tempData?.[0] || {});
    setAllData(transformData(allTempData));
  }, []);

  const transformData = (data: any) => {
    const result = [];
    for (let i = 0; i < data.length; i += 6) {
      const subArray = data.slice(i, i + 6).map((item: any) => ({
        correlationId: item?.correlationId,
        englishName: item?.englishName,
        price: item?.price,
        taxRate: item?.taxRate,
        tag: item?.tag,
        itemOptionsCount: item?.itemOptions?.length,
        bucketKeyName: item?.bucketKeyName,
        isSelected: item?.isSelected,
      }));
      result.push(subArray);
    }
    return result;
  };

  const selectCategory = (item: any) => {
    setSelectedCategory(item);
    const tempData = [...categories];
    const index = tempData.findIndex(
      (data: any) => data.correlationId === item.correlationId
    );
    const data = transformData(tempData[index].items);
    setAllData(data);
  };

  const handleClick = (indexes: any) => {
    const updatedData = [...allData];
    const correlationId = updatedData[indexes[0]][indexes[1]].correlationId;
    const isSelected = !updatedData[indexes[0]][indexes[1]].isSelected;

    updatedData[indexes[0]][indexes[1]].isSelected = isSelected;

    const updatedCorrelationItems = isSelected
      ? [
          ...formik.values.itemCorrelationIds,
          updatedData[indexes[0]][indexes[1]],
        ]
      : formik.values.itemCorrelationIds.filter(
          (item: any) => item.correlationId !== correlationId
        );
    formik.setFieldValue("itemCorrelationIds", updatedCorrelationItems);
    setAllData(updatedData);
  };

  const searchOrders = (search: string) => {
    const allOrginalData = [...orignalData];
    if (!search) {
      setAllData(transformData(allOrginalData?.[0]?.items) || []);
      setSelectedCategory(allOrginalData?.[0] || {});
      return;
    }
    const data = [...orignalData].flatMap((category) => category.items || []);
    setAllData(
      transformData(
        data.filter((item) =>
          item.englishName?.toLowerCase()?.includes(search?.toLowerCase())
        )
      )
    );
    setSelectedCategory("");
  };

  return (
    <div
      className="relative z-10"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div className="fixed inset-0 flex bg-black/30 min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
        <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-[0px_2px_0px_880px_rgba(37,37,37,0.85)] transition-all sm:my-8">
          <div className="flex items-center justify-center bg-gray-100">
            <div className="bg-white px-8 py-6 rounded-lg w-[1000px] h-[700px] min-w-10 flex flex-col">
              <div className="top flex justify-between items-center">
                <span className="text-xl font-semibold text-[#252525]">
                  {title}
                </span>
                <img
                  className="w-3 h-3 cursor-pointer"
                  src={closeIcon}
                  alt="Close"
                  onClick={() => {
                    setShowItemModal(false);
                  }}
                />
              </div>
              <form className=" mt-6 flex items-center gap-x-2">
                <div className="relative w-[480px]">
                  <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                    <svg
                      className="w-4 h-4 text-gray-500 dark:text-gray-400"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 20 20"
                    >
                      <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                      />
                    </svg>
                  </div>
                  <input
                    type="search"
                    id="default-search"
                    className="block border-[#F1F1F2] border w-full p-2 ps-10 text-sm text-black rounded-lg "
                    placeholder="Search dishes by name..."
                    onChange={(e) => searchOrders(e.target.value)}
                  />
                </div>
                <div className="flex gap-x-8 ml-4 ">
                  {categories.map((item: any, index: any) => {
                    return (
                      <div
                        key={index}
                        className="flex"
                        onClick={() => selectCategory(item)}
                      >
                        <span
                          className={`text-[#939393] font-semibold cursor-pointer border-b-2 ${
                            selectedCategory === item
                              ? "border-[#FD7657]"
                              : "border-none"
                          }`}
                        >
                          {item.name}
                        </span>
                      </div>
                    );
                  })}
                </div>
              </form>
              <div className="w-full mt-4">
                {allData?.length > 0 ? (
                  <div className="overflow-x-scroll">
                    {allData.map((items: any, index1: any) => {
                      return (
                        <div key={index1} className="flex">
                          {items.map((item: any, index2: any) => {
                            return (
                              <ItemsCard
                                key={`${index1}-${index2}`}
                                item={item}
                                isSelected={item.isSelected}
                                isCombo={true}
                                handleClick={handleClick}
                                indexes={[index1, index2]}
                              />
                            );
                          })}
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  <div className="flex items-center justify-center">
                    <p>Create some items to add combo!</p>
                  </div>
                )}
              </div>
              <div className="absolute right-5 bottom-5">
                <div className="flex justify-end mt-4 flex-1 items-end gap-x-4">
                  <button
                    className={`px-8 text-xs flex justify-center items-center gap-2 text-white h-[34px] rounded-md bg-orange hover:bg-[#F5F5F6] hover:text-black duration-500`}
                    onClick={() => {
                      setShowItemModal();
                    }}
                  >
                    Add to Combo
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ComboItemsModal;
