import React from 'react';
import {useDispatch} from "react-redux";
import { search_restaurants } from 'src/store/features/restuarant-slice';


const SearchForm = ({placeholder}:any) => {
  const dispatch = useDispatch();

  const searchRestaurant = (value: string) => {
   dispatch(search_restaurants(value));
  };

  return (
    <form className="w-[280px]">
     
      <div className="relative">
        <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
          <svg 
            className="w-4 h-4 text-gray-500 dark:text-gray-400" 
            aria-hidden="true" 
            xmlns="http://www.w3.org/2000/svg" 
            fill="none" 
            viewBox="0 0 20 20"
          >
            <path 
              stroke="currentColor" 
              strokeLinecap="round" 
              strokeLinejoin="round" 
              strokeWidth="2" 
              d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
            />
          </svg>
        </div>
        <input
          type="search" 
          id="default-search" 
          className="block border-[#F1F1F2] border w-full p-3 ps-10 text-sm text-black rounded-lg " 
          placeholder={placeholder} 
          onChange={(e) => searchRestaurant(e.target.value)}
        />
      </div>
    </form>
  );
};

export default SearchForm;
