import React from "react";
import { useDispatch } from "react-redux";
import { sort_categories } from "src/store/features/category-slice";

const SortbyDropdown = ({ type, sortBy, isDispatched = true }: any) => {
  const dispatch = useDispatch();

  const sortCategory = (value: string) => {
    if (sortBy && isDispatched) {
      dispatch(sortBy(value));
    } else if (sortBy && !isDispatched) {
      sortBy(value);
    } else {
      dispatch(sort_categories(value));
    }
  };

  return (
    <div className="absolute right-0 top-16 mt-2 w-56 shadow-[0_4px_6px_rgba(0,0,0,0.08)] bg-white rounded-xl z-[999]">
      <div className="pt-5 pb-3">
        <p
          onClick={() => sortCategory("aToZ")}
          className="block py-2 text-sm font-normal text-[#828282] hover:bg-[#f1f1f2] hover:text-black hover:rounded-xl mx-[10px] px-[12px] cursor-pointer"
        >
        {type ? "Table Numbers: 1 to 100" : "Alphabetically: A to Z"}   
        </p>
        <p 
         onClick={() => sortCategory("zToA")}
        className="block py-2 text-sm font-normal text-[#828282] hover:bg-[#f1f1f2] hover:text-black hover:rounded-xl mx-[10px] px-[12px] cursor-pointer">
          {type ? "Table Numbers: 100 to 1" : " Alphabetically: Z to A"}
        </p>
      </div>
    </div>
  );
};

export default SortbyDropdown;
