import React from "react";
import ArrowBack from "../../assests/images/landing-page/arrow_back.png";
import { Link} from "react-router-dom";
import Logo from "../../components/common/Logo";

export const UserAccountRegistered = () => {
  return (
    <div className="flex justify-center flex-col items-center gap-64">
      <div className=" mt-[5%]">
        <div className="flex ">
          <Logo />
          <h1 className="ml-2 text-3xl">EatsBits</h1>
        </div>
      </div>

      <div className="max-sm:mt-[5%] w-[420px] rounded-[12px] mt-[0%] p-6 border border-lightGrey">
        <h1 className="text-center text-2xl mt-2 font-semibold">
          Thank you for registering an account with us!
        </h1>
        <p className="text-sm text-[#9B9B9B] text-center mt-4">
          {/* We sent a password reset link to{" "} */}
          <span>
            We have sent an activation link to the registered email, please
            click the link to activate your account.
          </span>
        </p>
      </div>

      <div className="flex justify-center items-center absolute bottom-12 cursor-pointer">
        <div className="mr-2">
          <img src={ArrowBack} alt="" />
        </div>
        <Link to="/login">
          <h1 className="text-orange">Back To Login</h1>
        </Link>
      </div>
    </div>
  );
};
