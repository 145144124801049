import { useEffect } from "react";
import CreateRestuarantHeader from "../../../components/CreateRestuarantHeader/CreateRestuarantHeader";
import { API_ENDPOINT } from "src/constants/endpoints";
import { useAxiosInterceptor } from "../../../hooks/useAxiosInterceptor";
import { useNavigate } from "react-router-dom";
import ErrorCard from "src/components/errorCard/ErrorCard";

const Cancel = () => {
  const { axBe } = useAxiosInterceptor();
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      if (!API_ENDPOINT) {
        console.error("API endpoint is not defined.");
        return;
      }
    })();
  }, [axBe]);

  const cancleSubcription = async () => {
    if (!API_ENDPOINT) {
      console.error("API endpoint is not defined.");
      return;
    }
    axBe
      .post(`${API_ENDPOINT}stripe/cancel-subscription`)
      .then(() => {
        navigate("/my-restaurants");
      })
      .catch((error) => {});
  };

  return (
    <>
      <CreateRestuarantHeader heading={"Subscription failed"} />
      <ErrorCard
        heading="Something went wrong while subscribing plan."
        subHeading="Your plan was not subscribed, Please try again"
        onHanldeSecondaryBtn={cancleSubcription}
        secondaryButtonText="Cancle"
      />
    </>
  );
};

export default Cancel;
