import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";

import AuthProvider from "./contexts/auth-provider";
import store from "./store";
import Routes from "./routes";

import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import { ApolloClient, ApolloProvider, InMemoryCache } from "@apollo/client";
import { GRAPHQL_ENDPOINT } from "./constants/endpoints";

const client = new ApolloClient({
  uri: GRAPHQL_ENDPOINT,
  cache: new InMemoryCache(),
});

function App() {
  return (
    <Provider store={store}>
      <ApolloProvider client={client}>
        <AuthProvider>
          <Routes />
          <ToastContainer
            position="top-right"
            autoClose={1000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss={false}
            draggable
            pauseOnHover
            limit={1}
          />
        </AuthProvider>
      </ApolloProvider>
    </Provider>
  );
}

export default App;
