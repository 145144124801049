import { useFormik } from "formik";
import * as Yup from "yup";
import CrossIcon from "../../assests/images/dashboard/Vector 3.png";
import { useAuth } from "src/contexts/auth-provider";
import { useEffect, useState } from "react";
import { GRAPHQL_ENDPOINT } from "src/constants/endpoints";
import { useAxiosInterceptor } from "src/hooks/useAxiosInterceptor";
import { UPDATE_RESTAURANT } from "src/constants/mutations";
import { successToast } from "src/utils/toasts";
import { BtnLoader } from "src/Loader";

const DeliveryPolicy = () => {
  const { axBe } = useAxiosInterceptor();
  const { hasRestaurant, sethasRestaurant } = useAuth();

  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      deliveryPolicies: [
        {
          startMile: "",
          endMile: "",
          deliveryCharge: "",
        },
      ],
    },
    validationSchema: Yup.object({
      deliveryPolicies: Yup.array()
        .of(
          Yup.object({
            startMile: Yup.number()
              .required("Start Mile is required")
              .min(0, "Start Mile must be greater than or equal to 0"),
            endMile: Yup.number()
              .required("End Mile is required")
              .min(0, "End Mile must be greater than or equal to 0"),
            deliveryCharge: Yup.string().required(
              "Delivery Charges is required"
            ),
          })
        )
        .test(
          "validate-mileage-order",
          "Start Mile must be equal to the End Mile of the previous policy",
          function (deliveryPolicies: any) {
            if (!deliveryPolicies || deliveryPolicies.length < 2) {
              return true;
            }

            for (let i = 1; i < deliveryPolicies.length; i++) {
              const prevPolicy = deliveryPolicies[i - 1];
              const currentPolicy = deliveryPolicies[i];

              const prevEndMile = parseInt(prevPolicy.endMile);
              const currentStartMile = parseInt(currentPolicy.startMile);

              if (currentStartMile !== prevEndMile) {
                return false;
              }
            }

            return true;
          }
        ),
    }),
    onSubmit: () => {},
  });

  useEffect(() => {
    if (hasRestaurant?.deliveryPolicies?.length > 0) {
      formik.setFieldValue("deliveryPolicies", hasRestaurant?.deliveryPolicies);
    }
  }, [hasRestaurant?.deliveryPolicies]);

  const handleAddDeliveryPolicy = () => {
    const prevDeliveryPolicies = [...formik.values.deliveryPolicies];
    prevDeliveryPolicies.push({
      startMile: "",
      endMile: "",
      deliveryCharge: "",
    });
    formik.setFieldValue("deliveryPolicies", prevDeliveryPolicies);
  };

  const handleRemoveDeliveryPolicy = (index: any) => {
    const prevDeliveryPolicies = [...formik.values.deliveryPolicies];
    prevDeliveryPolicies.splice(index, 1);
    formik.setFieldValue("deliveryPolicies", prevDeliveryPolicies);
  };

  const updateRestaurantSetting = async () => {
    setLoading(true);
    try {
      const variables = {
        updateStoreInput: {
          ...formik.values,
          storeCorrelationId: hasRestaurant.correlationId,
        },
      };
      const { data }: any = await axBe.post(GRAPHQL_ENDPOINT, {
        query: UPDATE_RESTAURANT,
        variables: variables,
      });
      const payloadData = {
        ...hasRestaurant,
        deliveryPolicies: formik.values.deliveryPolicies,
      };
      localStorage.setItem("restaurant", JSON.stringify(payloadData));
      sethasRestaurant(payloadData);
      setLoading(false);

      if (!data.errors) {
        successToast("Delivery Policy has been Updated!");
      }
    } catch (error) {
      console.error("Error creating user:", error);
      setLoading(false);
    }
  };

  const isDisabled = !formik.isValid || !formik.dirty;

  return (
    <div className="flex justify-center items-center">
      <div className="mt-20 bg-white border border-[#25252514] rounded-2xl p-4 w-[900px]">
        <div className="flex justify-end items-center">
          <div className="flex items-center gap-x-2 justify-end px-2 mt-2">
            <button
              type="button"
              className="text-xs bg-orange text-white rounded-md w-[60px] h-[25px]"
              onClick={handleAddDeliveryPolicy}
            >
              Add
            </button>
          </div>
        </div>
        <div className="mt-4">
          {formik.values.deliveryPolicies?.map((policy: any, index: any) => (
            <div
              key={index}
              className="h-[45px] flex gap-x-8 pr-2 relative pl-4"
            >
              <div className="flex items-center gap-x-2 relative">
                <h1 className="text-sm text-[#9B9B9B]">Greater or equal to:</h1>
                <input
                  className="text-xs text-[#9B9B9B] h-[30px] border rounded-lg border-[#F1F1F2] mt-1 px-2 w-[70px]"
                  type="number"
                  name={`deliveryPolicies[${index}].startMile`}
                  id={`deliveryPolicies[${index}].startMile`}
                  placeholder=""
                  value={formik.values.deliveryPolicies[index]?.startMile}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                />
              </div>
              <div className="flex items-center gap-x-2">
                <h1 className="text-sm text-[#9B9B9B]">and less than:</h1>
                <input
                  className="text-xs text-[#9B9B9B] h-[30px] border rounded-lg border-[#F1F1F2] mt-1 px-2 w-[70px]"
                  type="number"
                  name={`deliveryPolicies[${index}].endMile`}
                  id={`deliveryPolicies[${index}].endMile`}
                  placeholder=""
                  value={formik.values.deliveryPolicies[index]?.endMile || ""}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                />
              </div>
              <div className="flex items-center gap-x-2 relative">
                <h1 className="text-sm text-[#9B9B9B]">Delivery Charges:</h1>
                <input
                  className="text-xs text-[#9B9B9B] h-[30px] border rounded-lg border-[#F1F1F2] mt-1 px-2 w-[70px]"
                  type="number"
                  name={`deliveryPolicies[${index}].deliveryCharge`}
                  id={`deliveryPolicies[${index}].deliveryCharge`}
                  placeholder=""
                  value={formik.values.deliveryPolicies[index]?.deliveryCharge}
                  onBlur={formik.handleBlur}
                  onChange={(e) =>
                    formik.setFieldValue(
                      `deliveryPolicies[${index}].deliveryCharge`,
                      e.target.value.toString()
                    )
                  }
                />
              </div>
              <div
                className={`${
                  index === 0 && "hidden"
                } relative top-[18px] cursor-pointer`}
                onClick={() => handleRemoveDeliveryPolicy(index)}
              >
                <img className="h-[12px] w-[12px]" src={CrossIcon} alt="" />
              </div>
            </div>
          ))}

          <div className="flex justify-end mt-4">
            {loading ? (
              <BtnLoader />
            ) : (
              <button
                disabled={isDisabled}
                className={`${
                  isDisabled && "opacity-20"
                }  bg-orange text-white rounded-lg w-[85px] h-[35px] text-xs `}
                onClick={updateRestaurantSetting}
              >
                Update
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeliveryPolicy;
