import * as Yup from "yup";
import { useFormik } from "formik";
import { useAuth } from "src/contexts/auth-provider";

const CartCard = ({
  orderDetail,
  handlePlaceOrder,
  index,
  changeCardQuantity,
  addMoreItems,
}: any) => {
  const { hasRestaurant, hasEmployee } = useAuth();

  const formik = useFormik({
    initialValues: {
      customerNote: "",
      customerFirstName: "",
      customerLastName: "",
      tableNumber: undefined,
    },
    validationSchema: Yup.object().shape({
      tableNumber: Yup.number().required("Table number is required"),
    }),
    onSubmit: () => {},
  });

  // useEffect(() => {
  //   formik.setFieldValue("customerNote", "");
  //   formik.setFieldValue("customerFirstName", "Guest");
  //   formik.setFieldValue("customerLastName", "Guest");
  // }, []);

  const totalPrice =
    orderDetail?.items?.reduce(
      (acc: any, item: any) => acc + +item?.price * item.quantity,
      0
    ) || 0;

  const placeOrder = (index: number) => {
    const data = {
      ...formik.values,
      appUserCorrelationId: hasEmployee?.correlationId,
      storeCorrelationId: hasRestaurant?.correlationId,
      orderType: "DINE_IN",
      orderItems: orderDetail?.items?.map((item: any) => {
        return {
          quantity: item.quantity,
          isCombo: false,
          orderItemCorrelationId: item?.correlationId,
        };
      }),
    };
    handlePlaceOrder(data, index);
  };

  const updateQuantity = (itemIndex: any, quantity: any) => {
    changeCardQuantity(index, itemIndex, quantity);
  };

  const handleMoreItem = () => {
    const correlationIds = orderDetail.items.map(
      (item: any) => item.correlationId
    );
    addMoreItems({
      cardIndex: index,
      correlationIds: correlationIds,
    });
  };

  return (
    <div className="flex flex-wrap justify-center items-center w-full">
      <div
        className="relative mx-2 mb-3 border-[#F1F1F2] border bg-white rounded-xl  w-[600px]"
      >
        <div className="p-4">
          <div className="flex items-center justify-between">
          </div>
          <div className="flex justify-between items-center mt-4">
            <h1 className="font-normal text-sm text-[#666666]">Table</h1>
            <input
              id="tableNumber"
              name="tableNumber"
              value={formik.values.tableNumber}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              type="number"
              placeholder="Enter table number"
              className="font-normal border border-[#F1F1F2] rounded-lg p-2 text-xs text-[#666666] w-[140px]"
            />
          </div>
          {formik.touched.tableNumber && formik.errors.tableNumber ? (
            <div className="text-orange text-xs mt-2">
              {formik.errors.tableNumber}
            </div>
          ) : null}

          <div className="flex items-center gap-x-2 mt-3">
            <div className="flex w-full flex-col">
              <h1 className="font-normal text-sm text-[#666666] ml-[2px]">
                Customer First name
              </h1>
              <input
                id="customerFirstName"
                name="customerFirstName"
                value={formik.values.customerFirstName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                type="text"
                placeholder="Enter First name"
                className="font-normal border border-[#F1F1F2] rounded-lg p-2 text-xs text-[#666666] w-full mt-1"
              />
            </div>
            <div className="flex w-full flex-col">
              <h1 className="font-normal text-sm text-[#666666] ml-[2px]">
                Customer Last name
              </h1>
              <input
                id="customerLastName"
                name="customerLastName"
                value={formik.values.customerLastName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                type="text"
                placeholder="Enter Last name"
                className="font-normal border border-[#F1F1F2] rounded-lg p-2 text-xs text-[#666666] w-full mt-1"
              />
            </div>
          </div>
          <div className="flex flex-col mt-2">
            <h1 className="font-normal text-sm text-[#666666] ml-[2px]">
              Customer Note
            </h1>
            <input
              id="customerNote"
              name="customerNote"
              value={formik.values.customerNote}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              type="text"
              placeholder="Enter Note"
              className="font-normal border border-[#F1F1F2] rounded-lg p-2 text-xs text-[#666666] w-full mt-1"
            />
          </div>

          <div className="divider border-[#EBF5FF] border-[1px] mt-4 "></div>
          <div className="flex items-center mt-4 justify-between">
            <div className="w-[70%]">
              <h1 className="font-normal text-xs text-[#666666]">Items</h1>
            </div>
            <div className="flex items-center gap-x-10">
              <h1 className="font-normal text-xs text-[#666666] mr-6">Qty</h1>
              <h1 className="font-normal text-xs text-[#666666] w-[45px]">
                Price
              </h1>
            </div>
          </div>
          <div className="h-[140px] overflow-y-scroll">
            {orderDetail?.items?.map((item: any, index: number) => {
              return (
                <div
                  className="flex items-center justify-between mt-3"
                  key={index}
                >
                  {item?.items?.map((nestedItem: any, nestedIndex: number) => {
                    return (
                      <div className="w-full" key={nestedIndex}>
                        <div className="flex items-center justify-between">
                          <div className="w-[70%]">
                            <h1 className="font-normal text-sm text-[#252525]">
                              {nestedItem?.englishName || ""}
                            </h1>
                          </div>
                          <div className="flex items-center gap-x-4">
                            <button
                              className="bg-[#f6f6f6] px-3 rounded-md"
                              onClick={() =>
                                updateQuantity(index, nestedItem?.count - 1)
                              }
                            >
                              -
                            </button>
                            <h1 className="font-normal text-sm text-[#252525]">
                              {nestedItem?.count}
                            </h1>
                            <button
                              className="bg-[#f6f6f6] px-3 rounded-md"
                              onClick={() =>
                                updateQuantity(index, nestedItem?.count + 1)
                              }
                            >
                              +
                            </button>
                            <h1 className="font-normal text-sm text-[#252525] w-[45px]">
                              {nestedItem?.price
                                ? `$${(
                                    +nestedItem?.price * nestedItem?.count
                                  ).toFixed(2)}`
                                : ""}
                            </h1>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              );
            })}

            <div onClick={handleMoreItem}>
              <h1 className="w-[88px] mt-2 text-xs px-4 py-2 rounded-lg bg-[#FD76571A] text-[#FD7657] cursor-pointer">
                Add item
              </h1>
            </div>
            <div className="divider border-[#EBF5FF] border-[1px] mt-4 "></div>
            <div className="flex justify-between items-center mt-2 ">
              <h1 className="font-medium text-sm">Total</h1>
              <h1 className="font-medium text-sm">
                {" "}
                ${Math.round(totalPrice)?.toFixed(2) || 0}
              </h1>
            </div>
          </div>

          <div className="flex w-full justify-center my-4">
            <button
              className={`${
                !formik.isValid && "opacity-20"
              } w-full text-sm text-white h-[40px] rounded-xl bg-[#FD7657]`}
              onClick={() => placeOrder(index)}
              disabled={!formik.isValid}
            >
              Place order
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CartCard;
