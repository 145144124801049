import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { AuthContext } from "../contexts/auth-context";
import { useDispatch } from "react-redux";
import { set_restaurants } from "src/store/features/restuarant-slice";
import {
  AvailbleFeatures,
  getAvailableFeatures,
} from "src/utils/isAllowedFeature";

export interface AuthContextType {
  token: string | null;
  userRole: any;
  refreshToken: string | null;
  user: any;
  hasRestaurant: any;
  hasEmployee: any;
  setRestaurantData: any;
  sethasRestaurant: any;
  setHasEmployee: any;
  setToken: (newToken: string) => void;
  setUser: (userInfo: any) => void;
  setRefreshtoken: (refreshToken: any) => void;
  signOut: () => void;
  isReady: boolean;
  permissions: Record<AvailbleFeatures, boolean>;
}

const AuthProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const isReady = useRef(false);
  const [userRole, setUserRole] = useState<any>(null);
  const [permissions, setPermissions] = useState<
    Record<AvailbleFeatures, boolean>
  >(getAvailableFeatures());

  const [user, setUser] = useState<any>(
    JSON.parse(localStorage.getItem("user") || "{}")
  );
  const [hasRestaurant, sethasRestaurant] = useState<any>(
    JSON.parse(localStorage.getItem("restaurant") || "null")
  );
  const [hasEmployee, setHasEmployee] = useState<any>(
    JSON.parse(localStorage.getItem("employee") || "null")
  );
  const [token, setToken] = useState<string | null>(
    localStorage.getItem("token")
  );
  const [refreshtoken, setRefreshtoken] = useState<string | null>(
    localStorage.getItem("refreshToken")
  );
  const dispatch = useDispatch();

  const signOut = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    localStorage.removeItem("restaurant");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("employee");
    localStorage.removeItem("role");
    setUser({});
    setUserRole(null);
    setToken(null);
    sethasRestaurant(null);
    setHasEmployee(null);
  };

  useEffect(() => {
    const role: any = localStorage.getItem("role");
    const token = localStorage.getItem("token");
    let user = localStorage.getItem("user");
    const restaurant = localStorage.getItem("restaurant");
    const parsedRestaurant = restaurant ? JSON.parse(restaurant) : null;
    const parsedUser = user ? JSON.parse(user) : null;
    if (userRole?.toLowerCase()?.includes("employee") && token && parsedUser) {
      setUserRole(role);
      setToken(token);
      setUser(parsedUser);
      sethasRestaurant(parsedRestaurant);
    } else if (token && parsedUser) {
      const employee: any = localStorage.getItem("employee");
      const parsedRestaurant = restaurant ? JSON.parse(restaurant) : null;
      const parsedEmployee = restaurant ? JSON.parse(employee) : null;
      isReady.current = true;
      setToken(token);
      setUser(parsedUser);
      sethasRestaurant(parsedRestaurant);
      setHasEmployee(parsedEmployee);
      setUserRole(role);
      dispatch(
        set_restaurants({ restaurants: parsedUser?.storeUser?.stores || [] })
      );
    } else {
      setToken(null);
      setUser(null);
      sethasRestaurant(null);
    }
  }, [token]);

  const setRestaurantData = (restaurant: any) => {
    // let user = localStorage.getItem("user");
    // const parsedUser = user ? JSON.parse(user) : null;
    // const getRestaurantIndex = parsedUser?.storeUser?.stores?.findIndex(
    //   (store: any) => store?.correlationId === restaurant?.correlationId
    // );
    // if (getRestaurantIndex >= 0) {
    //   const updatedRestautant = {
    //     ...parsedUser?.storeUser?.stores[getRestaurantIndex],
    //     ...restaurant,
    //   };
    //   parsedUser.storeUser.stores[getRestaurantIndex] = updatedRestautant;
    //   setUser(parsedUser);
    //   sethasRestaurant(updatedRestautant);
    //   localStorage.setItem("user", JSON.stringify(parsedUser));
    //   localStorage.setItem("restaurant", JSON.stringify(updatedRestautant));
    //   dispatch(
    //     set_restaurants({ restaurants: parsedUser?.storeUser?.stores || [] })
    //   );
    // }
  };

  useEffect(() => {
    if (hasRestaurant) {
      const tempPermission = { ...permissions };  
      hasRestaurant.allowedFeatures.forEach((element: AvailbleFeatures) => {
        tempPermission[element] = true;
      });
      setPermissions(tempPermission);
    }
  }, [hasRestaurant]);

  const contextValue = useMemo(
    () => ({
      token,
      user,
      hasRestaurant,
      hasEmployee,
      sethasRestaurant,
      setRestaurantData,
      setHasEmployee,
      setToken,
      setUser,
      signOut,
      isReady: isReady.current,
      refreshtoken,
      setRefreshtoken,
      userRole,
      permissions,
    }),
    [token, hasRestaurant, user, refreshtoken, hasEmployee, userRole, permissions]
  );

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};

export const useAuth = (): AuthContextType => {
  const authContext = useContext(AuthContext);
  if (!authContext) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return authContext;
};

export default AuthProvider;
