export const STATES: { [key: string]: string } = {
  Alabama: "America/Chicago",
  Alaska: "America/Anchorage",
  Arizona: "America/Phoenix",
  Arkansas: "America/Chicago",
  California: "America/Los_Angeles",
  Colorado: "America/Denver",
  Connecticut: "America/New_York",
  Delaware: "America/New_York",
  Florida: "America/New_York",
  Georgia: "America/New_York",
  Hawaii: "Pacific/Honolulu",
  Idaho: "America/Denver",
  Illinois: "America/Chicago",
  Indiana: "America/Indiana/Indianapolis",
  Iowa: "America/Chicago",
  Kansas: "America/Chicago",
  Kentucky: "America/New_York",
  Louisiana: "America/Chicago",
  Maine: "America/New_York",
  Maryland: "America/New_York",
  Massachusetts: "America/New_York",
  Michigan: "America/Detroit",
  Minnesota: "America/Chicago",
  Mississippi: "America/Chicago",
  Missouri: "America/Chicago",
  Montana: "America/Denver",
  Nebraska: "America/Chicago",
  Nevada: "America/Los_Angeles",
  NewHampshire: "America/New_York",
  NewJersey: "America/New_York",
  NewMexico: "America/Denver",
  NewYork: "America/New_York",
  NorthCarolina: "America/New_York",
  NorthDakota: "America/Chicago",
  Ohio: "America/New_York",
  Oklahoma: "America/Chicago",
  Oregon: "America/Los_Angeles",
  Pennsylvania: "America/New_York",
  RhodeIsland: "America/New_York",
  SouthCarolina: "America/New_York",
  SouthDakota: "America/Chicago",
  Tennessee: "America/Chicago",
  Texas: "America/Chicago",
  Utah: "America/Denver",
  Vermont: "America/New_York",
  Virginia: "America/New_York",
  Washington: "America/Los_Angeles",
  WestVirginia: "America/New_York",
  Wisconsin: "America/Chicago",
  Wyoming: "America/Denver",
};
export const US_TIMEZONE: { [key: string]: string } = {
  Eastern: "US/Eastern",
  Central: "US/Central",
  Mountain: "US/Mountain",
  Pacific: "US/Pacific",
  Hawaii: "US/Hawaii",
};