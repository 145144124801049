import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  combo: [],
  filteredCombo: [],
  hasComboId: null,
  isComboLoading: false,
};

export const comboSlice = createSlice({
  name: "combo",
  initialState,
  reducers: {
    set_combo: (state: any, { payload }) => {
      state.combo = payload.combo;
      state.filteredCombo = payload.combo;
    },
    search_combo: (state, { payload }) => {
      state.filteredCombo = state.combo.filter((combo: any) =>
        combo.englishName.toLowerCase().includes(payload.toLowerCase())
      );
    },
    delete_combo_modal: (state: any, { payload }) => {
      state.hasComboId = payload;
    },
    sort_combo: (state, { payload }) => {
      if (payload === "aToZ") {
        state.filteredCombo = state.combo
          .slice()
          .sort((a: any, b: any) =>
            a.englishName
              .toLowerCase()
              .localeCompare(b.englishName.toLowerCase())
          );
      } else if (payload === "zToA") {
        state.filteredCombo = state.combo
          .slice()
          .sort((a: any, b: any) =>
            b.englishName
              .toLowerCase()
              .localeCompare(a.englishName.toLowerCase())
          );
      }
    },
    set_combo_loding: (state, { payload }) => {
      state.isComboLoading = payload;
    },
  },
});

export const {
  set_combo,
  search_combo,
  delete_combo_modal,
  sort_combo,
  set_combo_loding,
} = comboSlice.actions;

export default comboSlice.reducer;
