import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  categories: [],
  filteredCategories: [],
  hasCategoryId: null,
  isCategoryLoading: false,
};

export const categorySlice = createSlice({
  name: "category",
  initialState,
  reducers: {
    set_categories: (state: any, { payload }) => {
      state.categories = payload.categories;
      state.filteredCategories = payload.categories;
    },
    search_categories: (state, { payload }) => {
      state.filteredCategories = state.categories.filter((category: any) =>
        category.name.toLowerCase().includes(payload.toLowerCase())
      );
    },
    delete_category_modal: (state: any, { payload }) => {
      state.hasCategoryId = payload;
    },
    sort_categories: (state, { payload }) => {
      if (payload === "aToZ") {
        state.filteredCategories = state.categories.slice().sort((a: any, b: any) => 
          a.name.toLowerCase().localeCompare(b.name.toLowerCase())
        );
      } else if (payload === "zToA") {
        state.filteredCategories = state.categories.slice().sort((a:any, b: any) => 
          b.name.toLowerCase().localeCompare(a.name.toLowerCase())
        );
      }
    },
     set_category_loding:(state, { payload }) => {
      state.isCategoryLoading = payload;      
    },
  },
});

export const {
  set_categories,
  search_categories,
  delete_category_modal,
  sort_categories,
  set_category_loding,
} = categorySlice.actions;

export default categorySlice.reducer;
