import React, { useEffect, useState } from "react";
import mac from "../../assests/images/landing-page/macbook.png";
import iphone from "../../assests/images/landing-page/iphone.png";
import ipad from "../../assests/images/landing-page/tab.png";
import macdonald from "../../assests/images/landing-page/McDonalds.png";
import burgerKing from "../../assests/images/landing-page/Burger.png";
import starbucks from "../../assests/images/landing-page/starbucks.png";
import eatBits from "../../assests/images/landing-page/eatbitcrowd.png";
import restaurant from "../../assests/images/landing-page/Restaurant.png";
import orders from "../../assests/images/landing-page/Orders.png";
import dashboard_general from "../../assests/images/landing-page/dashboard_general.png";
import restaurant_settings from "../../assests/images/landing-page/Restaurant-settings.png";

import forOwner1 from "../../assests/images/landing-page/block_4.png";
import forOwner2 from "../../assests/images/landing-page/block_5.png";
import forOwner3 from "../../assests/images/landing-page/block_6.png";

import forOwnerLogo1 from "../../assests/images/landing-page/block_7.png";
import forOwnerLogo2 from "../../assests/images/landing-page/block_8.png";
import forOwnerLogo3 from "../../assests/images/landing-page/block_9.png";

import forEmployee1 from "../../assests/images/landing-page/block_10.png";
import forEmployee2 from "../../assests/images/landing-page/block_11.png";
import forEmployee3 from "../../assests/images/landing-page/block_12.png";

import forEmployeeLogo1 from "../../assests/images/landing-page/block_13.png";
import forEmployeeLogo2 from "../../assests/images/landing-page/block_14.png";
import forEmployeeLogo3 from "../../assests/images/landing-page/block_15.png";

import forCustomer1 from "../../assests/images/landing-page/block_16.png";
import forCustomer2 from "../../assests/images/landing-page/block_17.png";
import forCustomer3 from "../../assests/images/landing-page/block_18.png";

import forCustomerLogo1 from "../../assests/images/landing-page/block_19.png";
import forCustomerLogo2 from "../../assests/images/landing-page/block_20.png";
import forCustomerLogo3 from "../../assests/images/landing-page/block_21.png";

import dashboard from "../../assests/images/landing-page/Dashboard opt 11.png";
import dashboardMobile from "../../assests/images/landing-page/Dashboard opt 12.png";

import instaLogo from "../../assests/images/landing-page/insta.png";
import tiktokLogo from "../../assests/images/landing-page/media_1.png";
import facebookLogo from "../../assests/images/landing-page/media_2.png";

import togglenav from "../../assests/images/landing-page/block_3.png";
import loginuser from "../../assests/images/landing-page/Button container.png";

import crossicon from "../../assests/images/landing-page/block_22.png";
import tickicon from "../../assests/images/landing-page/tick.png";

import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";

import { Link } from "react-router-dom";

import "./style.css";

import "swiper/css";
import "swiper/css/pagination";
import { GRAPHQL_ENDPOINT } from "src/constants/endpoints";
import { PLANS } from "src/constants/queries";
import { useRef } from "react";
import { Swiper as SwiperInstance } from "swiper";
import Logo from "../../components/common/Logo";
import { useAxiosInterceptor } from "src/hooks/useAxiosInterceptor";

const LandingPage = () => {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [plans, setPlans] = useState([]);
  const [selectedPackage, setSelectedPackage] = useState("monthly");
  const [currentSlide, setCurrentSlide] = useState<number>(0);
  const { axBe } = useAxiosInterceptor();

  const swiperRef = useRef<SwiperInstance | null>(null);
  const swiperOwnerRef = useRef<SwiperInstance | null>(null);
  const swiperEmployeeRef = useRef<SwiperInstance | null>(null);
  const swiperCustomerRef = useRef<SwiperInstance | null>(null);

  const navigatetoSection = (name: string) => {
    window.location.replace(name);
    setMenuOpen(false);
  };

  const handlePaginationClick = (index: number) => {
    if (swiperRef.current) {
      swiperRef.current.slideTo(index);
    }
    setCurrentSlide(index);
  };

  const handleOwnerPaginationClick = (index: number) => {
    if (swiperOwnerRef.current) {
      swiperOwnerRef.current.slideTo(index);
    }
  };

  const handleEmployeePaginationClick = (index: number) => {
    if (swiperEmployeeRef.current) {
      swiperEmployeeRef.current.slideTo(index);
    }
  };

  const handleCustomerPaginationClick = (index: number) => {
    if (swiperCustomerRef.current) {
      swiperCustomerRef.current.slideTo(index);
    }
  };

  const swiperOwnerSlides = [
    {
      imgSrc: forOwner1,
      logoSrc: forOwnerLogo1,
      title: "Comprehensive Dashboard",
      description: [
        "Manage orders, reservations, and customer interactions",
        "from a single interface.",
      ],
    },
    {
      imgSrc: forOwner2,
      logoSrc: forOwnerLogo2,
      title: "Analytics and Reporting",
      description: [
        "Gain insights into your restaurant’s performance with",
        "detailed analytics and reports.",
      ],
    },
    {
      imgSrc: forOwner3,
      logoSrc: forOwnerLogo3,
      title: "Restaurant Management",
      description: [
        "Create and update menus in real-time. Manage staff",
        "schedules, roles, performance, and payroll efficiently",
      ],
    },
  ];

  const swiperEmployeeData = [
    {
      imgSrc: forEmployee1,
      logoSrc: forEmployeeLogo1,
      title: "Order Management",
      description: [
        "Manage orders, reservations, and customer interactions",
        "from a single interface",
      ],
    },
    {
      imgSrc: forEmployee2,
      logoSrc: forEmployeeLogo2,
      title: "Table Sitting Facilitation",
      description: [
        "Optimize table seating arrangements and manage",
        "reservations to maximize efficiency and satisfaction.",
      ],
    },
    {
      imgSrc: forEmployee3,
      logoSrc: forEmployeeLogo3,
      title: "Bill Management",
      description: [
        "Easily manage and process customer bills, ensuring",
        "accurate and timely transactions",
      ],
    },
  ];

  const swiperCustomerData = [
    {
      imgSrc: forCustomer1,
      logoSrc: forCustomerLogo1,
      title: "Restaurant Discovery",
      description: [
        "Explore a vast database of restaurants based on",
        "cuisine, location, and reviews.",
      ],
    },
    {
      imgSrc: forCustomer2,
      logoSrc: forCustomerLogo2,
      title: "Seamless Journey",
      description: [
        "Browse and review restaurant menus, order takeaway, or",
        "get food delivered directly to your location.",
      ],
    },
    {
      imgSrc: forCustomer3,
      logoSrc: forCustomerLogo3,
      title: "Order History Tracking",
      description: [
        "Track your past orders to revisit favorite dishes and",
        "make informed dining decisions.",
      ],
    },
  ];

  useEffect(() => {
    const fetchCharacters = async () => {
      if (!GRAPHQL_ENDPOINT) {
        console.error("GraphQL endpoint is not defined.");
        return;
      }
      try {
        const { data: result } = await axBe.post(GRAPHQL_ENDPOINT, {
          query: PLANS,
        });
        if (result?.data && result?.data.plans) {
          setPlans(result?.data.plans);
        } else {
          console.error("Unexpected result structure:", result);
        }
      } catch (error) {}
    };

    fetchCharacters();
  }, [axBe]);

  return (
    <>
      <div>
        {/* Navbar */}
        <div className="sticky z-50 top-[0px] bg-white max-md:bg-white max-md:z-10">
          <div className="flex justify-between items-center max-md:pl-3 max-md:pr-3 pl-10 pr-10 pt-[24px] pb-[24px] border-b-[0.5px] border-borderGray">
            <div className="flex items-center">
              <Logo />
              <span className="pl-3 text-2xl">EatsBits</span>
            </div>

            {/* Desktop Menu */}
            <div className="hidden lg:flex space-x-6 max-lg:text-[16px]">
              <span
                onClick={() => window.location.replace("/#whatIsEatsBits")}
                className="cursor-pointer"
              >
                {" "}
                What is EatsBits?{" "}
              </span>
              <span
                onClick={() => window.location.replace("/#forOwner")}
                className="cursor-pointer"
              >
                For Owners
              </span>
              <span
                onClick={() => window.location.replace("/#forEmployee")}
                className="cursor-pointer"
              >
                For Employees
              </span>
              <span
                onClick={() => window.location.replace("/#forCustomers")}
                className="cursor-pointer"
              >
                For Customers
              </span>
              <span
                onClick={() => window.location.replace("/#pricing")}
                className="cursor-pointer"
              >
                Pricing
              </span>
            </div>

            {/* Mobile Menu Toggle Button */}
            <div className="flex lg:hidden">
              <button
                className="lg:hidden flex items-center mr-1 "
                onClick={() => setMenuOpen(!isMenuOpen)}
              >
                <div className="lg:hidden">
                  <img className="h-10" src={togglenav} alt="" />
                </div>
              </button>

              <div className="lg:hidden">
                <img className="h-10 lg:hidden " src={loginuser} alt="" />
              </div>
            </div>

            {/* Desktop Login/Signup Buttons */}
            <div className="hidden lg:flex space-x-4 items-center">
              <Link to="/login">
                <button className="text-orange font-medium  hover:text-black duration-500">Login</button>
              </Link>
              <Link to="/Sign-up">
                <button className="bg-orange text-white w-[135px] h-[40px] rounded-[12px] hover:bg-white hover:text-orange duration-500">
                  Sign Up
                </button>
              </Link>
            </div>
          </div>

          {/* Mobile Menu */}
          <div
            className={`fixed inset-0 bg-white z-50 transition-transform transform ${
              isMenuOpen ? "translate-x-0" : "translate-x-full"
            } lg:hidden`}
          >
            <div className="flex flex-col ml-4 pt-6 space-y-6">
              <div className="flex justify-between">
                <div className="flex items-center">
                  <Logo />
                  <span className="pl-3 text-2xl">Eats Bits</span>
                </div>
                <button
                  className="self-end px-6 py-4 text-orange "
                  onClick={() => {
                    setMenuOpen(false);
                  }}
                >
                  <img src={crossicon} alt="" />
                </button>
              </div>

              <h1 className="text-3xl">Menu</h1>
              <span
                onClick={() => navigatetoSection("/#whatIsEatsBits")}
                className="cursor-pointer"
              >
                {" "}
                What is EatsBits?{" "}
              </span>
              <span
                onClick={() => navigatetoSection("/#forOwner")}
                className="cursor-pointer"
              >
                For Owners
              </span>
              <span
                onClick={() => navigatetoSection("/#forEmployee")}
                className="cursor-pointer"
              >
                For Employees
              </span>
              <span
                onClick={() => navigatetoSection("/#forCustomers")}
                className="cursor-pointer"
              >
                For Customers
              </span>

              <span
                onClick={() => navigatetoSection("/#pricing")}
                className="cursor-pointer"
              >
                Pricing
              </span>

              <div className="flex max-md:my-3 max-md:w-full">
                <img
                  className="p-2 max-md:pl-0 cursor-pointer"
                  src={instaLogo}
                  alt=""
                />
                <img className="p-2 cursor-pointer" src={tiktokLogo} alt="" />
                <img className="p-2 cursor-pointer" src={facebookLogo} alt="" />
              </div>

              <div className="flex flex-col items-center absolute bottom-0 w-full p-4 left-[0%] space-y-4">
                <button className="bg-orange text-white text-sm w-full h-[40px] rounded-[12px] cursor-pointer">
                  <Link to="/Sign-up">Sign Up</Link>
                </button>

                <Link to="/login">
                  <button className="text-orange font-medium cursor-pointer">
                    Login
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>

        {/* first section */}
        <div className="pt-[60px] max-md:pt-[25px] ">
          <div className="flex justify-center max-md:mb-[28px]">
            <div className="min-h-[319px] text-center w-[80%]">
              <p className="text-6xl leading-tight max-md:text-3xl">
                Elevate Your Dining & Restaurant Business Experience
              </p>
              <p className="text-[#475467] pt-[30px] max-md:text-sm">
                Effortlessly manage your restaurant operations, explore top
                dining spots, and seamlessly order
              </p>
              <p className="text-[#475467] pb-[30px] max-md:text-sm">
                your favorite dishes—all from one convenient platform.
              </p>
              <button className="bg-orange text-white max-md:h-[46px] w-[281px] h-[56px] rounded-[12px] ml-[12px]">
                Find Your Role
              </button>
            </div>
          </div>

          <div className="relative flex flex-col items-center h-[550px] max-md:max-h-[200px] overflow-hidden xl:h-[750px] ">
            <div className="absolute bottom-[0%] top-5 w-[75%]">
              <img
                className="absolute bottom-0"
                src={mac}
                alt=""
                width="100%"
                height="auto"
              />
              <img
                className="absolute left-[-26%]  bottom-0"
                src={iphone}
                alt=""
                width="80%"
                height="auto"
              />
              <img
                className="absolute left-[60%] bottom-0"
                src={ipad}
                alt=""
                width="50%"
                height="auto"
              />
            </div>
          </div>

          <div className="flex  max-md:flex-wrap  max-md:justify-center items-center justify-between h-[104px]  max-md:h-[110px] bg-black px-[56px]">
            <div className=" max-md:text-center max-md:mt-2">
              <p className="text-white max-md:hidden">
                Thrusted by innovators & <br /> industry leaders
              </p>
              <p className="text-white md:hidden w-max">
                Thrusted by innovators & industry leaders
              </p>
            </div>
            <div className="flex  max-md:mb-6  max-md:gap-[10px] max-md:justify-center max-md:w-full justify-evenly w-[41%] border-white">
              <img className="w-[51px] h-[56]" src={macdonald} alt="" />
              <img className="w-[51px] h-[56]" src={burgerKing} alt="" />
              <img className="w-[51px] h-[56]" src={starbucks} alt="" />
              <img className="w-[51px] h-[56]" src={macdonald} alt="" />
              <img className="w-[51px] h-[56]" src={burgerKing} alt="" />
              <img className="w-[51px] h-[56]" src={starbucks} alt="" />
            </div>
          </div>
        </div>

        <div className="flex flex-col items-center bg-offWhite">
          {/* What is EatBits Section */}

          <div className="w-[90%] py-[80px] max-md:pt-10 ">
            <div id="whatIsEatsBits">
              <div className="text-center pb-[20px]">
                <p className="text-black text-5xl max-md:text-[32px]">
                  What Is EatsBits?
                </p>
              </div>
              <div className="h-[369px] overflow-hidden flex max-md:flex-col max-md:h-full">
                <div className="flex-1 flex mr-[15px] flex-col justify-between">
                  <p className="bg-orange text-white rounded-[32px] p-[48px] max-md:p-[12px] max-md:text-[14px] max-md:rounded-[16px]">
                    A dining platform for enhancing customer and restaurant
                    experiences.
                  </p>
                  <p className="bg-white text-textColor rounded-[32px] p-[32px] max-md:p-[12px] max-md:text-[8px] max-md:my-4 ">
                    For customers, it offers restaurant discovery, reviews, and
                    ordering options. Restaurant owners manage menus, staff, and
                    operations,and service updates in real-time. Our goal is
                    elevate the dining experience for all stakeholders.
                  </p>
                </div>
                <div className="flex-1 max-md:flex justify-center ml-[15px] max-md:ml-[0]">
                  <img
                    className="w-[100%] h-[100%] max-md:h-[212px] max-md:w-[330px] object-cover rounded-[32px] border-[#F1F1F2]"
                    src={eatBits}
                    alt=""
                  />
                </div>
              </div>
            </div>

            {/* One App, many opportunities section*/}

            <div className="h-[500px] max-md:h-full mt-[70px] max-md:mt-10">
              <div className="flex flex-col items-center h-[140px] max-md:h-[100%] max-md:text-center">
                <p className="text-5xl mb-[20px] max-md:text-3xl">
                  One App, many opportunities
                </p>
                <p className="text-textColor max-md:text-sm">
                  Discover your ideal role with EatBits! Whether you're an
                  eater,owner, or
                </p>
                <p className="text-center text-textColor max-md:text-sm">
                  employee, our app is tailored to elevate your experience.
                </p>
              </div>

              <div className="flex  h-[360px] gap-[32px] max-md:gap-[15px] max-md:flex-wrap max-md:h-[430px] max-md:mt-5">
                <div className="bg-orange max-md:items-center px-[48px] flex flex-col justify-evenly rounded-[48px] w-[45%] relative overflow-hidden max-md:w-[100%] max-md:pl-[20px] max-md:rounded-[32px] max-md:py-4">
                  <p className="text-black text-6xl max-lg:hidden">
                    I'm An <br />
                    <span className="text-white">Customer</span>
                  </p>
                  <p className="text-black text-6xl max-lg:text-3xl lg:hidden">
                    I'm An
                    <span className="text-white"> Customer</span>
                  </p>
                  <p className="text-white max-w-[370px] max-md:text-sm max-md:mt-4">
                    Lorem ipsum dolor sit amet consectetur. Tellus quisque
                    tortor dolor egestas integer diam nulla.
                  </p>
                  <button className="bg-white text-orange w-[200px] h-[56px] rounded-[12px] ml-[12px] max-md:h-[46px] max-md:mt-6">
                    Sign Up As Customer
                  </button>
                  <img
                    className="absolute right-0 top-[53px] w-[155px] max-xl:hidden"
                    src={restaurant}
                    alt=""
                  />
                </div>

                <div className="bg-black max-md:items-center px-[48px] flex flex-col justify-evenly rounded-[48px] w-[55%] relative overflow-hidden max-md:w-[100%] max-md:pl-[20px] max-md:rounded-[32px] max-md:py-4">
                  <p className="text-white text-6xl max-lg:hidden">
                    I'm An <br />
                    <span className="text-white max-md:text-3xl">Employee</span>
                  </p>

                  <p className="text-white text-6xl max-lg:text-3xl lg:hidden">
                    I'm An
                    <span className="text-white max-md:text-3xl ">
                      {" "}
                      Employee
                    </span>
                  </p>

                  <p className="text-white max-w-[370px] max-md:text-sm max-md:mt-4">
                    Lorem ipsum dolor sit amet consectetur. Tellus quisque
                    tortor dolor egestas integer diam nulla vestibulum mauris.
                    Tincidunt sollicitudin.
                  </p>
                  <button className="text-white bg-orange w-[200px] h-[56px] rounded-[12px] ml-[12px] max-md:h-[46px] max-md:mt-6">
                    Sign Up As Employee
                  </button>
                  <img
                    className="absolute right-0 top-[53px] w-[271px] max-xl:hidden"
                    src={orders}
                    alt=""
                  />
                </div>
              </div>
            </div>

            <div className="h-[463px] relative bg-white max-md:justify-center  pl-[48px] flex rounded-[48px] mt-[30px] max-md:mt-12 overflow-hidden max-md:h-[100%] max-md:w-[100%] max-md:pl-[20px] max-md:rounded-[32px] max-md:py-2 max-md:pb-4 ">
              <div className="flex flex-col flex-[0.4] ">
                <p className="text-black text-6xl mt-[40px] max-md:mt-4 max-md:hidden">
                  I'm An{" "}
                </p>
                <p className="text-orange text-6xl mt-[10px] max-md:text-3xl max-md:hidden">
                  Owner
                </p>
                <p className="text-black text-6xl mt-[40px] max-md:mt-4 max-md:text-3xl md:hidden ">
                  {" "}
                  I'm An <span className="text-orange">Owner</span>
                </p>
                <p className="text-textColor max-w-[370px] my-[40px] max-md:my-3 max-md:text-sm max-lg:mr-4">
                  Lorem ipsum dolor sit amet consectetur. Tellus quisque tortor
                  dolor egestas integer diam nulla vestibulum mauris. Tincidunt
                  sollicitudin.
                </p>
                <button className="bg-orange text-white w-[281px] h-[56px] rounded-[12px] ml-[12px] max-md:h-[46px] max-md:mt-6 max-lg:mr-9">
                  Sign Up As Owner
                </button>
              </div>
              <div className="flex-[0.6] max-md:hidden">
                <div className="">
                  <img
                    className="absolute max-xl:w-[85%]"
                    src={restaurant_settings}
                    alt=""
                    width="60%"
                    height="auto"
                  />
                  <img
                    className=" left-[35%] absolute bottom-0 max-xl:hidden"
                    src={dashboard_general}
                    alt=""
                    width="25%"
                    height="auto"
                  />
                </div>
              </div>
            </div>

            {/* For Owners section */}

            <div id="forOwner">
              <div className="flex flex-col items-center h-[140px] mt-20 max-md:mt-10 max-md:h-[170px] ">
                <p className="text-5xl mb-[20px] max-md:text-3xl ">
                  For Owners
                </p>
                <p className="text-textColor max-md:hidden">
                  EatBits provides restaurant owners with powerful tools to
                  manage daily operations, track performance, and grow their
                </p>
                <p className="text-textColor text-center max-md:hidden">
                  business. From reservations to analytics, our platform is
                  designed to make your life easier.
                </p>

                <p className="text-textColor max-md:text-sm md:hidden w-[80%] ">
                  EatBits provides restaurant owners with powerful tools to
                  manage daily operations, track performance, and grow their
                  business. From reservations to analytics, our platform is
                  designed to make your life easier.
                </p>
              </div>
              <div className="flex justify-between overflow-hidden max-md:flex-wrap max-md:mt-8 max-md:hidden">
                <div className="flex flex-col">
                  <div>
                    <img src={forOwner1} alt="" />
                  </div>
                  <div className="flex justify-center mt-4">
                    <img src={forOwnerLogo1} alt="" />
                  </div>
                  <div className="text-center mt-2">
                    <h1 className="text-lg">Comprehensive Dashboard</h1>
                    <p className="font-thin text-textColor text-sm max-md:hidden">
                      Manage orders, reservations, and customer interactions
                    </p>
                    <p className="font-thin text-textColor text-sm max-md:hidden">
                      from a single interface.
                    </p>
                    <p className="text-textColor text-center max-md:text-sm md:hidden">
                      Manage orders, reservations, and customer interactions
                      from a single interface.
                    </p>
                  </div>
                </div>

                <div className="flex mx-5 flex-col max-md:mt-4">
                  <div>
                    <img src={forOwner2} alt="" />
                  </div>
                  <div className="flex justify-center mt-4">
                    <img src={forOwnerLogo2} alt="" />
                  </div>
                  <div className="text-center mt-2">
                    <h1 className="text-lg">Analytics and Reporting</h1>
                    <p className="font-thin text-textColor text-sm">
                      Gain insights into your restaurant’s performance with
                    </p>
                    <p className="font-thin text-textColor text-sm">
                      fdetailed analytics and reports.
                    </p>
                  </div>
                </div>

                <div className="flex flex-col max-md:mt-4">
                  <div>
                    <img src={forOwner3} alt="" />
                  </div>
                  <div className="flex justify-center mt-4">
                    <img src={forOwnerLogo3} alt="" />
                  </div>
                  <div className="text-center mt-2">
                    <h1 className="text-lg">Restaurant Management</h1>
                    <p className="font-thin text-textColor text-sm">
                      Create and update menus in real-time. Manage staff{" "}
                    </p>
                    <p className="font-thin text-textColor text-sm">
                      schedules, roles, performance, and payroll efficiently{" "}
                    </p>
                  </div>
                </div>
              </div>
              <Swiper
                onSwiper={(swiper: SwiperInstance) => {
                  swiperOwnerRef.current = swiper;
                }}
                pagination={{ dynamicBullets: true }}
                modules={[Pagination]}
                onSlideChange={(swiper: SwiperInstance) =>
                  setCurrentSlide(swiper.activeIndex)
                }
                className="mySwiper md:hidden"
              >
                {swiperOwnerSlides.map((slide, index) => (
                  <SwiperSlide key={index}>
                    <div className="flex flex-col">
                      <div>
                        <img src={slide.imgSrc} alt={slide.title} />
                      </div>
                      <div className="flex justify-center mt-4">
                        <img src={slide.logoSrc} alt={`${slide.title} Logo`} />
                      </div>
                      <div className="text-center mt-2">
                        <h1 className="text-lg">{slide.title}</h1>
                        {slide.description.map((line, i) => (
                          <p
                            key={i}
                            className="font-thin text-textColor text-sm"
                          >
                            {line}
                          </p>
                        ))}
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
              <div className="flex justify-center mb-4 w-full md:hidden">
                {swiperOwnerSlides.map((_, index) => (
                  <button
                    key={index}
                    className={`w-3 h-3 rounded-full mx-1 ${
                      currentSlide === index ? "bg-orange" : "bg-[#EBEBEB]"
                    }`}
                    onClick={() => handleOwnerPaginationClick(index)}
                  />
                ))}
              </div>
            </div>
            <div className="flex justify-center mt-4">
              <button className="w-[281px] max-md:w-[100%] h-[56px] rounded-xl bg-orange text-white">
                Try Now
              </button>
            </div>

            {/* For Employee section */}

            <div id="forEmployee">
              <div className="flex flex-col items-center h-[140px] mt-20  max-md:h-[170px] ">
                <p className="text-5xl mb-[20px] max-md:text-3xl max-md:text-center">
                  For Employee
                </p>
                <p className="text-textColor max-md:hidden">
                  EatBits helps restaurant employees manage their tasks more
                  effectively, ensuring a smooth and efficient operation. From
                </p>
                <p className="text-textColor text-center max-md:hidden">
                  order management to shift scheduling, we provide the tools you
                  need to excel in your role
                </p>
                <p className="text-textColor text-center max-md:text-sm md:hidden w-[85%]">
                  EatBits helps restaurant employees manage their tasks more
                  effectively, ensuring a smooth and efficient operation. From
                  order management to shift scheduling, we provide the tools you
                  need to excel in your role
                </p>
              </div>

              <div className="flex justify-between overflow-hidden max-md:flex-wrap max-md:mt-6">
                <div className="flex flex-col max-md:hidden">
                  <div>
                    <img src={forEmployee1} alt="" />
                  </div>
                  <div className="flex justify-center mt-4">
                    <img src={forEmployeeLogo1} alt="" />
                  </div>
                  <div className="text-center mt-2">
                    <h1 className="text-lg">Order Management</h1>
                    <p className="font-thin text-textColor text-sm max-md:hidden">
                      Manage orders, reservations, and customer interactions
                    </p>
                    <p className="font-thin text-textColor text-sm max-md:hidden">
                      from a single interface
                    </p>
                    <p className="font-thin text-textColor text-sm md:hidden">
                      {" "}
                      Manage orders, reservations, and customer interactions
                      from a single interface
                    </p>
                  </div>
                </div>

                <div className="flex mx-5 flex-col max-md:hidden">
                  <div>
                    <img src={forEmployee2} alt="" />
                  </div>
                  <div className="flex justify-center mt-4">
                    <img src={forEmployeeLogo2} alt="" />
                  </div>
                  <div className="text-center mt-2">
                    <h1 className="text-lg">Table Sitting Facilitation</h1>
                    <p className="font-thin text-textColor text-sm">
                      Optimize table seating arrangements and manage
                    </p>
                    <p className="font-thin text-textColor text-sm">
                      reservations to maximize efficiency and satisfaction.
                    </p>
                  </div>
                </div>

                <div className="flex flex-col max-md:hidden">
                  <div>
                    <img src={forEmployee3} alt="" />
                  </div>
                  <div className="flex justify-center mt-4">
                    <img src={forEmployeeLogo3} alt="" />
                  </div>
                  <div className="text-center mt-2">
                    <h1 className="text-lg">Bill Management</h1>
                    <p className="font-thin text-textColor text-sm">
                      Easily manage and process customer bills, ensuring
                    </p>
                    <p className="font-thin text-textColor text-sm">
                      accurate and timely transactions
                    </p>
                  </div>
                </div>

                <Swiper
                  onSwiper={(swiper: SwiperInstance) => {
                    swiperEmployeeRef.current = swiper;
                  }}
                  pagination={{
                    dynamicBullets: true,
                  }}
                  modules={[Pagination]}
                  onSlideChange={(swiper: SwiperInstance) =>
                    setCurrentSlide(swiper.activeIndex)
                  }
                  className="mySwiper md:hidden h-[720px]"
                >
                  {swiperEmployeeData.map((slide, index) => (
                    <SwiperSlide key={index}>
                      <div className="flex flex-col">
                        <div>
                          <img src={slide.imgSrc} alt="" />
                        </div>
                        <div className="flex justify-center mt-4">
                          <img src={slide.logoSrc} alt="" />
                        </div>
                        <div className="text-center mt-2">
                          <h1 className="text-lg">{slide.title}</h1>
                          {slide.description.map((text, i) => (
                            <p
                              key={i}
                              className="font-thin text-textColor text-sm"
                            >
                              {text}
                            </p>
                          ))}
                        </div>
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper>
                <div className="flex justify-center mb-4 w-full md:hidden">
                  {swiperEmployeeData.map((_, index) => (
                    <button
                      key={index}
                      className={`w-3 h-3 rounded-full mx-1 ${
                        currentSlide === index ? "bg-orange" : "bg-[#EBEBEB]"
                      }`}
                      onClick={() => handleEmployeePaginationClick(index)}
                    />
                  ))}
                </div>
              </div>

              <div className="flex justify-center mt-8">
                <button className="w-[281px] max-md:w-[100%] h-[56px] rounded-xl bg-orange text-white">
                  Try Now
                </button>
              </div>
            </div>

            {/* For customer section */}

            <div id="forCustomers">
              <div className="flex flex-col items-center h-[140px] mt-20">
                <p className="text-5xl mb-[20px] max-md:text-3xl">
                  For Customers
                </p>
                <p className="text-textColor max-md:hidden">
                  EatBits empowers customers to explore a wide range of
                  restaurants, make reservations, and enjoy personalized dining
                </p>
                <p className="text-textColor text-center max-md:hidden">
                  experiences. With our intuitive search and recommendation
                  features, finding the perfect meal has never been easier.
                </p>

                <p className="text-textColor w-[85%] text-center md:hidden">
                  EatBits empowers customers to explore a wide range of
                  restaurants, make reservations, and enjoy personalized dining
                  experiences. With our intuitive search and recommendation
                  features, finding the perfect meal has never been easier.
                </p>
              </div>
              <div className="flex justify-between overflow-hidden max-md:flex-wrap max-md:mt-16">
                <div className="flex flex-col max-md:mt-6 max-md:hidden">
                  <div>
                    <img src={forCustomer1} alt="" />
                  </div>
                  <div className="flex justify-center mt-4">
                    <img src={forCustomerLogo1} alt="" />
                  </div>
                  <div className="text-center mt-2">
                    <h1 className="text-lg">Restaurant Discovery</h1>
                    <p className="font-thin text-textColor text-sm max-md:hidden">
                      Explore a vast database of restaurants based on cuisine,
                    </p>
                    <p className="font-thin text-textColor text-sm max-md:hidden">
                      location, and reviews.
                    </p>
                    <p className="font-thin text-textColor text-sm md:hidden text-center">
                      Explore a vast database of restaurants based on
                      cuisine,location, and reviews.
                    </p>
                  </div>
                </div>

                <div className="flex flex-col mx-5 max-md:hidden">
                  <div>
                    <img src={forCustomer2} alt="" />
                  </div>
                  <div className="flex justify-center mt-4">
                    <img src={forCustomerLogo2} alt="" />
                  </div>
                  <div className="text-center mt-2">
                    <h1 className="text-lg">Seamless Journey</h1>
                    <p className="font-thin text-textColor text-sm max-md:hidden">
                      Browse and review restaurant menus, order takeaway, or
                    </p>
                    <p className="font-thin text-textColor text-sm max-md:hidden">
                      get food delivered directly to your location.
                    </p>
                    <p className="font-thin text-textColor text-sm md:hidden text-center">
                      Browse and review restaurant menus, order takeaway, or get
                      food delivered directly to your location.
                    </p>
                  </div>
                </div>

                <div className="flex flex-col max-md:hidden">
                  <div>
                    <img src={forCustomer3} alt="" />
                  </div>
                  <div className="flex justify-center mt-4">
                    <img src={forCustomerLogo3} alt="" />
                  </div>
                  <div className="text-center mt-2">
                    <h1 className="text-lg">Order History Tracking</h1>
                    <p className="font-thin text-textColor text-sm">
                      Track your past orders to revisit favorite dishes and
                    </p>
                    <p className="font-thin text-textColor text-sm">
                      make informed dining decisions.
                    </p>
                  </div>
                </div>

                <Swiper
                  onSwiper={(swiper: SwiperInstance) => {
                    swiperCustomerRef.current = swiper;
                  }}
                  pagination={{
                    dynamicBullets: true,
                  }}
                  modules={[Pagination]}
                  onSlideChange={(swiper: SwiperInstance) =>
                    setCurrentSlide(swiper.activeIndex)
                  }
                  className="mySwiper md:hidden h-[720px]"
                >
                  {swiperCustomerData.map((slide, index) => (
                    <SwiperSlide key={index}>
                      <div className="flex flex-col max-md:mt-6">
                        <div>
                          <img src={slide.imgSrc} alt="" />
                        </div>
                        <div className="flex justify-center mt-4">
                          <img src={slide.logoSrc} alt="" />
                        </div>
                        <div className="text-center mt-2">
                          <h1 className="text-lg">{slide.title}</h1>
                          {slide.description.map((text, i) => (
                            <p
                              key={i}
                              className={`font-thin text-textColor text-sm ${
                                i === 0
                                  ? "max-md:hidden"
                                  : "md:hidden text-center"
                              }`}
                            >
                              {text}
                            </p>
                          ))}
                        </div>
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper>
                <div className="flex justify-center mb-4 w-full md:hidden">
                  {swiperCustomerData.map((_, index) => (
                    <button
                      key={index}
                      className={`w-3 h-3 rounded-full mx-1 ${
                        currentSlide === index ? "bg-orange" : "bg-[#EBEBEB]"
                      }`}
                      onClick={() => handleCustomerPaginationClick(index)}
                    />
                  ))}
                </div>
              </div>
              <div className="flex justify-center mt-8">
                <button className="w-[281px] max-md:w-[100%] h-[56px] rounded-xl bg-orange text-white">
                  Try Now
                </button>
              </div>
            </div>
          </div>

          {/* price section */}

          <div
            id="pricing"
            className="flex max-md:justify-center  max-md:w-[90%]"
          >
            <div className="flex max-md:w-full justify-center mt-8 mx-1 ">
              <button
                className={`w-[281px] max-md:w-[100%] h-[56px] rounded-xl  ${
                  selectedPackage === "monthly"
                    ? "bg-orange  text-white "
                    : "bg-white text-black"
                }`}
                onClick={() => setSelectedPackage("monthly")}
              >
                Monthly
              </button>
            </div>

            <div className="flex max-md:w-full  justify-center mt-8 mx-1 ">
              <button
                className={`w-[281px] max-md:w-[100%] h-[56px] rounded-xl bg-orange ${
                  selectedPackage === "yearly"
                    ? "bg-orange  text-white "
                    : "bg-white text-black"
                }`}
                onClick={() => setSelectedPackage("yearly")}
              >
                Annually
              </button>
            </div>
          </div>
          <div className="flex max-lg:justify-center max-lg:gap-[15px] gap-[10px] justify-between my-8 max-md:hidden max-lg:flex-wrap ">
            {plans.length > 0 ? (
              plans.map((plan: any, index) => (
                <div
                  className={`flex max-lg:w-[90%] justify-center h-[700px] w-[100%] rounded-2xl border border-[#F1F1F2]`}
                  key={index}
                >
                  <div className="w-[90%]">
                    <div key={index} className="mt-6">
                      <h1 className="text-lg font-medium p-2">{plan.name}</h1>
                      <p className="text-sm text-textColor p-2">
                        {plan.productDescription}
                      </p>

                      <h1 className="text-lg font-medium p-2">
                        $
                        {selectedPackage === "yearly"
                          ? plan.monthlyPriceAnnuallyBilled
                          : plan.monthlyPriceMonthlyBilled}
                        /mth
                      </h1>
                      {selectedPackage === "yearly" ? (
                        <h1 className="text-lg font-medium p-2">
                          <del> ${plan.monthlyPriceMonthlyBilled}/mth </del>
                        </h1>
                      ) : (
                        <></>
                      )}
                      <div className="flex  my-4">
                        <button className="w-[302px] text-white h-[48px] rounded-xl bg-[#FD7657]">
                          Get Started
                        </button>
                      </div>
                      <p className="text-sm text-textColor p-2">
                        {selectedPackage === "yearly" ? (
                          <>
                            <span className="text-black font-bold">
                              ${plan.monthlyPriceAnnuallyBilled * 12}
                            </span>{" "}
                            billed annually
                          </>
                        ) : (
                          ""
                        )}
                      </p>
                      <h1 className="text-lg font-medium p-2">
                        {index === 2
                          ? "Highlights of plan features:"
                          : "Cloud-based Point of Sale"}
                      </h1>
                      <div className="text-textColor text-sm mt-4 p-1">
                        {plan.features.map(
                          (feature: any, featureIndex: number) => (
                            <div
                              key={featureIndex}
                              className="flex items-center"
                            >
                              <img src={tickicon} alt="" />
                              <span className="p-1 ml-2">{feature}</span>
                            </div>
                          )
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className="flex w-full justify-center">
                <p className="">No plans available. :( </p>
              </div>
            )}
          </div>
          <Swiper
            onSwiper={(swiper: SwiperInstance) => {
              swiperRef.current = swiper;
            }}
            pagination={{ dynamicBullets: true }}
            modules={[Pagination]}
            onSlideChange={(swiper: SwiperInstance) =>
              setCurrentSlide(swiper.activeIndex)
            }
            className=" md:hidden h-[725px] mt-4"
          >
            {plans.length > 0 ? (
              plans.map((plan: any, index) => (
                <SwiperSlide key={index}>
                  <div className="flex justify-center h-[700px] md:w-[100%] m-4 rounded-2xl border border-[#F1F1F2]">
                    <div className="w-[90%]">
                      <div className="mt-6">
                        <h1 className="text-lg font-medium p-2 text-left">
                          {plan.name}
                        </h1>
                        <p className="text-sm text-textColor p-2 text-left">
                          {plan.productDescription}
                        </p>
                        <h1 className="text-lg font-medium p-2 text-left">
                          $
                          {selectedPackage === "yearly"
                            ? plan.monthlyPriceAnnuallyBilled
                            : plan.monthlyPriceMonthlyBilled}
                          /mth
                        </h1>
                        {selectedPackage === "yearly" && (
                          <h1 className="text-lg font-medium p-2 text-left">
                            <del>${plan.monthlyPriceMonthlyBilled}/mth</del>
                          </h1>
                        )}
                        <div className="flex my-4">
                          <button className="w-[302px] text-white h-[48px] rounded-xl bg-[#FD7657]">
                            Get Started
                          </button>
                        </div>
                        <p className="text-sm text-textColor p-2 text-left">
                          {selectedPackage === "yearly" && (
                            <>
                              <span className="text-black font-bold">
                                ${plan.monthlyPriceAnnuallyBilled * 12}
                              </span>{" "}
                              billed annually
                            </>
                          )}
                        </p>
                        <h1 className="text-lg font-medium p-2 text-left">
                          {index === 2
                            ? "Highlights of plan features:"
                            : "Cloud-based Point of Sale"}
                        </h1>
                        <div className="text-textColor text-sm mt-4 p-1">
                          {plan.features.map(
                            (feature: any, featureInde: any) => (
                              <div
                                key={featureInde}
                                className="flex items-center"
                              >
                                <img src={tickicon} alt="Tick icon" />
                                <span className="p-1 ml-2">{feature}</span>
                              </div>
                            )
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              ))
            ) : (
              <div className="flex w-full justify-center">
                <p>No plans available. :(</p>
              </div>
            )}
          </Swiper>

          {/* Custom Pagination Dots */}

          <div className="flex justify-center mb-4 w-full md:hidden">
            {plans.map((_, index) => (
              <button
                key={index}
                className={`w-3 h-3 rounded-full mx-1 ${
                  currentSlide === index ? "bg-orange" : "bg-[#EBEBEB]"
                }`}
                onClick={() => handlePaginationClick(index)}
              />
            ))}
          </div>

          {/* Try EatBits Today section */}

          <div className="bg-[#FD7657] text-white text-center w-full">
            <h1 className="text-6xl mt-16 max-md:text-3xl">
              Try EatBits Today
            </h1>
            <p className="mt-6 text-sm">
              Discover Powerful Tools To Manage Menus, Optimize Operations, And
              Elevate Your Restaurant's{" "}
            </p>
            <p className="text-sm">Success With Our Demo Version.</p>
            <div className="flex justify-center my-12">
              <button className="w-[281px]   h-[56px] rounded-xl bg-white text-[#FD7657]">
                Try Demo
              </button>
            </div>
          </div>

          <div className="bg-[#FD7657] w-full flex justify-center max-md:justify-end max-md:hidden">
            <img src={dashboard} alt="" width="80%" height="auto" />
          </div>

          <div className="bg-[#FD7657] w-full flex justify-center max-md:justify-end md:hidden">
            <img className="" src={dashboardMobile} alt="" />
          </div>

          {/* footer */}

          <div className=" max-lg:flex max-lg:flex-col max-lg:w-[100%] w-full flex justify-between items-center px-14 pt-[0px]  max-lg:px-6 border-b-[0.5px] max-lg:mt-14 mt-20 max-lg:border-t-[1px] max-lg:border-[#F1F1F2]  border-borderGray">
            <div className=" flex items-center  max-lg:w-[100%]">
              <Logo />
              <span className="pl-3">EatsBits</span>
            </div>
            <div className="max-lg:w-[100%] max-lg:flex max-lg:flex-col max-lg:pt-6">
              <span
                onClick={() => navigatetoSection("/#whatIsEatsBits")}
                className="cursor-pointer pr-6 max-lg:pt-3"
              >
                {" "}
                What is EatsBits?{" "}
              </span>
              <span
                onClick={() => navigatetoSection("/#forOwner")}
                className="cursor-pointer pr-6 max-lg:pt-3"
              >
                For Owners
              </span>
              <span
                onClick={() => navigatetoSection("/#forEmployee")}
                className="cursor-pointer pr-6 max-lg:pt-3"
              >
                For Employees
              </span>
              <span
                onClick={() => navigatetoSection("/#forCustomers")}
                className="cursor-pointer pr-6 max-lg:pt-3"
              >
                For Customers
              </span>
            </div>
            <div className="flex max-lg:my-3 max-lg:w-full">
              <img className="p-2 max-lg:pl-0" src={instaLogo} alt="" />
              <img className="p-2" src={tiktokLogo} alt="" />
              <img className="p-2" src={facebookLogo} alt="" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LandingPage;
