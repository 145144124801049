import * as React from "react";
const SVGComponent = ( {color}:any) => (
  <svg
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width="21"  viewBox="0 0 20 11"
  >
    <path
      d="M-0.00488281 10.2249V8.3349H13.2251V10.2249H-0.00488281ZM-0.00488281 6.4449V4.5549H13.2251V6.4449H-0.00488281ZM-0.00488281 2.6649V0.774902H13.2251V2.6649H-0.00488281ZM16.0601 10.2249C15.7924 10.2249 15.5679 10.1343 15.3868 9.95322C15.2057 9.77209 15.1151 9.54765 15.1151 9.2799C15.1151 9.01215 15.2057 8.78772 15.3868 8.60659C15.5679 8.42547 15.7924 8.3349 16.0601 8.3349C16.3279 8.3349 16.5523 8.42547 16.7334 8.60659C16.9146 8.78772 17.0051 9.01215 17.0051 9.2799C17.0051 9.54765 16.9146 9.77209 16.7334 9.95322C16.5523 10.1343 16.3279 10.2249 16.0601 10.2249ZM16.0601 6.4449C15.7924 6.4449 15.5679 6.35434 15.3868 6.17322C15.2057 5.99209 15.1151 5.76765 15.1151 5.4999C15.1151 5.23215 15.2057 5.00771 15.3868 4.82659C15.5679 4.64546 15.7924 4.5549 16.0601 4.5549C16.3279 4.5549 16.5523 4.64546 16.7334 4.82659C16.9146 5.00771 17.0051 5.23215 17.0051 5.4999C17.0051 5.76765 16.9146 5.99209 16.7334 6.17322C16.5523 6.35434 16.3279 6.4449 16.0601 6.4449ZM16.0601 2.6649C15.7924 2.6649 15.5679 2.57434 15.3868 2.39321C15.2057 2.21209 15.1151 1.98765 15.1151 1.7199C15.1151 1.45215 15.2057 1.22771 15.3868 1.04659C15.5679 0.865465 15.7924 0.774902 16.0601 0.774902C16.3279 0.774902 16.5523 0.865465 16.7334 1.04659C16.9146 1.22771 17.0051 1.45215 17.0051 1.7199C17.0051 1.98765 16.9146 2.21209 16.7334 2.39321C16.5523 2.57434 16.3279 2.6649 16.0601 2.6649Z"
      fill={color}
    />
  </svg>
);
export default SVGComponent;
