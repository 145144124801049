import { S3_BASE_URL } from "src/constants/endpoints";
import DeleteIcon from "../../assests/images/dashboard/delete.png";
import RestuarantImg from "../../assests/images/dashboard/default-thumbnail (1).jpg";

const MenuCard = ({
  category,
  handleCategoryClick,
  setEditCategoryModal,
}: any) => {
  return (
    <div className="flex flex-wrap justify-between">
      <div className="relative mx-2 mb-3 border-[#F1F1F2] border bg-white rounded-xl h-[240px] w-[280px]">
        <div
          className="flex justify-center bg-[#dadada] h-[140px] rounded-xl"
          onClick={() => setEditCategoryModal(category)}
        >
          <img
            alt=""
            className=" w-full rounded-t-xl cursor-pointer object-cover bg-no-repeat"
            src={
              category?.bucketKeyName
                ? category?.bucketKeyName.includes("blob")
                  ? category?.bucketKeyName
                  : S3_BASE_URL + category?.bucketKeyName
                : RestuarantImg
            }
          />
        </div>
        <div
          className="absolute right-2 top-2 h-[42px] w-[42px] rounded-xl bg-[#FEF5F4] flex justify-center items-center cursor-pointer"
          onClick={() => handleCategoryClick(category?.correlationId)}
        >
          <img className="w-[12px] h-[13px]" src={DeleteIcon} alt="Delete" />
        </div>

        <div className="pb-4 pr-4 pl-4 pt-[10px]">
          <div className="flex justify-between mt-1">
            <div className="tooltip">
              <h1 className="tooltip-section w-[220px] text-sm font-medium ">
              {category?.name}
              </h1>
              <span className="tooltip-text">{category?.name}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MenuCard;
