const PayBillAmountModal = ({
  paidAmount,
  actualAmount,
  showBillAmountModal,
}: any) => {
  return (
    <div
      className="relative z-[9999]"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div
        className="fixed inset-0 bg-[#000000] bg-opacity-60 transition-opacity"
        aria-hidden="true"
      ></div>

      <div className="fixed inset-0 z-[9999] overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <div className="w-[381px] relative transform overflow-hidden rounded-2xl bg-white text-left shadow-xl transition-all sm:my-8">
            <div className="flex items-center justify-center bg-gray-100 flex-col">
              <div className="bg-white flex flex-col items-center px-8 py-4">
                <div>
                  <h1 className="text-xl font-medium mt-3">Payment Detail</h1>
                </div>
                <div className="mt-4">
                  <h1 className="text-xl text-center text-[#16bc16] mt-6">
                    Change : ${paidAmount - actualAmount.toFixed()}
                  </h1>
                  <h1 className="text-sm text-center mt-2">
                    Cash Paid : ${paidAmount}
                  </h1>
                </div>
                <div className="flex gap-3 mt-4">
                  <div className="flex justify-center my-4">
                    <button
                      className="w-[100%] px-8 text-xs flex justify-center items-center gap-2 text-black h-[34px] rounded-xl bg-[#F5F5F6] hover:bg-orange hover:text-white duration-500"
                      onClick={showBillAmountModal}
                    >
                      Receipt
                    </button>
                  </div>
                  <div className="flex justify-center my-4">
                    <button
                      className="w-[100%] px-8 text-xs flex justify-center items-center gap-2 text-white h-[34px] rounded-xl bg-orange hover:bg-white hover:text-orange duration-500"
                      onClick={showBillAmountModal}
                    >
                      Continue
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PayBillAmountModal;
