import React from "react";
import defaultImg from "../../assests/images/dashboard/default-thumbnail (1).jpg";
import closeIcon from "../../assests/images/dashboard/Vector 3.png";
import { S3_BASE_URL } from "src/constants/endpoints";

const ItemPreviewModal = ({
  setShowPreviewModal,
  img,
  item,
  editCategoryItemModal,
}: any) => {

  return (
    <div
      className="relative z-10"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div className="fixed inset-0 flex bg-black/30 min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
        <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-[0px_4px_0px_880px_rgba(37,37,37,0.75)] transition-all sm:my-8">
          <img
            className="w-3 h-3 cursor-pointer absolute right-4 top-4"
            src={closeIcon}
            alt=""
            onClick={setShowPreviewModal}
          />

          <h1 className="text-xl px-8 py-3">Item Preview</h1>
          <div className="flex items-center justify-center bg-gray-100">
            <div
              className={`bg-white px-8 py-6 rounded-lg w-[348px] min-w-10 flex flex-col justify-center items-center`}
            >
              <div>
                <div className="h-[240px] w-[250px] relative mb-3 border-[#F1F1F2] border bg-white rounded-xl">
                  <div className="flex justify-center h-[140px]">
                    <img
                      alt=""
                      className="h-[140px] w-[300px] rounded-t-xl object-cover"
                      src={
                        img?.url
                          ? img?.url
                          : editCategoryItemModal?.bucketKeyName
                          ? editCategoryItemModal?.bucketKeyName.includes(
                              "blob"
                            )
                            ? editCategoryItemModal?.bucketKeyName
                            : S3_BASE_URL + editCategoryItemModal?.bucketKeyName
                          : defaultImg
                      }
                    />
                  </div>
                  <div className="pb-4 pr-4 pl-4 pt-[10px]">
                    <div className="flex justify-between mt-1">
                      <h1>{item.englishName}</h1>
                      <span>${item?.price}</span>
                    </div>
                    {item.englishDescription && (
                      <div>
                        <p className="mt-1 text-xs font-normal text-[#252525] w-[251px] overflow-hidden whitespace-nowrap text-ellipsis">
                          {item.englishDescription}
                        </p>
                      </div>
                    )}
                    <div className="mt-0">
                      <span className="text-[#A3A3A3] text-[12px]">
                        {item.nonEnglishDescription}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ItemPreviewModal;
