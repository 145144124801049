import React from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { v4 as uuidv4 } from "uuid";
import { BtnLoader } from "src/Loader";
import { isEmployee } from "src/utils/isEmployee";
import { successToast } from "src/utils/toasts";
import { useAuth } from "src/contexts/auth-provider";
import {
  GET_CATEGORIES,
  GET_CUSTOMERS_BY_CORRELATIONIDS,
  GET_CUSTOMER_TABLE_ORDER_FOR_UPDATE,
} from "src/constants/queries";
import { useLocation, useNavigate } from "react-router-dom";
import {
  CREATE_EMPLOYEE_ORDER,
  UPDATE_CUSTOMER_ORDER,
} from "src/constants/mutations";
import { useAxiosInterceptor } from "src/hooks/useAxiosInterceptor";
import { GRAPHQL_ENDPOINT, S3_BASE_URL } from "src/constants/endpoints";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import Logo from "src/components/common/Logo";
import OrderModal from "src/components/OrderModal/OrderModal";
import BackArrow from "../../assests/images/dashboard/Vector 7.png";
import RestuarantImg from "../../assests/images/dashboard/default-thumbnail (1).jpg";
import CheckExistingCustomerModal from "src/components/CheckExistingCustomerModal/CheckExistingCustomerModal";
import { OrderCategoryHeader } from "./OrderCategoryHeader";
import { MyCartSection } from "./MyCartSection";
import AddNewCustomerModal from "src/components/AddNewCustomerModal/AddNewCustomerModal";

const handleCheck = (orderType: string) => {
  if (orderType === "DINE_IN") {
    return Yup.object({
      tableNumber: Yup.string().required("Table Number is Required"),
    });
  }
  if (orderType === "PICK_UP") {
    return Yup.object({
      customerFirstName: Yup.string().required(
        "Customer First Name is Required"
      ),
      customerLastName: Yup.string().required("Customer Last Name is Required"),
      serverLastName: Yup.string().required("Server Last Name is Required"),
      customerPhoneNumber: Yup.string().required(
        "Customer Phone Number is Required"
      ),
    });
  }
  if (orderType === "DELIVERY") {
    return Yup.object({
      customerFirstName: Yup.string().required(
        "Customer First Name is Required"
      ),
      customerLastName: Yup.string().required("Customer Last Name is Required"),
      serverLastName: Yup.string().required("Server Last Name is Required"),
      customerPhoneNumber: Yup.string().required(
        "Customer Phone Number is Required"
      ),
      address: Yup.object({
        addressLine1: Yup.string().required("Street  is required"),
        city: Yup.string().required("City is required"),
        zipCode: Yup.string().required("Zip Code is required"),
        state: Yup.string().required("State is required"),
      }).required("Address is required"),
    });
  }
  return Yup.object({});
};

export const CreateNewOrder = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const editableTableOrder = location?.state?.TableOrderdata;
  const orderType = location?.state?.selectedOrderType;
  

  const { hasRestaurant } = useAuth();
  const { user, userRole } = useAuth();
  const { axBe } = useAxiosInterceptor();
  const categoryRefs = useRef<any[]>([]);
  const [data, setData] = useState<any>([]);
  const [orderTypeCustomerModal, setOrderTypeCustomerModal] =
    useState<any>(null);
  const [loading, setLoading] = useState(false);
  const [loader, setLoader] = useState<any>([]);
  const [orderStatus, setOrderStatus] = useState<string | null>(null);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [dropdownOpenStatus, setDropdownOpenStatus] = useState(false);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const toggleDropdown = () => setDropdownOpen(!dropdownOpen);
  const toggleDropdownStatus = () => setDropdownOpenStatus(!dropdownOpenStatus);
  const [selectedItem, setSelectedItem] = useState<any>(null);
  const [selectedOrders, setSelectedOrder] = useState<any>([]);
  const [selectedCategoryName, setSelectedCategoryName] = useState("");
  const [editableOrder, setEditableOrder] = useState<any>(null);
  const [existingCustomer, setExistingCustomer] = useState<any>(null);
  const [showAddCustomerModal, setShowAddCustomerModal] = useState(false);
  const [isCategoryFilterEnabled, setIsCategoryFilterEnabled] =
    useState<any>(false);
  const [customersDetails, setCustomersDetails] = useState<any>([]);

  const getCustomers = useCallback(async () => {
    try {
      const { data } = await axBe.post(GRAPHQL_ENDPOINT, {
        query: GET_CUSTOMERS_BY_CORRELATIONIDS,
        variables: {
          customersByCorrelationIdsInput: {
            storeCorrelationId: hasRestaurant?.correlationId,
            customerCorrelationIds: [
              editableOrder?.customersDetails?.[0]?.correlationId,
            ],
          },
        },
      });
      setCustomersDetails(data?.data?.customersByCorrelationIds || []);
    } catch (error) {
      console.error(error);
    }
  }, [editableOrder?.customersDetails, axBe, hasRestaurant?.correlationId]);
  useEffect(() => {
    if (
      editableOrder?.correlationId &&
      editableOrder?.customersDetails?.[0]?.correlationId
    ) {
      getCustomers();
    }
  }, [showAddCustomerModal,editableOrder?.correlationId,getCustomers, editableOrder?.customersDetails, showAddCustomerModal]);

  useEffect(() => {
    if (orderType) {
      setOrderTypeCustomerModal(orderType);
    }
  }, [orderType]);

  useEffect(() => {
    if (location?.state?.data) {
      setEditableOrder(location?.state?.data);
    }
  }, [location?.state?.data]);

  const mapItemOptionElements = (option: string) => {
    const parts = option.split("::");
    return {
      name: parts[0],
      correlationId: parts[1],
      description: parts[2],
      price: parseFloat(parts[3]),
      quantity: parseInt(parts[4], 10),
      count: parseInt(parts[4], 10),
      isSelected: true,
    };
  };

  const transformedData = useCallback((data: any) => {
    return data?.items?.map((item: any) => {
      return {
        ...item,
        correlationId: item.correlationId,
        abbreviation: item.englishName,
        englishName: item.englishName,
        nonEnglishName: item.nonEnglishName,
        englishDescription: item.englishDescription,
        nonEnglishDescription: item.nonEnglishDescription,
        price: parseFloat(item?.price),
        taxRate: parseFloat(item?.taxRate),
        tag: "Best Pizza",
        bucketKeyName: item.bucketKeyName,
        itemOptions:
          item?.itemOptionElements?.length > 0
            ? item?.itemOptionElements
                ?.split(",")
                ?.map((data: any, index: number) => ({
                  name: item.itemOptionElements?.split(",")[0].split("::")[0],
                  isOptionSelected: true,
                  itemOptionElements:
                    item?.itemOptionElements
                      ?.split(",")
                      ?.map(mapItemOptionElements) || [],
                }))
            : [],
        isMatch: true,
        count: item.quantity,
        isEditCase: true,
        totalElementPrice: parseFloat(item.price),
        cetogoryCorrelationId: item.correlationId,
      };
    });
  }, []);

  const getCategories = useCallback(async () => {
    setLoader(true);
    try {
      const { data } = await axBe.post(GRAPHQL_ENDPOINT, {
        query: GET_CATEGORIES,
        variables: {
          storeCorrelationId: hasRestaurant.correlationId,
        },
      });
      let modifiedData = data?.data?.categoriesByStoreCorrelationId?.map(
        (category: any, index: number) => {
          const categoryId = category?.correlationId;
          if (!categoryRefs.current[categoryId]) {
            categoryRefs.current[categoryId] = React.createRef();
          }
          return {
            ...category,
            isMatch: true,
            isToogle: true,
            isSelected: index === 0 ? true : false,
            items: category.items.map((item: any) => ({
              ...item,
              isMatch: true,
              count: 0,
            })),
            categoryRef: categoryRefs.current[categoryId],
          };
        }
      );
      setSelectedCategoryName(modifiedData[0].name);

      if (editableOrder?.correlationId) {
        modifiedData = modifiedData?.map((data: any) => ({
          ...data,
          items: data?.items?.map((item: any) => ({
            ...item,
            totalCount: editableOrder?.items.find(
              (option: any) => option?.itemCorrelationId === item.correlationId
            )?.quantity,
          })),
        }));
      }

      setData(modifiedData);
      setLoader(false);
    } catch (error) {
      console.error(error);
      setLoader(false);
    }
  }, [
    axBe,
    editableOrder?.correlationId,
    editableOrder?.items,
    hasRestaurant?.correlationId,
  ]);

  useEffect(() => {
    getCategories();
  }, [getCategories]);

  useEffect(() => {
    setData((prev: any) =>
      prev?.map((data: any, index: number) => {
        const isMatch = data.items.some((item: any) =>
          item.englishName.toLowerCase().includes(searchQuery.toLowerCase())
        );
        const updatedItems = data.items.map((item: any) => ({
          ...item,
          isMatch: item.englishName
            .toLowerCase()
            .includes(searchQuery.toLowerCase()),
        }));
        return {
          ...data,
          isMatch,
          isSelected: index === 0 ? true : false,
          items: updatedItems,
        };
      })
    );
    setIsCategoryFilterEnabled(false);
  }, [searchQuery]);

  const handleSelection = (item: any) => {
    const { parentIndex, childIndex, ...restData } = item;
    const allOrignalData = [...data];
    const allPreviosSelectedOrders = [...selectedOrders];
    allPreviosSelectedOrders.push({
      ...restData,
      cetogoryCorrelationId: allOrignalData[parentIndex].correlationId,
    });
    allOrignalData[parentIndex].items[childIndex].totalCount =
      restData.count + (restData.totalCount || 0);
    setData(allOrignalData);
    setSelectedOrder(allPreviosSelectedOrders);
    setSelectedItem(null);
  };

  const handleCategory = (categoryId: string) => {
    const allTempData = [...data].map((item) => ({ ...item, isMatch: false }));
    const index = allTempData.findIndex(
      (item: any) => item.correlationId === categoryId
    );
    const items = allTempData[index].items.map((item: any) => ({
      ...item,
      isMatch: true,
    }));
    allTempData[index] = {
      ...allTempData[index],
      items,
      isMatch: true,
    };
    setSelectedCategoryName(allTempData[index].name);
    setData(allTempData);
    setIsCategoryFilterEnabled(true);
  };

  const handleToogle = (parentIndex: number) => {
    const allTempData = data.map((item: any, index: any) =>
      index === parentIndex ? { ...item, isToogle: !item.isToogle } : item
    );
    setData(allTempData);
  };

  const formik = useFormik({
    initialValues: {
      serverFirstName: "",
      serverLastName: "",
      customerFirstName: "",
      customerLastName: "",
      customerEmail: "",
      customerPhoneNumber: "",
      tableNumber: undefined,
      customerNote: "",
      tip: "",
      address: {
        addressLine1: "",
        city: "",
        zipCode: "",
        state: "",
      },
    },
    validationSchema: handleCheck(orderType),
    onSubmit: () => {},
  });
  const { setFieldValue } = formik;

  useEffect(() => {
    if (existingCustomer?.correlationId) {
      setFieldValue(
        "address.addressLine1",
        `${existingCustomer?.address?.addressLine1 || ""}`
      );
      setFieldValue("address.city", `${existingCustomer?.address?.city || ""}`);
      setFieldValue(
        "address.state",
        `${existingCustomer?.address?.state || ""}`
      );
      setFieldValue(
        "address.zipCode",
        `${existingCustomer?.address?.zipCode || ""}`
      );
      setFieldValue("customerEmail", `${existingCustomer?.email || ""}`);
      setFieldValue(
        "customerFirstName",
        `${existingCustomer?.firstName || ""}`
      );
      setFieldValue("customerLastName", `${existingCustomer?.lastName || ""}`);
      setFieldValue(
        "customerPhoneNumber",
        `${existingCustomer?.phoneNumber || ""}`
      );
      setFieldValue("serverFirstName", `${user.storeUser.firstName}`);
      setFieldValue("serverLastName", `${user.storeUser.lastName}`);
    } else if (editableOrder?.correlationId && customersDetails?.length > 0) {
      setFieldValue("tip", `${editableOrder?.tip || ""}`);
      setFieldValue("customerNote", `${editableOrder?.customerNote || ""}`);
      setFieldValue("customerEmail", `${customersDetails?.[0]?.email || ""}`);
      setFieldValue(
        "address.addressLine1",
        `${customersDetails?.[0]?.address.addressLine1 || ""}`
      );
      setFieldValue(
        "address.city",
        `${customersDetails?.[0]?.address.city || ""}`
      );
      setFieldValue(
        "address.state",
        `${customersDetails?.[0]?.address.state || ""}`
      );
      setFieldValue(
        "address.zipCode",
        `${customersDetails?.[0]?.address.zipCode || ""}`
      );
      setFieldValue(
        "customerFirstName",
        `${customersDetails?.[0]?.firstName || ""}`
      );
      setFieldValue(
        "customerLastName",
        `${customersDetails?.[0]?.lastName || ""}`
      );
      setFieldValue(
        "serverFirstName",
        `${editableOrder.serverFirstName || ""}`
      );
      setFieldValue("serverLastName", `${editableOrder.serverLastName || ""}`);
      setFieldValue(
        "customerPhoneNumber",
        `${customersDetails?.[0]?.phoneNumber || ""}`
      );
      setFieldValue("tableNumber", `${editableOrder.tableNumber || ""}`);
    } else {
      setFieldValue("customerNote", "");
      setFieldValue("customerFirstName", "Guest");
      setFieldValue("customerLastName", "Guest");
      setFieldValue("serverFirstName", `${user.storeUser.firstName}`);
      setFieldValue("serverLastName", `${user.storeUser.lastName}`);
      setFieldValue("address.addressLine1", `""}`);
      setFieldValue("address.city", `""}`);
      setFieldValue("address.state", `""}`);
      setFieldValue("address.zipCode", `""}`);
    }
  }, [editableOrder, setFieldValue, user, existingCustomer, customersDetails]);

  console.log("formik", formik);

  const placeOrder = async () => {
    const orderItems: any[] = [];
    selectedOrders?.forEach((orderItem: any) => {
      if (orderItem?.itemOptions?.length) {
        orderItem.itemOptions
          ?.filter((option: any) => option?.isOptionSelected)
          ?.forEach((option: any) => {
            option?.itemOptionElements
              ?.filter((data: any) => data?.count > 0)
              ?.forEach((dataItem: any) => {
                orderItems.push({
                  quantity: orderItem?.count,
                  isCombo: false,
                  itemCorrelationId:
                    orderItem?.itemCorrelationId || orderItem?.correlationId,
                  itemOptionElements: `${option.name}::${dataItem?.correlationId}::${dataItem?.name}::${dataItem?.price}::${dataItem?.count}`,
                });
              });
          });
      } else {
        orderItems.push({
          itemCorrelationId:
            orderItem?.itemCorrelationId || orderItem.correlationId,
          quantity: orderItem.count,
          isCombo: false,
        });
      }
    });
    const newUUID = uuidv4();
    let payload: any = { ...formik.values, orderItems: orderItems };
    let tableNumber = Number(formik.values.tableNumber);

    // Remove address if Dine in order
    if (
      tableNumber ||
      orderType === "DINE_IN" ||
      editableOrder?.correlationId
    ) {
      delete payload?.address;
    }

    if (!editableOrder?.correlationId) {
      payload["customerCorrelationId"] = newUUID;
    }
    if (editableOrder?.correlationId) {
      setLoading(true);
      const { data: resData } = await axBe.post(GRAPHQL_ENDPOINT, {
        query: UPDATE_CUSTOMER_ORDER,
        variables: {
          updateCustomerOrderInput: {
            ...payload,
            tableNumber,
            orderType: orderType,
            orderStatus: orderStatus,
            customerOrderCorrelationId: editableOrder?.correlationId,
            appUserCorrelationId: user.storeUser.correlationId,
            storeCorrelationId: isEmployee(userRole)
              ? user?.storeUser?.stores?.[0]?.correlationId
              : hasRestaurant.correlationId,
          },
        },
      });
      if (resData?.data?.updateCustomerOrder?.correlationId) {
        navigate(`/active-order`);
        successToast("Order has been Updated");
        setLoading(false);
      }
      setLoading(false);
    } else {
      setLoading(true);
      const { data: resData } = await axBe.post(GRAPHQL_ENDPOINT, {
        query: CREATE_EMPLOYEE_ORDER,
        variables: {
          createCustomerOrderInput: {
            ...payload,
            tableNumber,
            orderType: orderType,
            storeCorrelationId: isEmployee(userRole)
              ? user?.storeUser?.stores?.[0]?.correlationId
              : hasRestaurant.correlationId,
          },
        },
      });
      if (resData?.data?.createCustomerOrder?.correlationId) {
        navigate(`/active-order`);
        successToast("Order has been placed");
        setLoading(false);
      }
      setLoading(false);
    }
  };

  const focusCategory = (correlationId: any) => {
    const categoryDiv = categoryRefs.current[correlationId];
    if (categoryDiv) {
      categoryDiv.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
    setSelectedCategoryName(
      data?.find((item: any) => item.correlationId === correlationId)?.name ||
        ""
    );
  };

  const removeCardItem = (item: any, index: number) => {
    const { count, cetogoryCorrelationId, ...rest } = item;
    const allSelectedOrders = [...selectedOrders];
    let allOrignalOrders: any = [...data];
    allSelectedOrders.splice(index, 1);
    if (item?.isEditCase) {
      allOrignalOrders = [...data].map((data: any) => ({
        ...data,
        items: data.items.map((item: any) => ({
          ...item,
          totalCount: item.totalCount - count,
        })),
      }));
    } else {
      const categoryIndex = allOrignalOrders.findIndex(
        (item: any) => item.correlationId === cetogoryCorrelationId
      );
      const itemIndex = allOrignalOrders[categoryIndex].items.findIndex(
        (data: any) => data.correlationId === rest.correlationId
      );
      allOrignalOrders[categoryIndex].items[itemIndex].totalCount =
        allOrignalOrders[categoryIndex].items[itemIndex].totalCount - count;
    }
    setData(allOrignalOrders);
    setSelectedOrder(allSelectedOrders);
  };

  const isCheckOutEnabled = useMemo(() => {
    return selectedOrders?.length > 0;
  }, [selectedOrders]);

  useEffect(() => {
    const queryString = location.search;
    const regex = /tableId\/(\d+)/;
    const match = queryString.match(regex);
    if (match) {
      const tableNumber = parseInt(match[1], 10);
      setFieldValue("tableNumber", tableNumber);
    }
  }, [location.search, setFieldValue]);

  const handleCheckoutCardCount = (item: any, index: number, count: number) => {
    const { count: previousCount, cetogoryCorrelationId, ...rest } = item;
    const allSelectedCard = [...selectedOrders];
    let allOrignalOrders: any = [...data];
    allSelectedCard[index].count = count;
    if (item?.isEditCase) {
      allOrignalOrders = allOrignalOrders.map((data: any) => ({
        ...data,
        items: data.items.map((item: any) => ({
          ...item,
          totalCount: item.totalCount - previousCount + count,
        })),
      }));
    } else {
      const categoryIndex = allOrignalOrders.findIndex(
        (item: any) => item.correlationId === cetogoryCorrelationId
      );
      const itemIndex = allOrignalOrders[categoryIndex].items.findIndex(
        (data: any) => data.correlationId === rest.correlationId
      );
      allOrignalOrders[categoryIndex].items[itemIndex].totalCount =
        allOrignalOrders[categoryIndex].items[itemIndex].totalCount -
        previousCount +
        count;
    }

    setSelectedOrder(allSelectedCard);
    setData(allOrignalOrders);
  };

  useEffect(() => {
    if (editableOrder?.correlationId) {
      const alreadySelectedOrder = transformedData(editableOrder);
      setSelectedOrder(alreadySelectedOrder);
    }
  }, [editableOrder, transformedData]);

  const getCustomerOrders = useCallback(async () => {
    try {
      const { data } = await axBe.post(GRAPHQL_ENDPOINT, {
        query: GET_CUSTOMER_TABLE_ORDER_FOR_UPDATE,
        variables: {
          customerOrderCorrelationId:
            editableTableOrder?.currentOrderCorrelationId,
        },
      });
      setEditableOrder(data?.data?.customerOrderByCorrelationId);
    } catch (error) {
      console.error(error);
    }
  }, [axBe, editableTableOrder?.currentOrderCorrelationId]);

  useEffect(() => {
    if (editableTableOrder?.currentOrderCorrelationId) {
      getCustomerOrders();
    }
  }, [getCustomerOrders, editableTableOrder?.currentOrderCorrelationId]);

  const handleCustomerSelect = (customer: any) => {
    setExistingCustomer(customer);
  };

  const isDisabled = !formik.isValid || !formik.dirty;

  return (
    <>
      <div className="sticky z-50 top-[0px] bg-white max-md:bg-white max-md:z-10">
        <div className="flex justify-between items-center max-md:pl-3 max-md:pr-3 pl-10 pr-10 pt-[24px] pb-[24px] border-b-[0.5px] border-borderGray">
          <div className="flex items-center">
            <div
              className="flex items-center cursor-pointer"
              onClick={() => navigate("/active-order")}
            >
              <img className="w-[9px] mr-4 h-[14px]" src={BackArrow} alt="" />
            </div>
            <Logo />
            <span className="pl-3 text-2xl">EatsBits</span>
          </div>
        </div>
      </div>
      {loader ? (
        <div className="absolute top-[50%] left-[50%]">
          <BtnLoader />
        </div>
      ) : (
        <div className="px-4">
          <div className="flex w-full gap-x-1 mt-6">
            <div className="w-full">
              <div className="mb-4">
                <form className="w-[35%]">
                  <div className="relative">
                    <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                      <svg
                        className="w-4 h-4 text-gray-500 dark:text-gray-400"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 20 20"
                      >
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                        />
                      </svg>
                    </div>
                    <input
                      type="search"
                      id="default-search"
                      className="block border-[#F1F1F2] border w-full p-3 ps-10 text-sm text-black rounded-lg "
                      placeholder="Search Menu..."
                      onChange={(e) => setSearchQuery(e.target.value)}
                    />
                  </div>
                </form>
              </div>
              <OrderCategoryHeader
                data={data}
                isCategoryFilterEnabled={isCategoryFilterEnabled}
                searchQuery={searchQuery}
                focusCategory={focusCategory}
                handleCategory={handleCategory}
                selectedCategoryName={selectedCategoryName}
              />
              <div className="w-full h-[550px] overflow-y-scroll rounded-xl">
                {!isCategoryFilterEnabled && !searchQuery
                  ? [...data?.slice(0, 1)]?.map((item: any, index: number) => {
                      return (
                        <div
                          className="border p-4 border-[#F1F1F2] rounded-xl w-[100%] bg-white"
                          key={index}
                        >
                          <h2>
                            <button
                              type="button"
                              className="flex items-center justify-between w-full p-2 font-medium rtl:text-right text-gray-500  rounded-t-xl gap-3"
                              onClick={() => handleToogle(index)}
                              aria-expanded={item.isToogle}
                              aria-controls="accordion-collapse-body-1"
                            >
                              <span className="font-medium text-base">
                                {item?.name} {`(${item?.items?.length})`}
                              </span>
                              <svg
                                className={`w-3 h-3 transition-transform ${
                                  item.isToogle ? "rotate-180" : ""
                                }`}
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 10 6"
                              >
                                <path
                                  stroke="currentColor"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                  d="M9 5 5 1 1 5"
                                />
                              </svg>
                            </button>
                          </h2>
                          {/* <--------------firsr card--------------> */}
                          <div className="flex row w-full flex-wrap gap-2 items-center">
                            {item.isToogle &&
                              item?.items?.map(
                                (dataItem: any, indexNested: number) => {
                                  return (
                                    <>
                                      <div className="flex items-center flex-wrap">
                                        <div
                                          className={`xl:w-[320px] relative ml-3 my-3 border-[#F1F1F2] border bg-white rounded-xl w-[300px]`}
                                        >
                                          {dataItem?.totalCount > 0 && (
                                            <div className="absolute right-3 top-2 z-10  h-[20px] w-[20px]  bg-orange text-white rounded-full flex items-center justify-center">
                                              <span className="text-sm ">
                                                {dataItem?.totalCount}
                                              </span>
                                            </div>
                                          )}
                                          <div
                                            className="flex justify-center bg-[#dadada] h-[140px] rounded-xl cursor-pointer"
                                            onClick={() =>
                                              setSelectedItem({
                                                ...dataItem,
                                                parentIndex: index,
                                                childIndex: indexNested,
                                              })
                                            }
                                          >
                                            <img
                                              className="w-full rounded-t-xl cursor-pointer object-cover bg-no-repeat"
                                              src={
                                                dataItem?.bucketKeyName
                                                  ? dataItem?.bucketKeyName?.includes(
                                                      "blob"
                                                    )
                                                    ? dataItem?.bucketKeyName
                                                    : S3_BASE_URL +
                                                      dataItem?.bucketKeyName
                                                  : RestuarantImg
                                              }
                                              alt=""
                                            />
                                          </div>
                                          <div className="pr-4 pl-4 pt-[10px]">
                                            <div className="flex justify-between items-center mt-1">
                                              <div className="">
                                                <h1 className="tooltip-section w-[200px] text-sm font-medium ">
                                                  {dataItem?.englishName}{" "}
                                                  {dataItem?.nonEnglishName &&
                                                    `, ${dataItem?.nonEnglishName}`}
                                                </h1>
                                              </div>
                                              <span className="text-base font-medium">
                                                ${dataItem?.price}
                                              </span>
                                            </div>

                                            <div className="flex flex-wrap w-full  gap-1  mt-1 items-center">
                                              {dataItem?.itemOptions &&
                                              dataItem?.itemOptions?.length >
                                                0 ? (
                                                dataItem?.itemOptions
                                                  ?.slice(0, 4)
                                                  ?.map(
                                                    (
                                                      option: any,
                                                      index: any
                                                    ) => (
                                                      <>
                                                        <h1
                                                          className="text-xs text-[#A3A3A3]"
                                                          key={index}
                                                        >
                                                          {option?.name}
                                                        </h1>
                                                        {index <
                                                          dataItem?.itemOptions?.slice(
                                                            0,
                                                            4
                                                          )?.length -
                                                            1 && (
                                                          <span className="w-[4px] h-[4px] bg-[#D9D9D9] rounded-full"></span>
                                                        )}
                                                      </>
                                                    )
                                                  )
                                              ) : (
                                                <></>
                                              )}
                                              {dataItem?.itemOptions?.length >
                                                5 && (
                                                <div className="mb-1">
                                                  <span className="text-[#252525] text-[10px] px-2 py-1 bg-[#f3f3f4] rounded-xl font-medium">
                                                    +
                                                    {dataItem?.itemOptions
                                                      ?.length - 4}
                                                  </span>
                                                </div>
                                              )}
                                            </div>

                                            <div className="flex gap-x-3">
                                              <div className="flex justify-center my-4 w-full">
                                                <button
                                                  className="w-[100%] px-3 text-xs flex justify-center items-center gap-2 text-black h-[34px] rounded-lg bg-[#F5F5F6]"
                                                  onClick={() =>
                                                    setSelectedItem({
                                                      ...dataItem,
                                                      parentIndex: index,
                                                      childIndex: indexNested,
                                                    })
                                                  }
                                                >
                                                  View Details
                                                </button>
                                              </div>
                                              <div className="flex justify-center my-4 w-full">
                                                <button
                                                  className="w-[100%] px-3 text-xs flex justify-center items-center gap-2 text-white h-[34px] rounded-lg bg-orange"
                                                  onClick={() => {
                                                    dataItem?.itemOptions
                                                      ?.length > 0
                                                      ? setSelectedItem({
                                                          ...dataItem,
                                                          parentIndex: index,
                                                          childIndex:
                                                            indexNested,
                                                        })
                                                      : handleSelection({
                                                          ...dataItem,
                                                          count:
                                                            dataItem.count + 1,
                                                          parentIndex: index,
                                                          childIndex:
                                                            indexNested,
                                                        });
                                                  }}
                                                >
                                                  Add to Cart
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  );
                                }
                              )}
                          </div>
                        </div>
                      );
                    })
                  : data.map((item: any, index: number) => {
                      return item?.isMatch ? (
                        <div
                          className="border p-4 border-[#F1F1F2] rounded-xl w-[100%] bg-white"
                          ref={(el) => {
                            categoryRefs.current[item.correlationId] = el;
                          }}
                          id={item?.correlationId}
                          key={item?.correlationId}
                        >
                          <h2>
                            <button
                              type="button"
                              className="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500  rounded-t-xl gap-3"
                              onClick={() => handleToogle(index)}
                              aria-expanded={item.isToogle === 1}
                              aria-controls="accordion-collapse-body-1"
                            >
                              <span className="font-medium text-base">
                                {item?.name} {`(${item?.items?.length})`}
                              </span>
                              <svg
                                className={`w-3 h-3 transition-transform ${
                                  item.isToogle === 1 ? "rotate-180" : ""
                                }`}
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 10 6"
                              >
                                <path
                                  stroke="currentColor"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                  d="M9 5 5 1 1 5"
                                />
                              </svg>
                            </button>
                          </h2>
                          {/* ---------second card------------- */}
                          <div className="flex row w-full flex-wrap gap-2 items-center">
                            {item.isToogle &&
                              item?.items?.map(
                                (dataItem: any, indexNested: number) => {
                                  return item.isMatch ? (
                                    <>
                                      <div className="flex items-center flex-wrap">
                                        <div
                                          className={`xl:w-[320px] relative ml-3 my-2 border-[#F1F1F2] border bg-white rounded-xl w-[300px]`}
                                        >
                                          {dataItem?.totalCount > 0 && (
                                            <div className="absolute right-3 top-2 z-10  h-[20px] w-[20px]  bg-orange text-white rounded-full flex items-center justify-center">
                                              <span className="text-sm ">
                                                {dataItem?.totalCount}
                                              </span>
                                            </div>
                                          )}
                                          <div
                                            className="flex justify-center bg-[#dadada] h-[140px] rounded-xl cursor-pointer"
                                            onClick={() =>
                                              setSelectedItem({
                                                ...dataItem,
                                                parentIndex: index,
                                                childIndex: indexNested,
                                              })
                                            }
                                          >
                                            <img
                                              className="w-full rounded-t-xl cursor-pointer object-cover bg-no-repeat"
                                              src={
                                                dataItem?.bucketKeyName
                                                  ? dataItem?.bucketKeyName?.includes(
                                                      "blob"
                                                    )
                                                    ? dataItem?.bucketKeyName
                                                    : S3_BASE_URL +
                                                      dataItem?.bucketKeyName
                                                  : RestuarantImg
                                              }
                                              alt=""
                                            />
                                          </div>
                                          <div className="pr-4 pl-4 pt-[10px]">
                                            <div className="flex justify-between items-center mt-1">
                                              <div className="">
                                                <h1 className="tooltip-section w-[200px] text-sm font-medium ">
                                                  {dataItem?.englishName}{" "}
                                                  {dataItem?.nonEnglishName &&
                                                    `, ${dataItem?.nonEnglishName}`}
                                                </h1>
                                              </div>
                                              <span className="text-base font-medium">
                                                ${dataItem?.price}
                                              </span>
                                            </div>
                                            <div className="flex flex-wrap w-full  gap-1  mt-1 items-center">
                                              {dataItem?.itemOptions &&
                                              dataItem?.itemOptions?.length >
                                                0 ? (
                                                dataItem?.itemOptions
                                                  ?.slice(0, 4)
                                                  ?.map(
                                                    (
                                                      option: any,
                                                      index: any
                                                    ) => (
                                                      <>
                                                        <h1
                                                          className="text-xs text-[#A3A3A3]"
                                                          key={index}
                                                        >
                                                          {option?.name}
                                                        </h1>
                                                        {index <
                                                          dataItem?.itemOptions?.slice(
                                                            0,
                                                            4
                                                          )?.length -
                                                            1 && (
                                                          <span className="w-[4px] h-[4px] bg-[#D9D9D9] rounded-full"></span>
                                                        )}
                                                      </>
                                                    )
                                                  )
                                              ) : (
                                                <></>
                                              )}
                                              {dataItem?.itemOptions?.length >
                                                5 && (
                                                <div className="mb-1">
                                                  <span className="text-[#252525] text-[10px] px-2 py-1 bg-[#f3f3f4] rounded-xl font-medium">
                                                    +
                                                    {dataItem?.itemOptions
                                                      ?.length - 4}
                                                  </span>
                                                </div>
                                              )}
                                            </div>

                                            <div className="flex gap-x-3">
                                              <div className="flex justify-center my-4 w-full">
                                                <button
                                                  className="w-[100%] px-3 text-xs flex justify-center items-center gap-2 text-black h-[34px] rounded-lg bg-[#F5F5F6]"
                                                  onClick={() =>
                                                    setSelectedItem({
                                                      ...dataItem,
                                                      parentIndex: index,
                                                      childIndex: indexNested,
                                                    })
                                                  }
                                                >
                                                  View Details
                                                </button>
                                              </div>
                                              <div className="flex justify-center my-4 w-full">
                                                <button
                                                  className="w-[100%] px-3 text-xs flex justify-center items-center gap-2 text-white h-[34px] rounded-lg bg-orange"
                                                  onClick={() => {
                                                    dataItem?.itemOptions
                                                      ?.length > 0
                                                      ? setSelectedItem({
                                                          ...dataItem,
                                                          parentIndex: index,
                                                          childIndex:
                                                            indexNested,
                                                        })
                                                      : handleSelection({
                                                          ...dataItem,
                                                          count:
                                                            dataItem.count + 1,
                                                          parentIndex: index,
                                                          childIndex:
                                                            indexNested,
                                                        });
                                                  }}
                                                >
                                                  Add to Cart
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  ) : null;
                                }
                              )}
                          </div>
                        </div>
                      ) : null;
                    })}
              </div>
            </div>
            {/* -------------------My cart section---------- */}
            <MyCartSection
              toggleDropdown={toggleDropdown}
              dropdownOpen={dropdownOpen}
              orderType={orderType}
              editableOrder={editableOrder}
              toggleDropdownStatus={toggleDropdownStatus}
              dropdownOpenStatus={dropdownOpenStatus}
              orderStatus={orderStatus}
              setOrderStatus={setOrderStatus}
              setDropdownOpenStatus={setDropdownOpenStatus}
              formik={formik}
              removeCardItem={removeCardItem}
              isCheckOutEnabled={isCheckOutEnabled}
              handleCheckoutCardCount={handleCheckoutCardCount}
              loading={loading}
              selectedOrders={selectedOrders}
              placeOrder={placeOrder}
              isDisabled={isDisabled}
              onOpenModal={() => setShowAddCustomerModal(true)}
              showAddCustomerModal={showAddCustomerModal}
              existingCustomer={existingCustomer}
              getCustomers={getCustomers}
            />
          </div>
        </div>
      )}
      {selectedItem?.correlationId && (
        <OrderModal
          data={selectedItem}
          onClose={() => setSelectedItem(null)}
          handleSelection={handleSelection}
        />
      )}

      {orderTypeCustomerModal && orderTypeCustomerModal !== "DINE_IN" && (
        <CheckExistingCustomerModal
          onHide={() => setOrderTypeCustomerModal(null)}
          onCustomerSelect={handleCustomerSelect}
        />
      )}

      {showAddCustomerModal && (
        <AddNewCustomerModal
          CustomerOrderDetail={editableOrder}
          onClose={() => setShowAddCustomerModal(false)}
        />
      )}
    </>
  );
};
