import React, { useEffect, useRef, useState } from "react";
import PlusIcon from "../../assests/images/dashboard/Vector 2.png";
import SortIcon from "../../assests/images/dashboard/swap.png";
import { useDispatch, useSelector } from "react-redux";

import { BtnLoader } from "src/commonLoader/index";
import { ManageComboModal } from "src/components/ManageComboModal/ManageComboModal";
import ComboCard from "src/components/ComboCard/ComboCard";
import {
  delete_combo_modal,
  search_combo,
  set_combo,
  set_combo_loding,
  sort_combo,
} from "src/store/features/combo-slice";
import { GRAPHQL_ENDPOINT } from "src/constants/endpoints";
import { useAxiosInterceptor } from "src/hooks/useAxiosInterceptor";
import { GET_COMBOS } from "src/constants/queries";
import SortbyDropdown from "../Menu/SortbyDropdown";
import { useAuth } from "src/contexts/auth-provider";
import { useNavigate } from "react-router-dom";

const Combo = () => {
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [isComboLoader, setIsComboLoader]: any = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const { axBe } = useAxiosInterceptor();
  const dropdownRef = useRef<any>(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { filteredCombo } = useSelector((state: any) => state.combo);
  const { hasRestaurant, hasEmployee, userRole } = useAuth();

  const handleComboClick = (comboId: any) => {
    dispatch(delete_combo_modal(comboId));
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  useEffect(() => {
    if (!isComboLoader) {
      setIsComboLoader(true);
      getCombos();
      dispatch(set_combo_loding(false));
    }
  }, []);

  const getCombos = async () => {
    dispatch(set_combo_loding(true));
    const variables = {
      storeCorrelationId: hasRestaurant?.correlationId,
    };
    try {
      const { data } = await axBe.post(GRAPHQL_ENDPOINT, {
        query: GET_COMBOS,
        variables: variables,
      });
      dispatch(
        set_combo({
          combo: data?.data?.combosByStoreCorrelationId || [],
        })
      );
      setTimeout(() => {
        setIsComboLoader(false);
        dispatch(set_combo_loding(false));
      }, 500);
    } catch (error) {
      console.error(error);
    }
  };

  const navigateTo = (combo: any) => {
    if (userRole?.toLowerCase()?.includes('employee')){
      navigate(
        `/combo/${combo?.correlationId}`,
        { state: { itemData: combo } }
      );
    }
    else{
      navigate(
        `/my-${hasEmployee ? 'employee' :'restaurant'}/${hasEmployee?.correlationId || hasRestaurant?.correlationId}/combo/${combo?.correlationId}`,
        { state: { itemData: combo } }
      );
    }
  };

  const searchCombo = (value: string) => {
    dispatch(search_combo(value));
  };

  return (
    <div>
      <div className="flex justify-between items-center">
        <div>
          <form className="w-[280px]">
            <div className="relative">
              <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                <svg
                  className="w-4 h-4 text-gray-500 dark:text-gray-400"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 20 20"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                  />
                </svg>
              </div>
              <input
                type="search"
                id="default-search"
                className="block border-[#F1F1F2] border w-full p-3 ps-10 text-sm text-black rounded-lg "
                placeholder="Search Combo..."
                onChange={(e) => searchCombo(e.target.value)}
              />
            </div>
          </form>
        </div>
        <div className="relative inline-block text-left" ref={dropdownRef}>
          <div className="flex justify-center my-4">
            <button
              className={`flex items-center justify-evenly duration-500 w-[118px] h-[46px] rounded-xl border-solid border border-[#FD7657] 
          ${
            isOpen
              ? "bg-[#FD76571A] text-[#FD7657]"
              : "bg-transparent text-[#FD7657] hover:bg-white hover:text-orange"
          }`}
              onClick={toggleDropdown}
            >
              <img
                className="w-[24px] h-[24px]"
                src={SortIcon}
                alt="Sort Icon"
              />
              <span>Sort by</span>
            </button>
          </div>
          {isOpen && <SortbyDropdown sortBy={sort_combo} />}
        </div>
      </div>
      <div className="w-full flex gap-3">
        <div className="w-[90px]">
          <div
            className="h-[766px] bg-white rounded-xl flex justify-center items-center cursor-pointer"
            onClick={() => setShowCreateModal(!showCreateModal)}
          >
            <img className="w-[17px] h-[17px] " src={PlusIcon} alt="" />
          </div>
        </div>
        <div className="w-full">
          {isComboLoader ? (
            <div className="flex items-center justify-center w-full h-full">
              <BtnLoader />
            </div>
          ) : filteredCombo && filteredCombo.length > 0 ? (
            <div className="flex flex-wrap">
              {filteredCombo.map((combo: any, index: any) => (
                <ComboCard
                  key={index}
                  combo={combo}
                  handleComboClick={handleComboClick}
                  navigateTo={navigateTo}
                />
              ))}
            </div>
          ) : (
            <div className="flex items-center justify-center w-full h-full">
              <p>Click the button on the left to create some combo!</p>
            </div>
          )}
        </div>
      </div>
      {showCreateModal ? (
        <ManageComboModal
          title={"Create Combo"}
          setShowCreateModal={() => setShowCreateModal(!showCreateModal)}
          getCombos={getCombos}
        />
      ) : (
        <></>
      )}
    </div>
  );
};

export default Combo;
