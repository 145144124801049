import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  addToCarts: [] as any,
};

export const cartsOrderSlice = createSlice({
  name: "carts_order",
  initialState,
  reducers: {
    setAddToCartOrders: (state, { payload }) => {
      state.addToCarts = [...state.addToCarts , payload]
    },
    removeAddToCartOrder: (state, { payload }) => {
      const allCards = [...state.addToCarts]
      allCards.splice(payload)
      state.addToCarts = allCards
    },
    removeAll: (state) => {
      state.addToCarts = []
    },

    updateAddToCartOrders: (state, { payload }) => {
      state.addToCarts = payload
    },
    
  },
});

export const { setAddToCartOrders, removeAddToCartOrder, removeAll, updateAddToCartOrders} = cartsOrderSlice.actions;

export default cartsOrderSlice.reducer;
