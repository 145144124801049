import { TagsInput } from "react-tag-input-component";
import { forwardRef, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import DatePicker from "react-datepicker";
import { useAxiosInterceptor } from "src/hooks/useAxiosInterceptor";
import { useAuth } from "src/contexts/auth-provider";
import { GRAPHQL_ENDPOINT } from "src/constants/endpoints";
import { ADD_PROMOTION } from "src/constants/mutations";
import { successToast } from "src/utils/toasts";
import { checkEmailValid } from "src/utils/email";
import { BtnLoader } from "src/Loader";

const PromotionSchema = Yup.object().shape({

  userEmails: Yup.array().of(Yup.string().email("Invalid email")).required("Required"),
  expiry: Yup.string().required("Expiry date is required"),
  maxNumberOfRedemptions: Yup.string().required("Max Number Of Redemptions date is required"),
  percent: Yup.number().min(0, "Must be at least 0%").max(100, "Must be 100% or less").required("Percentage is required"),
  isExistingCustomer: Yup.boolean(),
});

export const CreatePromotion = ({
  title,
  isShowEmailInput = true,
  selectedEmails,
}: any) => {
  const { hasRestaurant } = useAuth();
  const { axBe } = useAxiosInterceptor();
  const [selectedInputEmails, setSelectedEmails] = useState([]);
  const [loading, setLoading] = useState(false);
  const formik = useFormik({
    initialValues: {
      userEmails: [],
      expiry: "",
      percent: "",
      maxNumberOfRedemptions:"",
      isExistingCustomer: false,
    },
    validationSchema: PromotionSchema,
    onSubmit: () => {},
  });

  const DateCustomInput = forwardRef(
    ({ value, onClick, className,autoComplete }: any, ref: any) => (
      <input
        name="expiry"
        type="text"
        className={
          `h-[40px] text-xs w-full border rounded-lg border-[#F1F1F2] mt-1 px-2 ` +
          className
        }
        placeholder="Enter expiry"
        onClick={onClick}
        ref={ref}
        autoComplete={autoComplete}
        value={value}
      />
    )
  );

  const submitPromotion = async () => {
    setLoading(true);
    const { userEmails, expiry, percent, isExistingCustomer, maxNumberOfRedemptions } = formik.values;
    const variables: any = {
      createPromotionCodeInput: {
        userEmails: userEmails,
        promotionExpiry: Math.floor(new Date(expiry).getTime() / 1000),
        percentOff: percent,
        maxRedemptions : Number(maxNumberOfRedemptions),
        isExistingCustomer: isExistingCustomer,
        storeCorrelationId: hasRestaurant?.correlationId,
      },
    };
    const { data } = await axBe.post(GRAPHQL_ENDPOINT, {
      query: ADD_PROMOTION,
      variables: variables,
    });
    setLoading(false);
    if (!data?.data?.error?.length) {
      formik.setFieldValue("expiry", "");
      formik.setFieldValue("percent", "");
      setLoading(false);
      if (isShowEmailInput) {
        formik.setFieldValue("userEmails", []);
        setLoading(false);
      }
      setLoading(false);
    }
    if(data.data.createPromotionCode){
      successToast("Promotion Created!");
    }
  };

  return (
    <>
      <div className="flex w-full justify-center">
        <div className="bg-white rounded-lg w-[50%] relative mt-10">
          <div className="pb-4 pt-8 w-[95%] m-auto">
            <div>
              {isShowEmailInput && (
                <div>
                  <label className="block text-sm text-[#9B9B9B] font-medium p-1">
                    Add Emails
                  </label>
                  <TagsInput
                    value={selectedInputEmails}
                    name="userEmails"
                    placeHolder="Enter emails"
                    onChange={(emails) => {
                      const validEmails: any = emails.filter((email: string) =>
                        checkEmailValid(email)
                      );
                      setSelectedEmails(validEmails);
                      formik.setFieldValue("userEmails", validEmails);
                    }}
                  />
                </div>
              )}
              <div className="mt-2 w-full">
                <label className="block text-sm font-medium text-[#9B9B9B] p-1">
                  Expiry
                </label>
                <DatePicker
                  selected={formik.values.expiry as any}
                  onChange={(date) => formik.setFieldValue("expiry", date)}
                  customInput={<DateCustomInput />}
                  minDate={new Date()}
                  autoComplete="off"
                />
              </div>
              <div className="mt-2">
                <label className="block text-sm font-medium text-[#9B9B9B] p-1">
                  Percent
                </label>
                <input
                  defaultValue={formik.values.percent}
                  name="percent"
                  type="number"
                  className="h-[40px] text-xs w-full border rounded-lg border-[#F1F1F2] mt-1 px-2"
                  placeholder="Enter percent"
                  value={formik.values.percent}
                  onChange={formik.handleChange}
                />
              </div>
              <div className="mt-2">
                <label className="block text-sm font-medium text-[#9B9B9B] p-1">
                Max number of redemptions
                </label>
                <input
                  defaultValue={formik.values.maxNumberOfRedemptions}
                  name="maxNumberOfRedemptions"
                  type="number"
                  className="h-[40px] text-xs w-full border rounded-lg border-[#F1F1F2] mt-1 px-2"
                  placeholder="Enter Max number of redemptions"
                  value={formik.values.maxNumberOfRedemptions}
                  onChange={formik.handleChange}
                />
              </div>
              <div className="mt-6 flex justify-end space-x-2">
                {loading ? (
                  <BtnLoader />
                ) : (
                  <button
                    className={`${
                      !formik.isValid && "opacity-20"
                    } text-white bg-orange text-sm w-24 h-[35px] rounded-lg`}
                    onClick={submitPromotion}
                    disabled={!formik.isValid}
                  >
                    Create
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
