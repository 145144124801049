import { useCallback, useEffect } from "react";
import { axBackendInstance } from "./../utils/axios-instances";
import { useAuth } from "./../contexts/auth-provider";
import { failureToast } from "./../utils/toasts";
import { GRAPHQL_ENDPOINT } from "src/constants/endpoints";
import { REFRESH_ACCESS_TOKEN } from "src/constants/mutations";
import {
  toggle_login_expiry_modal,
  toggle_token_refresh_modal,
} from "src/store/features/auth-slice";
import { useDispatch } from "react-redux";

let isInitialized = false; // Singleton flag

export const useAxiosInterceptor = () => {
  const { token, user, setRefreshtoken, setToken } = useAuth();
  const { refreshToken } = user || {};
  const dispatch = useDispatch();

  const callRefreshToken = useCallback(
    async (errorMessage: string) => {
      try {
        const {
          data: { data, errors },
        } = (await axBackendInstance.post(GRAPHQL_ENDPOINT as string, {
          query: REFRESH_ACCESS_TOKEN,
          variables: {
            refreshAccessTokenInput: {
              refreshToken: refreshToken,
            },
          },
        })) as any;
        const {
          refreshAccessToken: { refreshToken: newRefreshToken, accessToken },
        } = data || { refreshAccessToken: {} };
        if (errors?.[0]?.message) {
          throw errors?.[0]?.message;
        }
        localStorage.setItem("refreshToken", newRefreshToken);
        localStorage.setItem("token", accessToken);
        setRefreshtoken(newRefreshToken);
        setToken(accessToken);
        dispatch(toggle_token_refresh_modal(true));
      } catch (error) {
        // failureToast(errorMessage || "Token expired, please login again");
        dispatch(toggle_login_expiry_modal(true));
      }
    },
    [dispatch, refreshToken, setRefreshtoken, setToken]
  );

  const reqResInterceptor = useCallback(
    (config: any) => {
      config.headers.Authorization = `Bearer ${token}`;
      return config;
    },
    [token]
  );

  const reqErrInterceptor = async (error: any) => Promise.reject(error);

  const resResInterceptor = useCallback(
    async (response: any) => {
      const error = response.data?.errors?.[0];
      if (error?.extensions?.errorCode === 401) {
        await callRefreshToken(error.message);
      } else if (error) {
        if (error.message.includes("Refresh token")) {
          return;
        }
        failureToast(error.message);
      }
      return response;
    },
    [callRefreshToken]
  );

  const resErrInterceptor = useCallback(
    async (error: any) => {
      const errorMessage =
        error.response?.data?.message ||
        error.response?.data?.error ||
        error.response?.data?.detail;
      if (error.response?.status === 401 || error.response?.status === 403) {
        await callRefreshToken(errorMessage);
      } else {
        if (errorMessage.includes("Refresh token")) {
          return;
        }
        failureToast(errorMessage);
      }
      return Promise.reject(error);
    },
    [callRefreshToken]
  );

  useEffect(() => {
    if (!isInitialized) {
      const reqInterceptor = axBackendInstance.interceptors.request.use(
        reqResInterceptor,
        reqErrInterceptor
      );

      const resInterceptor = axBackendInstance.interceptors.response.use(
        resResInterceptor,
        resErrInterceptor
      );

      isInitialized = true; // Set the flag to true

      return () => {
        axBackendInstance.interceptors.request.eject(reqInterceptor);
        axBackendInstance.interceptors.response.eject(resInterceptor);
        isInitialized = false; // Reset flag on unmount if needed
      };
    }
  }, [token, reqResInterceptor, resResInterceptor, resErrInterceptor]);

  return { axBe: axBackendInstance };
};
