import { useCallback, useEffect, useState } from "react";
import { API_ENDPOINT, GRAPHQL_ENDPOINT } from "src/constants/endpoints";
import { STORES } from "src/constants/queries";
import { useAxiosInterceptor } from "src/hooks/useAxiosInterceptor";
import { useAuth } from "src/contexts/auth-provider";
import ExistingConnectStripSection from "../ExistingConnectStripSection/ExistingConnectStripSection";
import ConnectStripSection from "../ConnectStripSection/ConnectStripSection";
import { BtnLoader } from "src/Loader/index";

const ConnectedAccount = () => {
  const [store, setStore] = useState<any>([]);
  const { axBe } = useAxiosInterceptor();
  const { hasRestaurant } = useAuth();

  const [existingAccountInProgress, setExistingAccountInProgress] =
    useState(false);
  const [existingConnectedAccounts, setExistingConnectedAccounts] =
    useState<any>([]);

  const fetchExistingAccounts = useCallback(async () => {
    try {
      const response = await axBe.get(
        `${API_ENDPOINT}stripe/connectedAccount/existingConnectedAccounts`
      );
      setExistingConnectedAccounts(response?.data || []);
    } catch (error) {
      setExistingConnectedAccounts([]);
    } finally {
      setExistingAccountInProgress(false);
    }
  }, [axBe]);

  useEffect(() => {
    setExistingAccountInProgress(true);
    const fetchStores = async () => {
      try {
        const response: any = await axBe.post(GRAPHQL_ENDPOINT, {
          query: STORES,
          variables: {
            retrieveStoresInput: {
              storeCorrelationIds: [hasRestaurant.correlationId],
            },
          },
        });
        const { data }: any = response.data;
        if (data?.stores && data.stores.length > 0) {
          setStore(data?.stores?.[0]);
          if (!data?.stores?.[0]["connectedAccountId"]) {
            fetchExistingAccounts();
          } else {
            setExistingAccountInProgress(false);
          }
        } else {
          setExistingAccountInProgress(false);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setExistingAccountInProgress(false);
      }
    };
    fetchStores();
  }, [axBe, fetchExistingAccounts, hasRestaurant.correlationId]);

  const toggleExistingAccountConnect = (index: number, value: boolean) => {
    const tempAccounts = [...existingConnectedAccounts];
    tempAccounts[index].inProgress = value;
    setExistingConnectedAccounts(tempAccounts);
  };

  return (
    <div className="mt-20">
      <div className="rounded-3xl p-4">
        <section className="mb-6">
          <div>
            {existingAccountInProgress ? (
              <div className="flex justify-center items-center">
                <BtnLoader />
              </div>
            ) : (
              <>
                {store.connectedAccountId ? (
                  <div>
                    <ConnectStripSection
                      hasConnectedId={store.connectedAccountId}
                      isSubscriptionTab={true}
                      isConnected={true}
                    />
                  </div>
                ) : (
                  <>
                    <div>
                      <ConnectStripSection
                        hasConnectedId={store.connectedAccountId}
                        isSubscriptionTab={true}
                        isConnected={false}
                      />
                    </div>
                    {existingConnectedAccounts?.length ? (
                      <div className="mt-24">
                        <ExistingConnectStripSection
                          existingConnectedAccounts={existingConnectedAccounts}
                          toggleExistingAccountConnect={
                            toggleExistingAccountConnect
                          }
                          isSubscriptionTab={true}
                        />
                      </div>
                    ) : (
                      <></>
                    )}
                  </>
                )}
              </>
            )}
          </div>
        </section>
      </div>
    </div>
  );
};

export default ConnectedAccount;
