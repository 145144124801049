export enum AvailbleFeatures {
  RestaurantCreationAndManagement = "Restaurant creation and management",
  MenuCreationAndManagement = "Menu creation and management",
  DigitalMenuThroughQRCode = "Digital menu through QR code",
  PaymentProcessingThroughStripe = "Payment processing through stripe",
  ReportingAndAnalytics = "Reporting and analytics",
  ComboCreationAndManagement = "Combo creation and management",
  TeamManagement = "Team management",
  SendOrderToPrinter = "Send order to printer",
  PromotionManagement = "Promotion management",
  TableManagement = "Table management",
}

export const allowedDeliveryPolicy: [3, 4, 5, 6] = [3, 4, 5, 6];

export const getAvailableFeatures = (): Record<AvailbleFeatures, boolean> => {
  return Object.fromEntries(
    Object.entries(AvailbleFeatures).map(([key, value]) => [value, false])
  ) as Record<AvailbleFeatures, boolean>;
};
