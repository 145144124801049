import { forwardRef, useMemo, useState } from "react";
import { useAuth } from "src/contexts/auth-provider";
import { useAxiosInterceptor } from "src/hooks/useAxiosInterceptor";
import { BtnLoader } from "src/Loader";
import { convertDateToTimeStamp, formatDateTime } from "src/utils/date";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./date-picker.css";
import CelenderIcon from "../../assests/images/dashboard/Button container 2.png";
import { CreatePromotionModal } from "../CreatePromotionModal/CreatePromotionModal";
import ArrowUpIcon from "src/icons/ArrowUPIcon";
import ArrowDownIcon from "src/icons/ArrowDownIcon";
import { GRAPHQL_ENDPOINT } from "src/constants/endpoints";
import {
  GET_PROMOTIONS_BY_DATES,
  GET_PROMOTIONS_BY_SEARCH,
} from "src/constants/queries";

const ExistingCustomerPromotions = () => {
  const { hasRestaurant } = useAuth();
  const { axBe } = useAxiosInterceptor();
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [sortConfig, setSortConfig] = useState({ key: "", order: "" });
  const [searchLoading, setSearchLoading] = useState(false);
  const [searchLoadingInput, setSearchLoadingInput] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [isSearchedButton, setIsSearchedButton] = useState(false);
  const [orignalData, setOrignalData] = useState([]);
  const [data, setData] = useState([]);
  const [searchField, setSearchField] = useState("");

  const onChangeStartDate = (startDate: any) => {
    setStartDate(startDate);
  };
  const onChangeEndtDate = (endDate: any) => {
    setEndDate(endDate);
  };

  const StartDate = forwardRef(({ onClick, value }: any, ref: any) => (
    <>
      <label className="text-sm text-[#9B9B9B]">From</label>
      <div
        className="flex cursor-pointer relative items-center bg-white w-48 h-[45px] rounded-xl text-sm border border-[#F1F1F2] text-center"
        onClick={onClick}
      >
        <input className="ml-2 w-28" ref={ref} placeholder={value} />
        <img
          className="w-[26px] h-[26px] absolute right-[10px]"
          src={CelenderIcon}
          alt=""
        />
      </div>
    </>
  ));

  const EndDate = forwardRef(({ onClick, value }: any, ref: any) => (
    <>
      <label className="text-sm text-[#9B9B9B]">To</label>
      <div
        className="flex cursor-pointer relative items-center bg-white w-48 h-[45px] rounded-xl text-sm border border-[#F1F1F2] text-center"
        onClick={onClick}
      >
        <input className="ml-2 w-28" ref={ref} placeholder={value} />
        <img
          className="w-[26px] h-[26px] absolute right-[10px]"
          src={CelenderIcon}
          alt=""
        />
      </div>
    </>
  ));

  const selectAll = () => {
    const temp: any = [...(data || [])];
    let checkedAll;
    if (isAllSelected) {
      checkedAll = temp?.map((item: any) => ({
        ...item,
        isSelected: false,
      }));
    } else {
      checkedAll = temp?.map((item: any) => ({
        ...item,
        isSelected: true,
      }));
    }
    setData(checkedAll);
    setOrignalData(checkedAll);
  };

  const isAllSelected = useMemo(() => {
    return data.every((item: any) => item.isSelected);
  }, [data]);

  const isButtonEnabled = useMemo(() => {
    return data.some((item: any) => item.isSelected);
  }, [data]);

  const handleSelection = (index: any) => {
    let tempAll: any = [...data];
    if (isAllSelected) {
      tempAll = tempAll.map((item: any) => ({ ...item, isSelected: false }));
    }
    tempAll[index].isSelected = !tempAll[index].isSelected;
    setData(tempAll);
    setOrignalData(tempAll);
  };

  const sortData = (key: any, order = "asc") => {
    const sortedData = [...data].sort((a: any, b: any) => {
      let comparison = 0;
      setSortConfig({ key, order });
      if (typeof a[key] === "string" && typeof b[key] === "string") {
        comparison = a[key].localeCompare(b[key]);
      } else {
        comparison = a[key] - b[key];
      }
      return order === "desc" ? -comparison : comparison;
    });
    setData(sortedData);
    setOrignalData(sortedData);
  };

  const getPromotions = async () => {
    setSearchLoading(true);
    try {
      const { data } = await axBe.post(GRAPHQL_ENDPOINT, {
        query: GET_PROMOTIONS_BY_DATES,
        variables: {
          storeCorrelationId: hasRestaurant.correlationId,
          startTimeStamp: convertDateToTimeStamp(startDate),
          endTimeStamp: convertDateToTimeStamp(endDate),
        },
      });
      const modifiedData =
        data?.data?.storeCustomersByStoreCorrelationIdBetweenTwoInstants?.map(
          (item: any) => ({ ...item, isSelected: false })
        );
      setData(modifiedData || []);
      setOrignalData(modifiedData || []);
      setSearchLoading(false);
      setIsSearchedButton(true);
    } catch (error) {
      console.error(error);
      setSearchLoading(false);
      setIsSearchedButton(true);
    }
  };

  const handleSearch = async (event: any) => {
    event.preventDefault();
    const tempAllData = [...orignalData];
    if (searchField === "") {
      setData(tempAllData);
      return;
    }
    try {
      setSearchLoadingInput(true);
      const { data } = await axBe.post(GRAPHQL_ENDPOINT, {
        query: GET_PROMOTIONS_BY_SEARCH,
        variables: {
          storeCorrelationId: hasRestaurant.correlationId,
          keyword: searchField,
        },
      });
      const modifiedData =
        data?.data?.storeCustomersByStoreCorrelationIdAndNameOrEmail?.map(
          (item: any) => ({ ...item, isSelected: false })
        );

      setData(modifiedData || []);
      setIsSearchedButton(true);
    } catch (error) {
      console.error(error);
      setIsSearchedButton(true);
    } finally {
      setSearchLoadingInput(false);
    }
  };

  return (
    <div>
      <h2 className="mt-4 text-lg text-[#9B9B9B]">Search customers</h2>
      <div className="flex justify-between items-center">
        <form className="flex items-center gap-y-10 mt-8">
          <label className="text-lg text-[#9B9B9B] text-nowrap">
            By name or email
          </label>
          <div className="relative w-[280px] ml-9">
            <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
              <svg
                className="w-4 h-4 text-gray-500 dark:text-gray-400"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 20 20"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                />
              </svg>
            </div>
            <input
              type="search"
              id="default-search"
              className="block border-[#F1F1F2] border w-full p-3 ps-10 text-sm text-black rounded-lg"
              placeholder="Search Customers..."
              onChange={(e) => setSearchField(e.target.value)}
            />
          </div>
          {searchLoadingInput ? (
            <BtnLoader />
          ) : (
            <button
              type="button"
              className={`text-[#FD7657] ${
                !searchField && "opacity-20"
              } mx-2 bg-[#FD76571A] w-32 h-[38px] rounded-lg text-sm`}
              onClick={handleSearch}
              disabled={!searchField}
            >
              Search
            </button>
          )}
        </form>
      </div>
      <div className="relative text-left flex items-center">
        <label className="mr-10 text-lg text-nowrap text-[#9B9B9B]">
          By visited dates
        </label>
        <div className="my-5 mx-2">
          <DatePicker
            selected={startDate || new Date()}
            onChange={onChangeStartDate}
            customInput={<StartDate />}
            popperClassName="custom-datepicker-popper"
          />
        </div>
        <div className="my-5 mx-2">
          <DatePicker
            selected={endDate || new Date()}
            onChange={onChangeEndtDate}
            customInput={<EndDate />}
            popperClassName="custom-datepicker-popper"
          />
        </div>

        <div>
          {searchLoading ? (
            <BtnLoader />
          ) : (
            <button
              className={`text-white ${
                !(startDate && endDate) && "opacity-20"
              } mx-2 bg-orange w-32 h-[38px] rounded-lg text-sm mt-4`}
              onClick={getPromotions}
              disabled={!(startDate && endDate)}
            >
              Search
            </button>
          )}
          {isButtonEnabled && (
            <button
              className="text-white bg-orange w-32 h-[38px] rounded-lg text-sm mt-4"
              onClick={() => setShowCreateModal(true)}
            >
              Add Promotion
            </button>
          )}
        </div>
      </div>
      {isSearchedButton ? (
        <>
          {data.length === 0 ? (
            <div className="absolute top-[50%] left-[45%]">
              <p className="text-[#9B9B9B]">No customers available.</p>
            </div>
          ) : (
            <div
              className={"w-full pb-3 flex justify-center rounded-xl bg-white"}
            >
              <table className="w-full text-left">
                <thead>
                  <tr className="p-20 text-[#9B9B9B] text-xs border-b border-borderGray">
                    <th className="px-2 py-5">
                      <input
                        type="checkbox"
                        checked={isAllSelected}
                        onClick={selectAll}
                        className="w-5 h-5"
                      />
                    </th>
                    <th className="px-2 py-5 pl-5">
                      <div className="flex items-center">
                        NAME
                        <span className="ml-2 cursor-pointer">
                          <div onClick={() => sortData("customerName", "asc")}>
                            <ArrowUpIcon
                              color={
                                sortConfig.key === "customerName" &&
                                sortConfig.order === "asc"
                                  ? "#FD7657"
                                  : "#9B9B9B"
                              }
                            />
                          </div>
                          <div
                            className="mt-1"
                            onClick={() => sortData("customerName", "desc")}
                          >
                            <ArrowDownIcon
                              color={
                                sortConfig.key === "customerName" &&
                                sortConfig.order === "desc"
                                  ? "#FD7657"
                                  : "#9B9B9B"
                              }
                            />
                          </div>
                        </span>
                      </div>
                    </th>
                    <th className="px-2 py-5">
                      <div className="flex items-center">
                        EMAIL
                        <span className="ml-2 cursor-pointer">
                          <div onClick={() => sortData("customerEmail", "asc")}>
                            <ArrowUpIcon
                              color={
                                sortConfig.key === "customerEmail" &&
                                sortConfig.order === "asc"
                                  ? "#FD7657"
                                  : "#9B9B9B"
                              }
                            />
                          </div>
                          <div
                            className="mt-1"
                            onClick={() => sortData("customerEmail", "desc")}
                          >
                            <ArrowDownIcon
                              color={
                                sortConfig.key === "customerEmail" &&
                                sortConfig.order === "desc"
                                  ? "#FD7657"
                                  : "#9B9B9B"
                              }
                            />
                          </div>
                        </span>
                      </div>
                    </th>
                    <th className="px-2 py-5">
                      <div className="flex items-center">
                        VISIT TIMES
                        <span className="ml-2 cursor-pointer">
                          <div onClick={() => sortData("visitTimes", "asc")}>
                            <ArrowUpIcon
                              color={
                                sortConfig.key === "visitTimes" &&
                                sortConfig.order === "asc"
                                  ? "#FD7657"
                                  : "#9B9B9B"
                              }
                            />
                          </div>
                          <div
                            className="mt-1"
                            onClick={() => sortData("visitTimes", "desc")}
                          >
                            <ArrowDownIcon
                              color={
                                sortConfig.key === "visitTimes" &&
                                sortConfig.order === "desc"
                                  ? "#FD7657"
                                  : "#9B9B9B"
                              }
                            />
                          </div>
                        </span>
                      </div>
                    </th>
                    <th className="px-2 py-5">
                      <div className="flex items-center">
                        LAST TIME VISITED
                        <span className="ml-2 cursor-pointer">
                          <div onClick={() => sortData("lastVisitTime", "asc")}>
                            <ArrowUpIcon
                              color={
                                sortConfig.key === "lastVisitTime" &&
                                sortConfig.order === "asc"
                                  ? "#FD7657"
                                  : "#9B9B9B"
                              }
                            />
                          </div>
                          <div
                            className="mt-1"
                            onClick={() => sortData("lastVisitTime", "desc")}
                          >
                            <ArrowDownIcon
                              color={
                                sortConfig.key === "lastVisitTime" &&
                                sortConfig.order === "desc"
                                  ? "#FD7657"
                                  : "#9B9B9B"
                              }
                            />
                          </div>
                        </span>
                      </div>
                    </th>
                  </tr>
                </thead>

                <tbody>
                  {data?.map((promotion: any, index: any) => {
                    const isOdd = index % 2 !== 0;
                    return (
                      <tr
                        key={index}
                        className={`${
                          data?.length !== index + 1 && "border-b"
                        } p-20 text-[#252525] text-sm font-normal border-[#EBF5FF] ${
                          isOdd ? "bg-[#FCFCFC]" : ""
                        }`}
                      >
                        <div className="px-2 py-5">
                          <input
                            checked={promotion?.isSelected}
                            onClick={() => handleSelection(index)}
                            type="checkbox"
                            className="w-5 h-5"
                          />
                        </div>
                        <td className="px-2 py-5 pl-5">
                          {promotion.customerName}
                        </td>
                        <td className="px-2 py-5">{promotion.customerEmail}</td>
                        <td className="px-2 py-5">{promotion.visitTimes}</td>
                        <td className="px-2 py-5">
                          {formatDateTime(promotion.lastVisitTime)}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          )}
          {showCreateModal && (
            <CreatePromotionModal
              onClose={() => setShowCreateModal(false)}
              isShowEmailInput={false}
              selectedEmails={data
                .filter((customer: any) => customer.isSelected)
                .map((item: any) => item?.customerEmail)}
            />
          )}
        </>
      ) : (
        <div className="absolute top-[50%] left-[30%] text-[#9B9B9B]">
          <p>Please select date From & To to send promotions!</p>
        </div>
      )}
    </div>
  );
};

export default ExistingCustomerPromotions;
