import React from "react";
import SearchForm from "src/components/Searchform/SearchFrom";
import SortIcon from "../../assests/images/dashboard/swap_vert.png";
import FilterIcon from "../../assests/images/dashboard/filter_list.png";

const orders = [
  {
    order: "DD773536",
    pickup: "56151",
    customer: "Bessie C.",
    combo: "Nourish Combo",
    item: "Sushi, Cola, Ramen",
    price: "$17.84",
    description: "Allergic to mushrooms...",
    status: "Error",
  },
  {
    order: "DD771583",
    pickup: "87712",
    customer: "Cameron W.",
    combo: "Sizzle",
    item: "Steaks, Burgers",
    price: "$11.70",
    description: "Please do not include...",
    status: "Completed",
  },
  {
    order: "DD781478",
    pickup: "79841",
    customer: "Wade W.",
    combo: "Big Mac",
    item: "Chicken Alfredo",
    price: "$8.99",
    description: "Do not add fish and oni...",
    status: "Pending",
  },
  {
    order: "DD780452",
    pickup: "88352",
    customer: "Kristin W.",
    combo: "Desserts",
    item: "Sushi, Cola, Ramen",
    price: "$8.99",
    description: "Kindly omit gluten and...",
    status: "Pending",
  },
  {
    order: "DD771583",
    pickup: "64772",
    customer: "Theresa W.",
    combo: "Sides",
    item: "Burger, Lemonade",
    price: "$5.22",
    description: "Do not add fish and oni...",
    status: "Pending",
  },
  {
    order: "DD772493",
    pickup: "92281",
    customer: "Floyd M.",
    combo: "Main Courses",
    item: "Sushi, Cobb, Ramen",
    price: "$6.48",
    description: "Please avoid adding po...",
    status: "Completed",
  },
  {
    order: "DD778679",
    pickup: "70312",
    customer: "Jane C.",
    combo: "Nourish",
    item: "Burger, Lemonade",
    price: "$6.48",
    description: "No broccoli and anchov...",
    status: "Error",
  },
  {
    order: "DD777582",
    pickup: "81625",
    customer: "Kathryn M.",
    combo: "Sides",
    item: "Garden, Cola, Ramen",
    price: "$14.81",
    description: "Kindly do not include c...",
    status: "Completed",
  },
];

const RecentOrders = () => {
  return (
    <div>
      <div className="flex justify-between items-center">
        <div>
          <SearchForm placeholder="Search by name..."/>
        </div>
        <div>
          <div className="flex justify-center gap-x-2 items-center my-4">
            <button className="flex items-center justify-center gap-x-2 text-sm hover:bg-white hover:text-orange duration-500 w-[150px] text-black h-[42px] rounded-xl border border-[#25252514]">
              <img className="w-[20px] h-[20px]" src={SortIcon} alt="" />
              Sort By
            </button>
            <button className="flex items-center justify-center gap-x-2 text-sm hover:bg-white hover:text-orange duration-500 w-[150px] text-black h-[42px] rounded-xl border border-[#25252514]">
              <img className="w-[24px] h-[24px]" src={FilterIcon} alt="" />
              Filter
            </button>
          </div>
        </div>
      </div>

      <div className="rounded-2xl bg-white pt-6 border-[#F1F1F2]">
        <table className="min-w-full">
          <thead>
            <tr className="bg-white">
              <th className=" text-sm text-[#9B9B9B] py-4 px-4 border-b border-[#F1F1F2]">
                ORDER
              </th>
              <th className=" text-sm text-[#9B9B9B] py-4 px-4 border-b border-[#F1F1F2]">
                PICKUP
              </th>
              <th className=" text-sm text-[#9B9B9B] py-4 px-4 border-b border-[#F1F1F2]">
                CUSTOMER
              </th>
              <th className=" text-sm text-[#9B9B9B] py-4 px-4 border-b border-[#F1F1F2]">
                COMBO
              </th>
              <th className=" text-sm text-[#9B9B9B] py-4 px-4 border-b border-[#F1F1F2]">
                ITEM
              </th>
              <th className=" text-sm text-[#9B9B9B] py-4 px-4 border-b border-[#F1F1F2]">
                PRICE
              </th>
              <th className=" text-sm text-[#9B9B9B] py-4 px-4 border-b border-[#F1F1F2]">
                DESCRIPTION
              </th>
              <th className=" text-sm text-[#9B9B9B] py-4 px-4 border-b border-[#F1F1F2]">
                STATUS
              </th>
            </tr>
          </thead>
          <tbody>
            {orders.map((order, index) => (
              <tr key={index} className="text-center">
                <td className=" text-sm py-6 px-6 border-b border-[#F1F1F2]">
                  {order.order}
                </td>
                <td className=" text-sm py-6 px-6 border-b border-[#F1F1F2]">
                  {order.pickup}
                </td>
                <td className=" text-sm py-6 px-6 border-b border-[#F1F1F2]">
                  {order.customer}
                </td>
                <td className=" text-sm py-6 px-6 border-b border-[#F1F1F2]">
                  {order.combo}
                </td>
                <td className=" text-sm py-6 px-6 border-b border-[#F1F1F2]">
                  {order.item}
                </td>
                <td className=" text-sm py-6 px-6 border-b border-[#F1F1F2]">
                  {order.price}
                </td>
                <td className=" text-sm py-6 px-6 border-b border-[#F1F1F2]">
                  {order.description}
                </td>
                <td
                  className={` text-sm py-6 px-6 border-b border-[#F1F1F2] ${getStatusStyle(
                    order.status
                  )}`}
                >
                  {order.status}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

const getStatusStyle = (status: any) => {
  switch (status) {
    case "Completed":
      return "text-[#3ACC48]";
    case "Pending":
      return "text-[#EAB42A]";
    case "Error":
      return "text-[#F14445]";
    default:
      return "";
  }
};

export default RecentOrders;
