export const getItemOptionElementPrice = (item: any) => {
  let elementPrice = 0;
  
  if (item?.itemOptionElements) {
    const elements = item.itemOptionElements.split(",");
    
    for (let element of elements) {
      const [price, quantity] = element.split("::");
      elementPrice += (Number(price) || 0) * (Number(quantity) || 0);
    }
  }
  return (Number(item?.price || 0) + elementPrice) * (Number(item?.quantity || 0));
};

export const itemsTotalPriceCalc = (items: any) =>
  items?.reduce((acc: any, item: any) => {
    const getTotalPrice = getItemOptionElementPrice(item);
    return acc + +(getTotalPrice || 0);
  }, 0);

export const itemTotalTaxCalc = (items: any) =>
  items?.reduce((acc: any, item: any) => {
    const getTotalPrice = getItemOptionElementPrice(item);
    return acc + +(getTotalPrice || 0) * +(item?.taxRate || 0);
  }, 0);
