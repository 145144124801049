import React from "react";

import MenuItems from "../../../assests/images/dashboard/toc.png";

export const OrderCategoryHeader = ({
  data,
  isCategoryFilterEnabled,
  searchQuery,
  focusCategory,
  handleCategory,
  selectedCategoryName,
}: any) => {
  return (
    <div className="h-[50px] overflow-x-scroll mb-4 shadow-md flex items-center bg-white rounded-3xl px-6 border border-[#F1F1F2]">
      <div className="flex gap-x-4 items-center">
        <div>
          <img src={MenuItems} alt="" />
        </div>
        {data?.map((item: any, index: number) => {
          return isCategoryFilterEnabled || item.isMatch ? (
            <div
              key={item.correlationId}
              onClick={() =>
                !!searchQuery
                  ? focusCategory(item.correlationId)
                  : handleCategory(item.correlationId)
              }
              className={`${
                selectedCategoryName === item.name &&
                "border-b-orange border-b-2 rounded-b-sm"
              } pb-[11px]`}
            >
              <h1 className="text-sm text-[#828282] mt-[15px] cursor-pointer">
                {item?.name}
              </h1>
            </div>
          ) : null;
        })}
      </div>
    </div>
  );
};

