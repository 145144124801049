import React, { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import SidebarLinkGroup from "./SidebarLinkGroup";
import ToggleIcon from "../../assests/images/dashboard/left_panel_close.png";
import ToggleIcon2 from "../../assests/images/dashboard/left_panel_close 2.png";
import Logo from "../../components/common/Logo";
import { useAuth } from "src/contexts/auth-provider";
import { ownerSidebar, restaurantSidebar } from "./sidebarRoutes";
import SignOutIcon from "../../assests/images/dashboard/logout.png";
import HelpIcon from "../../assests/images/dashboard/help.png";
import UserIcon from "../../assests/images/EmployeeOrder/download (2) 2.jpeg";
import SidebarItem from "./SidebarItem";
import {
  FRONTEND_URL,
  GRAPHQL_ENDPOINT,
  S3_BASE_URL,
} from "src/constants/endpoints";
import RestuarantImg from "../../assests/images/dashboard/test.png";
import { useDispatch, useSelector } from "react-redux";
import {
  set_categories,
  set_category_loding,
} from "src/store/features/category-slice";
import { GET_CATEGORIES } from "src/constants/queries";
import { useAxiosInterceptor } from "src/hooks/useAxiosInterceptor";
import { roleBasesRoute } from "src/utils/roleBasesRoute";

interface SidebarProps {
  sidebarOpen: boolean;
  setSidebarOpen: (arg: boolean) => void;
  setPageTitle: any;
}

const Sidebar = ({
  sidebarOpen,
  setSidebarOpen,
  setPageTitle,
}: SidebarProps) => {
  const [sidebarRoutes, setSidebarRoutes]: any = useState([]);
  const [isCategoryLoader, setIsCategoryLoader]: any = useState(false);
  const dispatch = useDispatch();
  const location = useLocation();
  const {
    hasRestaurant,
    hasEmployee,
    sethasRestaurant,
    setHasEmployee,
    permissions,
  } = useAuth();
  const { pathname } = location;
  const { axBe } = useAxiosInterceptor();
  const { categories } = useSelector((state: any) => state.category);

  const getCategories = useCallback(async () => {
    dispatch(set_category_loding(true));
    try {
      const { data } = await axBe.post(GRAPHQL_ENDPOINT, {
        query: GET_CATEGORIES,
        variables: {
          storeCorrelationId: hasRestaurant?.correlationId,
        },
      });
      dispatch(
        set_categories({
          categories: data?.data?.categoriesByStoreCorrelationId || [],
        })
      );
      setTimeout(() => {
        setIsCategoryLoader(false);
        dispatch(set_category_loding(false));
      }, 500);
    } catch (error) {
      console.error(error);
    }
  }, [axBe, dispatch, hasRestaurant?.correlationId]);

  useEffect(() => {
    setSidebarRoutes(
      !hasRestaurant
        ? ownerSidebar
        : roleBasesRoute(restaurantSidebar, permissions)
    );
    if (hasRestaurant && !isCategoryLoader) {
      setIsCategoryLoader(true);
      getCategories();
    }
    setSidebarRoutes(
      !hasRestaurant
        ? ownerSidebar
        : roleBasesRoute(restaurantSidebar, permissions)
    );
    if (hasRestaurant && !isCategoryLoader) {
      setIsCategoryLoader(true);
      getCategories();
    }
  }, [hasRestaurant, hasEmployee]);

  useEffect(() => {
    if (hasRestaurant && categories?.length) {
      const tempRotes = [...sidebarRoutes];
      const getMenuIndex = tempRotes?.findIndex(
        (route: any) => route?.name === "Menu"
      );
      if (getMenuIndex >= 0) {
        if (window.location.pathname.includes("/menu")) {
          tempRotes[getMenuIndex].isSubMenuOpen = true;
        }
        tempRotes[getMenuIndex].children = categories.map((category: any) => ({
          url: `/my-restaurant/${hasRestaurant?.correlationId}/menu/${category?.correlationId}?name=${category?.name}`,
          name: category?.name,
        }));
        setSidebarRoutes(tempRotes);
      }
    }
  }, [hasRestaurant, categories?.length]);

  const toggleMenu = (index: number) => {
    const tempRoutes = [...sidebarRoutes];
    if (tempRoutes?.[index]) {
      tempRoutes[index].isSubMenuOpen = !tempRoutes[index]?.isSubMenuOpen;
    }
    setSidebarRoutes([...tempRoutes]);
  };

  const changeModeToAdmin = () => {
    localStorage.removeItem("restaurant");
    localStorage.removeItem("employee");
    sethasRestaurant(null);
    setHasEmployee(null);
    window.location.href = FRONTEND_URL + "/dashboard";
  };

  const changeModeToRestaurant = () => {
    localStorage.removeItem("employee");
    setHasEmployee(null);
    if (!hasRestaurant?.correlationId) {
      window.location.href = FRONTEND_URL + "/dashboard";
      return;
    }
    window.location.href = `${FRONTEND_URL}/my-restaurant/${hasRestaurant?.correlationId}/employee`;
  };

  return (
    <div
      className={`border bg-white border-[#F1F1F2] left-0 top-0 z-10 flex h-screen ${
        sidebarOpen ? "w-[275px]" : "w-[90px]"
      } flex-col overflow-y-hidden  duration-500 ease-linear dark:bg-boxdark static translate-x-0 ${
        sidebarOpen ? "translate-x-0" : "-translate-x-full"
      }`}
      style={{ position: "static", translate: 0 }}
    >
      <div className="flex items-center justify-between gap-2 p-4 h-[80px] border-b-[#F1F1F2] border-b ">
        <div className="flex items-center justify-center gap-2">
          <Logo />
          {sidebarOpen && <h1 className="text-3xl font-medium">EatsBits</h1>}
        </div>
        <button onClick={() => setSidebarOpen(!sidebarOpen)}>
          <img
            className="h-[18px] w-[18px]"
            src={sidebarOpen ? ToggleIcon : ToggleIcon2}
            alt=""
          />
        </button>
      </div>
      {hasRestaurant && !hasEmployee && (
        <div className="flex items-center justify-center py-3 border-b border-[#EBF5FF] px-2">
          {sidebarOpen && (
            <div className="flex items-center">
              <img
                className="h-[36px] w-[36px] rounded-full mr-2"
                src={
                  hasRestaurant?.bucketKeyName
                    ? hasRestaurant?.bucketKeyName.includes("blob")
                      ? hasRestaurant?.bucketKeyName
                      : S3_BASE_URL + hasRestaurant?.bucketKeyName
                    : RestuarantImg
                }
                alt="restaurant img"
              />
              <div className="w-[180px]">
                <h1 className="text-xs">{hasRestaurant?.name}</h1>
                <div className="tooltip">
                  <h1 className="text-xs tooltip-section text-[#666666]">
                    {hasRestaurant?.address?.addressLine1}
                  </h1>
                  <span className="tooltip-text">
                    {hasRestaurant?.address?.addressLine1}
                  </span>
                </div>
              </div>
            </div>
          )}
          <div className="cursor-pointer p-2" onClick={changeModeToAdmin}>
            <img className="h-[15px] w-[15px]" src={SignOutIcon} alt="" />
          </div>
        </div>
      )}
      {hasEmployee && (
        <div className="flex items-center justify-center py-3 border-b border-[#EBF5FF] px-2">
          {sidebarOpen && (
            <div className="flex items-center">
              <img
                className="h-[36px] w-[36px] rounded-full mr-2"
                src={
                  hasEmployee?.bucketKeyName
                    ? hasEmployee?.bucketKeyName.includes("blob")
                      ? hasEmployee?.bucketKeyName
                      : S3_BASE_URL + hasEmployee?.bucketKeyName
                    : UserIcon
                }
                alt=""
              />
              <div className="w-[180px]">
                <h1 className="text-xs">{hasEmployee?.employerName}</h1>
                <div className="tooltip">
                  <h1 className="text-xs tooltip-section text-[#666666]">
                    {hasEmployee?.email}
                  </h1>
                </div>
              </div>
            </div>
          )}
          <div className="cursor-pointer p-2" onClick={changeModeToRestaurant}>
            <img className="h-[15px] w-[15px]" src={SignOutIcon} alt="" />
          </div>
        </div>
      )}

      <div className="no-scrollbar flex flex-col overflow-y-auto duration-500 ease-linear">
        <nav className=" px-4 ">
          <div>
            <ul className="mb-20 flex flex-col gap-1.5">
              <SidebarLinkGroup
                activeCondition={
                  pathname === "/" || pathname.includes("dashboard")
                }
              >
                {(_handleClick) => (
                  <React.Fragment>
                    <div className={`translate transform`}>
                      <ul className="mt-4 flex flex-col gap-1">
                        {sidebarRoutes?.map((route: any, index: number) => (
                          <div key={index}>
                            {!route?.children?.length ? (
                              <SidebarItem
                                index={index}
                                route={route}
                                isSubRoute={false}
                                sidebarOpen={sidebarOpen}
                                setPageTitle={setPageTitle}
                              />
                            ) : null}
                            {route?.children?.length ? (
                              <>
                                {sidebarOpen && (
                                  <h1 className="text-[#B7B7B7] text-xs">
                                    {route?.section}
                                  </h1>
                                )}
                                <SidebarItem
                                  index={index}
                                  route={route}
                                  isSubRoute={false}
                                  sidebarOpen={sidebarOpen}
                                  hasDropdown={!!route?.children?.length}
                                  toggleMenu={toggleMenu}
                                  setPageTitle={setPageTitle}
                                  isSubMenuOpen={route?.isSubMenuOpen}
                                />
                                {route?.isSubMenuOpen &&
                                  sidebarOpen &&
                                  route?.children?.map(
                                    (subRoute: any, subIndex: number) => (
                                      <SidebarItem
                                        key={subIndex}
                                        index={subIndex}
                                        route={subRoute}
                                        isSubRoute={true}
                                        sidebarOpen={sidebarOpen}
                                        isSubMenuOpen={route?.isSubMenuOpen}
                                        setPageTitle={setPageTitle}
                                        toggleMenu={toggleMenu}
                                      />
                                    )
                                  )}
                              </>
                            ) : (
                              <></>
                            )}
                          </div>
                        ))}
                      </ul>
                    </div>
                  </React.Fragment>
                )}
              </SidebarLinkGroup>
            </ul>
          </div>
          <div className={`${!hasRestaurant && "fixed bottom-6"} py-3`}>
            {sidebarOpen && (
              <h1 className="text-sm text-[#B7B7B7]">Applications</h1>
            )}
            <div
              className={`flex items-center pb-5 gap-x-4 mt-6 ${
                sidebarOpen ? "ml-2" : "justify-center mt-6"
              }`}
            >
              <img className="w-[17px] h-[17px]" src={HelpIcon} alt="" />
              {sidebarOpen && (
                <h1 className="text-xs text-[#B7B7B7]">Help Center</h1>
              )}
            </div>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default Sidebar;
