import { useEffect, useState } from "react";
import { useAuth } from "src/contexts/auth-provider";
import { GET_CUSTOMER_ORDERS } from "src/constants/queries";
import { GRAPHQL_ENDPOINT } from "src/constants/endpoints";
import { useAxiosInterceptor } from "src/hooks/useAxiosInterceptor";
import { formatDateTime } from "src/utils/date";
import { BtnLoader } from "src/Loader";
import ArrowUpIcon from "src/icons/ArrowUPIcon";
import ArrowDownIcon from "src/icons/ArrowDownIcon";
import { OrdersDetails } from "src/components/OrdersDetails/OrdersDetails";
import { UPDATE_CUSTOMER_ORDER } from "src/constants/mutations";

const Ordermanagement = () => {
  const { axBe } = useAxiosInterceptor();
  const { hasRestaurant } = useAuth();
  const [after, setAfter] = useState(null);
  const [showDetails, setShowDetails] = useState(false);
  const [orders, setOrders] = useState<any>([]);
  const [filterOrder, setFilterOrder] = useState<any>([]);
  const [pageInfo, setPageInfo]: any = useState(null);
  const [loading, setLoading] = useState(false);
  const [first] = useState(10);
  const [sortConfig, setSortConfig] = useState({ key: "", order: "" });
  const [selectedOrder, setSelectedOrder] = useState<any>(null);
  const [selectedOrderIndex, setSelectedOrderIndex] = useState<any>(null);

  const updateOrder = async (updatedOrder: any) => {
    try {
      const { data } = await axBe.post(GRAPHQL_ENDPOINT, {
        query: UPDATE_CUSTOMER_ORDER,
        variables: updatedOrder,
      });
      if (data?.data?.updateCustomerOrder) {
        setShowDetails(false);
        await getOrders(true);
      } else {
        throw new Error(data.error || "Failed to send invitation.");
      }
    } catch (error) {
      console.error("Error sending invitation:", error);
      setShowDetails(false);
    }
  };

  useEffect(() => {
    getOrders();
  }, [after, hasRestaurant]);

  const getOrders = async (isComingToUpdate: boolean = false) => {
    setLoading(true);
    try {
      const { data } = await axBe.post(GRAPHQL_ENDPOINT, {
        query: GET_CUSTOMER_ORDERS,
        variables: {
          storeCorrelationId: hasRestaurant?.correlationId,
          first,
          after,
        },
      });
      setOrders((prev: any) => [
        ...prev,
        ...(data?.data?.customerOrdersByStoreCorrelationId?.edges || []),
      ]);
      if (isComingToUpdate){
        setFilterOrder(data?.data?.customerOrdersByStoreCorrelationId?.edges || []);
      }
      else{
        setFilterOrder((prev: any) => [
          ...prev,
          ...(data?.data?.customerOrdersByStoreCorrelationId?.edges || []),
        ]);
      }
      setPageInfo(
        data?.data?.customerOrdersByStoreCorrelationId?.pageInfo || null
      );
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const searchOrders = (search: string) => {
    setFilterOrder(
      (orders || [])?.filter(
        (order: any) =>
          order?.node?.customerFirstName
            ?.toLowerCase()
            .includes(search.toLowerCase()) ||
          order?.node?.customerEmail
            ?.toLowerCase()
            .includes(search.toLowerCase())
      )
    );
  };

  const sortData = (key: any, order = "asc") => {
    const sortedData = [...filterOrder].sort((a: any, b: any) => {
      let comparison = 0;
      setSortConfig({ key, order });
      if (typeof a.node[key] === "string" && typeof b.node[key] === "string") {
        comparison = a.node[key].localeCompare(b.node[key]);
      } else {
        comparison = a.node[key] - b.node[key];
      }
      return order === "desc" ? -comparison : comparison;
    });
    setFilterOrder(sortedData);
  };

  const manageOrderCardDetail = (type: string) => {
    if (
      filterOrder.length === 0 ||
      (type === "increment" && selectedOrderIndex === filterOrder.length - 1) ||
      (type === "decrement" && selectedOrderIndex === 0)
    ) {
      return;
    }
    const data = [...filterOrder];
    if (type === "increment") {
      setSelectedOrder(data[selectedOrderIndex + 1].node);
      setSelectedOrderIndex(selectedOrderIndex + 1);
    } else {
      setSelectedOrder(data[selectedOrderIndex - 1].node);
      setSelectedOrderIndex(selectedOrderIndex - 1);
    }
  };

  const handleRowClick = (order: any, index: number) => {
    setSelectedOrderIndex(index);
    setSelectedOrder(order);
    setShowDetails(true);
  };

  return (
    <div>
      <div className="flex justify-between items-center">
        <div>
          <form className="w-[280px]">
            <div className="relative">
              <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                <svg
                  className="w-4 h-4 text-gray-500 dark:text-gray-400"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 20 20"
                >
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                  />
                </svg>
              </div>
              <input
                type="search"
                id="default-search"
                className="block border-[#F1F1F2] border w-full p-3 ps-10 text-sm text-black rounded-lg"
                placeholder="Search Order..."
                onChange={(e) => searchOrders(e.target.value)}
              />
            </div>
          </form>
        </div>
      </div>
      {loading && !pageInfo ? (
        <div className="absolute top-[50%] right-[50%]">
          <BtnLoader />
        </div>
      ) : (
        <>
          {(filterOrder || [])?.length === 0 ? (
            <p className="text-[#9B9B9B] absolute top-[50%] right-[50%]">
              No Order found
            </p>
          ) : (
            <>
              <div className="w-full bg-white flex justify-center rounded-xl mt-4">
                <table className="w-full text-left">
                  <thead>
                    <tr className="p-20 text-[#9B9B9B] text-xs border-b border-borderGray">
                      <th className="px-2 py-5 pl-5">
                        <div className="flex items-center">
                          ORDER ID
                          <span className="ml-2 cursor-pointer">
                            <div
                              onClick={() => sortData("correlationId", "asc")}
                            >
                              <ArrowUpIcon
                                color={
                                  sortConfig.key === "correlationId" &&
                                  sortConfig.order === "asc"
                                    ? "#FD7657"
                                    : "#9B9B9B"
                                }
                              />
                            </div>
                            <div
                              className="mt-1"
                              onClick={() => sortData("correlationId", "desc")}
                            >
                              <ArrowDownIcon
                                color={
                                  sortConfig.key === "correlationId" &&
                                  sortConfig.order === "desc"
                                    ? "#FD7657"
                                    : "#9B9B9B"
                                }
                              />
                            </div>
                          </span>
                        </div>
                      </th>
                      <th className="px-2 py-5 pl-5">
                        <div className="flex items-center">
                          NAME
                          <span className="ml-2 cursor-pointer">
                            <div
                              onClick={() =>
                                sortData("customerFirstName", "asc")
                              }
                            >
                              <ArrowUpIcon
                                color={
                                  sortConfig.key === "customerFirstName" &&
                                  sortConfig.order === "asc"
                                    ? "#FD7657"
                                    : "#9B9B9B"
                                }
                              />
                            </div>
                            <div
                              className="mt-1"
                              onClick={() =>
                                sortData("customerFirstName", "desc")
                              }
                            >
                              <ArrowDownIcon
                                color={
                                  sortConfig.key === "customerFirstName" &&
                                  sortConfig.order === "desc"
                                    ? "#FD7657"
                                    : "#9B9B9B"
                                }
                              />
                            </div>
                          </span>
                        </div>
                      </th>
                      <th className="px-2 py-5 pl-5">
                        <div className="flex items-center">
                          EMAIL
                          <span className="ml-2 cursor-pointer">
                            <div
                              onClick={() => sortData("customerEmail", "asc")}
                            >
                              <ArrowUpIcon
                                color={
                                  sortConfig.key === "customerEmail" &&
                                  sortConfig.order === "asc"
                                    ? "#FD7657"
                                    : "#9B9B9B"
                                }
                              />
                            </div>
                            <div
                              className="mt-1"
                              onClick={() => sortData("customerEmail", "desc")}
                            >
                              <ArrowDownIcon
                                color={
                                  sortConfig.key === "customerEmail" &&
                                  sortConfig.order === "desc"
                                    ? "#FD7657"
                                    : "#9B9B9B"
                                }
                              />
                            </div>
                          </span>
                        </div>
                      </th>
                      <th className="px-2 py-5 pl-5">
                        <div className="flex items-center">
                          CREATED AT
                          <span className="ml-2 cursor-pointer">
                            <div onClick={() => sortData("createdAt", "asc")}>
                              <ArrowUpIcon
                                color={
                                  sortConfig.key === "createdAt" &&
                                  sortConfig.order === "asc"
                                    ? "#FD7657"
                                    : "#9B9B9B"
                                }
                              />
                            </div>
                            <div
                              className="mt-1"
                              onClick={() => sortData("createdAt", "desc")}
                            >
                              <ArrowDownIcon
                                color={
                                  sortConfig.key === "createdAt" &&
                                  sortConfig.order === "desc"
                                    ? "#FD7657"
                                    : "#9B9B9B"
                                }
                              />
                            </div>
                          </span>
                        </div>
                      </th>
                      <th className="px-2 py-5 pl-5">
                        <div className="flex items-center">
                          IS GUEST
                          <span className="ml-2 cursor-pointer">
                            <div onClick={() => sortData("isGuest", "asc")}>
                              <ArrowUpIcon
                                color={
                                  sortConfig.key === "isGuest" &&
                                  sortConfig.order === "asc"
                                    ? "#FD7657"
                                    : "#9B9B9B"
                                }
                              />
                            </div>
                            <div
                              className="mt-1"
                              onClick={() => sortData("isGuest", "desc")}
                            >
                              <ArrowDownIcon
                                color={
                                  sortConfig.key === "isGuest" &&
                                  sortConfig.order === "desc"
                                    ? "#FD7657"
                                    : "#9B9B9B"
                                }
                              />
                            </div>
                          </span>
                        </div>
                      </th>
                      <th className="px-2 py-5 pl-5">
                        <div className="flex items-center">
                          PRICE
                          <span className="ml-2 cursor-pointer">
                            <div onClick={() => sortData("totalPrice", "asc")}>
                              <ArrowUpIcon
                                color={
                                  sortConfig.key === "totalPrice" &&
                                  sortConfig.order === "asc"
                                    ? "#FD7657"
                                    : "#9B9B9B"
                                }
                              />
                            </div>
                            <div
                              className="mt-1"
                              onClick={() => sortData("totalPrice", "desc")}
                            >
                              <ArrowDownIcon
                                color={
                                  sortConfig.key === "totalPrice" &&
                                  sortConfig.order === "desc"
                                    ? "#FD7657"
                                    : "#9B9B9B"
                                }
                              />
                            </div>
                          </span>
                        </div>
                      </th>
                      {/* <th className="px-2 py-5 pl-5">
                        <div className="flex items-center">
                          CUSTOMER NODE
                          <span className="ml-2 cursor-pointer">
                            <div
                              onClick={() => sortData("customerNote", "asc")}
                            >
                              <ArrowUpIcon
                                color={
                                  sortConfig.key === "customerNote" &&
                                  sortConfig.order === "asc"
                                    ? "#FD7657"
                                    : "#9B9B9B"
                                }
                              />
                            </div>
                            <div
                              className="mt-1"
                              onClick={() => sortData("customerNote", "desc")}
                            >
                              <ArrowDownIcon
                                color={
                                  sortConfig.key === "customerNote" &&
                                  sortConfig.order === "desc"
                                    ? "#FD7657"
                                    : "#9B9B9B"
                                }
                              />
                            </div>
                          </span>
                        </div>
                      </th> */}
                      {/* <th className="px-2 py-5 pl-5">
                        <div className="flex items-center">
                          PAID
                          <span className="ml-2 cursor-pointer">
                            <div onClick={() => sortData("paid", "asc")}>
                              <ArrowUpIcon
                                color={
                                  sortConfig.key === "paid" &&
                                  sortConfig.order === "asc"
                                    ? "#FD7657"
                                    : "#9B9B9B"
                                }
                              />
                            </div>
                            <div
                              className="mt-1"
                              onClick={() => sortData("paid", "desc")}
                            >
                              <ArrowDownIcon
                                color={
                                  sortConfig.key === "paid" &&
                                  sortConfig.order === "desc"
                                    ? "#FD7657"
                                    : "#9B9B9B"
                                }
                              />
                            </div>
                          </span>
                        </div>
                      </th> */}
                      <th className="px-2 py-5 pl-5">
                        <div className="flex items-center">
                          STATUS
                          <span className="ml-2 cursor-pointer">
                            <div onClick={() => sortData("orderStatus", "asc")}>
                              <ArrowUpIcon
                                color={
                                  sortConfig.key === "orderStatus" &&
                                  sortConfig.order === "asc"
                                    ? "#FD7657"
                                    : "#9B9B9B"
                                }
                              />
                            </div>
                            <div
                              className="mt-1"
                              onClick={() => sortData("orderStatus", "desc")}
                            >
                              <ArrowDownIcon
                                color={
                                  sortConfig.key === "orderStatus" &&
                                  sortConfig.order === "desc"
                                    ? "#FD7657"
                                    : "#9B9B9B"
                                }
                              />
                            </div>
                          </span>
                        </div>
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    {filterOrder?.map(({ node: val }: any, index: number) => {
                      const isOdd = index % 2 !== 0;
                      return (
                        <tr
                          key={index}
                          className={`${
                            filterOrder?.length !== index + 1 && "border-b"
                          } p-20 text-[#252525] cursor-pointer text-sm font-normal border-[#EBF5FF] ${
                            isOdd ? "bg-[#FCFCFC]" : ""
                          }`}
                          onClick={() => handleRowClick(val, index)}
                        >
                          <td className="px-2 py-5 pl-5">
                            {val.correlationId || "-"}
                          </td>
                          <td className="px-2 py-5 pl-5">
                            {val.customerFirstName || "-"}
                          </td>
                          <td className="px-2 py-5 pl-5">
                            {val.customerEmail || "-"}
                          </td>
                          <td className="px-2 py-5 pl-5">
                            {formatDateTime(val.createdAt) || "-"}
                          </td>
                          <td className="px-2 py-5 pl-5">
                            {val.isGuest === false
                              ? "Not Guest"
                              : "Guest" || "-"}
                          </td>
                          <td className="px-2 py-5 pl-5">
                            {`$${val.totalPrice}` || "-"}
                          </td>
                          <td className="px-2 py-5 pl-5">
                            <span
                              className={`${
                                val?.orderStatus?.toLowerCase() === "voided" &&
                                "bg-[#F144451A] text-[#F14445]"
                              } ${
                                val?.orderStatus?.toLowerCase() ===
                                  "completed" && "bg-[#3ACC481A] text-[#3ACC48]"
                              } ${
                                val?.orderStatus?.toLowerCase() === "pending" &&
                                "bg-[#3A51CC1A] text-[#3A51CC]"
                              } rounded-[8px] py-2 px-4 text-xs`}
                            >
                              {val?.orderStatus.charAt(0).toUpperCase() + val?.orderStatus.slice(1).toLowerCase() || "-"}
                            </span>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
              <div className="flex justify-center gap-x-2 mt-4">
                {orders?.length && pageInfo?.hasNextPage && (
                  <button
                    className={`h-[42px] px-6 text-sm text-[#FD7657] rounded-xl bg-[#FEF5F4] flex gap-x-2 items-center cursor-pointer`}
                    onClick={() => {
                      setAfter(pageInfo.endCursor);
                    }}
                    disabled={loading}
                  >
                    {loading ? "Loading..." : "Load More"}
                  </button>
                )}
              </div>
            </>
          )}
        </>
      )}
      {showDetails && (
        <OrdersDetails
          title={"Create Combo"}
          updateOrder={updateOrder}
          setShowCreateModal={() => setShowDetails(!showDetails)}
          orderDetails={selectedOrder}
          manageOrderCardDetail={manageOrderCardDetail}
        />
      )}
    </div>
  );
};

export default Ordermanagement;
