import ArrowBack from "../../assests/images/landing-page/arrow_back.png";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useMutation } from "@apollo/client";
import { RESET_PASSWORD } from "src/constants/mutations";
import { useEffect } from "react";
import Logo from "../../components/common/Logo";

export const ForgotPassword = () => {
  const navigate = useNavigate();
  const [resetPassword, { data, loading, error }] = useMutation(RESET_PASSWORD);

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Must be a valid email")
        .required("Email is required"),
    }),

    onSubmit: (value) => {
      const httpOrHttps = window.location.href.split("//")[0];
      resetPassword({
        variables: {
          resetPasswordInput: {
            email: value.email,
            hostName: httpOrHttps + "//" + window.location.host,
          },
        },
      });
    },
  });

  useEffect(() => {
    if (data && data.resetPassword.requestProcessed) {
      navigate("/password-reset-email-sent");
    }
  }, [data, navigate]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error : {error.message}</p>;

  return (
    <div className="flex justify-center flex-col items-center">
      <div className=" mt-[5%]">
        <div className="flex ">
          <Logo />
          <h1 className="ml-2 text-3xl">EatsBits</h1>
        </div>
      </div>
      <form onSubmit={formik.handleSubmit}>
        <div className=" max-sm:w-[95%] max-sm:ml-2 w-[420px] rounded-[12px] mt-[50%] p-6 border border-lightGrey">
          <h1 className="text-center text-2xl mt-4 font-semibold">
            Forgot Password ?
          </h1>
          <p className="text-sm text-[#9B9B9B] text-center mt-4">
            Please enter the email address you'd like your password reset
            information sent to.
          </p>

          <div className="mt-7">
            <div className="flex items-center">
              <input
                className="text-sm text-[[#9B9B9B]] h-[42px] w-[100%] border rounded-lg border-[#F1F1F2] mt-2 px-2"
                type="text"
                name="email"
                id="email"
                placeholder="Enter your email"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.email}
              />
              {formik.touched.email && formik.errors.email ? (
                <div className="text-orange text-xs mt-2 mx-1">
                  {formik.errors.email}
                </div>
              ) : null}
            </div>

            <div className="flex justify-center my-4">
              <button
                type="submit"
                disabled={!formik.isValid || !formik.dirty}
                className={`w-[100%] text-white h-[40px] rounded-xl bg-[#FD7657]
                ${
                  (!formik.isValid || !formik.dirty) &&
                  "opacity-[50%] pointer-events-none"
                }
              `}
              >
                Reset Password
              </button>
            </div>
          </div>
        </div>
      </form>

      <div className="flex justify-center items-center absolute bottom-10 cursor-pointer">
        <div className="mr-2">
          <img src={ArrowBack} alt="" />
        </div>
        <Link to="/login">
          <h1 className="text-orange">Back To Login</h1>
        </Link>
      </div>
    </div>
  );
};
