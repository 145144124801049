import { v4 as uuidv4 } from "uuid";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { successToast } from "src/utils/toasts";
import { useDispatch, useSelector } from "react-redux";
import { GRAPHQL_ENDPOINT } from "src/constants/endpoints";
import CartCard from "src/components/CartCard copy/CartCard";
import { CREATE_EMPLOYEE_ORDER } from "src/constants/mutations";
import { useAxiosInterceptor } from "src/hooks/useAxiosInterceptor";
import DeleteIcon from "../../assests/images/EmployeeOrder/Vector.png";
import {
  removeAddToCartOrder,
  removeAll,
  updateAddToCartOrders,
} from "src/store/features/carts-order";

export const EmployeeCart = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { axBe } = useAxiosInterceptor();
  const [allOrder, setAllOrder] = useState([]);
  const [filterAllOrder, setFilterAllOrder] = useState([]);
  const { addToCarts } = useSelector((state: any) => state.cartsOrder);

  useEffect(() => {
    setAllOrder(addToCarts);
    setFilterAllOrder(addToCarts);
  }, [addToCarts]);

  const clearAll = () => {
    dispatch(removeAll());
  };

  const handlePlaceOrder = async (item: any, index: number) => {
    const newUUID = uuidv4();
    let payload = { ...item };
    if (
      item?.customerFirstName.toLowerCase() === "guest" ||
      item?.customerLastName.toLowerCase() === "guest"
    ) {
      payload.isGuest = true;
      payload["appUserCorrelationId"] = newUUID;
    }

    const { data } = await axBe.post(GRAPHQL_ENDPOINT, {
      query: CREATE_EMPLOYEE_ORDER,
      variables: {
        createCustomerOrderInput: payload,
      },
    });
    if (data?.data?.createCustomerOrder?.correlationId) {
      dispatch(removeAddToCartOrder(index));
      navigate(`/active-order`);
      successToast("Order has been placed");
    }
  };

  const searchCarts = (search: string) => {
    setFilterAllOrder(
      (allOrder || [])?.filter(
        (cart: any) =>
          cart?.customerFirstName
            ?.toLowerCase()
            .includes(search.toLowerCase()) ||
          cart?.customerEmail?.toLowerCase().includes(search.toLowerCase())
      )
    );
  };

  const changeCardQuantity = (
    cardIndex: number,
    itemIndex: number,
    quantity: number
  ) => {
    const tempData = addToCarts.map((card: any) => ({
      ...card,
      items: card.items.map((item: any) => ({
        ...item,
        quantity: item.quantity,
      })),
    }));
    if (tempData[cardIndex] && tempData[cardIndex].items[itemIndex]) {
      tempData[cardIndex].items[itemIndex].quantity = quantity;
      dispatch(updateAddToCartOrders(tempData));
    } else {
      console.warn("Invalid cardIndex or itemIndex");
    }
  };


  return (
    <>
      <div className="">
        <div>
          <div className="flex justify-between items-center">
            <div className="flex items-center gap-x-3">
              <form className="w-[280px]">
                <div className="relative">
                  <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                    <svg
                      className="w-4 h-4 text-gray-500 dark:text-gray-400"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 20 20"
                    >
                      <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                      />
                    </svg>
                  </div>
                  <input
                    type="search"
                    id="default-search"
                    className="block border-[#F1F1F2] border w-full p-3 ps-10 text-sm text-black rounded-lg "
                    placeholder="Search Cart..."
                    onChange={(e) => searchCarts(e.target.value)}
                  />
                </div>
              </form>
            </div>
            <div className="flex gap-x-2 justify-center my-4">
              <button
                onClick={clearAll}
                className="flex items-center justify-evenly w-[125px] text-sm text-[#FD7657] h-[45px] rounded-xl bg-[#FD76571A]"
              >
                <img src={DeleteIcon} alt="" />
                Clean Cart
              </button>
            </div>
          </div>
          <div className="w-full flex gap-3">
            <div className="w-full">
              {filterAllOrder && filterAllOrder?.length > 0 ? (
                <div className="flex flex-wrap">
                  {filterAllOrder?.map((orderDetail: any, index: any) => (
                    <CartCard
                      index={index}
                      key={index}
                      orderDetail={orderDetail}
                      handlePlaceOrder={handlePlaceOrder}
                      changeCardQuantity={changeCardQuantity}
                    />
                  ))}
                </div>
              ) : (
                <div className="absolute top-[50%] right-[37%]">
                  <p>No Order found!</p>
                </div>
              )}
            </div>
          </div>

        </div>
      </div>
    </>
  );
};
