import React from "react";
import {
  RouterProvider,
  createBrowserRouter,
  Navigate,
  useLocation,
} from "react-router-dom";
import { useAuth } from "../contexts/auth-provider";
import routesForPublic from "../utils/public-routes";
import routesForAuthenticatedOnly, {
  routesForAuthenticatedEmployee,
} from "../utils/authenticated-routes";
import routesForNotAuthenticatedOnly from "../utils/nonAuthenticated-routes";
import PreviousLandingPage from "../pages/landing-page/previous-landing-page";
import { DefaultLayout } from "../layout/DefaultLayout";
import { Dashboard } from "src/pages/dashboard/dashboard";
import { DefaultEmployeeLayout } from "src/layout/DefaultEmployeeLayout";
import { EmployeeDashboard } from "src/pages/EmployeeDashboard/EmployeeDashboard";
import { roleBasesRoute } from "src/utils/roleBasesRoute";

const FallbackRoute = () => {
  const { token, user, userRole } = useAuth();
  const isAuthorized =
    !!token && userRole && Object.keys(user || {}).length > 0;
  const location = useLocation();

  if (!isAuthorized && location.pathname === "/login") {
    return <Navigate to="/login" replace />;
  }
  if (
    isAuthorized &&
    (location.pathname === "/login" || location.pathname === "/")
  ) {
    return (
      <Navigate
        to={
          userRole?.toLowerCase()?.includes("employee")
            ? "/active-order"
            : "/dashboard"
        }
        replace
      />
    );
  }
  return <Navigate to="/login" replace />;
};

const Routes: React.FC = () => {
  const { token, user, userRole, permissions } = useAuth();
  const isAuthorized =
    !!token && userRole && Object.keys(user || {}).length > 0;
  const publicRoute = [
    ...routesForPublic,
    {
      path: "/",
      element:
        isAuthorized && userRole?.toLowerCase()?.includes("employee") ? (
          <DefaultEmployeeLayout />
        ) : isAuthorized ? (
          <DefaultLayout />
        ) : (
          <PreviousLandingPage />
        ),
      children: [
        {
          path: "/",
          element:
            isAuthorized && userRole?.toLowerCase()?.includes("employee") ? (
              <EmployeeDashboard />
            ) : isAuthorized ? (
              <Dashboard />
            ) : (
              <PreviousLandingPage />
            ),
        },
      ],
    },
  ];

  // Combine and conditionally include routes based on authentication status
  const routes = [
    ...publicRoute,
    ...(isAuthorized && userRole?.toLowerCase()?.includes("employee")
      ? routesForAuthenticatedEmployee
      : isAuthorized
      ? roleBasesRoute(routesForAuthenticatedOnly, permissions)
      : routesForNotAuthenticatedOnly),
  ];

  // Create router with routes
  const router = createBrowserRouter([
    ...routes,
    {
      path: "*",
      element: <FallbackRoute />,
    },
  ]);

  return <RouterProvider router={router} />;
};

export default Routes;
