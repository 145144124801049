import closeIcon from "../../assests/images/dashboard/Vector 3.png";
import { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useAxiosInterceptor } from "src/hooks/useAxiosInterceptor";
import { useAuth } from "src/contexts/auth-provider";
import { GRAPHQL_ENDPOINT } from "src/constants/endpoints";
import { successToast } from "src/utils/toasts";
import { BtnLoader } from "src/Loader";
import {
  ADD_EMPLOYEE,
  UPDATE_EMPLOYEE,
} from "src/constants/mutations";
import eyeIconVisible from "../../assests/images/landing-page/eye-open.png";
import eyeIcon from "../../assests/images/landing-page/visibility_off.png";

const renderSchema = (editValues: boolean) => {
  return Yup.object().shape({
    firstName: Yup.string().required("Employer first name is required"),
    lastName: Yup.string().required("Employer last name is required"),
    email: Yup.string().email("Invalid email").required("Email Required"),
    phoneNumber: Yup.number().required("Phone number is required"),
    employerName: Yup.string().required("Employer name is required"),
    roles: Yup.string().required("Password is required"),
    password: editValues
      ? Yup.string()
      : Yup.string().required("Password is required"),
  });
};
export const CreateEmployeeModal = ({
  onClose,
  editValues,
  getEmployees,
  setEditValues,
}: any) => {
  const [passwordVisible, setPasswordVisible] = useState(false);
  const { hasRestaurant } = useAuth();
  const { axBe } = useAxiosInterceptor();
  const [loading, setLoading] = useState(false);

  const hosteName = window.location.hostname;

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      employerName: "",
      password: "",
      roles: [],
    },
    validationSchema: renderSchema(!!editValues?.correlationId),
    onSubmit: () => {},
  });

  const addInvite = async () => {
    setLoading(true);
    try {
      const variables: any = {
        createStoreEmployeeInput: {
          employerStoreCorrelationId: hasRestaurant.correlationId,
          firstName: formik.values.firstName,
          lastName: formik.values.lastName,
          email: formik.values.email,
          phoneNumber: formik.values.phoneNumber.toString(),
          employerName: formik.values.employerName,
          password: formik.values.password,
          hostName: hosteName,
          roles: [formik.values.roles],
        },
      };
      const { data } = await axBe.post(GRAPHQL_ENDPOINT, {
        query: ADD_EMPLOYEE,
        variables: variables,
      });
       if ( data?.data?.registerStoreEmployee?.correlationId ) {
        getEmployees();
        successToast("Invitation has been successfully sent!");
        onClose();
        setEditValues({});
      } else {
        throw new Error(data.error || "Failed to send invitation.");
      }
    } catch (error) {
      console.error("Error sending invitation:", error);
      setEditValues({});
    } finally {
      setLoading(false);
    }
  };

  const UpdateEmployee = async () => {
    setLoading(true);
    try {
      const payload: any = {
        employeeCorrelationId: editValues.correlationId,
        firstName: formik.values.firstName,
        lastName: formik.values.lastName,
        email: formik.values.email,
        phoneNumber: formik.values.phoneNumber.toString(),
        employerName: formik.values.employerName,
        password: formik.values.password,
        roles: [formik.values.roles],
      }
      if (!payload.password){
        delete payload.password
      }
      const variables: any = {
        updateStoreEmployeeInput: payload,
      };
    
      const { data } = await axBe.post(GRAPHQL_ENDPOINT, {
        query: UPDATE_EMPLOYEE,
        variables: variables,
      });
        if (data && data.data.updateStoreEmployee        ) {
        successToast("Employee has been Updated!");
        setEditValues({});
        getEmployees();
      } else {
        throw new Error(data.error || "Failed to send invitation.");
      }
    } catch (error) {
      console.error("Error sending invitation:", error);
      setEditValues({});
    } finally {
      setLoading(false);
      onClose();
    }
  };

  const {setFieldValue} = formik
  useEffect(() => {
    if (editValues?.correlationId) {
      setFieldValue("firstName", editValues?.firstName);
      setFieldValue("lastName", editValues?.lastName);
      setFieldValue("email", editValues?.email);
      setFieldValue("phoneNumber", editValues?.phoneNumber);
      setFieldValue("employerName", editValues?.employerName);
      setFieldValue("password", editValues?.password);
      setFieldValue(
        "roles",
        editValues?.roles ? editValues.roles[0] : []
      );
    }
  }, [editValues, setFieldValue]);

  const togglePassword = () => {
    setPasswordVisible(!passwordVisible);
  };

  const handleClose = () => {
    setEditValues({});
    onClose();
  };


  return (
    <div
      className="relative z-10"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div className="promo-modal fixed inset-0 flex bg-black/30 min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
        <div className="relative w-[45%] transform overflow-hidden rounded-lg bg-white text-left shadow-[0px_4px_0px_880px_rgba(37,37,37,0.75)] transition-all sm:my-8">
          <div className="bg-white rounded-lg w-[100%] relative p-6">
            <button
              onClick={handleClose}
              className="absolute h-[12px] w-[12px] top-6 right-6"
            >
              <img src={closeIcon} alt="Close" />
            </button>
            <h2 className="text-lg font-bold mb-4">
              {editValues?.correlationId
                ? "Edit Employee"
                : "Invite New Employer"}
            </h2>
            <div>
              <div className="mt-2">
                <label className="block text-xs font-medium text-[#9B9B9B] p-1">
                  Employee first Name <span>*</span>
                </label>
                <input
                  name="firstName"
                  type="test"
                  className="h-[40px] text-xs w-full border rounded-lg border-[#F1F1F2] mt-1 px-2"
                  placeholder="Enter first name"
                  value={formik.values.firstName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.firstName && formik.errors.firstName ? (
                  <div className="text-orange text-xs mt-2">
                    {formik.errors.firstName}
                  </div>
                ) : null}
              </div>
              <div className="mt-2">
                <label className="block text-xs font-medium text-[#9B9B9B] p-1">
                  Employee Last Name <span>*</span>
                </label>
                <input
                  name="lastName"
                  type="text"
                  className="h-[40px] text-xs w-full border rounded-lg border-[#F1F1F2] mt-1 px-2"
                  placeholder="Enter last name"
                  value={formik.values.lastName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.lastName && formik.errors.lastName ? (
                  <div className="text-orange text-xs mt-2">
                    {formik.errors.lastName}
                  </div>
                ) : null}
              </div>
              <div className="mt-2">
                <label className="block text-xs font-medium text-[#9B9B9B] p-1">
                  Email <span>*</span>
                </label>
                <input
                  name="email"
                  type="email"
                  className="h-[40px] text-xs w-full border rounded-lg border-[#F1F1F2] mt-1 px-2"
                  placeholder="Enter email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.email && formik.errors.email ? (
                  <div className="text-orange text-xs mt-2">
                    {formik.errors.email}
                  </div>
                ) : null}
              </div>
              <div className="mt-2">
                <label className="block text-xs font-medium text-[#9B9B9B] p-1">
                  Phone Number <span>*</span>
                </label>
                <input
                  name="phoneNumber"
                  type="number"
                  className="h-[40px] text-xs w-full border rounded-lg border-[#F1F1F2] mt-1 px-2"
                  placeholder="Enter phone number"
                  value={formik.values.phoneNumber}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.phoneNumber && formik.errors.phoneNumber ? (
                  <div className="text-orange text-xs mt-2">
                    {formik.errors.phoneNumber}
                  </div>
                ) : null}
              </div>
              <div className="mt-2">
                <label className="block text-xs font-medium text-[#9B9B9B] p-1">
                  Employer Name <span>*</span>
                </label>
                <input
                  name="employerName"
                  type="text"
                  className="h-[40px] text-xs w-full border rounded-lg border-[#F1F1F2] mt-1 px-2"
                  placeholder="Enter employer name"
                  value={formik.values.employerName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.employerName && formik.errors.employerName ? (
                  <div className="text-orange text-xs mt-2">
                    {formik.errors.employerName}
                  </div>
                ) : null}
              </div>
              <div className="mt-2 relative">
                  <label className="block text-xs font-medium text-[#9B9B9B] p-1">
                    Password <span>*</span>
                  </label>
                  <input
                    name="password"
                    type={passwordVisible ? "text" : "password"}
                    className="h-[40px] text-xs w-full border rounded-lg border-[#F1F1F2] mt-1 px-2"
                    placeholder="Enter password"
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  <img
                    onClick={togglePassword}
                    className="absolute right-[10px] top-[38px] cursor-pointer"
                    src={passwordVisible ? eyeIconVisible : eyeIcon}
                    alt="Toggle visibility"
                  />
                  {formik.touched.password && formik.errors.password ? (
                    <div className="text-orange text-xs mt-2">
                      {formik.errors.password}
                    </div>
                  ) : null}
                </div>
              <div className="mt-2">
                <label className="block text-xs text-[#9B9B9B] p-1">
                  Roles <span>*</span>
                </label>
                <select
                  name="roles"
                  className="text-[#] h-[40px] text-xs w-full border rounded-lg border-[#F1F1F2] mt-1 px-2"
                  value={formik.values.roles}
                  onChange={formik.handleChange}
                >
                  <option className="text-[]" value="">
                    Select Roles
                  </option>
                  <option className="text-[]" value="ROLE_EMPLOYEE_REGULAR">
                    Regular
                  </option>
                  <option className="text-[]" value="ROLE_EMPLOYEE_MANAGER">
                    Manager
                  </option>
                </select>
              </div>
              {formik.touched.roles && formik.errors.roles ? (
                <div className="text-orange text-xs mt-2">
                  {formik.errors.roles}
                </div>
              ) : null}
              <div className="mt-6 flex justify-end space-x-2">
                {loading ? (
                  <BtnLoader />
                ) : (
                  <button
                    className={`${
                      !formik.isValid && "opacity-20"
                    } text-white bg-orange text-sm w-24 h-[35px] rounded-lg`}
                    onClick={
                      editValues?.correlationId ? UpdateEmployee : addInvite
                    }
                    disabled={!formik.isValid}
                  >
                    {editValues?.correlationId ? "Update" : "Add invite"}
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
