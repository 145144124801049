import React from "react";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

const AreaChartBar = ({ areaChartData, color, isShowDollarSign= true }: any) => {
  return (
    <ResponsiveContainer width="100%" height="100%">
      <AreaChart data={areaChartData.data} margin={{ left: -15, bottom: 0 }}>
        <defs>
          <linearGradient id={"colorPv" + color} x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor={color} stopOpacity={0.8} />
            <stop offset="95%" stopColor={color} stopOpacity={0} />
          </linearGradient>
        </defs>
        <XAxis
          dataKey="name"
          tickLine={false}
          tick={{ dx: 5, dy: 10  }}
          padding={{ right: 35 }}
          fontSize={10}
        />
        <YAxis
          tickLine={false}
          tickFormatter={(value) => (isShowDollarSign ? `$${value}` : value)}
          padding={{ top: 12 }}
          tick={{ dy: -5 }}
          fontSize={10}
        />

        <Tooltip />
        {areaChartData?.graphKeys?.map((item: any) => {
          return (
            <Area
              type="monotone"
              dataKey={item.name}
              stroke={item.strokeColor}
              fillOpacity={1}
              fill={`url(#${"colorPv" + color})`}
            />
          );
        })}
      </AreaChart>
    </ResponsiveContainer>
  );
};

export default AreaChartBar;
