import React from "react";
import { US_TIMEZONE } from "src/constants/timezone";

const TimeZone = ({ value, onChange, onBlur, error }: any) => {
  return (
    <div>
      <select
        id="timeZone"
        name="timeZone"
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        className="text-xs text-[#9B9B9B]  h-[40px] w-full border rounded-lg border-[#F1F1F2] mt-1 px-2 cursor-pointer"
      >
        <option value="" disabled selected>
          Select your time zone
        </option>
        {Object.entries(US_TIMEZONE).map(([key, value]) => (
          <>
            <option key={key} value={value}>
              {key}
            </option>
          </>
        ))}
      </select>
      {error &&
        (error.touched.timeZone && error.errors.timeZone ? (
          <div className="text-orange text-xs mt-2">
            {error.errors.timeZone}
          </div>
        ) : null)}
    </div>
  );
};

export default TimeZone;
