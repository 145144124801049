import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  employees: [],
  filteredEmployees: [],
};

export const employeeSlice = createSlice({
  name: "employee",
  initialState,
  reducers: {
    set_employees: (state, { payload }) => {
      state.employees = payload.employees;
      state.filteredEmployees = payload.employees;
    },
    search_employees: (state, { payload }) => {
      state.filteredEmployees = state.employees.filter((employee: any) => employee.firstName.toLowerCase().includes(payload.toLowerCase()));
    },
    delete_employee_modal: (state :any, { payload }) => {
      state.hasEmployeeId = payload;
    },
  },
});

export const { set_employees, search_employees, delete_employee_modal } = employeeSlice.actions;

export default employeeSlice.reducer;
