import { useState } from "react";
import closeIcon from "../../assests/images/dashboard/Vector 3.png";
import { GRAPHQL_ENDPOINT } from "src/constants/endpoints";
import {
  ADD_ITEM_OPTION,
  ADD_ITEM_OPTION_ELEMENT,
  UPDATE_ITEM_OPTION,
  UPDATE_ITEM_OPTION_ELEMENT,
} from "src/constants/mutations";
import { successToast } from "src/utils/toasts";
import { useAxiosInterceptor } from "src/hooks/useAxiosInterceptor";
import { BtnLoader } from "src/Loader";

export const ManageOptionModal = ({
  setShowOptionModal,
  title,
  formik,
  optionIndex,
  removeOption,
  itemCorrelationId,
}: any) => {
  const { axBe } = useAxiosInterceptor();

  const [UpdateOptionLoading, setUpdateOptionLoading] = useState(false);

  const option = formik.values.item.options[optionIndex];
  const optionError = formik.errors?.item?.options?.[optionIndex];
  const optionTouched = formik?.touched?.item?.options;

  const addElement = () => {
    const newElement = {
      name: "",
      description: "",
      renderSequence: null,
      price: null,
    };
    const tempOptions = [...formik.values.item?.options];
    tempOptions[optionIndex].elements = [...option?.elements, newElement];
    formik.setFieldValue("item.options", tempOptions);
  };

  const onChangeValue = (fieldName: string, value: any, type?: string) => {
    const tempOptions = [...formik.values.item?.options];
    if (type === "checkbox") {
      tempOptions[optionIndex][fieldName] =
        !tempOptions[optionIndex][fieldName];
      formik.setFieldValue("item.options", tempOptions);
    } else {
      tempOptions[optionIndex][fieldName] = value.target.value;
      formik.setFieldValue("item.options", tempOptions);
    }
  };

  const onChangeElementValue = (
    fieldName: string,
    value: any,
    elementIndex: number
  ) => {
    const tempOptions = [...formik.values.item?.options];
    tempOptions[optionIndex].elements[elementIndex][fieldName] =
      value.target.value;
    formik.setFieldValue("item.options", tempOptions);
  };

  const removeElement = (index: number) => {
    const tempOptions = [...formik.values.item?.options];
    tempOptions[optionIndex].elements.splice(index, 1);
    formik.setFieldValue("item.options", tempOptions);
  };

  const removeElements = () => {
    const tempOptions = [...formik.values.item?.options];
    tempOptions[optionIndex].elements = tempOptions[
      optionIndex
    ].elements.filter((element: any) => element?.correlationId);
    formik.setFieldValue("item.options", tempOptions);
  };

  const updateOption = async () => {
    setUpdateOptionLoading(true);
    try {
      const { name, description, renderSequence, isRequired } = option;
      const variables = {
        updateItemOptionInput: {
          itemOptionCorrelationId: option?.correlationId,
          name,
          description,
          renderSequence: Number(renderSequence),
          isRequired: isRequired,
        },
      };
      const { data } = await axBe.post(GRAPHQL_ENDPOINT, {
        query: UPDATE_ITEM_OPTION,
        variables: variables,
      });
      if (data?.errors?.length) {
        throw data?.errors[0];
      }
      successToast("Item Option Updated!");
      setUpdateOptionLoading(false);
    } catch (error) {
      console.error(error);
      setUpdateOptionLoading(false);
    }
  };

  const addOptionWithElements = async () => {
    setUpdateOptionLoading(true);
    try {
      const { name, description, renderSequence, isRequired } =
        option;
      const variables = {
        addItemOptionInput: {
          itemCorrelationId: itemCorrelationId,
          name,
          description,
          renderSequence: Number(renderSequence),
          isRequired: isRequired,
          addItemOptionElementInputList: option?.elements?.map(
            (element: any) => ({
              name: element?.name,
              description: element?.description,
              price: element?.price,
              renderSequence: Number(element.renderSequence),
            })
          ),
        },
      };
      const { data } = await axBe.post(GRAPHQL_ENDPOINT, {
        query: ADD_ITEM_OPTION,
        variables: variables,
      });
      if (data?.errors?.length) {
        throw data?.errors[0];
      }
      const { correlationId, itemOptionElements: newElements } =
        data?.data?.addItemOption || {};
      const tempOptions = [...formik.values.item?.options];

      tempOptions[optionIndex].correlationId = correlationId;
      tempOptions[optionIndex].elements = newElements;
      formik.setFieldValue("item.options", tempOptions);
      successToast("Item Option Added!");
      setUpdateOptionLoading(false);
      setShowOptionModal(false);
    } catch (error) {
      console.error(error);
      setUpdateOptionLoading(false);
    }
  };

  const elementLoaderHandler = (
    property: string,
    value: boolean,
    index: number,
    correlationId = null,
    isDeleted = false
  ) => {
    const tempOptions = [...formik.values.item?.options];
    tempOptions[optionIndex].elements[index][property] = value;
    if (correlationId) {
      tempOptions[optionIndex].elements[index].correlationId = correlationId;
    }
    if (isDeleted) {
      tempOptions[optionIndex].elements.splice(index, 1);
    }
    formik.setFieldValue("item.options", tempOptions);
  };

  const updateElement = async (
    index: number,
    isDeleted = false,
    property = "isLoading"
  ) => {
    elementLoaderHandler(property, true, index);
    try {
      const { name, description, renderSequence, price, correlationId } =
        option?.elements[index];
      const variables = {
        updateItemOptionElementInput: {
          itemOptionElementCorrelationId: correlationId,
          name,
          description,
          renderSequence: Number(renderSequence),
          price,
          isActive: isDeleted ? false : true,
        },
      };
      const { data } = await axBe.post(GRAPHQL_ENDPOINT, {
        query: UPDATE_ITEM_OPTION_ELEMENT,
        variables: variables,
      });
      if (data?.errors?.length) {
        throw data?.errors[0];
      }
      elementLoaderHandler(property, false, index, null, isDeleted);
      successToast(
        isDeleted ? "Option Element Deleted!" : "Option Element Updated!"
      );
    } catch (error) {
      console.error(error);
      elementLoaderHandler(property, false, index);
    }
  };

  const addNewElement = async (index: number) => {
    elementLoaderHandler("isLoading", true, index);
    try {
      const { name, description, renderSequence, price } =
        option?.elements[index];
      const variables = {
        addItemOptionElementInput: {
          itemOptionCorrelationId: option?.correlationId,
          name,
          description,
          renderSequence: Number(renderSequence),
          price,
        },
      };
      const { data } = await axBe.post(GRAPHQL_ENDPOINT, {
        query: ADD_ITEM_OPTION_ELEMENT,
        variables: variables,
      });
      if (data?.errors?.length) {
        throw data?.errors[0];
      }
      successToast("Option Element Added!");
      elementLoaderHandler(
        "isLoading",
        false,
        index,
        data?.data?.addItemOptionElement?.correlationId
      );
    } catch (error) {
      console.error(error);
      elementLoaderHandler("isLoading", false, index);
    }
  };

  return (
    <>
      <div
        className="relative z-10"
        aria-labelledby="modal-title"
        role="dialog"
        aria-modal="true"
      >
        <div className="fixed inset-0 flex bg-black/30 min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-[0px_2px_0px_880px_rgba(37,37,37,0.85)] transition-all sm:my-8">
            <div className="flex items-center justify-center bg-gray-100">
              <div className="bg-white px-8 py-6 rounded-lg w-[788px] max-w-[788px] min-w-10 flex flex-col">
                <div className="top flex justify-between items-center">
                  <span className="text-xl font-semibold text-[#252525]">
                    {title}
                  </span>
                  <img
                    className="w-3 h-3 cursor-pointer"
                    src={closeIcon}
                    alt="Close"
                    onClick={() => {
                      !option?.correlationId && removeOption(optionIndex);
                      if (option?.correlationId) {
                        removeElements();
                      }
                      setShowOptionModal();
                    }}
                  />
                </div>

                <div className="mt-8 flex">
                  <div className="w-full">
                    <span className="text-[#9B9B9B] text-sm">
                      Name <span className="text-[red]">*</span>
                    </span>
                    <input
                      className="text-xs text-[#9B9B9B] h-[40px] w-full border rounded-lg border-[#F1F1F2] mt-1 px-2"
                      type="text"
                      name={`item.options.name`}
                      value={option?.name}
                      onBlur={formik.handleBlur}
                      onChange={(value) => onChangeValue("name", value)}
                    />
                    {optionTouched?.name && optionError?.name ? (
                      <div className="text-orange text-xs mt-2">
                        {optionError.name}
                      </div>
                    ) : null}
                  </div>
                  <div className="w-full ml-7">
                    <span className="text-[#9B9B9B] text-sm">
                      Show sequence
                      <span className="text-[red]">*</span>
                    </span>
                    <input
                      className="text-xs text-[#9B9B9B] h-[40px] w-full border rounded-lg border-[#F1F1F2] mt-1 px-2"
                      type="number"
                      name={`item.options.renderSequence`}
                      value={option?.renderSequence}
                      onBlur={formik.handleBlur}
                      onChange={(value) =>
                        onChangeValue("renderSequence", value)
                      }
                    />
                    {optionTouched?.renderSequence &&
                    optionError?.renderSequence ? (
                      <div className="text-orange text-xs mt-2">
                        {optionError.renderSequence}
                      </div>
                    ) : null}
                  </div>
                  <div className="flex items-center ml-4">
                    <input
                      className="mr-2 h-3.5 w-8 appearance-none rounded-[0.4375rem] bg-[#9B9B9B] before:pointer-events-none before:absolute before:h-3.5 before:w-3.5 before:rounded-full before:bg-transparent before:content-[''] after:absolute after:z-[2] after:-mt-[0.1875rem] after:h-5 after:w-5 after:rounded-full after:border-none after:bg-white after:shadow-[0_0px_3px_0_rgb(0_0_0_/_7%),_0_2px_2px_0_rgb(0_0_0_/_4%)] after:transition-[background-color_0.2s,transform_0.2s] after:content-[''] checked:bg-orange checked:after:absolute checked:after:z-[2] checked:after:-mt-[3px] checked:after:ml-[1.0625rem] checked:after:h-5 checked:after:w-5 checked:after:rounded-full checked:after:border-none checked:after:bg-primary checked:after:shadow-[0_3px_1px_-2px_rgba(0,0,0,0.2),_0_2px_2px_0_rgba(0,0,0,0.14),_0_1px_5px_0_rgba(0,0,0,0.12)] checked:after:transition-[background-color_0.2s,transform_0.2s] checked:after:content-[''] hover:cursor-pointer focus:outline-none focus:ring-0 focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-[3px_-1px_0px_13px_rgba(0,0,0,0.6)] focus:before:transition-[box-shadow_0.2s,transform_0.2s] focus:after:absolute focus:after:z-[1] focus:after:block focus:after:h-5 focus:after:w-5 focus:after:rounded-full focus:after:content-[''] checked:focus:border-primary checked:focus:bg-primary checked:focus:before:ml-[1.0625rem] checked:focus:before:scale-100 checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca] checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] dark:bg-neutral-600 dark:after:bg-neutral-400 dark:checked:bg-primary dark:checked:after:bg-primary dark:focus:before:shadow-[3px_-1px_0px_13px_rgba(255,255,255,0.4)] dark:checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca]"
                      type="checkbox"
                      role="switch"
                      name={`item.options.isRequired`}
                      value={option?.isRequired}
                      checked={option?.isRequired}
                      onBlur={formik.handleBlur}
                      onChange={(value) =>
                        onChangeValue("isRequired", value, "checkbox")
                      }
                    />
                    <p className="mx-2 text-[14px] font-medium">Required</p>
                  </div>
                </div>

                <div className="flex gap-x-4">
                  <div className="w-full">
                    <span className="text-[#9B9B9B] text-sm">Description</span>
                    <textarea
                      className="p-2 text-xs text-[#9B9B9B] h-[70px] w-full border rounded-lg border-[#F1F1F2] mt-1 px-2"
                      name={`item.options.description`}
                      value={option?.description}
                      onBlur={formik.handleBlur}
                      onChange={(value) => onChangeValue("description", value)}
                    />
                    {optionTouched?.description && optionError?.description ? (
                      <div className="text-orange text-xs mt-2">
                        {optionError.description}
                      </div>
                    ) : null}
                  </div>
                </div>

                {option?.correlationId && (
                  <div className="flex justify-end mt-4 flex-1 items-end gap-x-4">
                    {UpdateOptionLoading ? (
                      <div className="mb-2">
                        <BtnLoader />
                      </div>
                    ) : (
                      <button
                        disabled={!option?.name || !option?.renderSequence}
                        onClick={() => updateOption()}
                        className={`${
                          (!option?.name || !option?.renderSequence) &&
                          "opacity-20"
                        } px-8 text-xs flex justify-center items-center gap-2 text-white h-[34px] rounded-md bg-orange hover:bg-[#F5F5F6] hover:text-black duration-500`}
                      >
                        Update Option
                      </button>
                    )}
                  </div>
                )}

                <div className="divider border-[#EBF5FF] border-[1px] mt-2 mb-6"></div>
                <div className="flex item-center w-full mb-4 ">
                  <h1>Option Elements</h1>
                  <div>
                    <button
                      onClick={addElement}
                      className="ml-4 text-xs flex justify-center items-center gap-2 text-white h-[30px] w-[30px] rounded-full bg-orange hover:bg-[#F5F5F6] hover:text-black duration-500"
                    >
                      +
                    </button>
                  </div>
                </div>
                <div className="h-[460px] overflow-scroll p-3">
                  {option?.elements?.map((element: any, index: any) => (
                    <div
                      key={index}
                      className="border border-[#F1F1F2] p-4 mb-6 relative"
                    >
                      {/* ////////////////// */}
                      {option?.elements?.length !== 1 && (
                        <>
                          {element.isLoading ? (
                            <div className="absolute right-[-28px] top-[-10px] flex items-center justify-center">
                              <BtnLoader />
                            </div>
                          ) : (
                            <div
                              className="absolute cursor-pointer right-[-10px] top-[-10px] ml-4 text-xs flex justify-center items-center gap-2 text-white h-[20px] w-[20px] rounded-md bg-orange hover:bg-[#F5F5F6] hover:text-black duration-500"
                              onClick={() =>
                                element?.correlationId
                                  ? updateElement(index, true, "isDeleting")
                                  : removeElement(index)
                              }
                            >
                              -
                            </div>
                          )}
                        </>
                      )}
                      <div className="flex gap-x-4">
                        <div className="w-full">
                          <span className="text-[#9B9B9B] text-sm">
                            Name <span className="text-[red]">*</span>
                          </span>
                          <input
                            className="text-xs text-[#9B9B9B] h-[40px] w-full border rounded-lg border-[#F1F1F2] mt-1 px-2"
                            type="text"
                            name={`item.elements[${index}].name`}
                            value={element?.name}
                            onBlur={formik.handleBlur}
                            onChange={(value) =>
                              onChangeElementValue("name", value, index)
                            }
                          />
                          {formik?.touched?.item?.elements?.[index]?.name &&
                          optionError?.elements?.[index]?.name ? (
                            <div className="text-orange text-xs mt-2">
                              {optionError.elements[index].name}
                            </div>
                          ) : null}
                        </div>
                        <div className="w-full">
                          <span className="text-[#9B9B9B] text-sm">
                            Show sequence
                            <span className="text-[red]">*</span>
                          </span>
                          <input
                            className="text-xs text-[#9B9B9B] h-[40px] w-full border rounded-lg border-[#F1F1F2] mt-1 px-2"
                            type="number"
                            name={`item.elements[${index}].renderSequence`}
                            value={element?.renderSequence}
                            onBlur={formik.handleBlur}
                            onChange={(value) =>
                              onChangeElementValue(
                                "renderSequence",
                                value,
                                index
                              )
                            }
                          />
                          {formik.touched?.item?.elements?.[index]
                            ?.renderSequence &&
                          optionError?.elements?.[index]?.renderSequence ? (
                            <div className="text-orange text-xs mt-2">
                              {optionError.elements[index].renderSequence}
                            </div>
                          ) : null}
                        </div>
                        <div className="w-full">
                          <span className="text-[#9B9B9B] text-sm">
                            Price <span className="text-[red]">*</span>
                          </span>
                          <input
                            className="text-xs text-[#9B9B9B] h-[40px] w-full border rounded-lg border-[#F1F1F2] mt-1 px-2"
                            type="number"
                            name={`item.elements[${index}].price`}
                            value={element?.price}
                            onBlur={formik.handleBlur}
                            onChange={(value) =>
                              onChangeElementValue("price", value, index)
                            }
                          />
                          {formik.touched?.item?.elements?.[index]?.price &&
                          optionError?.elements?.[index]?.price ? (
                            <div className="text-orange text-xs mt-2">
                              {optionError.elements?.[index].price}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className="flex gap-x-4 mt-2">
                        <div className="w-full">
                          <span className="text-[#9B9B9B] text-sm">
                            Description
                          </span>
                          <textarea
                            className="p-2 text-xs text-[#9B9B9B] h-[60px] w-full border rounded-lg border-[#F1F1F2] mt-1 px-2"
                            name={`item.elements[${index}].description`}
                            value={element?.description}
                            onBlur={formik.handleBlur}
                            onChange={(value) =>
                              onChangeElementValue("description", value, index)
                            }
                          />
                          {formik.touched?.item?.elements?.[index]
                            ?.description &&
                          optionError?.elements?.[index]?.description ? (
                            <div className="text-orange text-xs mt-2">
                              {optionError.elements[index].description}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      {option?.correlationId && (
                        <div className="flex justify-end mt-4 flex-1 items-end gap-x-4">
                          {element.isLoading ? (
                            <div className="mb-2">
                              <BtnLoader />
                            </div>
                          ) : (
                            <button
                              disabled={
                                !element.name ||
                                !element.renderSequence ||
                                !element.price
                              }
                              onClick={() =>
                                !element?.correlationId
                                  ? addNewElement(index)
                                  : updateElement(index)
                              }
                              className={`${
                                (!element.name ||
                                  !element.renderSequence ||
                                  !element.price) &&
                                "opacity-20"
                              } px-8 text-xs flex justify-center items-center gap-2 text-white h-[34px] rounded-md bg-orange hover:bg-[#F5F5F6] hover:text-black duration-500`}
                            >
                              {!element?.correlationId ? "Add" : "Update"}{" "}
                              Element
                            </button>
                          )}
                        </div>
                      )}
                    </div>
                  ))}
                  {!option?.correlationId && (
                    <div className="flex justify-end mt-4 flex-1 items-end gap-x-4">
                      <button
                        disabled={!formik.isValid || !formik.dirty}
                        onClick={() =>
                          itemCorrelationId
                            ? addOptionWithElements()
                            : setShowOptionModal(false)
                        }
                        className={`${
                          (!formik.isValid || !formik.dirty) && "opacity-20"
                        } px-8 text-xs flex justify-center items-center gap-2 text-white h-[34px] rounded-md bg-orange hover:bg-[#F5F5F6] hover:text-black duration-500`}
                      >
                        Done
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
