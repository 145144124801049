import AlertICon from "../../assests/images/dashboard/Vector 4.png";
import { useDispatch } from "react-redux";
import {
  delete_restaurant_modal,

} from "src/store/features/restuarant-slice";


const DeleteRestaurant = ({ restaurantId }: any) => {

  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(delete_restaurant_modal(null));
  };

  return (
    <div
      className="relative z-[9999]"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div
        className="fixed inset-0 bg-[#000000] bg-opacity-60 transition-opacity"
        aria-hidden="true"
      ></div>

      <div className="fixed inset-0 z-[9999] overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <div className="w-[381px] relative transform overflow-hidden rounded-2xl bg-white text-left shadow-xl transition-all sm:my-8">
            <div className="flex items-center justify-center bg-gray-100 flex-col">
              <div className="bg-white flex flex-col items-center px-8 py-4">
                <div>
                  <img
                    className="h-[82px] w-[82px] mt-2"
                    src={AlertICon}
                    alt="Delete Icon"
                  />
                </div>
                <div className="mt-4">
                  <p className="text-xs text-center text-[#666666] mt-1">
                    Please go to restaurant settings to cancel your
                    subscription, the restaurant will be deleted after
                    successful subscription cancellation
                  </p>
                </div>
                <div className="flex gap-3 mt-4">
                  <div className="flex justify-center my-4">
                    <button
                      className="w-[100%] px-8 text-xs flex justify-center items-center  text-white h-[34px] rounded-xl  bg-orange"
                      onClick={handleClose}
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeleteRestaurant;
