import React, { useEffect, useRef, useState } from "react";
import PlusIcon from "../../assests/images/dashboard/Vector 2.png";
import MenuCard from "src/components/MenuCard/MenuCard";
import SortIcon from "../../assests/images/dashboard/swap.png";
import SortbyDropdown from "./SortbyDropdown";
import { useDispatch, useSelector } from "react-redux";
import {
  delete_category_modal,
  search_categories,
} from "src/store/features/category-slice";
import { ManageMenuModal } from "src/components/ManageMenuModal/ManageMenuModal";
import { BtnLoader } from "src/commonLoader/index";

const Menu = () => {
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [editCategoryModal, setEditCategoryModal] = useState<any>(null);
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<any>(null);
  const dispatch = useDispatch();

  const { filteredCategories } = useSelector((state: any) => state.category);
  const { isCategoryLoading } = useSelector((state: any) => state.category);

  const handleCategoryClick = (categoryId: any) => {
    dispatch(delete_category_modal(categoryId));
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  

  const searchCategory = (value: string) => {
    dispatch(search_categories(value));
  };

  return (
    <div>
      <div className="flex justify-between items-center">
        <div>
          <form className="w-[280px]">
            <div className="relative">
              <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                <svg
                  className="w-4 h-4 text-gray-500 dark:text-gray-400"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 20 20"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                  />
                </svg>
              </div>
              <input
                type="search"
                id="default-search"
                className="block border-[#F1F1F2] border w-full p-3 ps-10 text-sm text-black rounded-lg "
                placeholder="Search Category..."
                onChange={(e) => searchCategory(e.target.value)}
              />
            </div>
          </form>
        </div>
        <div className="relative inline-block text-left" ref={dropdownRef}>
          <div className="flex justify-center my-4">
            <button
              className={`flex items-center justify-evenly duration-500 w-[118px] h-[46px] rounded-xl border-solid border border-[#FD7657] 
          ${
            isOpen
              ? "bg-[#FD76571A] text-[#FD7657]"
              : "bg-transparent text-[#FD7657] hover:bg-white hover:text-orange"
          }`}
              onClick={toggleDropdown}
            >
              <img
                className="w-[24px] h-[24px]"
                src={SortIcon}
                alt="Sort Icon"
              />
              <span>Sort by</span>
            </button>
          </div>
          {isOpen && <SortbyDropdown />}
        </div>
      </div>
      <div className="w-full flex gap-3">
        <div className="w-[90px]">
          <div
            className="h-[766px] bg-white rounded-xl flex justify-center items-center cursor-pointer"
            onClick={() => setShowCreateModal(!showCreateModal)}
          >
            <img className="w-[17px] h-[17px] " src={PlusIcon} alt="" />
          </div>
        </div>
        <div className="w-full">
          { isCategoryLoading ? (
            <div className="flex items-center justify-center w-full h-full">
              <BtnLoader />
            </div>
          ) 
          : filteredCategories && filteredCategories.length > 0 ? (
            <div className="flex flex-wrap">
              {filteredCategories.map((category: any, index: any) => (
                <MenuCard
                  key={index}
                  category={category}
                  handleCategoryClick={handleCategoryClick}
                  setEditCategoryModal={setEditCategoryModal}
                />
              ))}
            </div>
          ) : (
            <div className="flex items-center justify-center w-full h-full">
              <p>Click the button on the left to create some Categories!</p>
            </div>
          )}
        </div>
      </div>
      {showCreateModal || !!editCategoryModal ? (
        <ManageMenuModal
          title={!!editCategoryModal ? "Edit Category" : "Create Category"}
          setShowCreateModal={() =>
            !!editCategoryModal
              ? setEditCategoryModal(null)
              : setShowCreateModal(!showCreateModal)
          }
          editCategoryModal={editCategoryModal}
        />
      ) : (
        <></>
      )}
    </div>
  );
};

export default Menu;
