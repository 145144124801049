import { useEffect, useMemo, useState } from "react";
import { S3_BASE_URL } from "src/constants/endpoints";
import CrossIcon from "../../assests/images/dashboard/Vector 3.png";
import DeleteIcon from "../../assests/images/EmployeeOrder/Vector.png";
import RestuarantImg from "../../assests/images/dashboard/default-thumbnail (1).jpg";

const OrderModal = ({ data, onClose, handleSelection }: any) => {
  const [optionCount, setOptionCount] = useState(0);
  const [tempData, setTempData] = useState<any>({});
  const [count, setCount] = useState(data.count > 0 ? data.count : 1);

  useEffect(() => {
    setTempData(data);
  }, []);

  const handleCount = () => {
    const temp = { ...tempData, count };
    handleSelection(temp, (+data?.price + optionCount) * count);
  };

  const handleElementsCount = ({ parentIndex, childIndex, count }: any) => {
    const temp = { ...tempData };
    const itemOption =
      temp?.itemOptions?.[parentIndex]?.itemOptionElements?.[childIndex];
    temp.totalElementPrice =
      (temp?.totalElementPrice || 0) + +itemOption.price * count;
    if (itemOption) {
      if (itemOption.hasOwnProperty("count")) {
        itemOption.count = count;
      } else {
        itemOption.count = 1;
      }
    }
    setTempData(temp);
  };

  const handleOption = (parentIndex: number, childIndex: number) => {
    const temp = {
      ...tempData,
      itemOptions: tempData?.itemOptions?.map(
        (option: any, optionIndex: any) => {
          if (optionIndex === parentIndex) {
            return {
              ...option,
              itemOptionElements: option.itemOptionElements.map(
                (item: any) => ({
                  ...item,
                  isSelected: false,
                  count: 0,
                })
              ),
            };
          }
          return option;
        }
      ),
    };
    temp.itemOptions[parentIndex].isOptionSelected = true;
    const itemOption =
      temp?.itemOptions?.[parentIndex]?.itemOptionElements?.[childIndex];

    if (itemOption) {
      itemOption.isSelected = !itemOption.isSelected;
      itemOption.count = 1;
      temp.totalElementPrice =
        (temp?.totalElementPrice || 0) + +itemOption.price * count;
      setOptionCount(
        !itemOption.isSelected
          ? optionCount - +itemOption.price
          : optionCount + +itemOption.price
      );
    }
    setTempData(temp);
  };

  const resetOption = () => {
    setTempData((prevState: any) => ({
      ...prevState,
      itemOptions: prevState.itemOptions?.map((option: any) => ({
        ...option,
        itemOptionElements: option.itemOptionElements.map((item: any) => ({
          ...item,
          isSelected: false,
          count: 0,
        })),
      })),
    }));
  };

  const isAddToCartEnabled = useMemo(() => {
    if (tempData?.itemOptions?.length === 0) {
      return true;
    } else {
      const requiredOptions = tempData.itemOptions?.filter(
        (option: any) => option?.isRequired
      );
      if (requiredOptions?.length > 0) {
        return requiredOptions.every((option: any) =>
          option?.itemOptionElements?.some((element: any) => element.isSelected)
        );
      } else {
        return true;
      }
    }
  }, [tempData]);

  return (
    <div
      className="relative z-[9999]"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div
        className="fixed inset-0 bg-[#000000] bg-opacity-60 transition-opacity"
        aria-hidden="true"
      ></div>
      <div className="fixed inset-0 z-[9999] overflow-y-auto ">
        <div className="flex relative min-h-full justify-center p-4 sm:items-center sm:p-0">
          <div
            className={`${
              tempData?.itemOptions?.length > 0 && "min-w-[1000px] h-[600px]"
            }    relative transform overflow-hidden rounded-2xl bg-white shadow-xl transition-all sm:my-8 min-h-[510px]`}
          >
            <img
              className="w-[12px] h-[12px] absolute right-6 top-7 cursor-pointer"
              src={CrossIcon}
              alt=""
              onClick={onClose}
            />
            <h1 className="absolute left-5 top-4 text-lg font-medium">
              Details
            </h1>
            <div className="flex gap-x-4 p-4 mt-4">
              <div
                className={`${
                  tempData?.itemOptions?.length > 0 && "w-[350px]"
                } w-[400px] border border-[#F1F1F2] rounded-xl p-4 mt-8`}
              >
                <div className="flex justify-center items-center ">
                  <img
                    src={
                      tempData?.bucketKeyName
                        ? tempData?.bucketKeyName.includes("blob")
                          ? tempData.bucketKeyName
                          : S3_BASE_URL + tempData.bucketKeyName
                        : RestuarantImg
                    }
                    className="rounded-md object-cover h-[200px] w-[400px]"
                    alt=""
                  />
                </div>
                <div className="flex justify-between items-center w-full mt-4">
                  <h1 className="text-balance font-medium">
                    {tempData?.englishName}{" "}
                    {tempData?.nonEnglishName &&
                      `(${tempData?.nonEnglishName})`}
                  </h1>
                  <h1 className="text-balance font-medium">${data?.price}</h1>
                </div>
                  <p className="text-xs my-1">{tempData?.englishDescription}</p>
                <div className="flex items-center flex-wrap mt-2">
                  {tempData?.itemOptions &&
                  tempData?.itemOptions?.length > 0 ? (
                    tempData?.itemOptions
                      ?.slice(0, 4)
                      ?.map((option: any, index: any) => (
                        <>
                          <h1
                            className="text-xs text-[#A3A3A3] mr-1"
                            key={index}
                          >
                            {option?.name}
                          </h1>
                          {index <
                            tempData?.itemOptions?.slice(0, 4)?.length - 1 && (
                            <span className="w-[4px] h-[4px] bg-[#D9D9D9] rounded-full mr-1"></span>
                          )}
                        </>
                      ))
                  ) : (
                    <></>
                  )}
                  {tempData?.itemOptions?.length > 5 && (
                    <div className="mb-1">
                      <span className="text-[#252525] text-[10px] px-2 py-1 bg-[#f3f3f4] rounded-xl font-medium">
                        +{tempData?.itemOptions?.length - 4}
                      </span>
                    </div>
                  )}
                </div>
                <div
                  className={`${
                    data?.itemOptions?.length > 0
                      ? "bottom-20 w-[29%]"
                      : " w-[85%]"
                  } 
                    absolute bottom-6 flex gap-x-4 items-center`}
                >
                  <div className="flex w-[110px] gap-x-2 justify-between items-center mt-4">
                    <button
                      className="bg-[#f6f6f6] px-3 rounded-md"
                      disabled={count === 1}
                      onClick={(e) => {
                        e.stopPropagation();
                        setCount(count - 1);
                      }}
                    >
                      <h1 className="">-</h1>
                    </button>
                    <div>
                      <h1 className="text-sm">{count}</h1>
                    </div>
                    <button
                      className="bg-[#f6f6f6] px-3 rounded-md"
                      onClick={(e) => {
                        e.stopPropagation();
                        setCount(count + 1);
                      }}
                    >
                      <h1>+</h1>
                    </button>
                  </div>

                  <div
                    className={`flex gap-x-2 justify-center w-full mt-4 ${
                      !isAddToCartEnabled && "opacity-20"
                    }`}
                  >
                    <button
                      disabled={!isAddToCartEnabled}
                      className="w-full text-sm text-white h-[40px] rounded-lg bg-[#FD7657]"
                      onClick={handleCount}
                    >
                      Add to Cart
                    </button>
                  </div>
                </div>
              </div>
              <div
                className={`${
                  tempData?.itemOptions?.length > 0 && "h-[465px] w-[600px]"
                } mt-8 overflow-y-scroll`}
              >
                {tempData?.itemOptions?.map(
                  (option: any, parentIndex: number) => {
                    return (
                      <div className="mt-4">
                        <h1 className="text-base font-medium">
                          {option?.name}{" "}
                          {option?.isRequired && (
                            <span className="text-[#A3A3A3] text-xs font-light">
                              (Required)
                            </span>
                          )}
                        </h1>
                        <div className="divider border-[#F1F1F2] border-[1px] mt-1  w-full"></div>

                        <div className="flex gap-2 flex-wrap mt-2">
                          {option?.itemOptionElements?.map(
                            (element: any, childIndex: number) => {
                              return (
                                <div
                                  className={`${
                                    element?.isSelected && "border-orange"
                                  } w-[49%] h-[60px] flex items-center justify-between border-[#e5e7eb] border rounded-xl px-2`}
                                >
                                  <h1
                                    className={`${
                                      element?.isSelected
                                        ? "text-orange"
                                        : "text-[#666666]"
                                    } text-sm`}
                                  >
                                    {element?.name}
                                  </h1>
                                  <h1
                                    className={`${
                                      element?.isSelected
                                        ? "text-orange"
                                        : "text-[#666666]"
                                    } text-sm`}
                                  >
                                    +$
                                    {(parseFloat(element?.price) || 0)
                                      .toFixed(2)
                                      .replace(/^0+/, "")}
                                  </h1>
                                  {element?.isSelected ? (
                                    <div className="flex gap-x-2 justify-between items-center">
                                      <button
                                        className="bg-[#f6f6f6] px-3 rounded-md"
                                        disabled={element?.count === 1}
                                        onClick={() =>
                                          handleElementsCount({
                                            parentIndex,
                                            childIndex,
                                            count: element?.count
                                              ? element?.count - 1
                                              : 1,
                                          })
                                        }
                                      >
                                        <h1 className="">-</h1>
                                      </button>
                                      <div>
                                        <h1 className="text-sm">
                                          {element?.count}
                                        </h1>
                                      </div>
                                      <button
                                        className="bg-[#f6f6f6] px-3 rounded-md"
                                        onClick={() =>
                                          handleElementsCount({
                                            parentIndex,
                                            childIndex,
                                            count: element?.count
                                              ? element?.count + 1
                                              : 1,
                                          })
                                        }
                                      >
                                        <h1>+</h1>
                                      </button>
                                    </div>
                                  ) : (
                                    <div
                                      onClick={() =>
                                        handleOption(parentIndex, childIndex)
                                      }
                                      className={`cursor-pointer flex items-center justify-center rounded-full border-[#e5e7eb] h-[22px] w-[22px] border ${
                                        element?.isSelected &&
                                        " border border-[#FD7657] "
                                      }`}
                                    >
                                      {element?.isSelected ? (
                                        <div className="h-[14px] w-[14px] rounded-full bg-[#FD7657]"></div>
                                      ) : (
                                        <></>
                                      )}
                                    </div>
                                  )}
                                </div>
                              );
                            }
                          )}
                        </div>
                      </div>
                    );
                  }
                )}
              </div>
              {tempData?.itemOptions?.length > 0 && (
                <div className="absolute bottom-0 right-6  flex gap-x-2 justify-center my-4">
                  <button
                    onClick={resetOption}
                    className="flex items-center justify-evenly w-[138px] text-sm text-[#FD7657] h-[45px] rounded-xl bg-[#FD76571A]"
                  >
                    <img src={DeleteIcon} alt="" />
                    Clear Options
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderModal;
