import React, { useRef } from "react";
import SharedIcon from "../../assests/images/dashboard/share.png";
import Print from "../../assests/images/dashboard/print.png";
import CrossIcon from "../../assests/images/dashboard/Vector.png";
import { QRCode } from "react-qrcode-logo";
import Logo from "../common/Logo";
import { useReactToPrint } from "react-to-print";
import { useAuth } from "src/contexts/auth-provider";

const QrCodeModal = React.forwardRef(
  (props: any, ref: React.Ref<any>) => {
    const { setQrCodeModal, restaurantId } = props;  // Access props here
    const contentRef = useRef<HTMLDivElement>(null);
    const handlePrint = useReactToPrint({ contentRef });
    const { hasRestaurant } = useAuth();

    return (
      <div
        className="relative z-10"
        aria-labelledby="modal-title"
        role="dialog"
        aria-modal="true"
      >
        <div
          className="fixed inset-0 bg-[#000000] bg-opacity-60 transition-opacity"
          aria-hidden="true"
        ></div>

        <div className="fixed inset-0 z-10 overflow-y-auto ">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <div
              ref={contentRef}
              className="modal-print w-[381px] relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8"
            >
              <div className="flex items-center justify-center bg-gray-100 flex-col">
                <div className=" p-8 rounded-lg shadow w-full max-w-md bg-orange h-[185px]">
                  <div className="text-center relative">
                    <h1 className="text-sm text-[#FEFEFF85]">QR CODE</h1>
                    <h1 className="text-2xl text-white">
                      {hasRestaurant.name}
                    </h1>
                  </div>
                </div>
                <div className="h-[12px] w-[12px] absolute top-4 right-4 cursor-pointer">
                  <img src={CrossIcon} alt="" onClick={setQrCodeModal} />
                </div>
                <div className="bg-white rounded-xl absolute top-[100px]">
                  <div className="relative">
                    <QRCode value={"eatsbits.com/restaurant/" + restaurantId} />
                    <div className="absolute top-[50%] left-[50%] bg-white -translate-x-1/2 -translate-y-1/2">
                      <Logo />
                    </div>
                  </div>
                </div>

                <div className="flex flex-col justify-center items-center mt-28">
                  <h1 className="text-xl">Qr Code</h1>
                  <div className="p-6">
                    <p className="text-[#666666] text-sm text-center">
                      Utilize a barcode to give your customers quick and
                      convenient access to your digital menu.
                    </p>
                  </div>

                  <div className="print-exclude">
                    <div className="flex gap-3">
                      <div className="flex justify-center my-4">
                        <button
                          onClick={() => handlePrint()}
                          className="w-[100%] px-6 text-sm flex justify-center items-center gap-2 text-orange h-[38px] rounded-xl bg-[#FD76571A] hover:bg-orange hover:text-white duration-500"
                        >
                          <img
                            className="h-[15px] w-[15px]"
                            src={Print}
                            alt=""
                          />
                          Print
                        </button>
                      </div>

                      <div className="flex justify-center my-4">
                        <button className="w-[100%] px-6 text-sm flex justify-center items-center gap-2  text-[#FD7657] h-[38px] rounded-xl bg-[#FD76571A] hover:bg-orange hover:text-white duration-500">
                          <img
                            className="h-[15px] w-[15px]"
                            src={SharedIcon}
                            alt=""
                          />
                          Share
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
);

export default QrCodeModal;
